import { reportClient, tagsClient } from 'api-clients';
import getApplicationsOwnersByAppIdsV2 from './get-app-owners-by-appIds-v2/get-app-owners-by-appIds';
import getApplicationsOwnersByAppIds from './get-app-owners-by-appIds/get-app-owners-by-appIds';
import getApplicationsOwnersV2 from './get-applications-owners-v2/get-applications-owners';
import getApplicationsOwners from './get-applications-owners/get-applications-owners';
import setApplicationsOwnersByAppIdsV2 from './set-applications-owners-by-role-v2/set-applications-owners-by-role';
import setApplicationsOwnersByRole from './set-applications-owners-by-role/set-applications-owners-by-role';

const applicationOwnerService = {
  getApplicationsOwners: getApplicationsOwners(reportClient),
  getApplicationsOwnersByAppIds: getApplicationsOwnersByAppIds(reportClient),
  setApplicationsOwnersByRole: setApplicationsOwnersByRole(reportClient),
  // fetch from tags
  getApplicationsOwnersV2: getApplicationsOwnersV2(tagsClient),
  getApplicationsOwnersByAppIdsV2: getApplicationsOwnersByAppIdsV2(tagsClient),
  setApplicationsOwnersByRoleV2: setApplicationsOwnersByAppIdsV2(tagsClient),
};

export default applicationOwnerService;
