import { AppPages } from 'app-navigator';
import { Nullable } from 'ox-common-types';
import { FC, useCallback, useState } from 'react';
import { Panel } from 'reactflow';
import VulnerabilityGraph from '../../../../IssueItem/ScaVulnerabilitiesTable/VulnerabilityGraph';
import VulnerabilitySwitcher from '../../../../IssueItem/ScaVulnerabilitiesTable/VulnerabilitySwitcher';
import {
  DependencyEdge,
  DependencyNode,
} from '../../../../../types/issues-types';
import { MenuItem, Select, useTheme } from '@mui/material';

const ScaDependencyGraph: FC<
  React.PropsWithChildren<ScaDependencyGraphProps>
> = ({
  dependencyGraph,
  appId,
  selectedTrigger,
  onChangeTriggerPackage,
  triggerPackages,
}) => {
  const theme = useTheme();
  const [showAll, setShowAll] = useState(false);
  const toggleAll = useCallback(() => {
    setShowAll(prev => !prev);
  }, []);
  const onNodeClick = useCallback(
    (_, node) => {
      const url = encodeURI(`${AppPages.Sbom}?sbomId=${appId}@@${node.id}`);
      window.open(url, '_blank');
    },
    [appId],
  );

  return (
    <div key='Dependency Graph' style={{ height: '100%', width: '100%' }}>
      {dependencyGraph?.nodes?.length ? (
        <VulnerabilityGraph
          onNodeClick={onNodeClick}
          nodeType={showAll ? 'tb' : 'lr'}
          nodes={showAll ? dependencyGraph.allNodes : dependencyGraph.nodes}
          edges={showAll ? dependencyGraph.allEdges : dependencyGraph.edges}>
          <Panel
            position='top-left'
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(4),
            }}>
            <VulnerabilitySwitcher checked={showAll} handleChange={toggleAll} />

            <Select
              variant='standard'
              value={selectedTrigger}
              onChange={e => onChangeTriggerPackage(e.target.value)}
              sx={{ width: 400 }}>
              {triggerPackages?.map(item => {
                const value = `${item.triggerPackage} at ${item.fileName}`;
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Panel>
        </VulnerabilityGraph>
      ) : null}
    </div>
  );
};

export interface ScaDependencyGraphProps {
  dependencyGraph: Nullable<{
    nodes: DependencyNode[];
    edges: DependencyEdge[];
    allNodes: DependencyNode[];
    allEdges: DependencyEdge[];
  }>;
  selectedTrigger: string;
  triggerPackages: Nullable<Trigger[]>;
  onChangeTriggerPackage: (triggerPackage: string) => void;
  appId: string;
}

type Trigger = {
  triggerPackage: string;
  fileName: string;
};
export default ScaDependencyGraph;
