import create from 'zustand';
import {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnNodesChange,
  OnEdgesChange,
  ReactFlowInstance,
} from 'reactflow';
import { Nullable } from 'ox-common-types';
import { WorkflowNode, WorkflowNodeType } from '../../policy-workflow-types';

type RFState = {
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  setInstance: (instance: ReactFlowInstance) => void;
  nodes: Node<WorkflowNode>[];
  edges: Edge[];
  selectedEdges: string[];
  selectedNodes: string[];
  addDialogIsOpen: boolean;
  deleteDialogIsOpen: boolean;
  nodeCandidate: Nullable<Node<WorkflowNode>>;
  dragInProgress: boolean;
  selectedTypes: WorkflowNodeType[];
  editDialogIsOpen: boolean;
  sourceCandidate: Nullable<Node<WorkflowNode>>;
  draggedType: Nullable<WorkflowNodeType>;

  loaded: boolean;
  workflowValid: boolean;
  reactFlowInstance;
  counts: {
    [WorkflowNodeType.Action]: number;
    [WorkflowNodeType.Condition]: number;
    [WorkflowNodeType.Trigger]: number;
  };
  undoActions: Function[];
  loading: boolean;
  pipelineSettingsModal: boolean;
  draggedObject: Nullable<{ name: string; type: WorkflowNodeType }>;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create<RFState>((set, get) => ({
  undoActions: [],
  draggedObject: null,
  reactFlowInstance: null,
  nodes: [],
  edges: [],
  counts: {
    [WorkflowNodeType.Action]: 0,
    [WorkflowNodeType.Condition]: 0,
    [WorkflowNodeType.Trigger]: 0,
  },
  selectedEdges: [],
  sourceCandidate: null,
  nodeCandidate: null,
  selectedNodes: [],
  addDialogIsOpen: false,
  editDialogIsOpen: false,
  deleteDialogIsOpen: false,
  dragInProgress: false,
  selectedTypes: [],
  triggers: [],
  conditions: [],
  actions: [],
  draggedType: null,
  loading: false,
  loaded: false,
  workflowValid: true,
  setInstance: reactFlowInstance => {
    set({ reactFlowInstance });
  },
  setNodes: nodes => {
    set({ nodes });
  },
  setEdges: edges => {
    set({ edges });
  },
  onNodesChange: (changes: NodeChange[]) => {
    // set({
    //   nodes: applyNodeChanges(changes, get().nodes),
    // });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    // set({
    //   edges: applyEdgeChanges(changes, get().edges),
    // });
  },
  pipelineSettingsModal: false,
}));

export default useStore;
