import { Nullable } from 'ox-common-types';
import { ApplicationContainer } from '../types';
import ContainersStore from './containers-store';

export const openAddDialog = () => {
  ContainersStore.addDialogIsOpen = true;
};

export const closeAddDialog = () => {
  ContainersStore.addDialogIsOpen = false;
};

export const closeRemoveDialog = () => {
  ContainersStore.removeDialogIsOpen = false;
};

export const openRemoveDialog = () => {
  ContainersStore.removeDialogIsOpen = true;
};

export const openEditDialog = () => {
  ContainersStore.editDialogIsOpen = true;
};
export const closeEditDialog = () => {
  ContainersStore.editDialogIsOpen = false;
};

export const setSelectedContainer = (
  container: Nullable<ApplicationContainer>,
) => {
  ContainersStore.selectedContainer = container;
};

export const setSelectedDockerfile = (dockerfile: string) => {
  ContainersStore.dockerPath = dockerfile;
};

export const setContainers = (containers: ApplicationContainer[]) => {
  ContainersStore.containers = containers;
};

export const setPackageContainers = (containers: ApplicationContainer[]) => {
  ContainersStore.packageContainers = containers;
};

export const setAvailableContainers = (
  availableContainers: ApplicationContainer[],
) => {
  ContainersStore.availableContainers = availableContainers;
};

export const setAvailableDockerfiles = (files: { path: string }[]) => {
  ContainersStore.availableDockerfiles = files?.map(f => f.path) || [];
};

export const setAppId = (appId: string) => {
  ContainersStore.appId = appId;
};

export const setAppName = (appName: string) => {
  ContainersStore.appName = appName;
};
