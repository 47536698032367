import { FilterData, FilterItems } from 'ox-filter-utils';
import { ScaVulnerability } from '../../issues/common/types/issues-types';
import { SupplyChainItem } from '../../supply-chain/types/supplychain-types';
import { Misconfiguration } from '../services/get-artifact-missconfigurations/get-artifact-missconfigurations';
import ArtifactsStore from '../stores/artifacts-store';
import {
  ArtifactInfo, ArtifactsType,
  ArtifactTopFilter,
  ArtifactTopFiltersTypes,
} from '../types/artifacts-type';

export const setArtifacts = (
  artifacts: ArtifactInfo[],
  isLoadMoreItems: boolean,
) => {
  if (isLoadMoreItems) {
    ArtifactsStore.artifacts?.push(...artifacts);
  } else {
    ArtifactsStore.artifacts = artifacts;
  }
};

export const setArtifactsIds = (artifactsIds: string[]) => {
  ArtifactsStore.artifactsIds = artifactsIds;
};

export const setArtifactsTotalNumber = (totalArtifacts: number) => {
  ArtifactsStore.total = totalArtifacts;
};

export const setHashSearch = (search: string) => {
  ArtifactsStore.hashSearch = search;
};

export const setIsOpenArtifactDrawer = (isDrawerOpen: boolean) => {
  ArtifactsStore.isArtifactDrawerOpen = isDrawerOpen;
};

export const setSelectedArtifactId = (artifactId: string) => {
  ArtifactsStore.selectedArtifactId = artifactId;
};

export const setArtifactsOffset = (offset: number) => {
  ArtifactsStore.offset = offset;
};

export const setSelectedArtifact = (artifact: ArtifactInfo | null) => {
  ArtifactsStore.selectedArtifact = artifact;
};

export const setLoadingSingleArtifact = (loading: boolean) => {
  ArtifactsStore.loadingArtifact = loading;
};

export const setTotalFilteredArtifacts = (totalFiltered: number) => {
  ArtifactsStore.totalFiltered = totalFiltered;
};

export const toggleFiltersPanel = () => {
  ArtifactsStore.filtersOpen = !ArtifactsStore.filtersOpen;
};

export const setArtifactsTopFiltersList = (
  artifactsTopFiltersList: ArtifactTopFilter[],
) => {
  ArtifactsStore.artifactsTopFiltersList = artifactsTopFiltersList;
};

export const clearArtifactsStore = () => {
  ArtifactsStore.filterArtifactsBy = {};
  ArtifactsStore.artifacts = [];
  ArtifactsStore.selectedArtifact = null;
  ArtifactsStore.total = 0;
  ArtifactsStore.topFiltersArtifactsBy = [];
  ArtifactsStore.hashSearch = '';
};

export const filtersExists = (filter: ArtifactTopFiltersTypes) => {
  return ArtifactsStore.topFiltersArtifactsBy.includes(filter);
};

export const addFilter = (filter: ArtifactTopFiltersTypes) => {
  ArtifactsStore.topFiltersArtifactsBy.push(filter);
};

export const setTopFiltersArtifactsBy = (
  filtersList: ArtifactTopFiltersTypes[],
) => {
  ArtifactsStore.topFiltersArtifactsBy = filtersList;
};

export const removeFilter = (filter: ArtifactTopFiltersTypes) => {
  ArtifactsStore.topFiltersArtifactsBy =
    ArtifactsStore.topFiltersArtifactsBy.filter(f => f !== filter);
};

export const updateFilters = (filter: ArtifactTopFiltersTypes) => {
  filtersExists(filter) ? removeFilter(filter) : addFilter(filter);
  return ArtifactsStore.topFiltersArtifactsBy;
};

export const setSupplyChainItems = (supplyChainItems: SupplyChainItem[]) => {
  if (ArtifactsStore.selectedArtifact) {
    ArtifactsStore.selectedArtifact.supplyChainItems = supplyChainItems;
  }
};

export const setSearchValues = (type: string, searchValue: string) => {
  ArtifactsStore.searchValues[type] = searchValue;
};

export const setDepIssuesVulnerabilities = (
  vulnerabilities: ScaVulnerability[],
) => {
  ArtifactsStore.vulnDepIssues = vulnerabilities;
};
export const setDepBaseIssuesVulnerabilities = (
  vulnerabilities: ScaVulnerability[],
) => {
  ArtifactsStore.vulnDepBaseIssues = vulnerabilities;
};
export const setDepInstructionIssuesVulnerabilities = (
  vulnerabilities: ScaVulnerability[],
) => {
  ArtifactsStore.vulnDepInstructionIssues = vulnerabilities;
};

export const setSecretIssues = (issues: Misconfiguration[]) => {
  ArtifactsStore.secretIssues = issues;
};

export const setConfIssues = (issues: Misconfiguration[]) => {
  ArtifactsStore.confIssues = issues;
};

export const setArtifactsTabValue = (artifactsTabValue: ArtifactsType) => {
  ArtifactsStore.artifactsTabValue = artifactsTabValue
}

export const setStoreArtifactsLazyFilters = (
  resultsFilters: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = resultsFilters.find(
      item => item.type === key,
    );
    if (
      ArtifactsStore.lazyFilters &&
      ArtifactsStore.lazyFilters[key] &&
      selectedResultFilterItem
    ) {
      ArtifactsStore.lazyFilters[key].items = selectedResultFilterItem.items;
    }
  });
};
