export enum OxIcons {
  gear = 'gear',
  moon = 'moon',
  brightness = 'brightness',
  circleQuestion = 'circleQuestion',
  messageSmile = 'messageSmile',
  users = 'users',
  chevronDown = 'chevronDown',
  chevronsUp = 'chevronsUp',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  ellipsisVertical = 'ellipsisVertical',
  circleUser = 'circleUser',
  magnifyingGlass = 'magnifyingGlass',
  arrowDownToLine = 'arrowDownToLine',
  fire = 'fire',
}

export type OxIconKey = keyof typeof OxIcons;
