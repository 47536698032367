import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import slackService from '../services';
import {
  setChannels,
  setDisableTypingOnFirstRequest,
  setFallbackChannels,
  setIsLoadingSlackChannels,
  setIsLoadingSlackChannelsFallback,
  setLoadingSubmit,
  setPublicChannelsWorkflows,
} from '../store-actions/slack-store-actions';
import SlackStore from '../store/slack-store';
import { Channel, GetSlackUsersAndChannelsInput } from '../types/slack-types';
import { debounce } from 'lodash-es';
import { Nullable, scanTypes } from 'ox-common-types';
import { IssuePages } from '../../../../../apps/web/src/issues/common/types/issues-types';

export const SLACK_API_GET_CHANNELS_LIMIT: number = 200;

export const getPublicChannels = async (
  input: GetSlackUsersAndChannelsInput,
) => {
  try {
    const results = await slackService.getUsersAndPublicChannels.execute(input);
    if (
      !results?.getSlackUsersAndChannelList ||
      isNull(results) ||
      isNull(results.getSlackUsersAndChannelList.channels)
    ) {
      throw new Error('Invalid response');
    }
    if (results.getSlackUsersAndChannelList.error) {
      openSnackbar(results.getSlackUsersAndChannelList.error, {
        variant: 'info',
      });
      throw new Error(results.getSlackUsersAndChannelList.error);
    }
    return results.getSlackUsersAndChannelList;
  } catch (error) {
    logger.error(error);
    openSnackbar(
      `Something went wrong while loading your public slack channels. Please try again later...`,
      {
        variant: 'warning',
      },
    );
    throw new Error(
      'Something went wrong while loading your public slack channels. Please try again later',
    );
  }
};
export const getAndSetPublicChannels = async (
  input: GetSlackUsersAndChannelsInput,
  withSuggested = true,
  selectedChannel: Nullable<Channel>,
) => {
  try {
    setIsLoadingSlackChannels(true);
    const results = await slackService.getUsersAndPublicChannels.execute(input);
    if (
      !results?.getSlackUsersAndChannelList ||
      isNull(results) ||
      isNull(results.getSlackUsersAndChannelList.channels)
    ) {
      throw new Error('Invalid response');
    }
    if (results.getSlackUsersAndChannelList.error) {
      openSnackbar(results.getSlackUsersAndChannelList.error, {
        variant: 'info',
      });
      throw new Error(results.getSlackUsersAndChannelList.error);
    }
    setChannels(results.getSlackUsersAndChannelList, withSuggested);
    let resultChannelsAndUsers = [
      ...results.getSlackUsersAndChannelList.users,
      ...results.getSlackUsersAndChannelList.channels,
    ];
    if (selectedChannel && selectedChannel.display) {
      resultChannelsAndUsers = [
        ...resultChannelsAndUsers,
        { ...selectedChannel },
      ];
    }
    setPublicChannelsWorkflows(resultChannelsAndUsers);
  } catch (error) {
    logger.error(error);
    openSnackbar(
      `Something went wrong while loading your public slack channels. Please try again later...`,
      {
        variant: 'warning',
      },
    );
    throw new Error(
      'Something went wrong while loading your public slack channels. Please try again later',
    );
  } finally {
    setIsLoadingSlackChannels(false);
  }
};
export const requestChannelsWithDebounce = debounce(
  async (value: string, setLoader: Function, setState: Function) => {
    const results = await getPublicChannels({
      limit: SLACK_API_GET_CHANNELS_LIMIT,
      search: value,
    });
    setState(results, false);
    setLoader(false);
  },
  500,
);

export const debounceForParamsLoad = debounce(
  (suggestionsChannels: Channel[], setLoader: Function) => {
    setLoader(true);

    setPublicChannelsWorkflows(suggestionsChannels);
    setLoader(false);
  },
  500,
);

export async function loadChannelsAndFallbackChannels(
  selectedChannel: Nullable<Channel>,
) {
  setIsLoadingSlackChannels(true);
  setIsLoadingSlackChannelsFallback(true);
  setDisableTypingOnFirstRequest(true);
  const results = await getPublicChannels({
    limit: SLACK_API_GET_CHANNELS_LIMIT,
    search: '',
    channelsInput: { appId: '' },
  });
  if (selectedChannel && selectedChannel.display) {
    setPublicChannelsWorkflows([
      selectedChannel,
      ...results.users,
      ...results.channels,
    ]);
  } else {
    setPublicChannelsWorkflows([...results.users, ...results.channels]);
  }
  setFallbackChannels([...results.users, ...results.channels]);
  setIsLoadingSlackChannels(false);
  setIsLoadingSlackChannelsFallback(false);
  setDisableTypingOnFirstRequest(false);
}

export const sendSlackNotification = async (
  issuesPage: IssuePages,
  issueId: string,
) => {
  try {
    setLoadingSubmit(true);
    const { fields } = snapshot(SlackStore);

    const results = await slackService.sendSlackNotification.execute({
      channel: fields.selectedChannel,
      issueId: issueId,
      text: fields.userComment,
      scanType:
        issuesPage === IssuePages.PipelineIssues
          ? scanTypes.PipelineScan
          : scanTypes.FullScan,
    });

    if (isNull(results) || isNull(results.sendSlackNotification)) {
      throw new Error('Invalid response');
    }

    if (results.sendSlackNotification.error) {
      openSnackbar(results.sendSlackNotification.error, {
        variant: 'info',
      });
    }
    const { sentSuccessfully } = results.sendSlackNotification;
    if (sentSuccessfully) {
      openSnackbar('Sent successfully!', {
        variant: 'success',
      });
      // TelemetryManager.setTelemetryAction(
      //   TelemetryActions.SendSlackMessage,
      //   issueInfo,
      // );
      return results.sendSlackNotification;
    }
  } catch (error) {
    logger.error(error);
    openSnackbar(
      `Opss! somthing went wrong, but no worry we are on it. Please try again later ...`,
      {
        variant: 'warning',
      },
    );
    return false;
  } finally {
    setLoadingSubmit(false);
  }
};
