import { AggregationColumn, AggregationItem } from 'ox-common-types';
import { FaGlobe } from 'react-icons/fa';
import { SeverityEvidenceItem } from '../SeverityEvidence/types';

export const createNewDate = (date: string) => {
  if (date !== null) {
    try {
      const newDate = new Date(date);
      if (!isNaN(newDate.valueOf())) {
        return newDate;
      }
    } catch {
      return null;
    }
  }
};

export const createNewDateFromNumber = (date: string) => {
  if (date !== null) {
    try {
      const newDate = new Date(Number(date));
      if (!isNaN(newDate.valueOf())) {
        return newDate;
      }
    } catch {
      return null;
    }
  }
};

export const filterConfigByTypes = (
  columns: AggregationColumn[] | undefined,
  items: AggregationItem[],
): AggregationColumn[] => {
  if (!columns) return [];
  const validKeys = new Set<string>();
  items.forEach(item => {
    Object.entries(item).forEach(([key, value]) => {
      if (key === 'startLine') {
        // Only add 'startLine' if its value is not -1.
        if (Number(value) !== -1) {
          validKeys.add(key);
        }
      } else if (value) {
        validKeys.add(key);
      }
    });
  });
  return columns.filter(column => validKeys.has(column.key));
};

export interface AggregationContextItem {
  key: AggregationContextKey;
  evidences: SeverityEvidenceItem[];
}
export enum AggregationContextKey {
  'API Exposure' = 'API Exposure',
}

const AggregationContextIcons = new Map([
  [AggregationContextKey['API Exposure'], FaGlobe],
]);

export const resolveAggregationContextIcon = (key: AggregationContextKey) => {
  return AggregationContextIcons.get(key);
};
