import { LogoutOptions } from '@auth0/auth0-react';
import { AppsPagesNotContainOrgId, navigate } from 'app-navigator';
import { snapshot } from 'valtio';
import {
  availableOrganizations,
  removeCurrentOrganization,
} from '../../organizations/store-actions/organization-store-actions';
import { removeOrgFromLocalStorage } from '../../organizations/utils/local-storage-utils';
import AvailableRolesStore from '../stores/available-roles-store';
import MembersStore from '../stores/members-store';
import RemoveMemberStore from '../stores/remove-member-store';
import { ActiveMember } from '../types/members-types';
import { Roles } from '@oxappsec/ox-unified-permissions';

export const closeRemoveMemberModal = (): void => {
  setModalVisibility(false);
  setRemoveVerificationValue('');
};

export const openRemoveMemberModal = (): void => {
  setIsLastAdminStanding();
  setModalVisibility(true);
};

const setModalVisibility = (payload: boolean): void => {
  RemoveMemberStore.isModalVisible = payload;
};

export const setSelectedMemberToRemove = (member: ActiveMember): void => {
  RemoveMemberStore.selectedActiveMember = member;
};

export const handleSelfDeletion = (
  logout: (options?: LogoutOptions) => void,
): void => {
  removeOrgFromLocalStorage();
  if (availableOrganizations().length === 1) {
    logout({
      returnTo: window.location.origin,
    });
    return;
  }

  removeCurrentOrganization();
  navigate(AppsPagesNotContainOrgId.Home);
};

export const isSelfDeletion = (userId: string): boolean => {
  const { loggedInUserId } = snapshot(RemoveMemberStore);
  return userId === loggedInUserId;
};

export const setIsLastAdminStanding = (): void => {
  const { availableRoles } = snapshot(AvailableRolesStore);
  const { activeMembers } = snapshot(MembersStore);

  RemoveMemberStore.activeMemberCount = activeMembers.length;
  const adminRole = availableRoles.find(r => r.name === Roles.Admin);
  if (!adminRole) {
    return;
  }

  const adminCount = activeMembers.reduce((acc, cur) => {
    const foundAdmin = cur.roles.findIndex(r => r.id === adminRole.id) !== -1;
    return foundAdmin ? acc + 1 : acc;
  }, 0);

  RemoveMemberStore.isLastAdminStanding = adminCount === 1;
};

export const setIsMemberAdmin = (member: ActiveMember): void => {
  const { availableRoles } = snapshot(AvailableRolesStore);

  const adminRole = availableRoles.find(r => r.name === Roles.Admin);
  if (!adminRole) {
    return;
  }

  RemoveMemberStore.isActiveMemberAdmin =
    member.roles.findIndex(r => r.id === adminRole.id) !== -1;
};

export const setRemoveVerificationValue = (verificationValue: string): void => {
  RemoveMemberStore.removeVerificationValue = verificationValue;
};

export const setLoggedInUserId = (userId: string | undefined): void => {
  if (!userId) {
    return;
  }

  RemoveMemberStore.loggedInUserId = userId;
};

export const isMemberCurrentUser = (member: ActiveMember): boolean => {
  const { loggedInUserId } = RemoveMemberStore;
  return member.user_id === loggedInUserId;
};
