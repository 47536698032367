import { Box } from '@mui/material';
import { logger } from 'logging-utils';
import { GeneralErrorPage } from 'ox-react-components';
import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorBoundaryMessage from './ErrorBoundaryMessage';

class PagesErrorBoundary extends Component<
  PagesErrorBoundaryProps,
  ErrorBoundaryState
> {
  promiseRejectionHandler = (event: ErrorEvent | Event) => {
    logger.error('Unhandled error caught: ', event);

    // fix for sunburst chart transition error
    if ((event as ErrorEvent).error?.message?.includes('transition')) return;

    // This is a hot fix to don't crash the app when we were not able to load google analitics script
    if (
      (event as PromiseRejectionEvent).reason?.message?.includes(
        'Failed to fetch',
      )
    )
      return;
    if ((event as ErrorEvent).message?.includes('ResizeObserver loop')) return;
    if (
      (event as ErrorEvent).message?.includes(
        "evaluating 'e[0].contentBoxSize[0]'",
      )
    )
      return;
    this.props.setErrorState(true);
  };

  componentDidMount() {
    window.addEventListener('error', this.promiseRejectionHandler);
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('ErrorBoundary caught an error', error);
    logger.error('Error Info:', errorInfo);
    this.props.setErrorState(true);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.promiseRejectionHandler);
    window.removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler,
    );
  }

  render() {
    if (this.props.hasError) {
      return (
        <Box width='100%' height='100%'>
          <GeneralErrorPage
            showOxIcon
            errorTitle='Oops, an error has occurred :('
            errorMsg={<ErrorBoundaryMessage />}
          />
        </Box>
      );
    }

    return this.props.children;
  }
}

interface PagesErrorBoundaryProps {
  children: ReactNode;
  hasError: boolean;
  setErrorState: (hasError: boolean) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default PagesErrorBoundary;
