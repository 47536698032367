import { snapshot, useSnapshot } from 'valtio';
import {
  Connector,
  ConnectorFieldValue,
  ConnectorsByFamilyObject,
} from '../connectors-types';
import ConnectorsStore from '../stores/connectors-store';
import { AppPages, navigate } from 'app-navigator';

export const setIsLoadingConnectors = (isLoading: boolean): void => {
  ConnectorsStore.loadingConnectors = isLoading;
};

export const setConnectors = (payload: Connector[]): void => {
  ConnectorsStore.connectors = payload;
};

export const setConnectorsByFamily = (
  payload: ConnectorsByFamilyObject[],
): void => {
  ConnectorsStore.connectorsByFamily = payload;
};

export const setFilteredConnectorsByFamily = (
  payload: ConnectorsByFamilyObject[],
): void => {
  ConnectorsStore.filteredConnectorsByFamily = payload;
};

export const updateSingleConnectorsField = (
  connectorId: string,
  fieldName: string,
  fieldValue: ConnectorFieldValue,
): void => {
  const { connectorsByFamily, filteredConnectorsByFamily } = ConnectorsStore;
  for (const familyObject of connectorsByFamily) {
    const foundConnector = familyObject.connectors.find(
      c => c.id === connectorId,
    );
    if (!foundConnector) {
      continue;
    }
    const familyToUpdate = filteredConnectorsByFamily.find(
      familyObjectFiltered =>
        familyObjectFiltered.family === foundConnector.family,
    );
    const filteredConnectorToUpdate = familyToUpdate?.connectors.find(
      connector => connector.id === connectorId,
    );
    if (filteredConnectorToUpdate) {
      filteredConnectorToUpdate[fieldName] = fieldValue;
      foundConnector[fieldName] = fieldValue;
    }
    break;
  }
};

export const updateAggregatedConnectorsStatus = (
  connectorToUpdateId: string,
  aggConnectorId: string,
  isConfigured: boolean,
) => {
  const { connectorsByFamily, filteredConnectorsByFamily } = ConnectorsStore;
  for (const familyObject of connectorsByFamily) {
    const foundConnector = familyObject.connectors.find(
      c => c.id === connectorToUpdateId,
    );
    if (!foundConnector) {
      continue;
    }
    const familyToUpdate = filteredConnectorsByFamily.find(
      familyObjectFiltered =>
        familyObjectFiltered.family === foundConnector.family,
    );
    const filteredConnectorToUpdate = familyToUpdate?.connectors.find(
      connector => connector.id === connectorToUpdateId,
    );
    if (filteredConnectorToUpdate) {
      const aggConnectorToUpdate =
        filteredConnectorToUpdate.aggregatedConnectors?.find(
          connector => connector.id === aggConnectorId,
        );
      if (aggConnectorToUpdate) {
        aggConnectorToUpdate.isConfigured = isConfigured;
      }
    }
    const aggConnectorToUpdate = foundConnector.aggregatedConnectors?.find(
      connector => connector.id === aggConnectorId,
    );
    if (aggConnectorToUpdate) {
      aggConnectorToUpdate.isConfigured = isConfigured;
    }
    break;
  }
};

export const availableConnectors = (): Connector[] => {
  const connectors = snapshot(ConnectorsStore).connectors;
  return connectors;
};

export const setSearchValue = (value: string) => {
  const isConnectorPage = window.location.pathname.includes(
    AppPages.Connectors,
  );
  if (!isConnectorPage) {
    return;
  }
  const params = new URLSearchParams(window.location.search);
  if (!value) {
    params.delete('search');
  } else {
    params.set('search', value);
  }
  navigate(AppPages.Connectors, params.toString());
};

export const useIsLoadingConnectors = () => {
  const { loadingConnectors } = useSnapshot(ConnectorsStore);
  return loadingConnectors;
};

export const setScanType = (scanType?: string) => {
  ConnectorsStore.scanType = scanType;
};

export const setNumberOfScans = (numberOfScans?: number) => {
  ConnectorsStore.numberOfScans = numberOfScans;
};

export const dismissBanner = () => {
  ConnectorsStore.connectivityBannerDismissed = true;
};
