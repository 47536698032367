import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { downloadUsersToFile } from '../utils/organization-info-utils';
import { ModalDialog } from 'ox-react-components';
import { DevCount, SimpleUser } from '../organization-types';
import { useSnapshot } from 'valtio';
import OrgInfoStore from '../stores/org-info-store';
import { fetchOrgUsersInfo } from '../actions/org-info-actions';
import { useOxFlags } from '../../feature-flags/api/feature-flags-api';

const DownloadsUsersDialog: FC<
  React.PropsWithChildren<DownloadsUsersDialogProps>
> = ({ orgId, children }) => {
  const { organization } = useSnapshot(OrgInfoStore);

  const [, setClicks] = useState(0);
  const [open, setOpen] = useState(false);
  const [docType, setDocType] = useState(DocType.html);
  const theme = useTheme();

  const flags = useOxFlags();
  const devCountFlag = flags.developerCountByCommits;

  const handleClose = useCallback(() => {
    setClicks(0);
    setOpen(false);
  }, []);

  const handleClick = useCallback(event => {
    setClicks(prev => {
      const newCount = prev + 1;

      if (event.shiftKey && newCount >= 3) {
        fetchOrgUsersInfo();
        setOpen(true);
        return 0; // Reset counter
      }
      return newCount; // Keep incrementing for both regular and shift clicks
    });
  }, []);

  return (
    <>
      <div onClick={handleClick}>{children}</div>
      <ModalDialog
        open={open}
        modalHeader={'Users List'}
        onClose={handleClose}
        modalColor={theme.palette.info.main}>
        <Box flexGrow={1}>
          <Typography variant='body1'>
            {
              'Download a list of all the users based on the commits in this organization.'
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            gap: '16px',
            justifyContent: 'flex-start',
          }}>
          <Button
            sx={{ marginBlockStart: 2 }}
            color='primary'
            size='small'
            variant='contained'
            onClick={() => {
              downloadUsersToFile(orgId, organization, devCountFlag, docType);
              setOpen(false);
            }}>
            Download Users List
          </Button>

          <Select
            sx={{ marginBlockStart: 2 }}
            size='small'
            value={docType}
            onChange={event => setDocType(event.target.value as DocType)}>
            <MenuItem value={DocType.html}>Html</MenuItem>
            <MenuItem value={DocType.txt}>Text</MenuItem>
            <MenuItem value={DocType.md}>Markdown</MenuItem>
          </Select>
        </Box>
      </ModalDialog>
    </>
  );
};

export interface DownloadsUsersDialogProps {
  orgId: string;
  usersList?: DevCount[];
  resourceType?: string;
  apiUsersList?: SimpleUser[];
}

export enum DocType {
  html = 'html',
  txt = 'txt',
  md = 'md',
}

export default DownloadsUsersDialog;
