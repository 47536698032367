import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  UpdateGeneralSettingsInput,
  UpdateSettingsRes,
} from '../../types/settings-types';
import mutation from './update-general-settings.gql';
import { graphqlUtils } from 'api-clients/src/graphql-utils';

export const updateGeneralSettings = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (input: UpdateGeneralSettingsInput) => {
      const errorMessage = 'Failed to update general settings';
      try {
        const results = await client.mutate<{
          updateGeneralSettings: UpdateSettingsRes;
        }>({
          mutation: mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        graphqlUtils.invalidateCachedQueries(client, ['getGeneralSettings']);
        if (!results.data?.updateGeneralSettings) {
          logger.error(errorMessage);
          throw new Error(errorMessage);
        }
        return results.data.updateGeneralSettings;
      } catch (error) {
        logger.error(errorMessage, error);
        throw error;
      }
    },
  };
};

export interface UpdateSlaSettingsExecutiveReportsResponse {
  success: boolean;
}
