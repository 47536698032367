import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme, Tooltip, Typography } from '@mui/material';
import { getCountryByCode } from 'common-utils';

const Location: FC<LocationProps> = ({ countryCode }) => {
  const { classes } = useStyles();
  const country = getCountryByCode(countryCode);
  return (
    <Tooltip
      title={
        country ? (
          <Typography variant='body2' color='text.primary'>
            {country}
          </Typography>
        ) : (
          ''
        )
      }>
      <div className={classes.flagContent}>
        {countryCode && (
          <img
            className={classes.flag}
            alt={country}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
          />
        )}
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  flagContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flag: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export interface LocationProps {
  countryCode: string;
}

export { Location };
