import { styled, Theme } from '@mui/system';
import { FontAwesomeIconWrapper } from './OxIconTypes';
export interface FontAwesomeIconContainer extends FontAwesomeIconWrapper {
  theme: Theme;
}

export const StyledFontAwesomeIconContainer = styled('div')(
  ({ theme, color, size, padding }: FontAwesomeIconContainer) => ({
    color: color || theme.palette.text.primary,
    width: size,
    height: size,
    display: 'flex',
    padding: padding,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  }),
);
