import { AppPages, navigate } from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { debounce } from 'lodash-es';
import { snapshot } from 'valtio';
import { getSbomApiParams } from '../sbom-utils';
import sbomService from '../services';
import { setTotalSbomFilters } from '../stores/sbom-filters-store-actions';
import SbomStore from '../stores/sbom-store';
import {
  setSbomLibraries,
  setSbomLibrariesTotal,
  setSbomOffset,
  setTopSearchValue,
  setSelectedSbomData,
} from '../stores/sbom-store-actions';
import { getSbomOpenFilterItems } from './sbom-libraries-filters-actions';

export type LoadSbomLibraries = {
  update?: boolean;
  cache?: boolean;
  search?: string;
};

export const loadSbomLibraries = createSimpleAsyncAction(
  async (params?: LoadSbomLibraries) => {
    const { update = false, cache = true, search = '' } = params || {};
    const limit = 50;
    if (search) {
      SbomStore.topSearchValue = search;
    }
    if (update) {
      SbomStore.offset = 0;
    }

    const { total, offset } = snapshot(SbomStore);
    if (total < offset) return;

    const sbomApiParams = getSbomApiParams(limit);

    const response = await sbomService.getSbomLibraries.execute(
      sbomApiParams,
      cache,
    );

    if (response && response.sbomLibs) {
      const { offset, sbomLibs, total, totalFilteredSbomLibs } = response;

      setSbomOffset(offset);
      setSbomLibraries(sbomLibs, update);
      setSbomLibrariesTotal(total);
      setTotalSbomFilters(totalFilteredSbomLibs);
    }
  },
  {
    asyncState: SbomStore.loading,
    errorMessage: 'Failed to load sbom libraries',
  },
);

export const onTopSearchChange = debounce(
  (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopSearchValue(e.target.value);
    loadSbomLibraries({ update: true });
    getSbomOpenFilterItems();
    selectSbom();
  },
  500,
);

export const selectSbom = async (
  appId?: string,
  library?: string,
  id?: string,
) => {
  const sbomId = [appId, library, id].join('@@');
  const { selectedSbom } = snapshot(SbomStore);
  if (sbomId !== selectedSbom) {
    SbomStore.selectedSbom = sbomId;

    let currentUrl = new URL(window.location.href);
    let params = new URLSearchParams(currentUrl.search);
    params.set('sbomId', sbomId);
    navigate(AppPages.Sbom, params.toString());

    if (appId && library) {
      const sbomData = await sbomService.getSingleSbom.execute({
        appId: appId,
        library,
        sbomId: id,
      });

      if (sbomData) {
        const trigger = sbomData.references?.[0];

        setSelectedSbomData(sbomData);
        if (trigger) {
          getDependencyGraph(
            appId,
            `${trigger.triggerPackage} at ${trigger.fileName}`,
            library,
          );
        }
      }
    } else {
      setSelectedSbomData(null);
    }
  }
};

export const getDependencyGraph = async (
  appId: string,
  triggerPackage: string,
  library: string,
) => {
  SbomStore.selectedTrigger = triggerPackage;
  SbomStore.loadingDependencyGraph = true;

  const [trigger, fileName] = triggerPackage.split(' at ');

  const dependencyGraph = await sbomService.getDependencyGraph.execute({
    appId,
    triggerPackage: trigger,
    fileName,
    library,
  });

  SbomStore.loadingDependencyGraph = false;

  if (dependencyGraph) {
    SbomStore.dependencyGraph = dependencyGraph;
  } else {
    SbomStore.dependencyGraph = null;
  }
};
