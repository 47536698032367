import { AppEvents } from 'common-events';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import InventoryFiltersStore from '../../dashboard-inventory/stores/inventory-filters-store';
import ScanStore from '../../new-scan/store/scan-store';
import { fetchActiveProfileOverviewAction } from '../../policies/api/policies-api';
import {
  DASHBOARD_PBOM_BREAKDOWN_ITEMS_LIST,
  DashboardResponse,
  PbomBreakdownItem,
  PbomBreakdownItemsNames,
} from '../dashboard-types';
import dashboardService from '../services';
import { GetCategoriesInPreventResponse } from '../services/get-categories-in-prevent/get-categories-in-prevent';
import {
  setIsErrorLoadingPbomBreakdown,
  setIsLoadingPbomBreakdown,
  setLoadingPreventCategories,
  setOrgScaneData,
  setPbomBreakdownItems,
  setPreventCategories,
} from '../store-actions/store-actions';
import { OX_IN_PIPELINE_FILTER_NAME } from '../../applications/applications-types';

const loadDashboardInfo = async (): Promise<boolean | undefined> => {
  let result: Nullable<DashboardResponse> = null;
  try {
    const { dateRange } = snapshot(TopBarStore);
    const [from, to] = calcDateRange(dateRange);
    const { filters } = snapshot(InventoryFiltersStore);
    const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
      GlobalDataViewSelector,
    );
    const { scanID, realScanId, isScanning } = snapshot(ScanStore);
    const useCache = !isScanning;
    result = await dashboardService.fetchOrgScanInfoResults.execute(
      {
        filters,
        ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        dateRange: {
          from,
          to,
        },
      },
      useCache,
    );
    fetchActiveProfileOverviewAction();
  } catch (err) {
    logger.info('Failed to load dashboard info', err);
    return;
  }

  document.dispatchEvent(
    new CustomEvent(AppEvents.DashboardInfo.Loaded, { detail: result }),
  );

  try {
    if (result) {
      handleResponse(result);
    }
    return !!result?.getOrgScanInfo;
  } catch (err) {
    logger.info('Failed to load org score data', err);
  }
};

const loadPreventCategories = async () => {
  try {
    setLoadingPreventCategories(true);

    const response = await dashboardService.getCategoriesInPrevent.execute();
    if (response) {
      handlePreventCategoriesResponse(response);
      return;
    }

    handlePreventCategoriesResponse({ getCategoriesInPrevent: [] });
  } catch (err) {
    logger.info('Failed to load categories in prevent info', err);
  } finally {
    setLoadingPreventCategories(false);
  }
};

const handleResponse = (result: DashboardResponse) => {
  setOrgScaneData(result.getOrgScanInfo);
};

const handlePreventCategoriesResponse = (
  result: GetCategoriesInPreventResponse,
) => {
  setPreventCategories(result.getCategoriesInPrevent);
};

const getPbomBreakdownData = async (cache = true) => {
  const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
    GlobalDataViewSelector,
  );

  const inputs = DASHBOARD_PBOM_BREAKDOWN_ITEMS_LIST.reduce((acc, item) => {
    if (!item.inputName) {
      return acc;
    }
    acc[item.inputName] = {
      owners: selectedAppOwnersEmails,
      tagIds: selectedTagIds,
    };
    if (item.name === PbomBreakdownItemsNames.PROTECTED_REPOS) {
      acc[item.inputName].openItems = [OX_IN_PIPELINE_FILTER_NAME];
    }
    return acc;
  }, {});

  setIsLoadingPbomBreakdown(true);

  const response = await dashboardService.getPbomBreakdown.execute(
    inputs,
    cache,
  );
  if (!response) {
    setIsErrorLoadingPbomBreakdown(true);
    setIsLoadingPbomBreakdown(false);
    return;
  }

  const pbomBreakdownItems = DASHBOARD_PBOM_BREAKDOWN_ITEMS_LIST.reduce(
    (acc: PbomBreakdownItem[], item) => {
      const responseItem = response[item.gqlKey];
      let total = 0;

      if (
        item.name === PbomBreakdownItemsNames.PROTECTED_REPOS &&
        responseItem
      ) {
        total = responseItem?.filters?.[0]?.items?.reduce(
          (acc, item) =>
            acc + (item.label?.includes('Enabled') ? item.count : 0),
          0,
        );
      } else if (responseItem && responseItem.total) {
        total = responseItem.total;
      }

      acc.push({
        ...item,
        number: total,
      });
      return acc;
    },
    [],
  );
  setPbomBreakdownItems(pbomBreakdownItems);
  setIsLoadingPbomBreakdown(false);
};

export const dashboardStoreActions = {
  loadDashboardInfo,
  loadPreventCategories,
  getPbomBreakdownData,
};
