import { isBefore } from 'date-fns';
import { logger } from 'logging-utils';
import pluralize from 'pluralize';
import { CredentialsType } from '../connector-configure-types';
import {
  APISecretAndAccessKeyCredentials,
  AWSAssumeRoleCredentials,
  AWSAssumeRoleCredentialsOnprem,
  ClientIdSecretKeyCredentials,
  Connector,
  Credential,
  CredentialsInput,
  OrganizationIdAndApiKeyCredentials,
  TenantClientsubscriptionIdSecretCredentials,
  TenantIdClientIdClientSecretCredentials,
  TokenAndProjectIdCredentials,
  ClientIdSecretApiUrlCredentials,
  TokenAndUserCredentials,
  TokenCredentials,
  UserPasswordAndTenantCredentials,
  UserPasswordCredentials,
  AzureCloudCredentials,
  ClientIdClientSecretCredentials,
  AppIdAndTokenCredentials,
  ServicePrincipalCredentials,
  OptionalConnectorInput,
  InputTypes,
  BrokerCredentials,
} from '../connectors-types';
import { CREDENTIAL_NAME_NUM_COUNT_DIGITS } from '../constants';

const extractCreds = (credentialsType: CredentialsType, creds: Credential) => {
  switch (credentialsType) {
    case CredentialsType.UserPasswordAndTenant: {
      return creds
        ? (creds as UserPasswordAndTenantCredentials)
        : ({
            name: '',
            password: '',
            tenant: '',
          } as UserPasswordAndTenantCredentials);
    }
    case CredentialsType.Token: {
      return creds ? (creds as TokenCredentials) : { token: '' };
    }
    case CredentialsType.UserPassword:
    case CredentialsType.UserPasswordOnly: {
      return creds
        ? (creds as UserPasswordCredentials)
        : { name: '', password: '' };
    }
    case CredentialsType.TokenAndUser: {
      return creds
        ? (creds as TokenAndUserCredentials)
        : { name: '', token: '' };
    }

    case CredentialsType.TokenAndProjectId: {
      return creds
        ? (creds as TokenAndProjectIdCredentials)
        : { projectId: '', token: '' };
    }

    case CredentialsType.ClientIdSecretApiUrl: {
      return creds
        ? (creds as ClientIdSecretApiUrlCredentials)
        : { clientId: '', clientSecret: '', apiUrl: '' };
    }

    case CredentialsType.TokenOnly: {
      return creds ? (creds as TokenCredentials) : { token: '' };
    }

    case CredentialsType.OrganizationIdAndApiKey: {
      return creds
        ? (creds as OrganizationIdAndApiKeyCredentials)
        : { name: '', password: '', organizationId: '', apiKey: '' };
    }

    case CredentialsType.TenantClientsubscriptionIdSecret: {
      return creds
        ? (creds as TenantClientsubscriptionIdSecretCredentials)
        : { tenantId: '', clientId: '', clientSecret: '', subscriptionId: '' };
    }

    case CredentialsType.TenantIdClientIdClientSecret: {
      return creds
        ? (creds as TenantIdClientIdClientSecretCredentials)
        : { tenantId: '', clientId: '', clientSecret: '' };
    }

    case CredentialsType.AzureCloud: {
      return creds
        ? (creds as AzureCloudCredentials)
        : { tenantId: '', clientId: '', clientSecret: '' };
    }

    case CredentialsType.ServicePrincipal: {
      return creds
        ? (creds as ServicePrincipalCredentials)
        : { tenantId: '', clientId: '', clientSecret: '' };
    }

    case CredentialsType.APISecretAndAccessKey: {
      return creds
        ? (creds as APISecretAndAccessKeyCredentials)
        : { apiAccessKey: '', apiSecretKey: '' };
    }

    case CredentialsType.ApiKey: {
      return creds ? (creds as TokenCredentials) : { token: '' };
    }
    case CredentialsType.ClientIdSecretKey: {
      return creds
        ? (creds as ClientIdSecretKeyCredentials)
        : { secretKey: '', clientId: '' };
    }
    case CredentialsType.AWSAssumeRoleCloudFormation:
    case CredentialsType.AWSOrganizationConnection:
    case CredentialsType.AWSAssumeRole:
    case CredentialsType.AWSAssumeRoleCodeCommit:
    case CredentialsType.AWSAssumeRoleCodeCommitOrganization: {
      return creds
        ? (creds as AWSAssumeRoleCredentials)
        : { awsRoleArn: '', awsExternalId: '' };
    }
    case CredentialsType.AWSAssumeRoleCloudFormationOnprem:
    case CredentialsType.AWSOrganizationConnectionOnprem:
    case CredentialsType.AWSAssumeRoleOnprem:
    case CredentialsType.AWSAssumeRoleCodeCommitOnprem:
    case CredentialsType.AWSAssumeRoleCodeCommitOrganizationOnprem: {
      return creds
        ? (creds as AWSAssumeRoleCredentialsOnprem)
        : {
            awsRoleArn: '',
            awsExternalId: '',
            awsAccessKey: '',
            awsAccessSecret: '',
          };
    }
    case CredentialsType.ClientIdClientSecret: {
      return creds
        ? (creds as ClientIdClientSecretCredentials)
        : { clientId: '', clientSecret: '' };
    }
    case CredentialsType.AppIdAndToken: {
      return creds
        ? (creds as AppIdAndTokenCredentials)
        : { appId: '', token: '' };
    }
    case CredentialsType.Broker: {
      return creds
        ? (creds as BrokerCredentials)
        : { brokerUsername: '', brokerPassword: '', brokerSSHPublicKey: '' };
    }
    case CredentialsType.GitHubApp:
    case CredentialsType.BitbucketApp:
    case CredentialsType.IdentityProvider:
    case CredentialsType.AWSEKS:
    case CredentialsType.AWSEKSDirect:
    case CredentialsType.AWSEKSPrivateLink:
    case CredentialsType.None: {
      return null;
    }
    default:
      logger.warn(
        `Could not extract credentials. Unknown type: ${credentialsType}`,
      );
      return null;
  }
};

const getModalInitialCreds = (
  connector: Connector,
  credential?: Credential,
): CredentialsInput => {
  const { credentialsType, credentials } = connector;
  // support only one for now
  const cred = credential || credentials[0];
  const extracted = extractCreds(credentialsType, cred) || {};

  const initialValues: CredentialsInput = {
    token:
      'token' in extracted ? (extracted as Record<string, string>).token : '',
    name: 'name' in extracted ? (extracted as Record<string, string>).name : '',
    password:
      'password' in extracted
        ? (extracted as Record<string, string>).password
        : '',
    clientId:
      'clientId' in extracted
        ? (extracted as Record<string, string>).clientId
        : '',
    secretKey:
      'secretKey' in extracted
        ? (extracted as Record<string, string>).secretKey
        : '',
    awsRoleArn:
      'awsRoleArn' in extracted
        ? (extracted as Record<string, string>).awsRoleArn!
        : '',
    awsExternalId:
      'awsExternalId' in extracted
        ? (extracted as Record<string, string>).awsExternalId!
        : '',
    brokerUsername:
      'brokerUsername' in extracted
        ? (extracted as Record<string, string>).brokerUsername
        : '',
    brokerPassword:
      'brokerPassword' in extracted
        ? (extracted as Record<string, string>).brokerPassword
        : '',
    brokerSSHPublicKey:
      'brokerSSHPublicKey' in extracted
        ? (extracted as Record<string, string>).brokerSSHPublicKey
        : '',
    appId:
      'appId' in extracted
        ? (extracted as Record<string, string>)['appId']
        : '',
    apiUrl:
      'apiUrl' in extracted
        ? (extracted as Record<string, string>)['apiUrl']
        : '',
    awsAccessKey:
      'awsAccessKey' in extracted
        ? (extracted as Record<string, string>)['awsAccessKey']
        : '',
    awsAccessSecret:
      'awsAccessSecret' in extracted
        ? (extracted as Record<string, string>)['awsAccessSecret']
        : '',
    tenant:
      'tenant' in extracted
        ? (extracted as Record<string, string>)['tenant']
        : '',
    projectId:
      'projectId' in extracted
        ? (extracted as Record<string, string>)['projectId']
        : '',
    organizationId:
      'organizationId' in extracted
        ? (extracted as Record<string, string>)['organizationId']
        : '',
    apiKey:
      'apiKey' in extracted
        ? (extracted as Record<string, string>)['apiKey']
        : '',
    apiAccessKey:
      'apiAccessKey' in extracted
        ? (extracted as Record<string, string>)['apiAccessKey']
        : '',
    apiSecretKey:
      'apiSecretKey' in extracted
        ? (extracted as Record<string, string>)['apiSecretKey']
        : '',
    tenantId:
      'tenantId' in extracted
        ? (extracted as Record<string, string>)['tenantId']
        : '',
    clientSecret:
      'clientSecret' in extracted
        ? (extracted as Record<string, string>)['clientSecret']
        : '',
    webhookUrl:
      'webhookUrl' in extracted
        ? (extracted as Record<string, string>)['webhookUrl']
        : '',
    subscriptionId:
      'subscriptionId' in extracted
        ? (extracted as Record<string, string>)['subscriptionId']
        : '',
    optionalFields:
      'optionalFields' in extracted ? cred['optionalFields'] || {} : {},
    extraOptionalCreds: cred ? cred['extraOptionalCreds'] || {} : {},
    brokerEnabled:
      'brokerEnabled' in extracted
        ? (extracted as Record<string, boolean>)['brokerEnabled']
        : false,
    isCertChecksDisabled:
      'isCertChecksDisabled' in extracted
        ? (extracted as Record<string, boolean>)['isCertChecksDisabled']
        : false,
  };
  return initialValues;
};

const getOptionalFieldsInitialValues = (
  optionalInputFields: OptionalConnectorInput[],
) => {
  return (optionalInputFields ?? []).reduce(
    (acc, { key, value, inputType }) =>
      value
        ? {
            ...acc,
            [key]:
              inputType === InputTypes.boolean
                ? Boolean(value === 'true')
                : value,
          }
        : acc,
    {},
  );
};

const areCredentialsExpired = (
  connector: Connector,
  enableMultiTokens: boolean,
): boolean => {
  if (!connector.credentials.length) {
    return false;
  }
  if (!connector.credentials[0].tokenExpirationDate) {
    return false;
  }
  return enableMultiTokens
    ? connector.credentials.every(item =>
        isTokenExpired(item.tokenExpirationDate),
      )
    : isTokenExpired(connector.credentials[0].tokenExpirationDate) || false;
};

const generateValidationMsg = (connectors: Connector[]) => {
  const invalidCredsConnectors = connectors.reduce(
    (acc: string[], connector) => {
      if (
        connector.credentials.some(cred =>
          isTokenExpired(cred.tokenExpirationDate),
        )
      ) {
        acc.push(connector.displayName);
      }
      return acc;
    },
    [],
  );
  if (invalidCredsConnectors.length === 0) {
    return {
      color: 'success',
      msg: '',
    };
  }
  const returnObj = {
    color: 'error',
    msg: `The credentials for the following ${pluralize(
      'system',
      invalidCredsConnectors.length,
    )} has become invalid: `,
  };
  invalidCredsConnectors.forEach((connectorName, index) => {
    returnObj.msg =
      returnObj.msg +
      `${connectorName}${
        index + 1 === invalidCredsConnectors.length ? '' : ', '
      }`;
  });
  return returnObj;
};

const isTokenExpired = (tokenExpirationDate?: string | Date) => {
  return (
    !!tokenExpirationDate && isBefore(new Date(tokenExpirationDate), new Date())
  );
};

const getCredentialsByType = (
  credentials: Credential[],
  type: CredentialsType,
) => credentials.filter(cred => cred.credentialsType === type);

const getCountCredentialsByType = (
  credentials: Credential[],
  type: CredentialsType,
) => credentials.filter(cred => cred.credentialsType === type).length;

const getCredentialsTypeTabLabelCounter = (
  credentials: Credential[],
  type: CredentialsType,
  isConnectorConfigured: boolean,
) => {
  const count = getCountCredentialsByType(credentials, type);
  return count && isConnectorConfigured ? `(${count})` : '';
};

const checkCredentialConfiguredByType = (
  credentials: Credential[],
  type: CredentialsType,
) => !!credentials.filter(item => item.credentialsType === type).length;

const getDefaultCredentialsNameNumber = (credentialNames: string[]) => {
  let next = 0;
  const nums = credentialNames
    .filter(
      name =>
        !Number.isNaN(
          parseInt(name.slice(-CREDENTIAL_NAME_NUM_COUNT_DIGITS)) &&
            name[name.length - CREDENTIAL_NAME_NUM_COUNT_DIGITS - 1] === ' ',
        ),
    )
    .map(name => parseInt(name.slice(-CREDENTIAL_NAME_NUM_COUNT_DIGITS)))
    .sort((a, b) => a - b);

  for (let i = 0; i < nums.length; i++) {
    if (nums[0] !== 1) {
      next = 1;
      break;
    }
    if (nums[i] + 1 !== nums[i + 1]) {
      next = nums[i] + 1;
      break;
    }
  }
  return next || nums.length + 1;
};

export {
  getCredentialsTypeTabLabelCounter,
  getDefaultCredentialsNameNumber,
  getCredentialsByType,
  getCountCredentialsByType,
  getModalInitialCreds,
  areCredentialsExpired,
  generateValidationMsg,
  isTokenExpired,
  getOptionalFieldsInitialValues,
  checkCredentialConfiguredByType,
};
