import { RedirectLoginOptions } from '@auth0/auth0-react';
import {
  setAppGeneralError,
  setAppIsFirstLogin,
  setAppPageError,
} from 'app-store';
import { TokenStore } from 'common-auth';
import { AppEvents } from 'common-events';
import { env } from 'env-utils';
import { Location } from 'history';
import { OrganizationsStore } from 'organizations';
import {
  BITBUCKET_SESSION_REDIRECT_TO_KEY,
  BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING,
  Nullable,
} from 'ox-common-types';
import { useKeyPress } from 'react-utils';
import { snapshot } from 'valtio';
import { Organization } from '../../organizations/organization-types';
import { CanUserRequestAccessResponse } from '../../request-access/request-access-types';

export const setLoadingNewToken = (isLoading: boolean): void => {
  TokenStore.loadingNewToken = isLoading;
};

export const setGeneralError = (hasError: boolean) => {
  setAppGeneralError(hasError);
};

export const setPageError = (hasError: boolean) => {
  setAppPageError(hasError);
};

export const setShouldForceLogout = (forceLogout: boolean) => {
  TokenStore.shouldForceLogout = forceLogout;
};

// fetching organizations, token, check if need to onboard
export const logUserIntoOrganization = async (
  isAuthenticated: boolean,
  organizations: Organization[],
  getAccessTokenSilently: () => Promise<string>,
  getAvailableOrganizations: () => Promise<Organization[] | undefined>,
  fetchOrgInfo: () => Promise<Nullable<Organization>>,
  fetchCanUserRequestAccess: () => Promise<
    Nullable<CanUserRequestAccessResponse>
  >,
) => {
  if (isAuthenticated && organizations.length === 0) {
    setLoadingNewToken(true);
    const token = await getAccessTokenSilently();
    TokenStore.authToken = token;
    setLoadingNewToken(false);
    const organizations = await getAvailableOrganizations();
    const isFirstLogin = !!organizations && organizations.length === 0;
    await fetchOrgInfo();

    const canUserRequestAccessInfo = isFirstLogin
      ? await fetchCanUserRequestAccess()
      : null;

    // set to true in order to start the onboarding process
    setAppIsFirstLogin(isFirstLogin);
    // return true if first login
    return { isFirstLogin, canUserRequestAccessInfo };
  }

  return { isFirstLogin: false, canUserRequestAccessInfo: null };
};

export const loginUser = (loginFunction: LoginFunction) => {
  loginFunction(createLoginArgs());
};

type LoginFunction = (options?: RedirectLoginOptions | undefined) => void;

const createLoginArgs = () => {
  const { chosenOrganization } = snapshot(OrganizationsStore);
  if (chosenOrganization) {
    return {
      organization: chosenOrganization.id,
    };
  }
  return undefined;
};

export const dispatchIdpCallbackEvent = (
  searchParams: URLSearchParams,
  enableMultiTokens: boolean,
) => {
  document.dispatchEvent(
    new CustomEvent<{
      searchParams: URLSearchParams;
      enableMultiTokens: boolean;
    }>(AppEvents.Connectors.IdentityProviderCallback, {
      detail: { searchParams, enableMultiTokens },
    }),
  );
};

export const dispatchGitHubAppInstallationCallbackEvent = (
  location: Location,
  enableMultiTokens: boolean,
) => {
  document.dispatchEvent(
    new CustomEvent<{ location: Location; enableMultiTokens: boolean }>(
      AppEvents.Connectors.GitHubAppInstallationCallback,
      {
        detail: { location, enableMultiTokens },
      },
    ),
  );
};

export const dispatchBitbucketAppInstallationCallbackEvent = (
  searchParams: URLSearchParams,
  enableMultiTokens,
) => {
  if (
    window.sessionStorage.getItem(BITBUCKET_SESSION_REDIRECT_TO_KEY) ===
    BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING
  ) {
    window.sessionStorage.removeItem(BITBUCKET_SESSION_REDIRECT_TO_KEY);
    searchParams.set(
      'state',
      encodeURIComponent(JSON.stringify({ onboarding: true })),
    );
  }

  document.dispatchEvent(
    new CustomEvent<{
      searchParams: URLSearchParams;
      enableMultiTokens: boolean;
    }>(AppEvents.Connectors.BitbucketAppInstallationCallback, {
      detail: { searchParams, enableMultiTokens },
    }),
  );
};

export const useDebugToggle = () => {
  const isShiftPressed = useKeyPress('Shift');
  const isDPressed = useKeyPress('D');
  if (isShiftPressed && isDPressed) {
    const isDebug = !!env()['isDebug'];
    // eslint-disable-next-line no-console
    console.log(isDebug ? 'Debug mode off' : 'Debug mode on');
    window['env']['isDebug'] = !isDebug;
  }
};
