import { Button, Tooltip, Typography } from '@mui/material';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { FC, useState, useRef, useEffect } from 'react';
import { SiOpenai } from 'react-icons/si';
import { makeStyles } from 'tss-react/mui';
import withDisabledIfUnauthorized, {
  WithDisabledIfUnauthorizedProps,
} from '../../../../permissions/higher-order-components/withDisabledIfUnauthorized';
import { setGptDisclaimerModalOpen } from '../../store-actions/chat-gpt-store-actions';
import { getIssueActionTooltip } from '../../utils/tooltip-utils';

const IssueCardDescription: FC<
  React.PropsWithChildren<IssueCardDescriptionProps>
> = ({
  learnMoreUrl,
  children,
  isGptAvailable,
  isEnabledChatGpt,
  isResolvedIssuesPage,
  disabled,
  maxHeight,
}) => {
  const { classes } = useStyles();

  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const openGptDisclaimerModal = () => {
    setGptDisclaimerModalOpen(true);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (contentRef.current && maxHeight) {
      setIsOverflowing(contentRef.current.scrollHeight > maxHeight + 10);
    }
  }, [children, maxHeight]);

  return (
    <div className={classes.issueCardDescription}>
      <div className={classes.header}>
        <Typography variant='body2' color='text.secondary'>
          Description
        </Typography>
        <span>
          {learnMoreUrl && (
            <Tooltip title={learnMoreUrl}>
              <Button
                href={learnMoreUrl}
                target='_blank'
                size='small'
                sx={{
                  textTransform: 'none',
                  marginInlineEnd: 2,
                }}>
                Learn More
              </Button>
            </Tooltip>
          )}
          {isGptAvailable && !!!isResolvedIssuesPage && (
            <Tooltip
              title={getIssueActionTooltip(
                !!isEnabledChatGpt,
                'Start ChatGPT',
                !disabled
                  ? 'ChatGPT is not enabled in your organization settings'
                  : 'You need permissions to perform this action',
              )}
              placement='top'
              arrow>
              <span>
                <Button
                  size='small'
                  startIcon={<SiOpenai size='0.9rem' />}
                  onClick={openGptDisclaimerModal}
                  sx={{
                    textTransform: 'none',
                    fontSize: '0.80rem',
                  }}
                  disabled={!!disabled || !isEnabledChatGpt}
                  variant='contained'>
                  Start ChatGPT
                </Button>
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <Typography
        variant='body2'
        color='text.primary'
        mt={1}
        component='div'
        style={{
          maxHeight: showMore ? 'none' : maxHeight,
          overflow: 'hidden',
          position: 'relative',
        }}
        ref={contentRef}>
        <div dangerouslySetInnerHTML={{ __html: children }} />
        {!showMore && isOverflowing && maxHeight && (
          <div className={classes.showMore} />
        )}
      </Typography>
      {isOverflowing && maxHeight && (
        <Button
          sx={{ width: 70, textTransform: 'none', p: 0, m: 0 }}
          onClick={handleShowMore}
          size='small'>
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  issueCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '350px',
    img: {
      width: 24,
      height: 24,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 40,
  },
  showMore: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '2rem',
  },
}));

export interface IssueCardDescriptionProps
  extends WithDisabledIfUnauthorizedProps {
  learnMoreUrl?: string;
  isGptAvailable?: boolean;
  children: string;
  isEnabledChatGpt?: boolean;
  isEnabledChatGptSnippet?: boolean;
  issueId?: string;
  isResolvedIssuesPage?: boolean;
  maxHeight?: number;
}

export default withDisabledIfUnauthorized(IssueCardDescription, {
  requiredPermissions: [
    {
      permissionScope: PermissionScope.Edit,
      permissionType: PermissionTypes.IssuesChatGPT,
    },
  ],
});
