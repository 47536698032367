import { createStore } from 'store-utils';
import {
  SettingsByName,
  SettingsObj,
  SettingsSubType,
  TabQueryParam,
} from '../types/settings-types';
import { createAsyncState } from 'async-utils';
import { Nullable } from 'ox-common-types';

const SettingsStore = createStore<SettingsStoreState>(
  {
    orgSettings: [],
    singleSetting: null,
    loading: createAsyncState(),
    loadingUpdate: false,
    orgSettingsMapByName: [],
    orgSettingsMapByNameResponseState: [],
    personalSettings: [],
    currentTab: TabQueryParam.OrgSettings,
    tooltipTimeAdvancedOptions: 2000,
    regexList: {},
    isButtonLoading: {},
    inputText: '',
    selectedSubSettingId: '',
    subSettingType: null,
    openUpdateInputModal: false,
    disabledRadioButtonsSubSettings: {},
    changeSettingsConfirmDialog: null,
  },
  'Settings Store',
);

interface SettingsStoreState {
  orgSettings: SettingsObj[];
  singleSetting: Nullable<SettingsObj>;
  loading: ReturnType<typeof createAsyncState>;
  loadingUpdate: boolean;
  orgSettingsMapByName: SettingsByName[];
  orgSettingsMapByNameResponseState: SettingsByName[];
  personalSettings: SettingsObj[];
  currentTab: TabQueryParam;
  tooltipTimeAdvancedOptions: number;
  regexList: Record<string, string[]>;
  isButtonLoading: Record<string, boolean>;
  inputText: string;
  selectedSubSettingId: string;
  subSettingType: Nullable<SettingsSubType>;
  openUpdateInputModal: boolean;
  disabledRadioButtonsSubSettings: Record<string, boolean>;
  changeSettingsConfirmDialog: {
    updateFunction: () => unknown;
    title: string;
    message: string;
  } | null;
}

export default SettingsStore;
