import { AppPages, navigate } from 'app-navigator';
import { ConditionalFilter, FilterData, FilterItems } from 'ox-filter-utils';
import createIssueStoreActions from '../../common/store-actions/create-issue-store-actions';
import { loadIssuesPipeline } from '../actions/pipeline-issues-actions';
import PipelineIssuesStore, {
  initialPipelineIssuesState,
} from '../stores/pipeline-issues-store';
import { getPipelineIssuesFilterItems } from '../actions/pipeline-filter-issues-actions';

const pipelineIssuesStoreActions = createIssueStoreActions(
  PipelineIssuesStore,
  initialPipelineIssuesState,
);

// https://stackoverflow.com/questions/29844074/es6-export-all-values-from-object
export const setStoreIssues = pipelineIssuesStoreActions.setStoreIssues;
export const setStoreTotalIssues =
  pipelineIssuesStoreActions.setStoreTotalIssues;
export const setStoreTotalIssuesFiltered =
  pipelineIssuesStoreActions.setStoreTotalIssuesFiltered;
export const setStoreIssuesOffset =
  pipelineIssuesStoreActions.setStoreIssuesOffset;
export const setStoreIssuesFiltersType =
  pipelineIssuesStoreActions.setStoreIssuesFiltersType;
export const setStoreIssuesFiltersTypeSearch =
  pipelineIssuesStoreActions.setStoreIssuesFiltersTypeSearch;
export const setModalVisibility = pipelineIssuesStoreActions.setModalVisibility;
export const closeIssuesDrawer = pipelineIssuesStoreActions.closeIssuesDrawer;
export const closeIssueModalWithoutNavigate =
  pipelineIssuesStoreActions.closeIssueModalWithoutNavigate;
export const openIssueModal = pipelineIssuesStoreActions.openIssueModal;
export const setSelectedIssue = pipelineIssuesStoreActions.setSelectedIssue;
export const toggleFiltersPanel = pipelineIssuesStoreActions.toggleFiltersPanel;
export const clearStore = pipelineIssuesStoreActions.clearStore;
export const setPolicyDisableModal =
  pipelineIssuesStoreActions.setPolicyDisableModal;
export const setApplicationIrrelevantModal =
  pipelineIssuesStoreActions.setApplicationIrrelevantModal;
export const setShowCreateJiraTicketDialog =
  pipelineIssuesStoreActions.setShowCreateJiraTicketDialog;
export const setShowSendSlackMessageDialog =
  pipelineIssuesStoreActions.setShowSendSlackMessageDialog;
export const openQuickFixModal = pipelineIssuesStoreActions.openQuickFixModal;
export const setQuickFixModalVisibility =
  pipelineIssuesStoreActions.setQuickFixModalVisibility;
export const setFilesDiffModalVisibility =
  pipelineIssuesStoreActions.setFilesDiffModalVisibility;
export const setSliderValuePriority =
  pipelineIssuesStoreActions.setSliderValuePriority;
export const setLastDrawerHeight =
  pipelineIssuesStoreActions.setLastDrawerHeight;
export const pushSlackNotificationToRawIssue =
  pipelineIssuesStoreActions.pushSlackNotificationToRawIssue;
export const setSelectedIssueId = pipelineIssuesStoreActions.setSelectedIssueId;
export const setExclusionIssueComment =
  pipelineIssuesStoreActions.setExclusionIssueComment;
export const setExclusionIssueCommentString =
  pipelineIssuesStoreActions.setExclusionIssueCommentString;
export const setExclusionPolicyComment =
  pipelineIssuesStoreActions.setExclusionPolicyComment;
export const setExclusionAppComment =
  pipelineIssuesStoreActions.setExclusionAppComment;
export const setFalsePostiveIssueComment =
  pipelineIssuesStoreActions.setFalsePostiveIssueComment;
export const addJiraTicketToRawIssue =
  pipelineIssuesStoreActions.addJiraTicketToRawIssue;
export const setJiraTicketsToRawIssue =
  pipelineIssuesStoreActions.setJiraTicketsToRawIssue;
export const setLoadingJiraStatusUpdate =
  pipelineIssuesStoreActions.setLoadingJiraStatusUpdate;
export const setDrawerJiraTicketsStatus =
  pipelineIssuesStoreActions.setDrawerJiraTicketsStatus;
export const setDrawerTicketsStatus =
  pipelineIssuesStoreActions.setDrawerTicketsStatus;
export const setStoreIssuesStatistics =
  pipelineIssuesStoreActions.setStoreIssuesStatistics;
export const setStoreTotalResolvedIssues =
  pipelineIssuesStoreActions.setStoreTotalResolvedIssues;
export const addJiraTicketToSelectedIssue =
  pipelineIssuesStoreActions.addJiraTicketToSelectedIssue;
export const setStoreTotalActiveIssues =
  pipelineIssuesStoreActions.setStoreTotalActiveIssues;
export const resetSelected = pipelineIssuesStoreActions.resetSelected;
export const setExcludedByAlert = pipelineIssuesStoreActions.setExcludedByAlert;
export const setShowUnlinkJiraTicketDialog =
  pipelineIssuesStoreActions.setShowUnlinkJiraTicketDialog;
export const removeJiraTicketsFromSelectedIssue =
  pipelineIssuesStoreActions.removeJiraTicketsFromSelectedIssue;
export const removeJiraTicketsFromRawIssue =
  pipelineIssuesStoreActions.removeJiraTicketsFromRawIssue;
export const setRowIssueTicketsPipelines =
  pipelineIssuesStoreActions.setRowIssueTickets;
export const setShowLinkJiraTicketDialog =
  pipelineIssuesStoreActions.setShowLinkJiraTicketDialog;
export const clearPipelineFilters = () => {
  PipelineIssuesStore.filterIssuesBy = {};
  PipelineIssuesStore.conditionalFilters = [];
  PipelineIssuesStore.offset = 0;
  PipelineIssuesStore.numberOfFilters = 0;
  PipelineIssuesStore.sliderValuePriority = [0, 100];
  navigate(AppPages.PipelineIssues);
  loadIssuesPipeline({ update: true, cache: false });
  getPipelineIssuesFilterItems();
};
export const setSnoozeIssueExpiredDatePipeline =
  pipelineIssuesStoreActions.setSnoozeIssueExpiredDate;

export const setLoadingIssue = pipelineIssuesStoreActions.setLoadingIssue;

export const setSearchValues = pipelineIssuesStoreActions.setSearchValues;

export const setIsLoadingApplicationStatistics =
  pipelineIssuesStoreActions.setIsLoadingApplicationStatistics;

export const setPipelineIssuesConditionalFiltersInStore = (
  conditionalFilters: ConditionalFilter[],
) => {
  PipelineIssuesStore.conditionalFilters = conditionalFilters;
};

export const setSelectedIssueRow =
  pipelineIssuesStoreActions.setSelectedIssueRow;

export const setPipelineIssuesFilterItemsInStore = (
  results: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = results.find(item => item.type === key);
    const selectedFilterItem = PipelineIssuesStore?.filterItems?.[key];
    if (selectedFilterItem && selectedResultFilterItem) {
      selectedFilterItem.items = selectedResultFilterItem.items;
    }
  });
};
