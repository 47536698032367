import {
  AggregationColumn,
  AggregationItem,
  ColumnType,
  IAggregationRow,
  Nullable,
} from 'ox-common-types';
import { createNewDate, filterConfigByTypes } from 'ox-react-components';
import { aggregationsColumnsConfig as config } from '@oxappsec/ox-consolidated-configs';
import { formatDistanceToNow } from 'date-fns';
import { snapshot } from 'valtio';
import { FeatureFlagsStore } from 'newfrontend/src/app/launch-darkly/feature-flags-store';
import { FeatureFlagKeys } from 'newfrontend/src/feature-flags/feature-flags-types';
import { IssueDetails } from 'newfrontend/src/issues/common/types/issues-types';
import { SettingsType } from '@oxappsec/ox-autogenerated-types-frontend-ticketing-service';
import {
  SettingsModel,
  AutoFillSettings,
} from '../../../../apps/web/src/ticketing-settings/types';
import { IssueTypeField } from './types/jira-ticket-types';

const ROWS_LIMIT = 15;
export function generateAggregationsTable(
  aggregations,
  selectedAggs: { [x: string]: string }[],
) {
  if (aggregations.type && config[aggregations.type]) {
    const table: IAggregationRow[] = [];

    const columns: AggregationColumn[] = filterConfigByTypes(
      config[aggregations.type].columns,
      aggregations.items,
    );
    const rowHeader: IAggregationRow = {
      value: [],
    };
    for (const column of columns) {
      rowHeader.value.push({
        value: column.header,
      });
    }

    table.push(rowHeader);
    let filterRows: AggregationItem[] =
      selectedAggs.length > 0 ? selectedAggs : aggregations.items;

    const limtedRows = filterRows.slice(0, ROWS_LIMIT);

    for (const item of limtedRows) {
      const rowData: IAggregationRow = {
        value: [],
      };
      for (const column of columns) {
        const value = item[column.key];
        const date = createNewDate(value);

        rowData.value.push({
          value:
            date && column.type === ColumnType.Date
              ? formatDistanceToNow(date) + ' ago'
              : !!!value
              ? ''
              : String(value),
          href: column.href && item[column.href] ? item[column.href] : null,
        });
      }
      table.push(rowData);
    }
    return table;
  }
  return null;
}

export const getDefaultPriority = (
  issue: IssueDetails,
  settings: SettingsModel[],
  priorityField: Nullable<IssueTypeField>,
): Nullable<string> => {
  const { flags } = snapshot(FeatureFlagsStore);
  const enableJiraAutoPriority =
    flags?.[FeatureFlagKeys.enableJiraAutoPriority];
  if (
    !enableJiraAutoPriority ||
    !priorityField ||
    !settings ||
    settings.length === 0
  ) {
    return null;
  }
  const jiraSettings = settings[0];
  const prioritySettings = jiraSettings.settings.find(
    setting =>
      setting.settingsType === SettingsType.autoFill &&
      (setting as AutoFillSettings).fieldKey === 'priority',
  ) as AutoFillSettings;
  if (prioritySettings) {
    const priorityMapping = prioritySettings.autoFillMap.find(
      item =>
        item.isActive &&
        item.key.toLowerCase() === issue[prioritySettings.autoFillByIssueField],
    );
    if (priorityMapping) {
      const priorityId = priorityField?.allowedValues?.find(
        allowedValuesItem => allowedValuesItem.name === priorityMapping.value,
      )?.id;
      return priorityId ?? null;
    }
  }
  return null;
};
