import { logger } from 'logging-utils';
import { OneOfValues } from 'ox-common-types';
import { Location, NavigateFunction } from 'react-router-dom';
import { snapshot } from 'valtio';
import ArtifactsStore from '../../../apps/web/src/artifacts/stores/artifacts-store';
import { ExclusionsFilters } from '../../../apps/web/src/exclusions/types/exclusion-types';
import { getFilteredFilters } from '../../../apps/web/src/exclusions/utils/exclusion-utils';
import { isEmpty } from 'lodash-es';
import { openSnackbar } from 'snackbar-utils';
import { getLastLoggedInOrg } from '../../../apps/web/src/organizations/utils/local-storage-utils';

let _navigate: NavigateFunction;
let _location: Location;

const history = () => {
  if (!_navigate || !_location) {
    throw new Error('app-navigator has not been initialized.');
  }
  return { _navigate, _location };
};

export const currentPage = (
  removeOrgIdIfExist?: boolean,
): OneOfValues<typeof AppPages> => {
  const { pathname } = history()._location;
  const splitPathName = pathname.split('/');
  const page = splitPathName[splitPathName.length - 1];
  //TODO: check of page is valid
  return removeOrgIdIfExist ? `/${page}` : pathname;
};

export const navigate = (
  page: OneOfValues<typeof AppPages>,
  queryString = '',
): void => {
  const { pathname: currentPath, search: currentQueryString } =
    history()._location;
  const pathChanged = currentPath !== page;
  const queryStringChanged = currentQueryString !== queryString;
  const canPushState = pathChanged || queryStringChanged;

  if (canPushState) {
    history()._navigate({ pathname: page, search: queryString });
  }
};

export const encodeForUrl = (item: unknown): string => {
  const replaceSpecialChars = (str: string) => {
    return str
      .replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
      .replace(/#/g, '%23')
      .replace(/\+/g, '%2B')
      .replace(/&/g, '%26');
  };

  if (typeof item === 'string')
    return encodeURIComponent(replaceSpecialChars(item));
  try {
    const encoded = encodeURIComponent(
      replaceSpecialChars(JSON.stringify(item)),
    );
    return encoded;
  } catch (e) {
    logger.error('Failed to encode for url', e);
    return '';
  }
};

export const decodeFromUrl = (item: string): unknown => {
  try {
    return JSON.parse(decodeURIComponent(item));
  } catch (e) {
    logger.error('Failed to decode from url', e);
    return '';
  }
};

export const navigateWithParameters = (
  /**
   * This function will allow you to navigate to a page with parameters.
   * This function will limit the number of parameters to MAX_URL_LENGTH characters.
   * @param page The name of the page to navigate to.
   * @param newParams The new parameters to append to the URL. If set to false, null, or undefined, the parameter will be removed from the URL.
   * @param persistExistingParameters If set to false, existing parameters will be removed from the URL.
   */
  page: string, // page The name of the page to navigate to.
  newParams: { [queryParamName: string]: unknown }, // newParams The new parameters to append to the URL. If set to false, null, or undefined, the parameter will be removed from the URL.
  persistExistingParameters: boolean = true,
) => {
  const MAX_URL_LENGTH = 10000;
  const getParametersFromURL = () => {
    /**
     * Retrieves the parameters from the current URL.
     * @returns An array containing objects with keys and values of URL parameters.
     */
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const parametersMap: { key: string; value: string }[] = [];

    params.forEach((value, key) => {
      parametersMap.push({ key, value });
    });
    return parametersMap;
  };

  const paramNames = Object.keys(newParams);
  const exitingParams = getParametersFromURL();
  const filteredExistingParams = exitingParams?.reduce(
    (acc: { [queryParamName: string]: string }, existingParam) => {
      const shouldRemoveFiltersParam =
        newParams.conditionalFilters && existingParam.key === 'filters';
      if (paramNames.includes(existingParam.key) || shouldRemoveFiltersParam) {
        return acc;
      }

      acc[existingParam.key] = existingParam.value;
      return acc;
    },
    {},
  );
  const allParams = persistExistingParameters
    ? { ...newParams, ...filteredExistingParams }
    : newParams;
  const appParamsKeys = Object.keys(allParams);
  try {
    const paramsString = appParamsKeys.reduce(
      (acc: string, queryParamName: string, index) => {
        if (!allParams[queryParamName] || isEmpty(allParams[queryParamName]))
          return acc; // This will remove empty or null param from the url
        const paramEncoded =
          filteredExistingParams[queryParamName] ||
          encodeForUrl(newParams[queryParamName]);
        const urlString = `${queryParamName}=${paramEncoded}`;
        if (index === appParamsKeys.length - 1) {
          acc += urlString;
          return acc;
        }
        acc += `${urlString}&`;
        return acc;
      },
      '',
    );

    if (paramsString && paramsString.length > MAX_URL_LENGTH) {
      openSnackbar(
        'Too many parameters selected, please clear some parameters',
        {
          variant: 'error',
        },
      );
      return;
    }

    navigate(page, paramsString);
  } catch (e) {
    logger.error(`Failed to navigate to ${page} page`, e);
  }
};

export const setParamsToUrl = (
  /**
   * This function will allow you to add or remove params from url without navigation so back button will work correctly.
   * @param newParams The new parameters to append to the URL. If set to false, null, or undefined, the parameter will be removed from the URL.
   */
  newParams: { [queryParamName: string]: unknown },
) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  Object.entries(newParams).forEach(([key, value]) => {
    // This will remove empty or null param from the url
    if (value === null || value === undefined) {
      searchParams.delete(key);
      return;
    }
    // If the parameter already exists, replace its value
    if (searchParams.has(key)) {
      searchParams.set(key, String(value));
    } else {
      // Otherwise, add it to the URL
      searchParams.append(key, String(value));
    }
  });

  // Update the URL without reloading the page
  window.history.replaceState({}, '', url.toString());
};

export const navigateToResolvedIssues = (filters: {}) => {
  try {
    const encodedFilters = encodeURI(JSON.stringify(filters));
    navigate(AppPages.ResolvedIssues, `filters=${encodedFilters}`);
  } catch (e) {
    logger.error('Failed to navigate to issues page', e);
  }
};

export const navigateWithFilters = (page: string, filters: {}) => {
  try {
    navigateWithParameters(page, { filters });
  } catch (e) {
    logger.error('Failed to navigate to page', e);
  }
};

export const getPageWithFilters = (page: string, filters: {}) => {
  try {
    const encodedFilters = encodeURI(JSON.stringify(filters));
    return `${page}?filters=${encodedFilters}`;
  } catch (e) {
    logger.error('Failed to encodeURI for getPageWithFilters', e);
    return null;
  }
};

export const navigateToSettings = (tab: string) => {
  try {
    navigate(AppPages.Settings, `tab=${tab}`);
  } catch (e) {
    logger.error('Failed to navigate to page', e);
  }
};

export const navigateToConnector = (connectorId: string) => {
  try {
    navigate(AppPages.Connectors, `connectorId=${connectorId}`);
  } catch (e) {
    logger.error('Failed to navigate to page', e);
  }
};

export const navigateToPipelineSummaryIssues = (filters: {}) => {
  try {
    const encodedFilters = encodeURI(JSON.stringify(filters));
    navigate(AppPages.PipelineSummary, `filters=${encodedFilters}`);
  } catch (e) {
    logger.error('Failed to navigate to issues page', e);
  }
};

export const navigateToWorkflow = (
  workflowId: string,
  workflowType: string,
  selectedNodes?: string[],
) => {
  const encodedId = encodeURIComponent(workflowId);
  const selectedNodesStringified =
    selectedNodes?.length && JSON.stringify(selectedNodes);
  navigate(
    AppPages.PolicyWorkflow,
    `workflowType=${workflowType}&id=${encodedId}${
      selectedNodesStringified
        ? `&selectedNodes=${encodeURIComponent(selectedNodesStringified)}`
        : ''
    }`,
  );
};

export const navigateToWorkflowType = (workflowType: string) => {
  navigate(AppPages.WorkflowManagement, `workflowType=${workflowType}`);
};

export const navigateToArtifactsType = (artifactsType: string) => {
  navigate(AppPages.Artifacts, `artifactsType=${artifactsType}`);
}

export const navigateToApps = (filters: {}) => {
  try {
    const encodedFilters = encodeURIComponent(JSON.stringify(filters)).replace(
      /%(?![0-9][0-9a-fA-F]+)/g,
      '%25',
    );

    navigate(AppPages.Applications, `filters=${encodedFilters}`);
  } catch (e) {
    logger.error('Failed to navigate to applications page', e);
  }
};

export const navigateToArtifacts = () => {
  const {
    filterArtifactsBy,
    topFiltersArtifactsBy,
    selectedArtifactId,
    hashSearch,
  } = snapshot(ArtifactsStore);
  try {
    let paramsString = '';
    const filtersExist =
      filterArtifactsBy && Object.keys(filterArtifactsBy).length > 0;
    const topFiltersExist =
      topFiltersArtifactsBy && Object.keys(topFiltersArtifactsBy).length > 0;

    if (filtersExist) {
      const encodedFilters = encodeURIComponent(
        JSON.stringify(filterArtifactsBy),
      );
      paramsString += `filters=${encodedFilters}`;
    }
    if (selectedArtifactId) {
      paramsString += `&artifactId=${selectedArtifactId}`;
    }
    if (topFiltersExist) {
      const encodedTopFilters = encodeURIComponent(
        JSON.stringify(topFiltersArtifactsBy),
      );
      paramsString += `&topFilters=${encodedTopFilters}`;
    }
    if (hashSearch) {
      paramsString += `&hashSearch=${hashSearch}`;
    }
    navigate(AppPages.Artifacts, paramsString);
  } catch (e) {
    logger.error('Failed to navigate to artifacts page', e);
  }
};

export const navigateToExclusions = (filters?: ExclusionsFilters) => {
  try {
    const filteredFilters = getFilteredFilters(filters) || {};

    if (!filteredFilters || Object.keys(filteredFilters).length === 0) {
      navigate(AppPages.Exclusions);
      return;
    }
    const encodedFilters = encodeURI(JSON.stringify(filteredFilters));
    navigate(AppPages.Exclusions, `filters=${encodedFilters}`);
  } catch (e) {
    logger.error('Failed to navigate to exclusions page', e);
  }
};

export const navigateToSbom = (filters: {}) => {
  try {
    const encodedFilters = encodeURIComponent(JSON.stringify(filters));
    navigate(AppPages.Sbom, `filters=${encodedFilters}`);
  } catch (e) {
    logger.error('Failed to navigate to artifacts page', e);
  }
};

export const navigateToConnectors = () => {
  navigate(AppPages.Connectors);
};

export const navigateTospesificPolicy = (
  categoryId: string,
  policyId: string,
) => {
  return AppPages.Policies + `?categoryId=${categoryId}&policyId=${policyId}`;
};

export const navigateToSpecificApp = (appId: string) => {
  appId &&
    navigate(AppPages.Applications, `appId=${encodeURIComponent(appId)}`);
};

export const getQueryString = () => {
  return new URLSearchParams(history()._location.search);
};

export const getParamFromUrl = (paramName: string) => {
  const params = getQueryString();
  return params.get(paramName);
};

export const setupRoutingObjects = (
  navigate: NavigateFunction,
  location: Location,
) => {
  _navigate = navigate;
  _location = location;
};

export const useQuery = (): URLSearchParams => {
  const queryString = history()._location.search;
  return new URLSearchParams(queryString);
};

const addOrgIdToAppPages = (pages: object) => {
  const orgId = getLastLoggedInOrg()?.id || '';
  const result: { [key: string]: string } = {};
  for (const [key, value] of Object.entries(pages)) {
    result[key] = orgId ? `/${orgId}${value}` : value;
  }
  return result;
};

export const AppsPagesNotContainOrgId = {
  Home: '/',
  Applications: '/applications',
  Connectors: '/connectors',
  Settings: '/settings',
  Policies: '/policies',
  Members: '/members',
  IrrelevantApps: '/irrelevant-apps',
  Exclusions: '/exclusions',
  Unauthorized: '/unauthorized',
  InviteOnly: '/invite-only',
  OrgLogin: '/login',
  OrgSsoLogin: '/sso-login',
  OrgSignup: '/signup',
  IDPConfigure: '/identity-provider-configure',
  GitHubAppInstallationConfigure: '/github-app-installation-configure',
  GitHubAppROInstallationConfigure: '/github-app-ro-installation-configure',
  BitbucketAppInstallationConfigure: '/bitbucket-app-installation-configure',
  Onboarding: '/onboarding',
  Dashboard: '/dashboard',
  ActiveIssues: '/issues',
  ResolvedIssues: '/resolved-issues',
  PipelineIssues: '/pipeline-issues',
  RemovedIssues: '/removed-issues',
  EmailVerification: 'email-verification',
  Bom: '/bom',
  Sbom: '/sbom',
  Artifacts: '/artifacts',
  PipelineSummary: '/pipeline-summary',
  AuditLogs: '/audit-logs',
  ScanHistory: '/scan-history',
  ReleaseNotes: '/release-notes',
  OSCAR: '/oscar',
  WorkflowManagement: '/workflow-management',
  PolicyWorkflow: '/policy-workflow',
  WhatsNew: '/whats-new',
  WorkflowExecutionLogs: '/workflow-execution-logs',
  ApiInventory: '/api-inventory',
  AppSecHero: '/appsechero',
  SaasBom: '/saas-bom',
  ExecutiveReport: '/executive-report',
  RequestAccess: '/request-access',
  CloudBom: '/cloud-bom',
  CloudAssets: '/cloud-assets',
};

export const AppPages = addOrgIdToAppPages(AppsPagesNotContainOrgId);

export const AppPagesDisplayName = {
  home: 'Home',
  bom: 'BOM',
  applications: 'Applications',
  connectors: 'Connectors',
  settings: 'Settings',
  policies: 'Policies',
  members: 'Users',
  'irrelevant-applications': 'Irrelevant Applications',
  exclusions: 'Exclusions',
  unauthorized: 'Unauthorized',
  'invite-only': 'InviteOnly',
  login: 'Login',
  signup: 'Signup',
  'identity-provider-configure': 'IDP Configure',
  'github-app-installation-configure': 'GitHub App Installation Configure',
  'github-app-ro-installation-configure':
    'GitHub App RO Installation Configure',
  'bitbucket-app-installation-configure':
    'Bitbucket App Installation Configure',
  onboarding: 'Onboarding',
  dashboard: 'Dashboard',
  issues: 'Issues',
  'resolved-issues': 'Resolved Issues',
  'pipeline-issues': 'Pipeline Issues',
  'removed-issues': 'Removed Issues',
  'mail-verification': 'Email Verification',
  sbom: 'SBOM',
  artifacts: 'Artifacts',
  'pipeline-summary': 'Pipeline Summary',
  'audit-logs': 'Audit Logs',
  'scan-history': 'Scan History',
  'release-notes': 'Release Notes',
  oscar: 'OSCAR',
  'workflow-management': 'Workflow Management',
  'policy-workflow': 'Policy Workflow',
  'whats-new': 'Whats New',
  'workflow-execution-logs': 'Workflow Execution Logs',
  'api-inventory': 'Api Inventory',
  appsechero: 'AppSec Hero',
  'executive-report': 'Executive Report',
  'request-access': 'Request Access',
};
