import { Theme, TooltipProps, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as InfoIconSvg } from './assets/info.svg';
import { CustomTooltip } from './CustomTooltip';

export const InfoIcon: FC<React.PropsWithChildren<InfoIconProps>> = props => {
  const { marginX = 0, title, className, customColor, ...tooltipProps } = props;
  const theme = useTheme();
  const { classes, cx } = useStyles();

  return (
    <CustomTooltip
      maxWidth={400}
      placement='top'
      arrow
      {...tooltipProps}
      title={title ? <div className={classes.title}>{title}</div> : ''}>
      <span style={{ display: 'inline-flex' }}>
        <InfoIconSvg
          data-testid='InfoIconSvg'
          className={cx(classes.icon, className)}
          color={customColor ? customColor : 'theme.palette.text.secondary'}
          style={{
            margin: theme.spacing(0, marginX),
          }}
        />
      </span>
    </CustomTooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  icon: {
    verticalAlign: 'middle',
    width: '1em',
    height: '1em',
  },
}));

interface InfoIconProps extends Omit<TooltipProps, 'children'> {
  children?: ReactElement<unknown, string>;
  marginX?: number;
  customColor?: string;
  className?: string;
}
