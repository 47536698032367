export enum FieldName {
  token = 'token',
  name = 'name',
  password = 'password',
  gitPassword = 'gitPassword',
  gitUsername = 'gitUsername',
  hostURL = 'hostURL',
  clientId = 'clientId',
  secretKey = 'secretKey',
  awsRoleArn = 'awsRoleArn',
  awsExternalId = 'awsExternalId',
  awsAccessKey = 'awsAccessKey',
  awsAccessSecret = 'awsAccessSecret',
  tenant = 'tenant',
  projectId = 'projectId',
  apiAccessKey = 'apiAccessKey',
  apiSecretKey = 'apiSecretKey',
  tenantId = 'tenantId',
  clientSecret = 'clientSecret',
  subscriptionId = 'subscriptionId',
  organizationId = 'organizationId',
  apiKey = 'apiKey',
  apiUrl = 'apiUrl',
  appId = 'appId',
  optionalFields = 'optionalFields',
  webhookUrl = 'webhookUrl',
  brokerUsername = 'brokerUsername',
  brokerPassword = 'brokerPassword',
  brokerSSHPublicKey = 'brokerSSHPublicKey',
  credentialsName = 'credentialsName',
  brokerEnabled = 'brokerEnabled',
}

export enum CredentialsType {
  UserPassword = 'UserPassword',
  Token = 'Token',
  TokenAndUser = 'TokenAndUser',
  IdentityProvider = 'IdentityProvider',
  ClientIdSecretKey = 'ClientIdSecretKey',
  AWSAssumeRole = 'AWSAssumeRole',
  AWSAssumeRoleCloudFormation = 'AWSAssumeRoleCloudFormation',
  AWSOrganizationConnection = 'AWSOrganizationConnection',
  AWSAssumeRoleOnprem = 'AWSAssumeRoleOnprem',
  AWSAssumeRoleCloudFormationOnprem = 'AWSAssumeRoleCloudFormationOnprem',
  AWSOrganizationConnectionOnprem = 'AWSOrganizationConnectionOnprem',
  AWSAssumeRoleCodeCommit = 'AWSAssumeRoleCodeCommit',
  AWSAssumeRoleCodeCommitOrganization = 'AWSAssumeRoleCodeCommitOrganization',
  AWSAssumeRoleCodeCommitOnprem = 'AWSAssumeRoleCodeCommitOnprem',
  AWSAssumeRoleCodeCommitOrganizationOnprem = 'AWSAssumeRoleCodeCommitOrganizationOnprem',
  UserPasswordAndTenant = 'UserPasswordAndTenant',
  TokenAndProjectId = 'TokenAndProjectId',
  TenantClientsubscriptionIdSecret = 'TenantClientsubscriptionIdSecret',
  TenantIdClientIdClientSecret = 'TenantIdClientIdClientSecret',
  APISecretAndAccessKey = 'APISecretAndAccessKey',
  None = 'None',
  ApiKey = 'ApiKey',
  GitHubApp = 'GitHubApp',
  BitbucketApp = 'BitbucketApp',
  OrganizationIdAndApiKey = 'OrganizationIdAndApiKey',
  UserPasswordOnly = 'UserPasswordOnly',
  ClientIdSecretApiUrl = 'ClientIdSecretApiUrl',
  TokenOnly = 'TokenOnly',
  AWSEKS = 'AWSEKS',
  AWSEKSDirect = 'AWSEKSDirect',
  AWSEKSPrivateLink = 'AWSEKSPrivateLink',
  AzureCloud = 'AzureCloud',
  ServicePrincipal = 'ServicePrincipal',
  AksDirect = 'AksDirect',
  AksRunCommand = 'AksRunCommand',
  ClientIdClientSecret = 'ClientIdClientSecret',
  AppIdAndToken = 'AppIdAndToken',
  Webhook = 'Webhook',
  Broker = 'Broker',
  K8sInspector = 'K8sInspector',
}

export const CredentialsTypeTitle = {
  [CredentialsType.UserPassword]: 'User Name & Password',
  [CredentialsType.UserPasswordOnly]: 'User Name & Password',
  [CredentialsType.Token]: 'Token',
  [CredentialsType.TokenAndUser]: 'User Name & Token',
  [CredentialsType.IdentityProvider]: 'Identity Provider',
  [CredentialsType.ClientIdSecretKey]: 'Access keys',
  [CredentialsType.AWSAssumeRoleCloudFormation]: 'Automatic',
  [CredentialsType.AWSOrganizationConnection]: 'Organization',
  [CredentialsType.AWSAssumeRole]: 'Manual',
  [CredentialsType.AWSAssumeRoleCloudFormationOnprem]: 'Automatic',
  [CredentialsType.AWSOrganizationConnectionOnprem]: 'Organization',
  [CredentialsType.AWSAssumeRoleOnprem]: 'Manual',
  [CredentialsType.AWSAssumeRoleCodeCommit]: 'AWS Connection',
  [CredentialsType.AWSAssumeRoleCodeCommitOrganization]:
    'AWS Organization Connection',
  [CredentialsType.AWSAssumeRoleCodeCommitOnprem]: 'AWS Connection',
  [CredentialsType.AWSAssumeRoleCodeCommitOrganizationOnprem]:
    'AWS Organization Connection',
  [CredentialsType.UserPasswordAndTenant]: 'Credentials',
  [CredentialsType.ApiKey]: 'API key',
  [CredentialsType.TokenAndProjectId]: 'Token & Project Id',
  [CredentialsType.ClientIdSecretApiUrl]: 'Client Id & Client Secret & API URL',
  [CredentialsType.TokenOnly]: 'Token',
  [CredentialsType.AWSEKS]: 'EKS Cluster',
  [CredentialsType.AWSEKSDirect]: 'EKS',
  [CredentialsType.AWSEKSPrivateLink]: 'Private Link',
  [CredentialsType.TenantClientsubscriptionIdSecret]:
    'Tenant Id & Client Id & Subscription Id & Client Secret',
  [CredentialsType.TenantIdClientIdClientSecret]:
    'Tenant Id & Client Id & Client Secret',
  [CredentialsType.APISecretAndAccessKey]: 'API Secret & API Access key',
  [CredentialsType.GitHubApp]: 'GitHub App',
  [CredentialsType.BitbucketApp]: 'Bitbucket App',
  [CredentialsType.OrganizationIdAndApiKey]: 'Atlassian',
  [CredentialsType.AzureCloud]: 'Azure Cloud Connection',
  [CredentialsType.ServicePrincipal]: 'Service Principal',
  [CredentialsType.AksDirect]: 'AKS Cluster',
  [CredentialsType.AksRunCommand]: 'AKS',
  [CredentialsType.ClientIdClientSecret]: 'Client ID & Client Secret',
  [CredentialsType.AppIdAndToken]: 'App ID & Token',
  [CredentialsType.Webhook]: 'Webhook URL',
  [CredentialsType.Broker]: 'Broker',
  [CredentialsType.K8sInspector]: 'K8s Inspector',
};
