import { decodeFromUrl, navigateToSbom } from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { throttle } from 'lodash-es';
import { Nullable } from 'ox-common-types';
import {
  ConditionalFilter,
  FilterItems,
  FilterPage,
  getFilterItemsLabels,
  getOpenFilterItemIds,
} from 'ox-filter-utils';
import getFilterLabels from 'ox-filter-utils/src/get-filter-labels/get-filter-labels';
import { snapshot } from 'valtio';
import { getSbomApiParams } from '../sbom-utils';
import sbomService from '../services';
import SbomFiltersStore from '../stores/sbom-filters-store';
import { setSbomOpenFilterItemsInStore } from '../stores/sbom-filters-store-actions';
import SbomStore from '../stores/sbom-store';
import { loadSbomLibraries, selectSbom } from './sbom-libraries-actions';

export const onChangeFilter = throttle(
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const { loadingFilters } = snapshot(SbomFiltersStore);
    const { loading } = snapshot(SbomStore);
    if (loadingFilters.isPending() || loading.isPending()) {
      return;
    }

    if (SbomFiltersStore.filterBy[e.target.id]) {
      const index = SbomFiltersStore.filterBy[e.target.id].indexOf(
        e.target.value,
      );
      if (index !== -1) {
        SbomFiltersStore.filterBy[e.target.id].splice(index, 1);
      } else {
        SbomFiltersStore.filterBy[e.target.id].push(e.target.value);
      }
    } else {
      SbomFiltersStore.filterBy[e.target.id] = [e.target.value];
    }
    selectSbom();
    navigateToSbom(SbomFiltersStore.filterBy);
    loadSbomLibraries({ update: true });
  },
  1000,
);

export const setInitialFilters = (encodedFilters: string | null) => {
  if (!encodedFilters) return;
  SbomFiltersStore.filterBy =
    (decodeFromUrl(encodedFilters) as ConditionalFilter[]) || {};
};

export const setSbomFilterLabels = createSimpleAsyncAction(
  async () => {
    const { filterItems } = snapshot(SbomFiltersStore);

    if (!filterItems) {
      const results = await getFilterLabels(FilterPage.Sboms);

      const lazyFilters: FilterItems = getFilterItemsLabels(results);
      SbomFiltersStore.filterItems = lazyFilters;
    }
  },
  {
    asyncState: SbomFiltersStore.loadingFilters,
    errorMessage: 'Failed to load SBOM filter labels',
  },
);

export const getSbomOpenFilterItems = createSimpleAsyncAction(
  async (type?: Nullable<string>, isOpen?: boolean) => {
    const cache = true;
    const limit = 100;
    const currentFilterItem =
      SbomFiltersStore.filterItems &&
      type &&
      SbomFiltersStore.filterItems[type];

    if (currentFilterItem) {
      currentFilterItem.isOpen = Boolean(isOpen);
      currentFilterItem.isLoading = Boolean(isOpen);
    }

    const { filterItems } = snapshot(SbomFiltersStore);
    const filterItem = filterItems && type && filterItems[type];
    const openFilterItemIds = filterItems
      ? getOpenFilterItemIds(filterItems)
      : [];
    const sbomApiParams = getSbomApiParams(limit);

    const currentFilterItemIsClosed = filterItem && !filterItem.isOpen;
    const allFiltersClosed =
      !openFilterItemIds || openFilterItemIds.length === 0;

    if (allFiltersClosed || currentFilterItemIsClosed) {
      if (currentFilterItem) {
        currentFilterItem.isLoading = false;
      }
      return;
    }

    const results = await sbomService.getSbomFiltersLazy.execute(
      { openItems: openFilterItemIds, ...sbomApiParams },
      cache,
    );

    if (currentFilterItem) {
      currentFilterItem.isLoading = false;
    }

    if (results && results.filters && filterItems) {
      setSbomOpenFilterItemsInStore(results.filters, filterItems);
    }
  },
  {
    asyncState: SbomFiltersStore.loadingFilters,
    errorMessage: 'Failed to load SBOM filters',
  },
);
