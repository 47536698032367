import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './update-settings.gql';
import {
  UpdateSettingsInput,
  UpdateSettingsRes,
} from '../../../ticketing-settings/types';

const updateSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (updateSettingsInput: UpdateSettingsInput) => {
      try {
        const results = await client.mutate<UpdateSettingsResponse>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { updateSettingsInput },
        });
        return results.data?.updateSettings.acknowledge;
      } catch (error) {
        logger.error('Failed to update settings', error);
        return null;
      }
    },
  };
};

export default updateSettings;
export interface UpdateSettingsResponse {
  updateSettings: UpdateSettingsRes;
}
