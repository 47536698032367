import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { Channel } from '../types/slack-types';
import ScanStore from '../../../../../apps/web/src/new-scan/store/scan-store';
import { default as SlackChannelsMockData } from '../../../../../apps/web/src/issues/common/mocks/slack-channels.json';

const baseStore = createStore<SlackState>(
  {
    loadingSubmit: false,
    isLoadingSlackChannels: false,
    isLoadingSlackChannelsFallback: false,
    isLoadingSlackParams: false,
    publicFallbackChannels: null,
    publicChannels: null,
    publicChannelsWorkflow: null,
    users: null,
    fields: {
      selectedChannel: null,
      userComment: '',
      selectedWorkflowChannel: null,
      selectedFallbackChannel: null,
      summarizeWorkflowAlerts: false,
    },
    disableTypingOnFirstRequest: false,
  },
  'Slack Store',
);

const SlackStore = derive(
  {
    demoAblePublicChannels: (get): Nullable<Channel[]> => {
      const { publicChannels } = get(baseStore);
      const { isDemoEnabled } = get(ScanStore);
      if (isDemoEnabled) {
        return SlackChannelsMockData.data.getSlackChannelsList.channels;
      } else {
        return publicChannels;
      }
    },
  },
  { proxy: baseStore },
);

interface SlackState {
  publicChannels: Nullable<Channel[]>;
  publicChannelsWorkflow: Nullable<Channel[]>;
  publicFallbackChannels: Nullable<Channel[]>;
  users: Nullable<Channel[]>;
  loadingSubmit: boolean;
  isLoadingSlackChannels: boolean;
  disableTypingOnFirstRequest: boolean;
  isLoadingSlackParams: boolean;
  isLoadingSlackChannelsFallback: boolean;
  fields: {
    selectedChannel: Nullable<Channel>;
    selectedWorkflowChannel: Nullable<Channel>;
    selectedFallbackChannel?: Nullable<Channel>;
    userComment: string;
    summarizeWorkflowAlerts: boolean;
  };
}

export default SlackStore;
