import { UIEvent, useCallback, useRef } from 'react';

export const useInfinityScroll = ({
  threshold,
  load,
  withTopScroll,
  loading,
}: InfinityScrollOptions) => {
  const lastScrollTop = useRef(0);
  const internalLoading = useRef(false);
  const onScroll = useCallback(
    async (event: UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

      if (
        (scrollTop + clientHeight) / scrollHeight > threshold &&
        !loading &&
        !internalLoading.current
      ) {
        internalLoading.current = true;
        load().then(() => {
          internalLoading.current = false;
        });
      }
      // Check for scroll up and close to top
      if (
        withTopScroll &&
        scrollTop < lastScrollTop.current && // User is scrolling upwards
        scrollTop <= 20 && // User is close to the top
        !loading &&
        !internalLoading.current
      ) {
        internalLoading.current = true;
        load({ scrollDirection: ScrollDirection.Top }).then(() => {
          internalLoading.current = false;
        });
      }

      lastScrollTop.current = scrollTop; // Update the last recorded scroll position
    },
    [load, loading, threshold, withTopScroll],
  );

  return { onScroll };
};

export interface InfinityScrollOptions {
  threshold: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  load: (params?: any) => Promise<void>;
  withTopScroll?: boolean;
  loading?: boolean;
}

export enum ScrollDirection {
  Top = 'top',
  Bottom = 'bottom',
}
