import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { IconType } from 'react-icons';
import {
  BiLink as LinkToTicketIcon,
  BiUnlink as UnlinkTicketIcon,
} from 'react-icons/bi';
import { SiAsana, SiGithub, SiJira, SiMicrosoftazure } from 'react-icons/si';
import { TbBrandMonday } from 'react-icons/tb';
import { snapshot } from 'valtio';
import { ReactComponent as ServiceNowIcon } from '../../../assets/icons/servicenow-action-icon.svg';
import ConnectorsStore from '../../../connectors/stores/connectors-store';
import { ticketingActions } from '../../../ticketing-module/actions/ticketing-actions';
import {
  TicketingVendor,
  TicketingVendorDisplayName,
} from '../../../ticketing-module/types/ticketing-types';
import { setMenuIssueId } from '../../active-issues/actions/active-issues-actions';
import { openConnectorsNotConnectedModal } from '../../active-issues/connectors-not-connected-modal/actions/connector-not-connected-modal-actions';
import { ConnectorsNotConnectedModalTypes } from '../../active-issues/connectors-not-connected-modal/types/connector-not-connected-modal-types';
import { setMenuIssueIdPipeline } from '../../pipeline-issues/actions/pipeline-issues-actions';
import {
  BulkIssuesAction,
  IssueAction,
  IssuePages,
} from '../types/issues-types';

const ticketingPermissions = [
  {
    permissionScope: PermissionScope.Edit,
    permissionType: PermissionTypes.TicketingCreate,
  },
];

export const resolveTicketingVendorIcon: Record<TicketingVendor, IconType> = {
  [TicketingVendor.AzureBoards]: SiMicrosoftazure,
  [TicketingVendor.Jira]: SiJira,
  [TicketingVendor.Asana]: SiAsana,
  [TicketingVendor.Monday]: TbBrandMonday,
  [TicketingVendor.GithubIssues]: SiGithub,
  [TicketingVendor.ServiceNow]: ServiceNowIcon as IconType,
};

export const getTicketingActions = (
  issuesPage: IssuePages,
  issueId: string,
  showUnlinkTicket: boolean,
  preActionFunction?: (issueId: string) => Promise<void>,
): IssueAction[] => {
  const { connectedTicketingConnectors } = snapshot(ConnectorsStore);
  const atLeastOneTicketingConnectorConnected = Object.values(
    connectedTicketingConnectors,
  ).some(c => c.isConfigured);
  if (!atLeastOneTicketingConnectorConnected) {
    return [
      {
        actionId: 'jira-actions',
        title: 'Jira Actions',
        tooltipText: 'Jira is not connected',
        icon: SiJira,
        onClick: () => {
          openConnectorsNotConnectedModal(
            ConnectorsNotConnectedModalTypes.Jira,
          );
        },
        permissions: ticketingPermissions,
        highlight: true,
        relevantIssuePages: [
          IssuePages.CurrentIssues,
          IssuePages.PipelineIssues,
        ],
      },
    ];
  }

  const handleCreateTickets = async (ticketingVendor: TicketingVendor) => {
    if (!atLeastOneTicketingConnectorConnected) {
      openConnectorsNotConnectedModal(
        ConnectorsNotConnectedModalTypes[ticketingVendor],
      );
      return;
    }

    if (preActionFunction) {
      await preActionFunction(issueId);
    }

    ticketingActions.openCreateTicketModal(ticketingVendor);
    setMenuIssueId(null);
    setMenuIssueIdPipeline(null);
  };

  const handleLinkUnlinkTicket = async (
    ticketingVendor: TicketingVendor,
    operation: 'link' | 'unlink',
  ) => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    ticketingActions.openLinkUnlinkTicketModal(ticketingVendor, operation);

    setMenuIssueId(null);
    setMenuIssueIdPipeline(null);
  };

  return Object.keys(connectedTicketingConnectors)
    .map(connectorName => {
      if (!connectedTicketingConnectors[connectorName]?.isConfigured) {
        return null;
      }
      // TODO: remove this once we move Jira into this implementation
      if (connectorName === 'Jira') {
        return null;
      }
      return {
        actionId: `${connectorName}-actions`,
        title: `${TicketingVendorDisplayName[connectorName]} Actions`,
        tooltipText: connectedTicketingConnectors[connectorName]
          .isConnectorInvalid
          ? `Access to ${TicketingVendorDisplayName[connectorName]} is expired or revoked`
          : `${TicketingVendorDisplayName[connectorName]} Actions`,
        disabled:
          connectedTicketingConnectors[connectorName]?.isConnectorInvalid,
        icon: resolveTicketingVendorIcon[connectorName],
        onClick: () => {},
        permissions: ticketingPermissions,
        highlight: true,
        relevantIssuePages: [
          IssuePages.CurrentIssues,
          IssuePages.PipelineIssues,
        ],
        subMenuActions: [
          {
            actionId: `create-${connectorName}-ticket`,
            title: `Create ${TicketingVendorDisplayName[connectorName]} Ticket`,
            tooltipText: `Create ${TicketingVendorDisplayName[connectorName]} Ticket`,
            icon: resolveTicketingVendorIcon[connectorName],
            onClickFunctionParams: [connectorName],
            onClick: handleCreateTickets,
            highlight: false,
            relevantIssuePages: [
              IssuePages.CurrentIssues,
              IssuePages.PipelineIssues,
            ],
            permissions: ticketingPermissions,
            disabled:
              connectedTicketingConnectors[connectorName]?.isConnectorInvalid,
          },
          {
            actionId: `link-${connectorName}-ticket`,
            title: `Link ${TicketingVendorDisplayName[connectorName]} Ticket`,
            tooltipText: `Link ${TicketingVendorDisplayName[connectorName]} Ticket`,
            icon: LinkToTicketIcon,
            onClick: handleLinkUnlinkTicket,
            onClickFunctionParams: [connectorName, 'link'],
            highlight: false,
            relevantIssuePages: [
              IssuePages.CurrentIssues,
              IssuePages.PipelineIssues,
            ],
            permissions: ticketingPermissions,
            disabled:
              connectedTicketingConnectors[connectorName]?.isConnectorInvalid,
          },
          {
            actionId: `unlink-${connectorName}-ticket`,
            title: `Unlink ${TicketingVendorDisplayName[connectorName]} Ticket`,
            tooltipText: `Unlink ${TicketingVendorDisplayName[connectorName]} Ticket`,
            icon: UnlinkTicketIcon,
            onClick: handleLinkUnlinkTicket,
            onClickFunctionParams: [connectorName, 'unlink'],
            highlight: false,
            hideAction: !showUnlinkTicket,
            relevantIssuePages: [
              IssuePages.CurrentIssues,
              IssuePages.PipelineIssues,
            ],
            permissions: ticketingPermissions,
            disabled:
              connectedTicketingConnectors[connectorName]?.isConnectorInvalid,
          },
        ],
      };
    })
    .filter(Boolean) as IssueAction[];
};

export const getBulkTicketingActions = ({
  selectedCount,
}: {
  selectedCount: number;
}): BulkIssuesAction[] => {
  const { connectedTicketingConnectors } = snapshot(ConnectorsStore);
  const atLeastOneTicketingConnectorConnected = Object.values(
    connectedTicketingConnectors,
  ).some(c => c.isConfigured);
  if (!atLeastOneTicketingConnectorConnected) {
    return [
      {
        disabled: selectedCount === 0,
        text:
          selectedCount === 0 ? 'No issues selected' : 'Jira is not connected',
        icon: SiJira,
        onClick: () => {
          openConnectorsNotConnectedModal(
            ConnectorsNotConnectedModalTypes.Jira,
          );
        },
        permissions: ticketingPermissions,
      },
    ];
  }

  const handleTicketingCreateTicket = (provider: TicketingVendor) => {
    const isActiveConnectionByVendor =
      connectedTicketingConnectors[provider]?.isConfigured &&
      !connectedTicketingConnectors[provider]?.isConnectorInvalid;
    if (isActiveConnectionByVendor) {
      ticketingActions.openCreateBulkTicketModal(provider);
    } else {
      openConnectorsNotConnectedModal(
        ConnectorsNotConnectedModalTypes[provider],
      );
    }
  };

  return Object.keys(connectedTicketingConnectors)
    .map(connectorName => {
      if (!connectedTicketingConnectors[connectorName]?.isConfigured) {
        return null;
      }
      // TODO: remove this once we move Jira into this implementation
      if (connectorName === 'Jira') {
        return null;
      }
      return {
        disabled:
          selectedCount === 0 ||
          !connectedTicketingConnectors[connectorName]?.isConfigured ||
          connectedTicketingConnectors[connectorName]?.isConnectorInvalid,
        text:
          selectedCount === 0
            ? 'No issues selected'
            : !connectedTicketingConnectors[connectorName].isConnectorInvalid
            ? `Create New ${TicketingVendorDisplayName[connectorName]} Ticket`
            : `Access to ${TicketingVendorDisplayName[connectorName]} is expired or revoked`,
        icon: resolveTicketingVendorIcon[connectorName],
        onClick: () =>
          handleTicketingCreateTicket(connectorName as TicketingVendor),
        permissions: ticketingPermissions,
      };
    })
    .filter(Boolean) as BulkIssuesAction[];
};
