import { createIssuesActions } from '../../common/actions/create-issues-actions';
import pipelineIssuesService from '../services';
import * as storeActions from '../store-actions/pipeline-issues-store-actions';
import PipelineIssuesStore from '../stores/pipeline-issues-store';

const pipelineIssuesActions = createIssuesActions(
  PipelineIssuesStore,
  pipelineIssuesService,
  storeActions,
);

// export actions individually
export const loadIssuesPipeline = pipelineIssuesActions.loadIssues;
export const setIssueDrawerPipeline = pipelineIssuesActions.setIssueDrawer;
export const setLoadingIssuePipeline = pipelineIssuesActions.setLoadingIssue;
export const handleIssueRowClickPipeline =
  pipelineIssuesActions.handleIssueRowClick;
export const sortIssuesPipeline = pipelineIssuesActions.sortIssues;
export const setMenuIssueIdPipeline = pipelineIssuesActions.setMenuIssueId;
export const updateJiraTicketsLatestStatus =
  pipelineIssuesActions.updateJiraTicketsLatestStatus;
