import {
  TicketingAction,
  TicketingVendor,
  TicketingVendorDisplayName,
} from '../types/ticketing-types';
import { SiJira, SiMicrosoftazure, SiAsana, SiGithub } from 'react-icons/si';
import { TbBrandMonday } from 'react-icons/tb';
import { IoTicket } from 'react-icons/io5';
import { useSnapshot } from 'valtio';
import { CreateTicketStore } from '../store/create-ticket-store';
import { Nullable } from 'ox-common-types';
import { TicketingStore } from '../store/ticketing-store';
import { ReactComponent as ServiceNowIcon } from '../../assets/icons/servicenow-action-icon.svg';

export const getTicketingActionModalHeader = (
  action: TicketingAction,
  provider: TicketingVendor,
) => {
  switch (action) {
    case TicketingAction.CreateTicket:
      return `Create ${TicketingVendorDisplayName[provider]} Ticket`;
    case TicketingAction.BulkCreateTicket:
      return `Bulk Create ${TicketingVendorDisplayName[provider]} Ticket`;
    case TicketingAction.Link:
      return `Link ${TicketingVendorDisplayName[provider]} Ticket`;
    case TicketingAction.Unlink:
      return `Unlink ${TicketingVendorDisplayName[provider]} Ticket`;
    default:
      return '';
  }
};

export const getTicketingActionSubmitButtonLabel = (
  action: TicketingAction,
) => {
  switch (action) {
    case TicketingAction.CreateTicket:
      return `Create Ticket`;
    case TicketingAction.BulkCreateTicket:
      return `Create Tickets`;
    case TicketingAction.Link:
      return `Link Ticket`;
    case TicketingAction.Unlink:
      return `Unlink Ticket`;
    default:
      return '';
  }
};

export const useIsSubmitDisabled = (action: Nullable<TicketingAction>) => {
  const {
    fieldsConfig,
    customFieldsConfig,
    inputControlObject,
    isLoadingInputFields,
    inputSelectedControlObject,
  } = useSnapshot(CreateTicketStore, { sync: true });
  const { ticketKeyInputControl } = useSnapshot(TicketingStore, { sync: true });
  switch (action) {
    case TicketingAction.BulkCreateTicket:
    case TicketingAction.CreateTicket:
      return (
        isLoadingInputFields ||
        fieldsConfig
          .concat(customFieldsConfig)
          .some(
            field =>
              field.required &&
              !inputControlObject[field.id] &&
              !inputSelectedControlObject[field.id],
          )
      );
    case TicketingAction.Link:
    case TicketingAction.Unlink:
      return !ticketKeyInputControl.length;
    default:
      return true;
  }
};

export const getTicketingVendorIcon = (provider: TicketingVendor) => {
  switch (provider) {
    case TicketingVendor.Jira:
      return SiJira;
    case TicketingVendor.AzureBoards:
      return SiMicrosoftazure;
    case TicketingVendor.Asana:
      return SiAsana;
    case TicketingVendor.GithubIssues:
      return SiGithub;
    case TicketingVendor.ServiceNow:
      return ServiceNowIcon;
    case TicketingVendor.Monday:
      return TbBrandMonday;
    default:
      return IoTicket;
  }
};

export const isCustomFieldsSupported = (
  provider: Nullable<TicketingVendor> | string,
) => {
  if (!provider) {
    return false;
  }

  const supportedProviders = [TicketingVendor.AzureBoards].flatMap(vendor => [
    vendor,
    vendor.toString(),
  ]);

  return supportedProviders.includes(provider);
};
