import { createTheme, Grow, Zoom } from '@mui/material';
import { sharedTypography } from './shared/theme-shared-typography';
import { themeBaseColors } from './shared/theme-based-colors';

type EnhancedWindow = Window & { safari: string | undefined };
const w = window as unknown as EnhancedWindow;

const { dark } = themeBaseColors;
const { typography } = sharedTypography;

export const oxDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#0F1321',
      default: '#191D25',
    },
    dashInventory: {
      textColor: {
        colorRelevant: '#E5E5E5',
        colorAppsText: '#7D68FF',
      },
    },
    primary: {
      main: '#9A8AFE',
      light: '#B39BFF',
    },
    secondary: {
      main: '#A7ACFF',
    },
    text: {
      primary: '#E5E5E5',
      secondary: '#E5E5E5AA',
      link: '#5059FF',
      disabled: '#E5E5E5',
    },
    info: {
      main: '#FFA302',
    },
    success: {
      main: '#33C600',
      disabled: '#C7EEB7',
    },
    warning: {
      main: '#FA8D83',
    },
    error: {
      main: '#FA1414',
      disabled: '#FFB2B2',
    },
    grey: {
      '100': '#E5E5E5',
    },
    progress: {
      backgroundColor: '#37375C',
      barColor: 'white',
      trackColor: '#696985',
      textColor: 'white',
    },
    badge: {
      connected: '#33C600',
      notConnected: '#FFA302',
      connectionIssue: '#FA1414',
      comingSoon: '#F1F2F1',
    },
    panel: {
      panelHeader: {
        backgroundColor: '#1C1F2A',
      },
    },
    coverageColors: {
      clientCoverage: '#A7ACFF',
      oxCoverage: '#6837FF',
      noCoverage: '#FA8D83',
      notApplicable: '#E5E5E5',
    },
    categoryRiskColor: {
      appox: '#8B30AB',
      appoxalypse: dark.severity.appoxalypse,
      critical: dark.severity.critical,
      high: dark.severity.high,
      medium: dark.severity.medium,
      low: dark.severity.low,
      info: dark.severity.info,
      passed: '#33C600',
      none: '#E5E5E5',
      noIssues: '#a9d295',
    },
    statButton: {
      stat: {
        border: '#ffffff40',
      },
      active: {
        background: '#7D68FF',
        border: '#7179fe80',
      },
      hover: {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      disabled: {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      increase: {
        main: '#E5E5E5',
      },
      decrease: {
        main: '#E5E5E5',
      },
    },
    workflow: {
      nodes: {
        Action: '#5C94ED',
        Condition: '#FFC051',
        Trigger: '#33C600',
        Pipeline: '#6837FF',
      },
    },
    onboarding: {
      codeRepoButton: {
        background: '#FFFFFF',
        text: '#37375C',
        selected:
          'linear-gradient(0deg, rgba(80, 89, 255, 0.1), rgba(80, 89, 255, 0.1)), #cabaff',
      },
      oxLogo: {
        color: '#FFFFFF',
      },
    },
    gptContent: {
      fontFamily:
        'Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
      answerBgColor: '#444654',
      questionBgColor: '#343541',
      fontColor: 'rgb(209, 213, 219)',
    },
  },
  typography: { ...typography },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'inherit',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent: w['safari'] !== undefined ? Zoom : Grow,
      },
      styleOverrides: {
        arrow: {
          '&:before': {
            backgroundColor: '#3D414E',
          },
        },
        tooltip: {
          opacity: 1,
          backgroundImage: 'inherit',
          backgroundColor: '#3D414E',
          filter: 'drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.35))',
        },
      },
    },
  },
});
