import { IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { getCountryByCode, isValidHttpUrl } from 'common-utils';
import { formatDistanceToNow } from 'date-fns';
import {
  AggregationColumn,
  AggregationItem,
  ColumnType,
  Nullable,
} from 'ox-common-types';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AggregationContextItem, createNewDate } from './aggregation-utils';
import { CustomLink } from './elements/CustomLink';
import AggregationContext from './elements/AggregationContext';
import { User } from './elements/User';
import { ModalDialog } from '../ModalDialog';
import { PiFileMagnifyingGlassThin } from 'react-icons/pi';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import CodeLocationList from '../ApiCallstack/CodeLocationList';

import { isDevelopment } from 'env-utils';
const AggregatedContentResolver: FC<
  PropsWithChildren<ContentResolverProps>
> = ({ item, column }) => {
  let content: Nullable<ReactNode> = null;
  const value = item[column.key];
  const date = createNewDate(value);
  const { classes, cx } = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (column.href && column.tooltip) {
    content = (
      <Tooltip title={item[column.tooltip]} className={classes.link}>
        <div>
          <Typography
            variant='body2'
            color='text.primary'
            className={cx(classes.overflow)}>
            {value}
          </Typography>
          {isValidHttpUrl(item[column.href]) && (
            <CustomLink
              textClassName={classes.overflow}
              href={item[column.href]}
              value={''}
            />
          )}
        </div>
      </Tooltip>
    );
  } else if (
    column.href &&
    (isValidHttpUrl(item[column.href]) || isValidHttpUrl(value))
  ) {
    content = (
      <Tooltip title={item[column.href] || value} className={classes.link}>
        <div>
          <CustomLink
            textClassName={classes.overflow}
            className={cx(classes.overflow)}
            href={item[column.href] || value}
            value={value}
          />
        </div>
      </Tooltip>
    );
  } else if (column.type === ColumnType.User) {
    content = (
      <User
        value={value}
        userLink={item.userLink}
        userAvatar={item.userAvatar}
      />
    );
  } else if (column.type === ColumnType.Date) {
    if (date) {
      content = (
        <Typography variant='caption' color='text.primary'>
          {date && formatDistanceToNow(date) + ' ago'}
        </Typography>
      );
    }
  } else if (column.type === ColumnType.Location) {
    const country = getCountryByCode(value);
    content = (
      <Tooltip
        title={
          country ? (
            <Typography variant='body2' color='text.primary'>
              {country}
            </Typography>
          ) : (
            ''
          )
        }>
        <div className={classes.flagContent}>
          {value && (
            <img
              className={classes.flag}
              alt={country}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
            />
          )}
        </div>
      </Tooltip>
    );
  } else if (
    column.type === ColumnType.Match &&
    column.tooltip &&
    item?.snippet !== ''
  ) {
    const snippetCode = item[column.tooltip];
    const callBranch = item.callBranch as unknown as string[];
    if (snippetCode !== '') {
      content = (
        <Tooltip
          arrow
          componentsProps={{
            tooltip: { sx: { maxWidth: window.innerWidth } },
          }}
          title={
            <CodeLocationList
              language={item.language || 'javascript'}
              callBranch={callBranch}
              snippet={snippetCode}
              snippetLineNumber={
                item.snippetLineNumber ? Number(item.snippetLineNumber) : 1
              }
            />
          }>
          <div className={classes.overflow}>{item.match}</div>
        </Tooltip>
      );
    }
  } else if (column.type === ColumnType.Match && item.match !== '') {
    content = (
      <Typography
        variant='body2'
        color='text.primary'
        className={cx(classes.overflow)}>
        {item.match}
      </Typography>
    );
  } else if (column.type === ColumnType.DaysOpen) {
    const date = new Date();
    date.setDate(date.getDate() - Number(item[column.type]));
    content = (
      <Tooltip
        arrow
        title={
          <Typography variant='caption' color='text.primary'>
            {`${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`}
          </Typography>
        }>
        <Typography variant='caption' color='text.primary'>
          {date && formatDistanceToNow(date, { addSuffix: true })}
        </Typography>
      </Tooltip>
    );
  } else if (column.type === ColumnType.FileName) {
    content = (
      <Tooltip
        arrow
        title={
          <Typography
            variant='caption'
            color='text.primary'
            sx={{ whiteSpace: 'pre-wrap' }}>
            {value}
          </Typography>
        }>
        <Typography
          width={150}
          variant='caption'
          color='text.primary'
          sx={{ whiteSpace: 'nowrap' }}>
          {value.length > 50 ? '...' : ''}
          {value?.substring(Math.max(0, value.length - 50))}
        </Typography>
      </Tooltip>
    );
  } else if (column.type === ColumnType.PopUp) {
    content = (
      <>
        <IconButton onClick={handleOpen}>
          <PiFileMagnifyingGlassThin />
        </IconButton>
        {open && item[column.key] && (
          <ModalDialog
            open={open}
            onClose={handleClose}
            modalHeader='Test Requests and Responses'>
            <div className={classes.code}>
              {convertHtmlToReact(item[column.key])}
            </div>
          </ModalDialog>
        )}
      </>
    );
  } else if (column.tooltip) {
    content = (
      <Tooltip
        arrow
        title={
          <Typography
            variant='caption'
            color='text.primary'
            sx={{ whiteSpace: 'pre-wrap' }}>
            {item[column.tooltip]}
          </Typography>
        }>
        <Typography
          variant='caption'
          color='text.primary'
          className={classes.overflow}>
          {item[column.key]}
        </Typography>
      </Tooltip>
    );
  } else if (column.type === ColumnType.Context && isDevelopment()) {
    content = (
      <AggregationContext
        contexts={item[column.key] as unknown as AggregationContextItem[]}
      />
    );
  } else {
    content = (
      <Typography
        variant='caption'
        color='text.primary'
        className={classes.overflow}>
        {value}
      </Typography>
    );
  }

  return <>{content}</>;
};

const useStyles = makeStyles()((theme: Theme) => ({
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    WebkitLineClamp: 2,
    wordBreak: 'break-word',
    WebkitBoxOrient: 'vertical',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
  },
  flag: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  flagContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  code: {
    whiteSpace: 'pre-line',
    padding: theme.spacing(2),
    border: '1px dashed ',
    borderColor: theme.palette.divider,
    fontFamily: "'Courier New', Courier, monospace",
    backgroundColor: theme.palette.background.default,
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    wordWrap: 'break-word',
    '& .highlight': {
      backgroundColor: '#FFFF00',
      color:
        theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.common.black,
    },
    '& .mark': {
      color: '#E72929',
    },
  },
}));

export interface ContentResolverProps {
  item: AggregationItem;
  column: AggregationColumn;
}

export default AggregatedContentResolver;
