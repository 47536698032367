import { FC } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { ApplicationContainer } from '../types';
import EditableTableCell from './EditableTableCell';
import {
  openEditDialog,
  openRemoveDialog,
  setSelectedContainer,
  setSelectedDockerfile,
} from '../stores/store-actions';
import { AiFillDelete } from 'react-icons/ai';
import { CustomLink } from 'ox-react-components';
import { AppPages } from 'app-navigator';

const ContainerItem: FC<
  React.PropsWithChildren<ContainerItemProps>
> = props => {
  const { container } = props;
  return (
    <TableRow>
      <TableCell>{container.registry}</TableCell>
      <TableCell>
        <CustomLink
          value={container.name}
          href={getArtifactLinkByFullName(container.name)}
        />
      </TableCell>
      <TableCell>{container?.matchedApp?.method}</TableCell>
      <EditableTableCell
        value={container?.matchedApp?.dockerfilePath}
        onClick={() => {
          setSelectedContainer(container);
          container?.matchedApp?.dockerfilePath &&
            setSelectedDockerfile(container.matchedApp.dockerfilePath);
          openEditDialog();
        }}
      />

      <TableCell align='right'>
        <IconButton
          size='small'
          onClick={() => {
            setSelectedContainer(container);
            openRemoveDialog();
          }}>
          <AiFillDelete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const getArtifactLink = (artifactName: string) => {
  const filters = { artifactName: [artifactName] };
  return `${AppPages.Artifacts}?filters=${encodeURIComponent(
    JSON.stringify(filters),
  )}`;
};

export const getArtifactLinkByFullName = (artifactFullName: string) => {
  const filters = { artifactFullName: [artifactFullName] };
  return `${AppPages.Artifacts}?filters=${encodeURIComponent(
    JSON.stringify(filters),
  )}`;
};

export const getArtifactLinkByHash = (hash: string, needFullLink?: boolean) => {
  const filters = { artifactSha: [hash] };
  return `${needFullLink ? window.origin : ''}${
    AppPages.Artifacts
  }?filters=${encodeURIComponent(JSON.stringify(filters))}`;
};

export interface ContainerItemProps {
  container: ApplicationContainer;
}

export default ContainerItem;
