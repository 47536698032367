import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  GetSlaSettingsInput,
  SlaSettings,
  SlaSettingsResponse,
} from '../../types/settings-types';
import query from './get-sla-settings.gql';

export const getSlaSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: GetSlaSettingsInput): Promise<SlaSettings> => {
      const errorMessage = 'Failed to get SLA settings';
      try {
        const results = await client.query<{
          getSlaSettings: SlaSettingsResponse;
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data) {
          logger.error(errorMessage);
          throw new Error(errorMessage);
        }
        return results.data.getSlaSettings.slaSettings;
      } catch (error) {
        logger.error(errorMessage, error);
        throw error;
      }
    },
  };
};
