import { SelectChangeEvent } from '@mui/material';
import {
  AppPages,
  navigateWithParameters,
  setParamsToUrl,
} from 'app-navigator';
import { getAllTableRowsIdsBetweenSelection } from 'common-utils';
import { isEmpty, isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { Nullable, TicketProvider, TicketRef } from 'ox-common-types';
import { FilterElements, FilterIssueType } from 'ox-filter-utils';
import { ChangeEvent } from 'react';
import { snapshot } from 'valtio';
import { ConditionalFilter } from '../../../../../../utilities/ox-filter-utils/src/filters-utils';
import { PullRequest } from '../../../quick-fix/types/quick-fix-types';
import { TicketingVendor } from '../../../ticketing-module/types/ticketing-types';
import { invalidateIssuesCachedQueries } from '../../active-issues/actions/active-issues-actions';
import { loadIssues } from '../../active-issues/api/active-issues-api';
import { setConditionalFiltersInStore } from '../../active-issues/store-actions/issue-store-actions';
import IssuesStore, {
  IssuesStoreState,
} from '../../active-issues/stores/issues-store';
import ResolvedIssuesStore, {
  ResolvedIssuesStoreState,
} from '../../resolved-issues/stores/resolved-issues-store';
import {
  FixIssue,
  Issue,
  IssueDetails,
  JiraTicketRef,
  MAX_SELECTED_ISSUES,
  SlackNotification,
} from '../types/issues-types';
import { RawIssue } from '../types/raw-issue-type';
import { convertIssue } from '../utils/convert-issue-utils';
import { createIssueDetails } from '../utils/issue-utils';
import { ScrollDirection } from 'react-utils';

const createIssueStoreActions = (
  store: typeof IssuesStore | typeof ResolvedIssuesStore,
  initalState: Partial<IssuesStoreState> | Partial<ResolvedIssuesStoreState>,
) => {
  const setStoreIssues = (
    rawIssues: RawIssue[],
    update?: boolean,
    scrollDirection = 'bottom',
  ) => {
    if (update) {
      store.issues = [
        ...rawIssues.map((issue, index) =>
          convertIssue(
            issue,
            ((store as IssuesStoreState).topOffset || 0) + index,
          ),
        ),
      ];
    } else {
      let newIssues = rawIssues.map((rawIssue, index) =>
        convertIssue(
          rawIssue,
          ((store as IssuesStoreState).topOffset || 0) +
            index +
            (scrollDirection === ScrollDirection.Bottom
              ? store.issues.length
              : 0),
        ),
      );

      if (scrollDirection === ScrollDirection.Bottom) {
        store.issues = [...store.issues, ...newIssues];
      } else {
        store.issues = [...newIssues, ...store.issues];
        (store as IssuesStoreState).selectedPosition = {
          pos: rawIssues.length,
        };
      }
    }
  };
  const setPolicyFixModal = (value: boolean) => {
    (store as IssuesStoreState).isPolicyFixOpen = value;
  };
  const setStoreTotalIssues = (totalIssues: number) => {
    store.totalIssues = totalIssues;
  };

  const setStoreTotalActiveIssues = (totalActiveIssues: number) => {
    store.totalActiveIssues = totalActiveIssues;
  };

  const setStoreTotalResolvedIssues = (totalResolvedIssues: number) => {
    store.totalResolvedIssues = totalResolvedIssues;
  };

  const setStoreTotalIssuesFiltered = (totalFilteredIssues: number) => {
    store.totalFilteredIssues = totalFilteredIssues;
  };

  const setStoreIssuesOffset = (offset: number) => {
    store.offset = offset;
  };
  const setStoreIssuesFiltersType = (issuesFilters: FilterIssueType) => {
    store.issuesFiltersType = issuesFilters;
  };

  const setStoreIssuesFiltersTypeSearch = (
    issuesFilters: FilterIssueType,
  ): void => {
    const { searchValues } = snapshot(store);
    store.issuesFiltersTypeSearch = Object.keys(issuesFilters).reduce(
      (acc, key) => {
        acc[key] = searchValues[key]
          ? issuesFilters[key].filter((f: FilterElements) =>
              f.label
                .toLocaleLowerCase()
                .includes(searchValues[key].toLowerCase()),
            )
          : issuesFilters[key];
        return acc;
      },
      {} as FilterIssueType,
    );
  };

  const setSearchValues = (type: string, searchValue: string) => {
    const index = store.searchValues.findIndex(key => key.fieldName === type);
    if (index !== -1) {
      store.searchValues[index].value = searchValue;
    } else {
      store.searchValues.push({ fieldName: type, value: searchValue });
    }
  };

  const setModalVisibility = (payload: boolean): void => {
    store.isModalVisible = payload;
  };

  const closeIssuesDrawer = (): void => {
    setModalVisibility(false);
    setSelectedIssue(null);
    setSelectedIssueId(null);
    setParamsToUrl({ issueId: null, tab: null });
    setSelectedIssueRow(null);
  };

  const closeIssueModalWithoutNavigate = (): void => {
    setModalVisibility(false);
    setSelectedIssue(null);
  };

  const openIssueModal = (): void => {
    setModalVisibility(true);
  };

  const setSelectedIssue = (issue: IssueDetails | null) => {
    store.selectedIssue = issue ? createIssueDetails(issue) : null;
    setModalVisibility(true);
  };

  const toggleFiltersPanel = () => {
    store.filtersOpen = !store.filtersOpen;
  };

  const clearFilters = () => {
    store.filterIssuesBy = {};
    store.offset = 0;
    store.numberOfFilters = 0;
    store.sliderValuePriority = [0, 100];
    store.searchValue = '';
    IssuesStore.conditionalFilters = [];
    navigateWithParameters(AppPages.ActiveIssues, {
      filters: null,
      conditionalFilters: null,
    });
    invalidateIssuesCachedQueries();
    loadIssues({ update: true, cache: false });
  };

  const clearStore = () => {
    initalState.filterIssuesBy = {};
    IssuesStore.conditionalFilters = [];
    Object.keys(initalState).forEach(key => {
      store[key] = initalState[key];
    });
  };

  const setPolicyDisableModal = (payload: boolean) => {
    store.policyDisableModal = payload;
  };

  const setApplicationIrrelevantModal = (payload: boolean) => {
    store.applicationIrrelevantModal = payload;
  };

  const setShowCreateJiraTicketDialog = (payload: boolean) => {
    store.showCreateJiraTicketDialog = payload;
  };

  const setShowCreateBulkJiraTicketDialog = (payload: boolean) => {
    store.showCreateBulkJiraTicketDialog = payload;
  };

  const setShowLinkJiraTicketDialog = (payload: boolean) => {
    store.showLinkJiraTicketDialog = payload;
  };

  const setShowUnlinkJiraTicketDialog = (payload: boolean) => {
    store.showUnlinkJiraTicketDialog = payload;
  };

  const setShowSendSlackMessageDialog = (payload: boolean) => {
    store.showSendSlackMessageDialog = payload;
  };

  const setShowConfirmDownloadCSVDialog = (payload: boolean) => {
    store.showConfirmExportCSVDialog = payload;
  };

  const openQuickFixModal = () => {
    store.isQuickFixModalVisible = true;
  };

  const setQuickFixModalVisibility = (isVisible: boolean) => {
    store.isQuickFixModalVisible = isVisible;
  };

  const openQuickFixPermissionDeniedModal = () => {
    store.isQuickFixPermissionDeniedModalVisible = true;
  };

  const setQuickFixPermissionDeniedModalVisibility = (isVisible: boolean) => {
    store.isQuickFixPermissionDeniedModalVisible = isVisible;
  };

  const setFilesDiffModalVisibility = (isVisible: boolean) => {
    store.isFilesDiffModalVisible = isVisible;
  };

  const setSliderValuePriority = (range: number[]) => {
    store.sliderValuePriority = range;
  };

  const setLastDrawerHeight = (height: number) => {
    store.lastDrawerHeight = height;
  };

  const setSelectedIssueId = (issueID: Nullable<string>) => {
    store.selectedIssueId = issueID;
  };

  const setExclusionIssueComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (store.selectedIssue) {
      store.selectedIssue.exclusionIssueComment = e.target.value;
    }
  };

  const setExclusionIssueCommentString = (comment: string) => {
    if (store.selectedIssue) {
      store.selectedIssue.exclusionIssueComment = comment;
    }
  };

  const setExclusionPolicyComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (store.selectedIssue) {
      store.selectedIssue.exclusionPolicyComment = e.target.value;
    }
  };

  const setFalsePostiveIssueComment = (payload: string) => {
    if (store.selectedIssue) {
      store.selectedIssue.falsePostiveIssueComment = payload;
    }
  };

  const setCancelFalsePositiveIssueComment = (payload: string) => {
    if (store.selectedIssue) {
      store.selectedIssue.cancelFalsePositiveIssueComment = payload;
    }
  };

  const setExclusionAppComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (store.selectedIssue) {
      store.selectedIssue.exclusionAppComment = e.target.value;
    }
  };

  const addJiraTicketToRawIssue = (jiraTicketRef: JiraTicketRef) => {
    const { selectedIssue } = snapshot(store);
    if (selectedIssue) {
      const rowIssue = store.issues.find(
        issue => issue.issueId === selectedIssue.issueId,
      );
      rowIssue && rowIssue.jiraTickets.push(jiraTicketRef);
    }
  };

  const setFalsePositiveToRawIssue = (comment: string | undefined) => {
    const { selectedIssue } = snapshot(store);
    if (selectedIssue) {
      const rowIssue = store.issues.find(
        issue => issue.issueId === selectedIssue.issueId,
      );

      if (store.selectedIssue) {
        store.selectedIssue.isFalsePositive = true;
        store.selectedIssue.falsePositiveComment = comment;
      }

      if (rowIssue) {
        rowIssue.isFalsePositive = true;
        rowIssue.falsePositiveComment = comment;
      }
    }
  };

  const setCancelReportFalsePositiveToRawIssue = (
    comment: string | undefined,
  ) => {
    const { selectedIssue } = snapshot(store);
    if (selectedIssue) {
      const rowIssue = store.issues.find(
        issue => issue.issueId === selectedIssue.issueId,
      );

      if (store.selectedIssue) {
        store.selectedIssue.isCanceledFalsePositive = true;
        store.selectedIssue.isFalsePositive = false;
        store.selectedIssue.cancelFalsePositiveComment = comment;
      }

      if (rowIssue) {
        rowIssue.isCanceledFalsePositive = true;
        rowIssue.isFalsePositive = false;
        rowIssue.cancelFalsePositiveComment = comment;
      }
    }
  };

  const setJiraTicketsToRawIssue = (
    issueId: string,
    jiraTicketsRef: JiraTicketRef[],
  ) => {
    const rowIssue = store.issues.find(issue => issue.issueId === issueId);
    if (rowIssue) {
      rowIssue.jiraTickets = jiraTicketsRef;
    }
  };

  const setRowIssueTickets = (issueId: string, tickets: TicketRef[]) => {
    const rowIssue = store.issues.find(issue => issue.issueId === issueId);
    if (rowIssue && tickets) {
      rowIssue.tickets = tickets;
    }
  };

  const removeJiraTicketsFromRawIssue = (
    issueId: string,
    jiraTicketKey: string,
  ) => {
    const rowIssue = store.issues.find(issue => issue.issueId === issueId);
    if (rowIssue) {
      rowIssue.jiraTickets = rowIssue.jiraTickets.filter(
        t => t.key !== jiraTicketKey,
      );
    }
  };

  const pushSlackNotificationToRawIssue = (
    issueId: string,
    slackNotification: SlackNotification,
  ) => {
    const rowIssue = store.issues.find(issue => issue.issueId === issueId);
    if (rowIssue) {
      rowIssue.slackNotification?.push(slackNotification);
    }
  };

  const addJiraTicketToSelectedIssue = (jiraTicketRef: JiraTicketRef) => {
    const { selectedIssue } = snapshot(store);
    if (selectedIssue && store.selectedIssue) {
      store.selectedIssue.tickets.push(jiraTicketRef);
    }
  };

  const addBulkJiraTicketsToSelectedIssues = (
    data: {
      id: string;
      key: string;
      self: string;
    }[],
  ) => {
    const { selected, issues } = snapshot(store);
    const selectedIssueIds = (Object.values(selected) as Issue[])
      .filter(issue => !isNull(issue))
      .map(issue => issue.issueId);

    for (const issueId of selectedIssueIds) {
      const selectedIndex = issues.findIndex(
        issue => issue.issueId === issueId,
      );
      const item = data.shift();
      if (selectedIndex >= 0 && item) {
        const ticketRef = {
          ticketId: item.id,
          key: item.key,
          link: item.self,
          provider: TicketProvider.Jira,
        };
        store.issues[selectedIndex].jiraTickets.push(ticketRef);
        if (
          store.selectedIssue &&
          issueId === snapshot(store).selectedIssue?.issueId
        ) {
          addJiraTicketToSelectedIssue(ticketRef);
        }
      }
    }
  };

  const removeJiraTicketsFromSelectedIssue = (jiraTicketKey?: string) => {
    const { selectedIssue } = snapshot(store);
    if (selectedIssue && store.selectedIssue) {
      store.selectedIssue.tickets = selectedIssue.tickets.filter(
        t => t.key !== jiraTicketKey,
      );
    }
  };

  const setLoadingJiraStatusUpdate = (payload: boolean) => {
    store.loadingJiraTicketsStatus = payload;
  };

  const setDrawerJiraTicketsStatus = (jiraTicketStatus: JiraTicketRef[]) => {
    const { selectedIssue } = snapshot(store);

    const nonJiraTickets = selectedIssue?.tickets.filter(ticket => {
      return (
        ticket.provider !== TicketingVendor.Jira.toLocaleLowerCase() ||
        (ticket.provider &&
          ticket.provider !== TicketingVendor.Jira.toLocaleLowerCase())
      );
    });

    if (selectedIssue && store.selectedIssue) {
      store.selectedIssue.tickets = [
        ...(jiraTicketStatus || []),
        ...(nonJiraTickets || []),
      ];
    }
  };

  const setDrawerTicketsStatus = (tickets: TicketRef[]) => {
    const { selectedIssue } = snapshot(store);

    //TODO: Remove the filter after jira integration into ticketing service
    const jiraTickets = selectedIssue?.tickets.filter(ticket => {
      return ticket.provider === 'jira';
    });

    if (selectedIssue && store.selectedIssue) {
      store.selectedIssue.tickets = [
        ...(jiraTickets || []),
        ...(tickets || []),
      ];
    }
  };

  const setLoadingIssue = (value: boolean) => {
    store.loadingIssue = value;
  };

  const setStoreIssuesStatistics = (applicationStatistics: FilterIssueType) => {
    store.applicationStatistics = applicationStatistics;
  };

  const setIsLoadingApplicationStatistics = (
    isLoadingApplicationStatistics: boolean,
  ) => {
    store.isLoadingApplicationStatistics = isLoadingApplicationStatistics;
  };

  const setCommentModal = (open: boolean) => {
    store.commentModal = open;
  };

  const setIssueComment = (deleteIssueComment?: boolean) => {
    if (store.selectedIssue) {
      if (deleteIssueComment) {
        store.selectedIssue.comment = null;
      }
      store.selectedIssue.comment = store.issueComment;
    }
  };

  const setIssueCommentTemp = (e: ChangeEvent<HTMLTextAreaElement>) => {
    store.issueComment = e.target.value;
  };

  const setAutoFixOnIssue = (autoFix: Nullable<FixIssue>, issueId?: string) => {
    const rowIssue = store.issues.find(issue => issue.issueId === issueId);
    if (rowIssue) {
      rowIssue.autoFix = autoFix;
    }
  };

  const setPrDeatils = (pr: Nullable<PullRequest>) => {
    if (store.selectedIssue) {
      store.selectedIssue.prDeatils = pr;
    }
  };

  const setIssueCommentTempValue = (value: string) => {
    store.issueComment = value;
  };

  const clearFilter = (type: string) => {
    delete store.filterIssuesBy[type];
    const { filterIssuesBy } = snapshot(store);
    navigateWithParameters(AppPages.ActiveIssues, {
      filters: filterIssuesBy,
    });
    loadIssues({ update: true });
  };

  const clearConditionalFilter = (type: string) => {
    const { conditionalFilters } = snapshot(IssuesStore);
    if (conditionalFilters && conditionalFilters.length > 0) {
      const updatedCondFilters = conditionalFilters.filter(
        (filter: ConditionalFilter) => filter.fieldName !== type,
      );
      setConditionalFiltersInStore(updatedCondFilters);
      navigateWithParameters(AppPages.ActiveIssues, {
        conditionalFilters: updatedCondFilters,
      });
    }
  };

  const setSnoozeIssueExpiredDate = (date?: Date) => {
    if (store.selectedIssue) {
      store.selectedIssue.snoozeExpiredDate = date;
    } else {
      logger.error('store.selectedIssue not found');
    }
  };

  const setSearchValue = (value: string) => {
    store.searchValue = value;
  };

  const setChangeSeverityModal = (value: boolean) => {
    store.changeSeverityModal = value;
  };

  const setShowBulkChangeSeverityModal = (value: boolean) => {
    store.showBulkChangeSeverityModal = value;
  };

  const setShowBulkIssueCommentModal = (value: boolean) => {
    store.showBulkIssueCommentModal = value;
  };

  const setChangeSeverity = (e: SelectChangeEvent) => {
    if (store.selectedIssue) {
      store.selectedIssue.newSeverity = e.target.value;
    }
  };

  const onSearchValueChange = e => {
    const { issues } = snapshot(store);
    setSearchValue(e.target.value);
    if (!e.target.value) loadIssues({ update: true, cache: false });
    const res = issues.filter(issue => {
      const { mainTitle, issueId, app, owner, category } = issue;
      return [mainTitle, issueId, app.name, owner[0], category]
        .toString()
        .toLocaleLowerCase()
        .trim()
        .includes(e.target.value);
    });
    store.issues = res.length ? res : issues;
  };

  const selectIssue = (issueId: string, isShiftPressed: boolean) => {
    const { issues, selected } = snapshot(store);
    const selectedIssuesIds = Object.keys(selected).filter(
      key => selected[key],
    );

    if (isShiftPressed && issues) {
      const selectedUpdatedIds = getAllTableRowsIdsBetweenSelection(
        issueId,
        'issueId',
        issues,
        selectedIssuesIds,
      ).slice(0, MAX_SELECTED_ISSUES);

      const selectedUpdated = issues.reduce(
        (acc: { [issueId: string]: Nullable<Issue> }, issue) => {
          if (selectedUpdatedIds.includes(issue.issueId)) {
            acc[issue.issueId] = issue;
          } else {
            acc[issue.issueId] = null;
          }
          return acc;
        },
        {},
      );
      store.selected = selectedUpdated;
    } else {
      const selectedIssueToExclude = issues.find(
        issue => issue.issueId === issueId,
      );
      store.selected[issueId] =
        isEmpty(snapshot(store).selected[issueId]) && selectedIssueToExclude
          ? selectedIssueToExclude
          : null;
    }
    store.allSelected = false;
  };

  const onOpenBulkChangeSeverityModal = () => {
    const { selected } = snapshot(store);
    const showBulkResetSeverityButton = (
      Object.values(selected) as Issue[]
    ).some(issue => {
      return !isEmpty(issue) && issue.overrideSeverity;
    });
    store.showBulkResetSeverityButton = showBulkResetSeverityButton;
    setShowBulkChangeSeverityModal(true);
  };

  const onOpenBulkIssuesCommentModal = () => {
    const { selected } = snapshot(store);
    const showBulkEditCommentButton = (Object.values(selected) as Issue[])
      .filter(issue => {
        return !isEmpty(issue);
      })
      .every(issue => {
        return !isEmpty(issue.comment);
      });
    const showBulkDeleteCommentButton = (
      Object.values(selected) as Issue[]
    ).some(issue => {
      return !isEmpty(issue) && !isEmpty(issue.comment);
    });
    store.showBulkEditCommentButton = showBulkEditCommentButton;
    store.showBulkDeleteCommentButton = showBulkDeleteCommentButton;
    setShowBulkIssueCommentModal(true);
  };

  const toggleSelectAllIssues = (checked: boolean) => {
    const { issues } = snapshot(store);
    if (checked) {
      const selected = issues
        .slice(0, MAX_SELECTED_ISSUES)
        .reduce((acc, issue) => {
          acc[issue.issueId] = issue;
          if (
            !snapshot(store).showBulkResetSeverityButton &&
            issue.overrideSeverity
          ) {
            store.showBulkResetSeverityButton = true;
          }
          return acc;
        }, {});
      store.selected = selected;
      store.allSelected = true;
    } else {
      resetSelected();
    }
  };

  const resetSelected = () => {
    store.selected = {};
    store.allSelected = false;
    store.showBulkResetSeverityButton = false;
  };

  const setExcludedByAlert = (isExcludedByAlert: boolean) => {
    if (store.selectedIssue)
      store.selectedIssue.excludedByAlert = isExcludedByAlert;
  };

  const setSelectedIssueRow = (issueRow: Nullable<Issue>) => {
    store.selectedIssueRow = issueRow;
  };

  return {
    setExcludedByAlert,
    resetSelected,
    selectIssue,
    toggleSelectAllIssues,
    setShowUnlinkJiraTicketDialog,
    setShowLinkJiraTicketDialog,
    setDrawerJiraTicketsStatus,
    setDrawerTicketsStatus,
    setStoreIssues,
    setStoreTotalIssues,
    setStoreTotalResolvedIssues,
    setStoreTotalIssuesFiltered,
    setStoreIssuesOffset,
    setStoreIssuesFiltersType,
    setStoreIssuesFiltersTypeSearch,
    setModalVisibility,
    closeIssuesDrawer: closeIssuesDrawer,
    closeIssueModalWithoutNavigate,
    openIssueModal,
    setSelectedIssue,
    toggleFiltersPanel,
    clearFilters,
    clearStore,
    setPolicyDisableModal,
    setApplicationIrrelevantModal,
    setShowCreateJiraTicketDialog,
    setShowCreateBulkJiraTicketDialog,
    openQuickFixModal,
    setQuickFixModalVisibility,
    openQuickFixPermissionDeniedModal,
    setQuickFixPermissionDeniedModalVisibility,
    setFilesDiffModalVisibility,
    setSliderValuePriority,
    setLastDrawerHeight,
    setSelectedIssueId,
    setExclusionIssueComment,
    setExclusionIssueCommentString,
    setExclusionPolicyComment,
    setExclusionAppComment,
    addJiraTicketToRawIssue,
    addJiraTicketToSelectedIssue,
    addBulkJiraTicketsToSelectedIssues,
    removeJiraTicketsFromSelectedIssue,
    setLoadingJiraStatusUpdate,
    setLoadingIssue,
    setStoreIssuesStatistics,
    setStoreTotalActiveIssues,
    setCommentModal,
    setIssueComment,
    setIssueCommentTemp,
    setPolicyFixModal,
    setSearchValues,
    clearFilter,
    setSnoozeIssueExpiredDate,
    setJiraTicketsToRawIssue,
    setRowIssueTickets,
    removeJiraTicketsFromRawIssue,
    setShowSendSlackMessageDialog,
    pushSlackNotificationToRawIssue,
    setSearchValue,
    onSearchValueChange,
    setIssueCommentTempValue,
    setAutoFixOnIssue,
    setShowConfirmDownloadCSVDialog,
    setPrDeatils,
    setChangeSeverityModal,
    setShowBulkChangeSeverityModal,
    setChangeSeverity,
    setFalsePositiveToRawIssue,
    setFalsePostiveIssueComment,
    setIsLoadingApplicationStatistics,
    setShowBulkIssueCommentModal,
    onOpenBulkChangeSeverityModal,
    onOpenBulkIssuesCommentModal,
    clearConditionalFilter,
    setCancelFalsePositiveIssueComment,
    setCancelReportFalsePositiveToRawIssue,
    setSelectedIssueRow,
  };
};

export default createIssueStoreActions;
