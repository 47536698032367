import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  FetchOrgScanInfoInput,
  Nullable,
} from 'ox-common-types';
import { DashboardResponse } from '../../dashboard-types';
import query from './get-org-scan-info.gql';

const fetchOrgScanInfoResults = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getOrgScanInfoInput: FetchOrgScanInfoInput,
      cache = true,
    ): Promise<Nullable<DashboardResponse>> => {
      const fetchPolicy: FetchPolicy = cache
        ? ApolloClientCache.CacheFirst
        : ApolloClientCache.NetworkOnly;
      try {
        const results = await client.query<DashboardResponse>({
          query,
          fetchPolicy,
          variables: { getOrgScanInfoInput },
        });
        if (!results.data) return null;
        return results.data;
      } catch (e) {
        logger.error('Failed to fetch org scan info results, ', e);
        return null;
      }
    },
  };
};

export default fetchOrgScanInfoResults;
