import { User } from '@auth0/auth0-react';
import { createStore } from 'store-utils';
import { Nullable } from '../../ox-common-types/src/typescript-types';
import { PermissionsConfig } from '@oxappsec/ox-unified-permissions';
import { LDClient } from 'launchdarkly-react-client-sdk';
export const AppStore = createStore<AppStoreState>(
  {
    initializedAppListeners: false,
    initializedHttpCalls: false,
    generalError: false,
    pageError: false,
    isFirstLogin: false,
    isReady: false,
    isEmptyOrg: true,
    user: undefined,
    ldClient: undefined,
  },
  'App Store',
);

export interface AppStoreState {
  initializedAppListeners: boolean;
  initializedHttpCalls: boolean;
  generalError: boolean;
  pageError: boolean;
  isFirstLogin: boolean;
  isReady: boolean;
  isEmptyOrg: boolean;
  user?: User & {
    scopes?: Nullable<string>;
  } & {
    permissions?: Nullable<PermissionsConfig>;
  };
  ldClient?: LDClient;
}
