import { createStore } from 'store-utils';
import { ApplicationContainer } from '../types';
import { Nullable } from 'ox-common-types';

const ContainersStore = createStore<ContainersStoreState>(
  {
    containers: [],
    packageContainers: [],
    addDialogIsOpen: false,
    editDialogIsOpen: false,
    selectedContainer: null,
    dockerPath: '',
    availableContainers: [],
    availableDockerfiles: [],
    appId: null,
    appName: null,
    removeDialogIsOpen: false,
  },
  'Contaienrs Store',
);

export interface ContainersStoreState {
  containers: ApplicationContainer[];
  packageContainers: ApplicationContainer[];
  addDialogIsOpen: boolean;
  editDialogIsOpen: boolean;
  selectedContainer: Nullable<ApplicationContainer>;
  dockerPath: string;
  availableContainers: ApplicationContainer[];
  availableDockerfiles: string[];
  appId: Nullable<string>;
  appName: Nullable<string>;
  removeDialogIsOpen: boolean;
}

export default ContainersStore;
