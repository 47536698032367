import { AppPages } from 'app-navigator';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeyPress } from 'react-utils';
import { useSnapshot } from 'valtio';
import { useIsLoadingConnectors } from '../../connectors/store-actions/connectors-store-actions';
import { scanAll, useIsScanning } from '../actions/scan-actions';
import ScanButton from '../components/ScanButton';
import ScanStore from '../store/scan-store';
import { openExceedScanLimitModal } from '../store/scan-store-actions';
import withDisabledIfUnauthorized, {
  WithDisabledIfUnauthorizedProps,
} from '../../permissions/higher-order-components/withDisabledIfUnauthorized';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { useOxFlags } from '../../feature-flags/actions/feature-flags-actions';
import { getSettingsBySubType } from '../../settings/utils/settings-utils';
import { SettingsSubType } from '../../settings/types/settings-types';
import SettingsStore from '../../settings/stores/settings-store';

const DeltaScanButton: FC<WithDisabledIfUnauthorizedProps> = ({ disabled }) => {
  const isLoadingConnectors = useIsLoadingConnectors();
  const isScanning = useIsScanning();
  const { isExceedScanLimit, isScanAllDisabled, isDeltaScan } =
    useSnapshot(ScanStore);
  const { orgSettingsMapByName, loading } = useSnapshot(SettingsStore);
  const isManualScanningEnabled = useMemo(() => {
    return getSettingsBySubType(
      orgSettingsMapByName,
      SettingsSubType.ToggleManualScanning,
    )?.enabled;
  }, [orgSettingsMapByName]);

  // delta scan
  const shiftPressed = useKeyPress('Shift');
  // container full scan
  const altOptionKeyPressed = useKeyPress('Alt');
  // third party full scan
  const oneKeyPressed = useKeyPress('1');

  const { disableFullScanOnShift } = useOxFlags();
  const navigate = useNavigate();

  const handleScan = useCallback(() => {
    if (isExceedScanLimit) {
      openExceedScanLimitModal();
      return;
    }
    const isDemo = false;
    const isFullScan = disableFullScanOnShift ? false : shiftPressed;
    const isContainerFullScan = altOptionKeyPressed;
    const isThirdPartyFullScan = oneKeyPressed;

    scanAll(isDemo, isFullScan, isContainerFullScan, isThirdPartyFullScan);
    navigate(AppPages.Dashboard);
  }, [
    isExceedScanLimit,
    disableFullScanOnShift,
    shiftPressed,
    navigate,
    altOptionKeyPressed,
    oneKeyPressed,
  ]);

  const getTitle = useCallback(() => {
    if (disabled) {
      return 'You need permissions to perform this action';
    }
    if (isLoadingConnectors) {
      return 'Loading..';
    }
    if (isScanning) {
      return 'Scan in progress.';
    }
    if (isScanAllDisabled) {
      return 'Ensure a valid token is available in the connectors screen.';
    }
    if (!isManualScanningEnabled) {
      return 'Scan is disabled by settings';
    }
    if (altOptionKeyPressed && shiftPressed) {
      return 'Will run a code and container FULL scan using all configured connectors';
    }
    if (oneKeyPressed && shiftPressed) {
      return 'Will run a code and external tools FULL scan using all configured connectors';
    }
    if (shiftPressed) {
      return 'Will run a FULL scan using all configured connectors';
    }
    if (altOptionKeyPressed) {
      return 'Will run a container FULL scan using all configured connectors';
    }
    if (oneKeyPressed) {
      return 'Will run a external tools FULL scan using all configured connectors';
    }
    return 'Will scan using all configured connectors';
  }, [
    altOptionKeyPressed,
    shiftPressed,
    isLoadingConnectors,
    isScanning,
    isScanAllDisabled,
    disabled,
    oneKeyPressed,
    isManualScanningEnabled,
  ]);

  const getValue = () => {
    if (isLoadingConnectors || loading.isPending()) {
      return '';
    }
    if (isScanning) {
      return isDeltaScan ? 'Δ Scanning...' : 'Scanning...';
    }
    return 'Scan Now';
  };

  return (
    <ScanButton
      value={getValue()}
      tooltip={getTitle()}
      disabled={
        isScanAllDisabled ||
        isLoadingConnectors ||
        disabled ||
        loading.isPending() ||
        !isManualScanningEnabled
      }
      onClick={handleScan}
      variant={isScanning ? 'outlined' : 'contained'}
      withProgress={isLoadingConnectors || loading.isPending()}
    />
  );
};

export default withDisabledIfUnauthorized(DeltaScanButton, {
  requiredPermissions: [
    {
      permissionScope: PermissionScope.Edit,
      permissionType: PermissionTypes.Scan,
    },
  ],
});
