import React, { FC } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  AggregationContextItem,
  resolveAggregationContextIcon,
} from '../aggregation-utils';
import SeverityEvidence from '../../SeverityEvidence/SeverityEvidence';

const AggregationContext: FC<
  React.PropsWithChildren<AggregationContextProps>
> = ({ contexts }) => {
  return (
    <Box display='flex' flexDirection='row'>
      {contexts?.map(context => {
        const Icon = resolveAggregationContextIcon(context.key);
        return (
          <Tooltip
            title={
              <Box display='flex' flexDirection='column'>
                <Typography variant='caption' color='text.primary'>
                  {context.key}
                </Typography>
                {context.evidences.map((evidence, index) => (
                  <Box display='flex' flexDirection='column'>
                    <SeverityEvidence index={index} evidence={evidence} />
                  </Box>
                ))}
              </Box>
            }>
            <div>{Icon && <Icon size={20} />}</div>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export interface AggregationContextProps {
  contexts: AggregationContextItem[];
}

export default AggregationContext;
