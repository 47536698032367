import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import { FetchIssuesInput } from '../../types/issues-types';
import query from './get-exported-issues.gql';
import queryV2 from './get-exported-issues-v2.gql';
import { snapshot } from 'valtio';
import { FeatureFlagsStore } from '../../../../app/launch-darkly/feature-flags-store';
import { FeatureFlagKeys } from '../../../../feature-flags/feature-flags-types';

const getExportedIssues = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<GetExportIssuesInput, Nullable<GetIssuesCSVExportRes>> => {
  return {
    execute: async (getIssuesInput: GetExportIssuesInput) => {
      try {
        const { flags } = snapshot(FeatureFlagsStore);
        const getIssuesCsvExportV2 =
          flags?.[FeatureFlagKeys.getIssuesCsvExportV2];
        const selectedQuery = getIssuesCsvExportV2 ? queryV2 : query;
        const results = await client.query<QueryResponse>({
          query: selectedQuery,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getIssuesInput },
        });
        if (!results.data) return null;

        return getIssuesCsvExportV2
          ? results.data.getIssuesCSVExportV2
          : results.data.getIssuesCSVExport;
      } catch (error) {
        logger.error('Failed to fetch exported csv issues', error);
        return null;
      }
    },
  };
};

type GetExportIssuesInput = {
  exportsOptions?: {
    flattenAgg?: boolean;
    isDemoEnabled?: boolean;
  };
} & Pick<
  FetchIssuesInput,
  'dateRange' | 'filters' | 'scanID' | 'owners' | 'sort'
>;

interface GetIssuesCSVExportRes {
  requestId: string;
}
export interface QueryResponse {
  getIssuesCSVExport: Nullable<GetIssuesCSVExportRes>;
  getIssuesCSVExportV2: Nullable<GetIssuesCSVExportRes>;
}

export default getExportedIssues;
