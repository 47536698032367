import { openErrorSnackbar, openSuccessSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import settingsService from '../services/index';
import { SLASettingsStoreActions } from '../store-actions/sla-settings-store-actions';
import SLASettingsStore from '../stores/sla-settings-store';
import { graphqlUtils } from 'api-clients/src/graphql-utils';
import { reportClient } from 'api-clients';

export const slaSettingsActions = {
  getSLASettings: async (isCreateDefaultSlaSettings: boolean = false) => {
    SLASettingsStoreActions.setIsErrorGetSettings(false);
    SLASettingsStoreActions.setIsLoadingGetSettings(true);
    try {
      const response = await settingsService.getSlaSettings.execute({
        isCreateDefaultSlaSettings,
      });

      if (!response || !response.severitySLA) {
        return;
      }

      SLASettingsStoreActions.setIsSLABySeveritySettingsEnabled(
        response.severitySLA?.isEnabled,
      );
      SLASettingsStoreActions.setSLAPerSeveritySettings(
        response.severitySLA?.settings,
      );
      SLASettingsStoreActions.setNearExceedingSLA(
        response.nearExceedingSLA || 0,
      );
    } catch (error) {
      SLASettingsStoreActions.setIsErrorGetSettings(true);
      return;
    } finally {
      SLASettingsStoreActions.setIsLoadingGetSettings(false);
    }
  },

  updateSLASettings: async () => {
    SLASettingsStoreActions.setIsLoadingSaveSettings(true);
    const { severitySLA, nearExceedingSLA } = snapshot(SLASettingsStore);
    const errorMessage = 'Failed to update SLA settings';
    try {
      const response = await settingsService.updateSlaSettings.execute({
        severitySLA,
        nearExceedingSLA,
      });

      if (!response || !response.updateSlaSettings) {
        openErrorSnackbar(errorMessage);
        return;
      }
      graphqlUtils.invalidateCachedQueries(reportClient, ['getIssues']);
      openSuccessSnackbar('SLA settings updated successfully');
    } catch (error) {
      openErrorSnackbar(errorMessage);
      return;
    } finally {
      SLASettingsStoreActions.setIsLoadingSaveSettings(false);
    }
  },
};
