import { TicketingActionFields } from '@oxappsec/policy-workflow-service';
import { CreateTicketStore, InputOption } from '../store/create-ticket-store';
import { TicketFieldConfig, TicketFieldTypes } from '../types/ticketing-types';
import { snapshot } from 'valtio';
import { setInitialControlObject } from '../actions/create-ticket-actions';

const setInputControlObject = (fieldKey: string, value: string | null) => {
  CreateTicketStore.inputControlObject[fieldKey] = value || null;
};

const setCustomFieldsControlObject = (
  fieldKey: string,
  value: InputOption | null,
) => {
  CreateTicketStore.customFieldsControlObject[fieldKey] = value;
};

const setInputSelectedControlValue = (
  fieldKey: string,
  value: string | InputOption | (string | InputOption)[] | null,
) => {
  CreateTicketStore.inputSelectedControlObject[fieldKey] = value;
};

const initInputValuesWorkflow = (
  values: TicketingActionFields['ticket'],
  fieldsConfig: TicketFieldConfig[],
) => {
  if (!values || !fieldsConfig) return;

  fieldsConfig.forEach(({ id, type }) => {
    const value = values[id];
    switch (type) {
      case TicketFieldTypes.Dropdown:
        setInputSelectedControlValue(id, value);
        break;
      case TicketFieldTypes.MultiSelect:
        setInputSelectedControlValue(id, value || []);
        break;
      default:
        setInputControlObject(id, value);
        break;
    }
  });
};

const resetInputSelectedControlObject = () => {
  CreateTicketStore.inputSelectedControlObject = {};
};

const resetInputControlObject = () => {
  CreateTicketStore.inputControlObject = {};
};

const setInputOptions = (fieldKey: string, value: InputOption[]) => {
  CreateTicketStore.inputOptions[fieldKey] = value;
};

const resetInputOptions = () => {
  CreateTicketStore.inputOptions = {};
};

const setIsLoadingInputFields = (isLoading: boolean) => {
  CreateTicketStore.isLoadingInputFields = isLoading;
};

const setFieldsConfig = (fieldsConfig: TicketFieldConfig[]) => {
  CreateTicketStore.fieldsConfig = fieldsConfig;
};

const setIsLoadingInputOptions = (fieldKey: string, isLoading: boolean) => {
  CreateTicketStore.isLoadingOptions[fieldKey] = isLoading;
};

const setIsErrorLoadingInputFields = (isError: boolean) => {
  CreateTicketStore.isErrorLoadingInputFields = isError;
};

const arrayItemsInFirstArrayOnly = (
  firstArray: TicketFieldConfig[],
  secondArray: TicketFieldConfig[],
): TicketFieldConfig[] => {
  const idsIn2ndArray = new Set(secondArray.map(item => item.id));
  return firstArray.filter(field => !idsIn2ndArray.has(field.id));
};

const resetCustomFieldsControlObjects = (
  fieldsToBeRemoved: TicketFieldConfig[],
) => {
  fieldsToBeRemoved.forEach(field => {
    if (CreateTicketStore.inputControlObject[field.id]) {
      delete CreateTicketStore.inputControlObject[field.id];
    }
    if (CreateTicketStore.inputSelectedControlObject[field.id]) {
      delete CreateTicketStore.inputSelectedControlObject[field.id];
    }
  });
};

const updateCustomFieldsConfig = (newCustomFields: TicketFieldConfig[]) => {
  const { customFieldsConfig: existingCustomFields } =
    snapshot(CreateTicketStore);
  const removedFieldsOnly = arrayItemsInFirstArrayOnly(
    existingCustomFields,
    newCustomFields,
  );
  const addedFieldsOnly = arrayItemsInFirstArrayOnly(
    newCustomFields,
    existingCustomFields,
  );

  resetCustomFieldsControlObjects(removedFieldsOnly);
  setInitialControlObject(addedFieldsOnly);
  CreateTicketStore.customFieldsConfig = newCustomFields;
};

const setIsLoadingCustomFields = (isLoading: boolean) => {
  CreateTicketStore.isLoadingCustomFields = isLoading;
};

const resetFieldsConfig = () => {
  CreateTicketStore.fieldsConfig = [];
  CreateTicketStore.customFieldsConfig = [];
};

export const createTicketStoreActions = {
  setInputControlObject,
  setInputSelectedControlValue,
  initInputValuesWorkflow,
  resetInputSelectedControlObject,
  resetInputControlObject,
  setInputOptions,
  resetInputOptions,
  setIsLoadingInputFields,
  setFieldsConfig,
  updateCustomFieldsConfig,
  resetFieldsConfig,
  setIsLoadingInputOptions,
  setIsErrorLoadingInputFields,
  setIsLoadingCustomFields,
  setCustomFieldsControlObject,
};
