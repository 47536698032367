import { Divider, Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Issue, IssueDetails } from '../../../../../types/issues-types';
import IssueCardDescription from '../../../../IssueCard/IssueCardDescription';
import IssueCardHeader from '../../../../IssueCard/IssueCardHeader';
import IssueCardImpact from '../../../../IssueCard/IssueCardImpact';
import IssueCardRecommendation from '../../../../IssueCard/IssueCardRecommendation';
import { Nullable } from 'ox-common-types';

const IssueSummaryTab: FC<React.PropsWithChildren<IssueSummaryTabProps>> = ({
  selectedIssue,
  filterIssuesBy,
  isEnabledChatGpt,
  selectedIssueRow,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.issueCard}>
      <div className={classes.twoCols}>
        <div>
          <IssueCardHeader
            filters={filterIssuesBy}
            selectedIssue={selectedIssue}
            selectedIssueRow={selectedIssueRow}
          />

          <Divider sx={{ mx: 2 }} orientation='vertical' flexItem />
        </div>
      </div>
      <Divider sx={{ mb: 1, mt: 2 }} />
      <div className={classes.twoCols}>
        <div className={classes.twoRows}>
          {selectedIssue.impact && (
            <>
              <div>
                <IssueCardImpact maxHeight={100}>
                  {selectedIssue.impact}
                </IssueCardImpact>
              </div>
              <Divider sx={{ mb: 1, mt: 2 }} />
            </>
          )}
          {selectedIssue.secondTitleMarkup && (
            <div>
              <IssueCardDescription
                isEnabledChatGpt={isEnabledChatGpt}
                learnMoreUrl={selectedIssue.learnMore?.[0]}
                issueId={selectedIssue.issueId}
                isGptAvailable={selectedIssue.isGPTFixAvailable}
                isResolvedIssuesPage={false}>
                {selectedIssue.secondTitleMarkup}
              </IssueCardDescription>
            </div>
          )}
        </div>
        {selectedIssue.recommendationMarkup &&
          selectedIssue.secondTitleMarkup && (
            <Divider sx={{ mx: 2 }} orientation='vertical' flexItem />
          )}
        {selectedIssue.recommendationMarkup && (
          <div>
            <IssueCardRecommendation
              withActions
              isResolvedIssuesPage={false}
              fixLink={selectedIssue.fixLink}
              issueId={selectedIssue.issueId}
              fixAvailable={selectedIssue.isFixAvailable}
              prAvailable={selectedIssue.isPRAvailable}>
              {selectedIssue.recommendationMarkup}
            </IssueCardRecommendation>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  category: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  categoryIcon: {
    width: '1em',
    height: '1em',
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  issueCard: {
    backgroundColor: theme.palette.background.paper,
  },
  appTypeSummeryScore: {
    display: 'flex',
  },
  appTypeSummery: {
    display: 'flex',
  },
  repoSummery: {
    display: 'flex',
    flexDirection: 'row',
  },
  twoCols: {
    display: 'flex',
    '& > div': {
      flex: '1 1 100%',
    },
  },
  twoRows: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      flex: '1 1 100%',
    },
  },
}));

interface IssueSummaryTabProps {
  selectedIssue: IssueDetails;
  filterIssuesBy: {};
  isEnabledChatGpt: boolean;
  selectedIssueRow?: Nullable<Issue>;
}

export default IssueSummaryTab;
