import {
  ApolloClient,
  ApolloError,
  NormalizedCacheObject,
} from '@apollo/client';
import { openErrorSnackbar } from 'snackbar-utils';

const invalidateCachedQueries = (
  client: ApolloClient<NormalizedCacheObject>,
  queryNames: string[],
) => {
  const fields = queryNames.reduce((acc: { [x: string]: () => void }, next) => {
    acc[next] = () => {
      // Returning `undefined` for a field will cause it to be refetched
      return undefined;
    };
    return acc;
  }, {});
  const ack = client.cache.modify({ fields });
  return ack;
};

const getErrorMessageFromApolloError = (error: ApolloError | unknown) => {
  const apolloError = error as ApolloError;
  return apolloError?.message;
};

const showErrorSnackbarFromBackend = (
  error: ApolloError | unknown,
  alternativeMessage?: string,
) => {
  const message = getErrorMessageFromApolloError(error) || alternativeMessage;
  message && openErrorSnackbar(message);
};

export const graphqlUtils = {
  invalidateCachedQueries,
  showErrorSnackbarFromBackend,
  getErrorMessageFromApolloError,
};
