import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import { CssBaseline } from '@mui/material';
import '@trutoo/funnel-graph/dist/index.min.css';
import { navigate } from 'app-navigator';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import ZoomPlugin from 'chartjs-plugin-zoom';
import 'diff2html/bundles/css/diff2html.min.css';
import { isDevelopmentNotLocal, isRemoteSite } from 'env-utils';
import 'highlight.js/styles/dark.css';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { logger } from 'logging-utils';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'reactflow/dist/style.css';
import { App } from './app/components/App';
import AppThemeProvider from './app/components/theme/AppThemeProvider';
import { getLDConfig } from './app/launch-darkly/config';
import { getConfig } from './auth/config/auth-config';
// import { initGoogleAnalytics } from './google-analytics/actions/google-analytics-actions';
import reportWebVitals from './reportWebVitals';
import './reset.css';

// init stuff before bootstrap
// initGoogleAnalytics(); // We decided to turn off GA for the app and leave it only for the marketing website
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  ZoomPlugin,
  BarElement,
);

const onRedirectCallback = (appState?: AppState) => {
  const pathname =
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname;
  navigate(pathname);
};

const getLDProvider = async () => {
  if (isRemoteSite()) {
    return React.Fragment;
  }
  try {
    const ldConfig = getLDConfig();
    const LDProvider = await asyncWithLDProvider({
      clientSideID: ldConfig.ldClientSideId,
      options: {
        logger,
      },
    });
    return LDProvider;
  } catch (error) {
    logger.error(error);
    return React.Fragment;
  }
};

const initSaolaDevOnly = () => {
  if (isDevelopmentNotLocal()) {
    logger.info('Loading Saola in development mode');
    import('@saola.ai/browser')
      .then(({ init: initSaola }) => {
        initSaola('56197279-774a-4fb6-8092-1cc3adde426e');
        logger.info('Saola loaded and initialized');
      })
      .catch(err => logger.error('Failed to load Saola:', err));
  }
};

export const bootstrap = async () => {
  const LDProvider = await getLDProvider();
  initSaolaDevOnly();
  // Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
  // for a full list of the available properties on the provider
  const config = getConfig();

  const providerConfig: Auth0ProviderOptions = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    onRedirectCallback,
    scope: `openid profile email ${!isRemoteSite() ? 'offline_access' : ''}`,
    useRefreshTokens: !isRemoteSite(),
    maxAge: 60 * 60 * 12,
  };

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <Auth0Provider {...providerConfig}>
      <LDProvider>
        <AppThemeProvider>
          <CssBaseline enableColorScheme />
          <App />
        </AppThemeProvider>
      </LDProvider>
    </Auth0Provider>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

bootstrap();

export {};
