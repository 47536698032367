import { FC, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { openSnackbar } from 'snackbar-utils';
import { useParams, Outlet } from 'react-router-dom';
import {
  getLastLoggedInOrg,
  getLastLoggedInOrgID,
} from '../../../../organizations/utils/local-storage-utils';
import { useAuth0 } from '@auth0/auth0-react';
import { AppPages, navigate } from 'app-navigator';
import { OrganizationsStore } from 'organizations/src/stores/organizations-store';
import { useOrgSwitchLoginWithRedirect } from './hooks/useOrgSwitchLoginWithRedirect';

export const AppOrgRouteParamProvider: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { user } = useAuth0();
  const { organizations } = useSnapshot(OrganizationsStore);
  const params = useParams();
  const orgIdFromRoute = params?.orgId;
  const { handleOrgLoginWithRedirect } = useOrgSwitchLoginWithRedirect();

  useEffect(() => {
    const hasOrganizations = organizations.length > 0;
    if (!hasOrganizations) return; // no orgs - cannot switch anything

    const lastLoggedInOrg = getLastLoggedInOrg();
    const noLastLoginOrg = !lastLoggedInOrg && organizations.length !== 0;
    const orgIdInUser = user?.org_id;
    const userOrg = organizations.find(org => org.id === orgIdInUser); // this is based on what we have in the token and what we have in the orgs list
    const orgFallback = noLastLoginOrg ? organizations[0] : lastLoggedInOrg; // this is based on the last logged in org or the first org in the list
    const org = userOrg ? userOrg : orgFallback; // prefer token over last logged in org
    const orgExistFindByRouteParam = organizations.find(
      org => org.id === orgIdFromRoute,
    );
    const hasValidOrgIdRouteParam = orgIdFromRoute?.includes('org_');
    const splitedLocation = window.location.pathname
      .split('/')
      .filter(item => !!item);

    const resultOrg = orgExistFindByRouteParam || org;

    if (hasValidOrgIdRouteParam && !orgExistFindByRouteParam) {
      // not an organization member
      openSnackbar(
        `You are not a member of an org with ID (${orgIdFromRoute})`,
        {
          variant: 'error',
          persist: true,
        },
      );
      splitedLocation[0] = resultOrg?.id!;
      return navigate(splitedLocation.join('/'));
    }

    if (
      hasValidOrgIdRouteParam &&
      orgExistFindByRouteParam &&
      orgExistFindByRouteParam?.id !== org?.id &&
      orgExistFindByRouteParam?.id !== getLastLoggedInOrgID()
    ) {
      //has org route param and organization member - need to login with the new org
      return handleOrgLoginWithRedirect(orgExistFindByRouteParam);
    }

    if (!hasValidOrgIdRouteParam) {
      //old style link support
      splitedLocation.unshift(resultOrg?.id!);
      return navigate(splitedLocation.join('/'), window.location.search);
    }

    if (hasValidOrgIdRouteParam && splitedLocation.length <= 1) {
      // redirect to Dashboard
      return navigate(AppPages.Dashboard);
    }
  });

  return <Outlet />;
};
