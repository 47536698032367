import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TableCell, Theme, Typography } from '@mui/material';
import { AiFillEdit } from 'react-icons/ai';

const EditableTableCell: FC<
  React.PropsWithChildren<EditableTableCellProps>
> = props => {
  const { onClick, value } = props;
  const { classes } = useStyles();
  return (
    <TableCell>
      <Typography
        onClick={onClick}
        className={classes.editable}
        variant='caption'
        color='text.secondary'
        display='inline-flex'
        flexDirection='row'
        alignItems='center'
        gap={1}>
        <AiFillEdit style={{ marginBottom: 3 }} /> {value || 'Set Dockerfile'}
      </Typography>
    </TableCell>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  editable: {
    '&:hover': {
      backgroundColor: 'rgba(229, 229, 229, 0.3)',
    },
    cursor: 'pointer',
    borderRadius: 5,
    padding: theme.spacing(0.5),
  },
}));

export interface EditableTableCellProps {
  onClick: () => void;
  value?: string;
}

export default EditableTableCell;
