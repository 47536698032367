import { snapshot } from 'valtio';
import membersService from '../services';
import { setPendingInvitationMembersInStore } from '../store-actions/invite-user-store-actions';
import { setRequestAccessInStore } from '../store-actions/request-access-store-actions';
import AvailableRolesStore from '../stores/available-roles-store';
import MembersStore from '../stores/members-store';
import { MembersScreenResponse } from '../types/members-types';
import {
  createRolesDescriptionMap,
  reduceRelatedMembersIDs,
  sortRolesByPermissionsStrength,
} from '../utils/members-utils';

export const loadMembersScreen = async (): Promise<void> => {
  MembersStore.isLoading = true;
  const results: MembersScreenResponse =
    await membersService.getMembersScreen.execute();
  const cloneResultsMembers = JSON.parse(JSON.stringify(results.activeMembers));
  const rolesDescriptionMap = createRolesDescriptionMap(results.availableRoles);
  cloneResultsMembers.forEach(member => {
    member.roles.forEach(role => {
      role.description = rolesDescriptionMap[role.name];
    });
  });

  MembersStore.activeMembers = reduceRelatedMembersIDs(cloneResultsMembers);

  AvailableRolesStore.rawNonFilteredRoles = sortRolesByPermissionsStrength(
    JSON.parse(JSON.stringify(results.availableRoles)),
  );

  setPendingInvitationMembersInStore(results.pendingInvitationMembers);
  setRequestAccessInStore(results.pendingRequests);

  MembersStore.isLoading = false;
};

export const getActiveMembers = async () => {
  const membersStore = snapshot(MembersStore);
  if (membersStore.activeMembers.length > 0) {
    return membersStore.activeMembers.map(m => ({
      user_id: m.user_id,
      name: m.name,
      email: m.email,
    }));
  }
  const activeMembers = await membersService.getActiveMembers.execute();
  if (activeMembers) {
    return reduceRelatedMembersIDs(activeMembers).map(m => ({
      user_id: m.user_id,
      name: m.name,
      email: m.email,
    }));
  }
};
