import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { AppPages } from 'app-navigator';
import dayjs from 'dayjs';
import {
  BiLink as LinkToTicketIcon,
  BiUnlink as UnlinkTicketIcon,
} from 'react-icons/bi';
import { DiGit } from 'react-icons/di';
import { FaCode, FaFlag, FaLink, FaRegCommentDots } from 'react-icons/fa';
import { ReactComponent as CancelFaFlag } from '../../../assets/icons/cancel-report-false-positive.svg';

import { MdAccessTimeFilled, MdVisibilityOff } from 'react-icons/md';
import { SiJira, SiOpenai } from 'react-icons/si';
import { SlMagicWand } from 'react-icons/sl';
import { snapshot } from 'valtio';
import { ReactComponent as TbExchange } from '../../../assets/icons/exchange.svg';
import { ReactComponent as ExclusionsIcon } from '../../../assets/icons/exclusions-small.svg';
import { ReactComponent as PolicyEdit } from '../../../assets/icons/policy-edit.svg';
import { ReactComponent as PolicyXIcon } from '../../../assets/icons/policyX.svg';
import ConnectorsStore from '../../../connectors/stores/connectors-store';
import { setCurrentSnoozeDateValue } from '../../../exclusions/store-actions/exclusions-store-actions';
import {
  ExclusionsActionIconMap,
  getExclusionTypeFromLabel,
} from '../../../exclusions/utils/exclusion-utils';
import { FeatureFlags } from '../../../feature-flags/feature-flags-types';
import SettingsStore from '../../../settings/stores/settings-store';
import {
  SettingsName,
  SettingsSubType,
} from '../../../settings/types/settings-types';
import { setMenuIssueId } from '../../active-issues/actions/active-issues-actions';
import { openConnectorsNotConnectedModal } from '../../active-issues/connectors-not-connected-modal/actions/connector-not-connected-modal-actions';
import { ConnectorsNotConnectedModalTypes } from '../../active-issues/connectors-not-connected-modal/types/connector-not-connected-modal-types';
import {
  setApplicationIrrelevantModal,
  setChangeSeverityModal,
  setCommentModal,
  setFilesDiffModalVisibility,
  setPolicyDisableModal,
  setPolicyFixModal,
  setQuickFixModalVisibility,
} from '../../active-issues/store-actions/issue-store-actions';
import { setShowReportFalseResolvedIssueDialog } from '../../resolved-issues/store-actions/resolved-issue-store-actions';
import { openCreateJiraTicketDialog } from '../actions/create-jira-ticket-modal-actions';
import { setGptDisclaimerModalOpen } from '../store-actions/chat-gpt-store-actions';
import {
  setExcludeCategoryID,
  setExcludeCategoryName,
  setExcludeIssueModal,
  setExcludeOxRuleId,
  setExclusionScope,
  setIsExcludeDefault,
  setShowCancelReportFalsePositiveModal,
  setShowReportFalsePositiveModal,
} from '../store-actions/issues-exclusions-store-actions';
import { Exclusions, IssueAction, IssuePages } from '../types/issues-types';
import { copyIssueLink } from '../utils/issue-utils';
import { getIssueActionsByIssuesPage } from '../utils/issues-jira-utils';
import { getIssueActionTooltip } from '../utils/tooltip-utils';
import { getMessagingActions } from './messeging-actions-config';
import { getTicketingActions } from './ticketing-actions-config';

export const getIssueActionsConfig = ({
  exclusionsCategories,
  hasComment,
  isFixAvailable,
  isGptAvailable,
  isPRAvailable,
  categoryId,
  flags,
  policyId,
  selectedAggs,
  showJiraUnlinkTicketSubMenuItem,
  isFalsePositive,
  issueId,
  issuesPage,
  preActionFunction,
  showUnlinkTicket,
}: IssuesActionsConfigOptions): IssueAction[][] => {
  const { isActiveJiraConnection, connectedTicketingConnectors } =
    snapshot(ConnectorsStore);
  const { orgSettingsMapByName } = snapshot(SettingsStore);
  const { connectedSourceControlConnectors } = snapshot(ConnectorsStore);
  const isSourceControlConnected = connectedSourceControlConnectors?.length > 0;
  const isEnableChatGpt = orgSettingsMapByName
    .find(s => s.name === SettingsName.ChatGPT)
    ?.settings.find(
      s => s.settingsSubType === SettingsSubType.ChatGPT,
    )?.enabled;

  const openPRModal = async () => {
    if (!selectedAggs?.length && !preActionFunction) return;
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setQuickFixModalVisibility(true);
    setFilesDiffModalVisibility(false);
  };

  const onViewCodeFixClick = async () => {
    if (!selectedAggs?.length && !preActionFunction) return;
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setQuickFixModalVisibility(false);
    setFilesDiffModalVisibility(true);
    setMenuIssueId(null);
  };

  const handleExcludeIssue = async (
    category?: Exclusions,
    isSnooze?: boolean,
  ) => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    if (category) {
      const expireDateModalValue = isSnooze
        ? dayjs().add(30, 'day').toDate()
        : null;
      setExcludeCategoryID(category.id);
      setExcludeOxRuleId(category.oxRuleId);
      setExcludeCategoryName(category.label);
      setCurrentSnoozeDateValue(expireDateModalValue);
      if (category.exclusionScope) {
        setExclusionScope(category.exclusionScope);
      }
      setMenuIssueId(null);
    } else {
      setIsExcludeDefault(true);
    }
    setExcludeIssueModal(true);
  };

  const openPolicyFix = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setPolicyFixModal(true);
    setMenuIssueId(null);
  };

  const openGptConsentModal = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setGptDisclaimerModalOpen(true);
  };

  const handleApplicationIrrelevant = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setApplicationIrrelevantModal(true);
    setMenuIssueId(null);
  };

  const handleAddComment = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setCommentModal(true);
  };

  const handleDisablePolicy = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setPolicyDisableModal(true);
    setMenuIssueId(null);
  };

  const handleChangeSeverity = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setChangeSeverityModal(true);
    setMenuIssueId(null);
  };

  const handleReportFalsePositive = async () => {
    if (issuesPage === IssuePages.CurrentIssues) {
      if (preActionFunction) {
        await preActionFunction(issueId);
      }
      setIsExcludeDefault(true);
      setShowReportFalsePositiveModal(true);
      setMenuIssueId(null);
    } else if (issuesPage === IssuePages.ResolvedIssues) {
      setShowReportFalseResolvedIssueDialog(true);
      setMenuIssueId(null);
    }
  };

  const handleCancelReportFalsePositive = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    setIsExcludeDefault(false);
    setShowCancelReportFalsePositiveModal(true);
    setMenuIssueId(null);
  };

  const openPolicyConfig = () => {
    const win = window.open(
      `${window.location.origin}${AppPages.Policies}?categoryId=${categoryId}&policyId=${policyId}`,
      '_blank',
    );
    if (win != null) {
      win.focus();
    }
    setMenuIssueId(null);
  };

  const handleCreateJiraTicket = async () => {
    if (isActiveJiraConnection) {
      if (preActionFunction) {
        await preActionFunction(issueId);
      }
      openCreateJiraTicketDialog(issuesPage);
      setMenuIssueId(null);
    } else {
      openConnectorsNotConnectedModal(ConnectorsNotConnectedModalTypes.Jira);
    }
  };

  const handleUnlinkJiraTickets = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    const { showUnlinkTicketDialog } = getIssueActionsByIssuesPage(issuesPage);

    showUnlinkTicketDialog(true);

    setMenuIssueId(null);
  };

  const handleLinkJiraTicket = async () => {
    if (preActionFunction) {
      await preActionFunction(issueId);
    }
    const { showLinkTicketDialog } = getIssueActionsByIssuesPage(issuesPage);

    showLinkTicketDialog(true);
    setMenuIssueId(null);
  };

  const ticketingActions: IssueAction[] = [];
  // ** THIS IS LEGACY, PLEASE DON'T DUPLICATE THIS CODE **
  // ** FOR NEW TICKETING CONNECTORS, USE getTicketingActions **
  if (connectedTicketingConnectors['Jira']?.isConfigured) {
    ticketingActions.push({
      actionId: 'jira-actions',
      title: 'Jira Actions',
      disabled: connectedTicketingConnectors['Jira'].isConnectorInvalid,
      tooltipText: getIssueActionTooltip(
        !connectedTicketingConnectors['Jira'].isConnectorInvalid,
        'Jira Actions',
        'Jira access expired or revoked',
      ),
      icon: SiJira,
      // Will only be called if no subMenuActions are defined
      onClick: () => {
        openConnectorsNotConnectedModal(ConnectorsNotConnectedModalTypes.Jira);
      },
      permissions: [
        {
          permissionScope: PermissionScope.Edit,
          permissionType: PermissionTypes.TicketingCreate,
        },
      ],
      highlight: true,
      relevantIssuePages: [IssuePages.CurrentIssues, IssuePages.PipelineIssues],
      subMenuActions: [
        {
          actionId: 'create-jira-ticket',
          title: 'Create Jira Ticket',
          tooltipText: getIssueActionTooltip(
            isActiveJiraConnection,
            'Create Jira Ticket',
            'Jira is not connected',
          ),
          icon: SiJira,
          onClick: handleCreateJiraTicket,
          highlight: false,
          relevantIssuePages: [
            IssuePages.CurrentIssues,
            IssuePages.PipelineIssues,
          ],
          permissions: [
            {
              permissionScope: PermissionScope.Edit,
              permissionType: PermissionTypes.TicketingCreate,
            },
          ],
          disabled: connectedTicketingConnectors['Jira'].isConnectorInvalid,
        },
        {
          actionId: 'link-jira-ticket',
          title: 'Link Jira Ticket',
          tooltipText: getIssueActionTooltip(
            isActiveJiraConnection,
            'Link Jira Ticket',
            'Jira is not connected',
          ),
          icon: LinkToTicketIcon,
          onClick: handleLinkJiraTicket,
          highlight: false,
          relevantIssuePages: [
            IssuePages.CurrentIssues,
            IssuePages.PipelineIssues,
          ],
          hideAction: !isActiveJiraConnection,
          permissions: [
            {
              permissionScope: PermissionScope.Edit,
              permissionType: PermissionTypes.TicketingCreate,
            },
          ],
          disabled: connectedTicketingConnectors['Jira'].isConnectorInvalid,
        },
        {
          actionId: 'unlink-jira-ticket',
          title: 'Unlink Jira Ticket',
          tooltipText: getIssueActionTooltip(
            isActiveJiraConnection,
            'Unlink Jira Ticket',
            'Jira is not connected',
          ),
          icon: UnlinkTicketIcon,
          onClick: handleUnlinkJiraTickets,
          highlight: false,
          hideAction: !showJiraUnlinkTicketSubMenuItem,
          relevantIssuePages: [
            IssuePages.CurrentIssues,
            IssuePages.PipelineIssues,
          ],
          permissions: [
            {
              permissionScope: PermissionScope.Edit,
              permissionType: PermissionTypes.TicketingCreate,
            },
          ],
          disabled: connectedTicketingConnectors['Jira'].isConnectorInvalid,
        },
      ],
    });
  }

  ticketingActions.push(
    ...getTicketingActions(
      issuesPage,
      issueId,
      !!showUnlinkTicket,
      preActionFunction,
    ),
  );

  const groupedCurrentIssuesActions: IssueAction[][] = [
    [
      {
        actionId: 'copy-issue-link',
        title: 'Copy link to issue',
        tooltipText: 'Copy link to issue',
        icon: FaLink,
        onClick: () => copyIssueLink(issueId),
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [],
      },
      {
        actionId: 'add-comment',
        title: hasComment ? 'Edit Comment' : 'Add Comment',
        tooltipText: getIssueActionTooltip(
          !!hasComment,
          'Edit Comment',
          'Add Comment',
        ),
        icon: FaRegCommentDots,
        onClick: handleAddComment,
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesComments,
          },
        ],
      },
      {
        actionId: 'chat-gpt',
        title: 'Start ChatGPT',
        tooltipText: getIssueActionTooltip(
          !!isGptAvailable,
          isEnableChatGpt && isEnableChatGpt
            ? 'Start ChatGPT'
            : 'ChatGPT disabled by admin',
          isEnableChatGpt && isEnableChatGpt
            ? 'Start ChatGPT'
            : 'ChatGPT disabled by admin',
        ),
        relevantIssuePages: [IssuePages.CurrentIssues],
        icon: SiOpenai,
        onClick: openGptConsentModal,
        highlight: false,
        disabled: !isGptAvailable || !isEnableChatGpt,
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesChatGPT,
          },
        ],
      },
      {
        actionId: 'fix-issue',
        title: 'Fix Issue',
        tooltipText: getIssueActionTooltip(
          !!isFixAvailable,
          'Fix Issue',
          'Fix unavailable for this issue',
        ),
        icon: SlMagicWand,
        onClick: openPolicyFix,
        disabled: !isFixAvailable,
        highlight: !!isFixAvailable,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesFix,
          },
        ],
      },
      {
        actionId: 'view-code-fix',
        title: 'View Code Fix',
        tooltipText: getIssueActionTooltip(
          isPRAvailable,
          'View Code Fix',
          'Code fix unavailable for this issue',
        ),
        icon: FaCode,
        onClick: onViewCodeFixClick,
        disabled: !isPRAvailable,
        highlight: isPRAvailable,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Read,
            permissionType: PermissionTypes.IssuesFix,
          },
        ],
      },
      {
        actionId: 'open-pr',
        title: 'Open PR',
        tooltipText: getIssueActionTooltip(
          isPRAvailable && isSourceControlConnected,
          'Open PR',
          !isPRAvailable
            ? 'PR unavailable for this issue'
            : 'Source Control not connected',
        ),
        icon: DiGit,
        onClick: openPRModal,
        disabled: !isPRAvailable || !isSourceControlConnected,
        highlight: isPRAvailable,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.PullRequest,
          },
        ],
      },

      ...getMessagingActions(issuesPage, [issueId]),
      ...ticketingActions,
    ],
    [
      {
        actionId: 'exclude-issue',
        title: 'Exclude Actions',
        tooltipText: getIssueActionTooltip(true, 'Exclude Actions', ''),
        icon: ExclusionsIcon,
        onClick: handleExcludeIssue,
        highlight: false,
        relevantIssuePages: [
          IssuePages.CurrentIssues,
          IssuePages.PipelineIssues,
        ],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesExclude,
          },
        ],
        subMenuActions: exclusionsCategories
          ?.filter(
            cat => !cat.ffKey || (!!cat.ffKey && flags && flags[cat.ffKey]),
          )
          .map(exclusionCat => ({
            actionId: 'exclude-issue',
            title: exclusionCat.label,
            tooltipText: exclusionCat.label,
            icon:
              ExclusionsActionIconMap.get(
                getExclusionTypeFromLabel(exclusionCat.label),
              ) || null,
            onClick: handleExcludeIssue,
            onClickFunctionParams: [exclusionCat],
            highlight: false,
            permissions: [
              {
                permissionScope: PermissionScope.Edit,
                permissionType: PermissionTypes.IssuesExclude,
              },
            ],
            relevantIssuePages: [
              IssuePages.CurrentIssues,
              IssuePages.PipelineIssues,
            ],
          })),
      },
      {
        actionId: 'snooze-issue',
        title: 'Snooze Issue',
        tooltipText: getIssueActionTooltip(true, 'Snooze Issue', ''),
        icon: MdAccessTimeFilled,
        onClick: handleExcludeIssue,
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesSnooze,
          },
        ],
        subMenuActions:
          exclusionsCategories
            ?.filter(
              cat => !cat.ffKey || (!!cat.ffKey && flags && flags[cat.ffKey]),
            )
            .map(exclusionCat => ({
              actionId: 'snooze-issue',
              title: exclusionCat.label.replace('Exclude', 'Snooze'),
              tooltipText: exclusionCat.label.replace('Exclude', 'Snooze'),
              icon:
                ExclusionsActionIconMap.get(
                  getExclusionTypeFromLabel(exclusionCat.label),
                ) || null,
              onClick: handleExcludeIssue,
              onClickFunctionParams: [exclusionCat, true],
              relevantIssuePages: [IssuePages.CurrentIssues],
              highlight: false,
              permissions: [
                {
                  permissionScope: PermissionScope.Edit,
                  permissionType: PermissionTypes.IssuesSnooze,
                },
              ],
            })) || [],
      },
      {
        actionId: 'make-app-irrelevant',
        title: 'Make App Irrelevant',
        tooltipText: getIssueActionTooltip(true, 'Make App Irrelevant', ''),
        icon: MdVisibilityOff,
        onClick: handleApplicationIrrelevant,
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.ApplicationsRelevancy,
          },
        ],
      },
      {
        actionId: 'disable-policy',
        title: 'Disable Policy',
        tooltipText: getIssueActionTooltip(true, 'Disable Policy', ''),
        icon: PolicyXIcon,
        onClick: handleDisablePolicy,
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.Policies,
          },
        ],
      },
      {
        actionId: 'view-policy',
        title: 'View/Edit Policy',
        tooltipText: getIssueActionTooltip(true, 'View/Edit Policy', ''),
        icon: PolicyEdit,
        onClick: openPolicyConfig,
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Read,
            permissionType: PermissionTypes.Policies,
          },
        ],
      },
      {
        actionId: 'change-severity',
        title: 'Change Severity',
        tooltipText: getIssueActionTooltip(true, 'Change Severity', ''),
        icon: TbExchange,
        onClick: handleChangeSeverity,
        highlight: true,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesSeverity,
          },
        ],
      },
      {
        actionId: 'report-false-positive',
        title: 'Report False Positive',
        tooltipText: getIssueActionTooltip(true, 'Report False Positive', ''),
        icon: FaFlag,
        hideAction: isFalsePositive,
        onClick: handleReportFalsePositive,
        highlight: false,
        relevantIssuePages: [
          IssuePages.CurrentIssues,
          IssuePages.ResolvedIssues,
        ],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesReport,
          },
        ],
      },
      {
        actionId: 'cancel-report-false-positive',
        title: 'Cancel Report False Positive',
        tooltipText: getIssueActionTooltip(
          true,
          'Cancel Report False Positive',
          '',
        ),
        icon: CancelFaFlag,
        hideAction: !isFalsePositive,
        onClick: handleCancelReportFalsePositive,
        highlight: false,
        relevantIssuePages: [IssuePages.CurrentIssues],
        permissions: [
          {
            permissionScope: PermissionScope.Edit,
            permissionType: PermissionTypes.IssuesCancelReport,
          },
        ],
      },
    ],
  ];

  return groupedCurrentIssuesActions
    .map(group =>
      group.filter(action => {
        if (action.relevantIssuePages?.includes(issuesPage)) {
          if (action.subMenuActions) {
            action.subMenuActions = action.subMenuActions.filter(subAction => {
              return subAction.relevantIssuePages?.includes(issuesPage);
            });
          }
          return true;
        } else {
          return false;
        }
      }),
    )
    .filter(group => group.length > 0);
};

// const disableAllActions = (
//   groupedActions: IssueAction[][],
//   ignoreNames: IssueAction['tooltipText'][] = [],
// ) => {
//   return groupedActions.map(actions =>
//     actions.map(action => ({
//       ...action,
//       disabled: !ignoreNames.includes(action.title) || action.disabled,
//     })),
//   );
// };

interface IssuesActionsConfigOptions {
  exclusionsCategories?: Exclusions[];
  isPRAvailable: boolean;
  policyId?: string;
  categoryId?: string;
  hasComment: boolean;
  isFixAvailable: boolean;
  isGptAvailable: boolean;
  showJiraUnlinkTicketSubMenuItem?: boolean;
  showUnlinkTicket?: boolean;
  isFalsePositive?: boolean;
  flags?: FeatureFlags;
  selectedAggs?: {
    [x: string]: string;
  }[];
  issueId: string;
  issuesPage: IssuePages;
  preActionFunction?: (issueId: string) => Promise<void>;
}
