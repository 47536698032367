import { logger } from 'logging-utils';
import {
  baseOption,
  KeyStringGenericObject,
  Nullable,
  TicketProvider,
} from 'ox-common-types';
import pluralize from 'pluralize';
import { openErrorSnackbar, openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { getSelectedIssuesIds } from '../../issues/active-issues/store-actions/issue-store-actions';
import AggsUtilityStore from '../../issues/common/stores/aggs-utility-store';
import { IssuePages } from '../../issues/common/types/issues-types';
import ticketingService from '../services';
import { CreateTicketsInput } from '../services/create-ticket/create-tickets';
import { createTicketStoreActions } from '../store-actions/create-ticket-store-actions';
import { ticketingStoreActions } from '../store-actions/ticketing-store-actions';
import { CreateTicketStore, InputOption } from '../store/create-ticket-store';
import { TicketingStore } from '../store/ticketing-store';
import {
  CustomField,
  CustomFieldsRequestType,
  TicketFieldConfig,
  TicketFieldTypes,
} from '../types/ticketing-types';
import { ticketingActions } from './ticketing-actions';
import { isCustomFieldsSupported } from '../utils/ticketing-utils';
import { graphqlUtils } from 'api-clients/src/graphql-utils';
import { reportClient } from 'api-clients';

const setInputControlValue = (fieldKey: string, value: string | null) => {
  createTicketStoreActions.setInputControlObject(fieldKey, value);
};

export const handleInputChange = ({
  fieldKey,
  value,
  isSearchable,
  isRequiredForCustomFields,
  dependsOnValue,
  dependsOnId,
}: {
  fieldKey: string;
  value: string;
  isSearchable: boolean;
  isRequiredForCustomFields?: boolean;
  dependsOnValue?: string;
  dependsOnId?: string;
}) => {
  setInputControlValue(fieldKey, value);
  if (isSearchable) {
    handleSearchableFieldChange(fieldKey, value, dependsOnValue, dependsOnId);
  }
  if (isRequiredForCustomFields) {
    handleCustomFieldsRequiredField(fieldKey, value);
  }
};

export const handleCustomFieldsRequiredField = async (
  fieldKey: string,
  value: string,
) => {
  const { chosenTicketingVendor } = snapshot(TicketingStore);
  const { inputOptions } = snapshot(CreateTicketStore);

  const selectedCustomFieldRequiredOption =
    inputOptions[fieldKey].find(option => option.name === value) || null;
  createTicketStoreActions.setCustomFieldsControlObject(
    fieldKey,
    selectedCustomFieldRequiredOption,
  );
  const { customFieldsControlObject } = snapshot(CreateTicketStore);

  const project = customFieldsControlObject?.project?.id;
  const ticketType = customFieldsControlObject?.type?.id;

  if (chosenTicketingVendor && project && ticketType) {
    createTicketStoreActions.setIsLoadingCustomFields(true);
    const newFieldsConfig = await ticketingService.getInputFields.execute({
      isBulkAction: false,
      issueType: IssuePages.CurrentIssues,
      issueId: '',
      ticketingVendor: chosenTicketingVendor,
      customFieldsRequest: CustomFieldsRequestType.CustomFieldsOnly,
      aggItemsIds: [],
      project,
      ticketType,
    });

    if (newFieldsConfig?.customFields) {
      createTicketStoreActions.updateCustomFieldsConfig(
        newFieldsConfig?.customFields,
      );
    }
    createTicketStoreActions.setIsLoadingCustomFields(false);
  }
};

export const handleSearchableFieldChange = async (
  fieldKey: string,
  value: string,
  dependsOnValue?: string,
  dependsOnId?: string,
) => {
  createTicketStoreActions.setIsLoadingInputOptions(fieldKey, true);
  const { chosenTicketingVendor } = snapshot(TicketingStore);
  const { inputSelectedControlObject } = snapshot(CreateTicketStore);
  const organization = inputSelectedControlObject['organization'] as baseOption;
  const organizationName = organization?.name || undefined;
  if (!chosenTicketingVendor) {
    logger.error(
      'Ticketing provider not chosen - will not search for input options',
    );
    return;
  }

  const searchableFieldValues =
    await ticketingService.searchInputOptions.execute({
      inputKey: fieldKey,
      searchValue: value,
      ticketingVendor: chosenTicketingVendor,
      dependsOnValue,
      dependsOnId,
      owner: organizationName,
    });
  if (!searchableFieldValues) {
    logger.error(`Failed to get searchable field values for ${fieldKey}`);
    return;
  }

  createTicketStoreActions.setInputOptions(fieldKey, searchableFieldValues);
  createTicketStoreActions.setIsLoadingInputOptions(fieldKey, false);
};

export const handleInputSelected = (
  fieldKey: string,
  value: InputOption | (string | InputOption)[],
) => {
  createTicketStoreActions.setInputSelectedControlValue(fieldKey, value);
};

export const fetchTicketInputFields = async ({
  chosenTicketingVendor,
  issueId,
  issueType,
  isBulkAction,
  customFieldsRequest,
  project,
  ticketType,
}: {
  chosenTicketingVendor: string;
  issueId: string;
  issueType: IssuePages;
  aggItemsIds: string[];
  isBulkAction: boolean;
  customFieldsRequest?: CustomFieldsRequestType;
  project?: string;
  ticketType?: string;
}): Promise<void> => {
  createTicketStoreActions.setIsLoadingInputFields(true);
  createTicketStoreActions.setIsErrorLoadingInputFields(false);

  const { selectedAggIds } = snapshot(AggsUtilityStore);

  const config = await ticketingService.getInputFields.execute({
    issueId,
    ticketingVendor: chosenTicketingVendor,
    issueType,
    aggItemsIds: selectedAggIds,
    isBulkAction,
    customFieldsRequest,
    project,
    ticketType,
  });
  if (!config) {
    createTicketStoreActions.setIsErrorLoadingInputFields(true);
    openErrorSnackbar(
      'Failed to get ticket input fields, please try again later',
    );
    logger.error('Failed to get ticket input fields');
    return;
  }
  setInitialControlObject(config.fields);
  createTicketStoreActions.setFieldsConfig(config.fields);
  if (
    isCustomFieldsSupported(chosenTicketingVendor) &&
    config.customFields.length > 0
  ) {
    setInitialControlObject(config.customFields);
    createTicketStoreActions.updateCustomFieldsConfig(config.customFields);
  }
  createTicketStoreActions.setIsLoadingInputFields(false);
};

export const setInitialControlObject = (fieldsConfig: TicketFieldConfig[]) => {
  fieldsConfig.forEach(field => {
    const isMultiSelect = field.type === TicketFieldTypes.MultiSelect;
    if (isMultiSelect && !field.preFilledValue) {
      handleInputSelected(field.id, []);
      return;
    } else if (!field.preFilledValue) {
      return;
    } else if (isMultiSelect) {
      const selectedValue = Array.isArray(field.preFilledValue)
        ? field.preFilledValue
        : [field.preFilledValue];
      handleInputSelected(field.id, selectedValue as InputOption[]);
    } else if (field.type === TicketFieldTypes.Dropdown) {
      createTicketStoreActions.setInputOptions(field.id, [
        field.preFilledValue as InputOption,
      ]);
      handleInputSelected(field.id, field.preFilledValue as InputOption);
    } else {
      setInputControlValue(
        field.id,
        (field.preFilledValue as InputOption)?.name!,
      );
    }
    if (field.isRequiredForCustomFields && field.preFilledValue) {
      createTicketStoreActions.setCustomFieldsControlObject(
        field.id,
        field.preFilledValue as InputOption,
      );
    }
  });
};

export const extractDropdownInputOptionValue = (value): string => {
  if (typeof value === 'object' && value !== null && 'id' in value) {
    return value.id;
  } else {
    logger.error(
      'Error: Cannot convert dynamic ticket fields for CreateTicketsInput',
    );
    return '';
  }
};

export const packCustomFields = ({
  ticketFieldConfig,
  ticket,
}: {
  ticketFieldConfig: TicketFieldConfig[];
  ticket: KeyStringGenericObject<
    Nullable<string | InputOption | (string | InputOption)[]>
  >;
}): {
  ticket: KeyStringGenericObject<
    Nullable<string | InputOption | (string | InputOption)[]>
  >;
  customFields?: CustomField[];
} => {
  const customFieldsIds = ticketFieldConfig
    .filter(field => field.isCustomField)
    .map(field => field.id);

  if (customFieldsIds.length === 0) {
    return { ticket };
  }

  const customFields: CustomField[] = [];
  Object.keys(ticket).forEach(key => {
    if (customFieldsIds.includes(key)) {
      const customValue = ticket[key];
      const value =
        typeof customValue === 'string'
          ? customValue
          : extractDropdownInputOptionValue(customValue);
      customFields.push({ id: key, value });
      delete ticket[key];
    }
  });

  return { ticket, customFields };
};

export const createTickets = async (
  isBulk: boolean,
  selectedIssueId: Nullable<string>,
  issuePage: IssuePages,
) => {
  ticketingStoreActions.setIsLoadingSubmitAction(true);
  const { chosenTicketingVendor } = snapshot(TicketingStore);
  const { inputControlObject, inputSelectedControlObject, customFieldsConfig } =
    snapshot(CreateTicketStore);

  const { selectedAggIds } = snapshot(AggsUtilityStore);

  if (!chosenTicketingVendor) {
    openErrorSnackbar(
      'An error occurred. Please refresh the page and try again.',
    );
    logger.error('Ticketing provider not chosen');
    ticketingStoreActions.setIsLoadingSubmitAction(false);
    return;
  }

  const tickets: CreateTicketsInput['tickets'] = [];
  if (isBulk) {
    const selectedIssuesIds = getSelectedIssuesIds();
    selectedIssuesIds.forEach(issueId => {
      const { ticket, customFields } = packCustomFields({
        ticket: { ...inputControlObject, ...inputSelectedControlObject },
        ticketFieldConfig: customFieldsConfig,
      });
      tickets.push({
        issueId,
        issueType: issuePage,
        ticket,
        customFields,
      });
    });
    ticketingStoreActions.setShowCreateBulkTicketModal(false);
  } else {
    if (!selectedIssueId) {
      openErrorSnackbar(
        'An error occurred. Please refresh the page and try again.',
      );
      logger.error('Error: Invalid selected issue (undefined or null)');
      return;
    }
    const { ticket, customFields } = packCustomFields({
      ticket: { ...inputControlObject, ...inputSelectedControlObject },
      ticketFieldConfig: customFieldsConfig,
    });
    tickets.push({
      issueId: selectedIssueId,
      issueType: issuePage,
      ticket,
      aggItemsIds: selectedAggIds,
      customFields,
    });
  }

  const createTicketsInput = {
    ticketingVendor: chosenTicketingVendor,
    tickets,
  };

  const createdTickets = await ticketingService.createTickets.execute(
    createTicketsInput,
  );
  graphqlUtils.invalidateCachedQueries(reportClient, ['getSingleIssueInfo']);

  if (createdTickets) {
    openSnackbar(
      `${!!createdTickets.length && createdTickets.length}${pluralize(
        'Ticket',
        createdTickets.length,
      )} created successfully`,
      { variant: 'success' },
    );
    if (!isBulk && selectedIssueId) {
      ticketingActions.addTicketToIssue({
        ticketRef: {
          key: createdTickets[0].key,
          ticketId: createdTickets[0].ticketId,
          link: createdTickets[0].ticketLink,
          status: createdTickets[0].status,
          provider: chosenTicketingVendor as unknown as TicketProvider,
          reporter: createdTickets[0].reporter,
          assignTo: createdTickets[0].assignTo,
          project: createdTickets[0].project,
          created: createdTickets[0].created,
          updated: createdTickets[0].updated,
        },
        issuePage,
        issueId: selectedIssueId,
      });
    }
  }
  ticketingStoreActions.setIsLoadingSubmitAction(false);
  if (!createdTickets) {
    openErrorSnackbar('Failed to create tickets, please try again later');
    logger.error('Failed to create tickets');
    return;
  }
  ticketingActions.closeCreateTicketModal();
};
