import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { formatDistanceStrict } from 'date-fns';
import { logger } from 'logging-utils';
import { Nullable, SeverityAlert } from 'ox-common-types';
import React from 'react';

export const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export const isValidEmail = (email: string): boolean => {
  const lowerCasedEmail = email.toLowerCase();

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    lowerCasedEmail,
  );
};

export const isValidOrgName = (orgDisplayName: Nullable<string>) => {
  if (!orgDisplayName) {
    return false;
  }

  return (
    isValidOrgDisplayNameStr(orgDisplayName) &&
    isValidOrgDisplayNameLen(orgDisplayName)
  );
};

export const isValidOrgDisplayNameStr = (orgDisplayName: string) => {
  return /^[a-z0-9 ' .]*$/i.test(orgDisplayName);
};

export const sanitizeOrgDisplayNameInput = (orgDisplayName: string) => {
  return orgDisplayName.replaceAll(/[^a-z0-9'.]/gi, ' ');
};

export const isValidOrgDisplayNameLen = (orgDisplayName: string) => {
  return orgDisplayName.length > 3;
};

export const download = (
  content: string,
  fileName: string,
  contentType: string = 'text/plain',
) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};

export const stopPropagation = (
  event: React.MouseEvent | React.SyntheticEvent,
) => {
  event.stopPropagation();
};

export const textNumberVerificator = (
  value: string,
  min: number,
  max: number,
) => {
  let n = value.replace(/[^0-9]/g, '');
  if (!n) return '';
  try {
    const number = parseInt(n);
    if (number <= max && number > min) {
      return number;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const isOxUserEmail = (email?: string) =>
  email && email.indexOf('@ox.security') !== -1;

export const percentageFormatter = (value: string | number) => `${value}%`;

export const createDate = (date: string | null | number) => {
  if (date) {
    let t: Date | null = null;
    try {
      const number = parseInt(date as string);
      t = new Date(number);
    } catch {}
    return t;
  }
  return null;
};

export const isValidHttpUrl = (path: string) => {
  let url;
  try {
    url = new URL(path);
  } catch (e) {
    return false;
  }
  return /https?/.test(url.protocol);
};

export const getFlagEmoji = (countryCode: string) => {
  if (!countryCode) return '';

  return getUnicodeFlagIcon(countryCode);
};

export const getCountryByCode = (countryCode?: string) => {
  if (!countryCode) return '';
  try {
    return regionNames.of(countryCode.toUpperCase());
  } catch {
    logger.error(
      'Failed to convert country code to country name:',
      countryCode,
    );
  }
  return countryCode;
};

export const downloadTextToCsv = (content: string, fileName?: string) => {
  let blob;
  blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const element = document.createElement('a');
  element.href = URL.createObjectURL(blob);
  element.download = fileName ? fileName : 'csvFile.csv';
  document.body.appendChild(element);
  element.click();
};

export const issuesCountNotExist = (severities?: SeverityAlert) => {
  let isZero;
  if (severities) {
    isZero = !Object.values(severities).every(o => o === 0);
    if (!isZero) {
      return true;
    }
  }
  return false;
};

export const getDuration = (scanStartDate: Date, scanFinishDate: Date) => {
  let duration = '';
  try {
    duration = formatDistanceStrict(scanStartDate, scanFinishDate, {
      unit: 'minute',
    });
  } catch {}
  return duration;
};

export const ColorsPallete = [
  '#36a2ea',
  '#ff6383',
  '#4bc0c0',
  '#ff9e40',
  '#9a66fe',
  '#ffcd57',
  '#c8cbcf',
  '#32a88f',
  '#5232a8',
  '#a832a0',
  '#a67ca3',
  '#b6d9ab',
  '#fcba03',
  '#0af5d5',
  '#3a1d66',
  '#661d3e',
  '#887bd4',
  '#f5c1ea',
];

export const downloadFileFromURL = (url: string) => {
  const element = document.createElement('a');
  element.href = url;
  document.body.appendChild(element);
  element.click();
};

export const getLocalTime = (epochTimeSec: number) => {
  const date = new Date(epochTimeSec * 1000);

  return date.toLocaleString();
};

export const getAllTableRowsIdsBetweenSelection = (
  currentItemId: string, // Id of the clicked / hovered item
  idKey: string, // Can be 'id' or 'appId' etc.
  items: unknown[], // Array of objects like issues, applications etc.
  selectedItemsIds: string[], // Array of selected items ids
): string[] => {
  const startIndex = items.findIndex(item => {
    const _item = item as { [idKey: string]: string };
    return selectedItemsIds.includes(_item[idKey]);
  });
  const endIndex = items.findIndex(item => {
    const _item = item as { [idKey: string]: string };
    return (
      _item && _item.hasOwnProperty(idKey) && _item[idKey] === currentItemId
    );
  });

  return items.reduce((acc: string[], item: unknown, index) => {
    if (
      (startIndex < endIndex && index >= startIndex && index <= endIndex) ||
      (index >= endIndex && index <= startIndex)
    ) {
      const _item = item as { [idKey: string]: string };
      _item && _item.hasOwnProperty(idKey) && acc.push(_item[idKey] as string);
    }
    return acc;
  }, []);
};

export const formatToNDigits = (number: number, n: number) => {
  return number.toString().padStart(n, '0');
};
