import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationsStore } from 'organizations';
import { FC, SyntheticEvent } from 'react';
import { useSnapshot } from 'valtio';
import OrganizationSelector from '../components/OrganizationSelector';
import { useOrgSwitchLoginWithRedirect } from '../../app/components/providers/app-organization-provider/hooks/useOrgSwitchLoginWithRedirect';
import { Organization } from '../organization-types';

const OrganizationSelectorContainerV2: FC = () => {
  const { user } = useAuth0();
  const { organizations, chosenOrganization, isLoading } =
    useSnapshot(OrganizationsStore);
  const { handleOrgLoginWithRedirect } = useOrgSwitchLoginWithRedirect();

  const handleChange = (
    _event: SyntheticEvent<Element, Event> | null,
    organization: Organization,
  ) => handleOrgLoginWithRedirect(organization, window.location.pathname, null);

  if (!user?.org_id) {
    return null;
  }

  return organizations.length > 1 ? (
    <OrganizationSelector
      organizations={organizations}
      lastLoggedInOrg={chosenOrganization}
      isLoading={isLoading ?? true}
      handleChange={handleChange}
    />
  ) : null;
};

export default OrganizationSelectorContainerV2;
