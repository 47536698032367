import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import {
  CredentialsConfigureResponse,
  IdentityProviderInput,
} from '../../connectors-types';
import mutation from './configure-identity-provider.gql';

const configureIdentityProvider = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      identityProviderInput: IdentityProviderInput,
      isMultiCredentials = false,
    ): Promise<Nullable<CredentialsConfigureResponse>> => {
      const results = await client.mutate<ConfigureIdentityProviderResponse>({
        mutation,
        variables: { identityProviderInput, isMultiCredentials },
      });

      if (results?.data?.configureIdentityProvider) {
        return results.data.configureIdentityProvider.connector;
      }

      throw new Error('Failed to add credentials');
    },
  };
};

interface ConfigureIdentityProviderResponse {
  configureIdentityProvider: { connector: CredentialsConfigureResponse };
}

export default configureIdentityProvider;
