import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import query from './get-settings.gql';
import { ApolloClientCache } from 'ox-common-types';
import { logger } from 'logging-utils';
import {
  GetSettingsInput,
  GetSettingsRes,
} from '../../../ticketing-settings/types';

export const getSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getSettingsInput: GetSettingsInput) => {
      try {
        const results = await client.query<GetSettingsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getSettingsInput },
        });
        return results?.data?.getSettings?.settings ?? [];
      } catch (error) {
        logger.error('Failed to get ticketing settings', error);
        return [];
      }
    },
  };
};

export interface GetSettingsResponse {
  getSettings: GetSettingsRes;
}
