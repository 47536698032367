import { FC, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Divider,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import DevIssueHeaderCell from './DevIssueHeaderCell';
import CategoryIconsMap from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/icons';
import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';
import {
  SettingsName,
  SettingsSubType,
} from '../../../../../../../settings/types/settings-types';
import SettingsStore from '../../../../../../../settings/stores/settings-store';
import { useSnapshot } from 'valtio';
import { formatDistanceToNow } from 'date-fns';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CustomLink } from 'ox-react-components';
import LearnMoreItem from './LearnMoreItem';
import IssueCardSeverity from '../../../../IssueCard/IssueCardSeverity';
import { Issue, IssueDetails } from '../../../../../types/issues-types';
import CorrelatedIssue from '../../../../IssueCard/CorrelatedIssue';
import { getSlaData } from '../../../../../utils/issue-utils';
import { useOxFlags } from '../../../../../../../feature-flags/actions/feature-flags-actions';
import { Nullable } from 'ox-common-types';

const DevIssueSummaryHeader: FC<
  React.PropsWithChildren<DevIssueSummaryHeaderProps>
> = ({ filters, moveToTab, selectedIssue, selectedIssueRow }) => {
  const { slaEnabled } = useOxFlags();
  const { classes } = useStyles();
  const {
    correlatedIssueId,
    correlatedRegistry,
    app,
    category,
    severity,
    latestCommit,
  } = selectedIssue;
  const categoryName = category.name;
  const appType = app.type;
  const CategoryIcon = CategoryIconsMap.get(
    categoryName as CategoryDisplayName,
  );
  const theme = useTheme();
  const { orgSettingsMapByName } = useSnapshot(SettingsStore);
  const isEnableAdvancedOptionsToolTip = useMemo(
    () =>
      orgSettingsMapByName
        .find(s => s.name === SettingsName.Tooltips)
        ?.settings.find(
          s => s.settingsSubType === SettingsSubType.AdvancedOptionsToolTip,
        )?.enabled,
    [orgSettingsMapByName],
  );
  const slaData = useMemo(() => {
    const SlaData = getSlaData(selectedIssueRow?.sla);
    if (!slaEnabled || !SlaData) return null;
    const Icon = SlaData.icon;
    return (
      <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
        <Icon size={16} color={SlaData.color} />
        <Typography>{SlaData.cardText}</Typography>
      </Box>
    );
  }, [slaEnabled, selectedIssueRow?.sla]);

  return (
    <Box
      display='flex'
      flexDirection='row'
      gap={2}
      alignItems='center'
      justifyContent='space-between'>
      <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
        <DevIssueHeaderCell title='Category'>
          <Box
            display='flex'
            flexDirection='row'
            gap={1}
            alignItems='flex-start'>
            {CategoryIcon && (
              <CategoryIcon
                color={theme.palette.primary.main}
                style={{ flexShrink: 0, fontSize: 16, marginTop: 3 }}
              />
            )}
            <Typography variant='body1' color='text.primary'>
              {categoryName}
            </Typography>
          </Box>
        </DevIssueHeaderCell>
        <Divider orientation='vertical' flexItem />
        <DevIssueHeaderCell title='Severity'>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            gap={0.5}>
            <IssueCardSeverity
              severity={severity}
              isEnableAdvancedOptionsToolTip={isEnableAdvancedOptionsToolTip}
              filters={filters}
            />
            {slaData}
          </Box>
        </DevIssueHeaderCell>
        <Divider orientation='vertical' flexItem />
        {latestCommit?.date && (
          <>
            <DevIssueHeaderCell title='Latest Commit'>
              <CustomLink
                href={latestCommit.commitLink}
                variant='body1'
                color='text.primary'
                className={classes.overflow}
                value={formatDistanceToNow(new Date(latestCommit.date), {
                  addSuffix: true,
                })}></CustomLink>
            </DevIssueHeaderCell>
            <Divider orientation='vertical' flexItem />
          </>
        )}

        {latestCommit && (
          <>
            <DevIssueHeaderCell title='Latest Code Match'>
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: { sx: { maxWidth: window.innerWidth } },
                }}
                title={
                  <SyntaxHighlighter
                    language={'javascript'}
                    style={theme.palette.mode === 'light' ? docco : dracula}
                    startingLineNumber={
                      latestCommit.snippetLineNumber
                        ? Number(latestCommit.snippetLineNumber)
                        : 1
                    }
                    showLineNumbers>
                    {latestCommit.snippet}
                  </SyntaxHighlighter>
                }>
                <Typography
                  variant='body1'
                  color='text.primary'
                  className={classes.overflow}>
                  {latestCommit.match}
                </Typography>
              </Tooltip>
            </DevIssueHeaderCell>
            <Divider orientation='vertical' flexItem />
          </>
        )}

        {latestCommit && (
          <LearnMoreItem
            title='View all commits'
            onClick={() => moveToTab('Commits')}
          />
        )}
      </Box>

      {correlatedIssueId && category && (
        <CorrelatedIssue
          className={classes.correlatedIssue}
          correlatedIssueId={correlatedIssueId}
          category={categoryName}
          appType={appType}
          registryType={correlatedRegistry}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    WebkitLineClamp: 2,
    wordBreak: 'break-word',
    WebkitBoxOrient: 'vertical',
  },
  correlatedIssue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export interface DevIssueSummaryHeaderProps {
  moveToTab: (tab: string) => void;
  filters: {};
  selectedIssue: IssueDetails;
  selectedIssueRow: Nullable<Issue>;
}

export default DevIssueSummaryHeader;
