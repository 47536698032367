import { createTheme, Grow, Zoom } from '@mui/material';
import { sharedTypography } from './shared/theme-shared-typography';
import { themeBaseColors } from './shared/theme-based-colors';

type EnhancedWindow = Window & { safari: string | undefined };
const w = window as unknown as EnhancedWindow;

const { light, base } = themeBaseColors;
const { typography } = sharedTypography;

export const oxLightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: base.white,
      default: '#EFF0F5',
    },
    dashInventory: {
      textColor: {
        colorRelevant: '#37375C',
        colorAppsText: '#7179FE',
      },
    },
    primary: {
      main: '#6837FF',
      light: '#B39BFF',
    },
    secondary: {
      main: '#A7ACFF',
    },
    text: {
      primary: '#37375C',
      secondary: '#9B9BAD',
      link: '#5059FF',
      disabled: '#a6a6a6',
    },
    info: {
      main: '#FFA302',
    },
    success: {
      main: '#33C600',
      disabled: '#C7EEB7',
    },
    warning: {
      main: '#FA8D83',
    },
    error: {
      main: '#FA1414',
      disabled: '#FFB2B2',
    },
    grey: {
      '100': '#E5E5E5',
    },
    progress: {
      backgroundColor: '#37375C',
      barColor: 'white',
      trackColor: '#696985',
      textColor: 'white',
    },
    badge: {
      connected: '#33C600',
      notConnected: '#FFA302',
      connectionIssue: '#FA1414',
      comingSoon: '#F1F2F1',
    },
    panel: {
      panelHeader: {
        backgroundColor: 'rgba(239, 240, 245, 0.5)',
      },
    },
    coverageColors: {
      clientCoverage: '#A7ACFF',
      oxCoverage: '#6837FF',
      noCoverage: '#FA8D83',
      notApplicable: '#E5E5E5',
    },
    categoryRiskColor: {
      appox: '#8B30AB',
      appoxalypse: light.severity.appoxalypse,
      critical: light.severity.critical,
      high: light.severity.high,
      medium: light.severity.medium,
      low: light.severity.low,
      info: light.severity.info,
      passed: '#33C600',
      none: '#E5E5E5',
      noIssues: '#a9d295',
    },
    statButton: {
      stat: {
        border: '#ffffff40',
      },
      active: {
        background: '#EFF0F5',
        border: '#A7ACFF',
      },
      disabled: {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      hover: {
        background:
          'linear-gradient(0deg, rgba(80, 89, 255, 0.05), rgba(80, 89, 255, 0.05)), #FFFFFF;',
      },
      increase: {
        main: '#37375C',
      },
      decrease: {
        main: '#37375C',
      },
    },
    workflow: {
      nodes: {
        Action: '#5C94ED',
        Condition: '#FFC051',
        Trigger: '#33C600',
        Pipeline: '#6837FF',
      },
    },
    onboarding: {
      codeRepoButton: {
        background: '#FFFFFF',
        text: '#37375C',
        selected:
          'linear-gradient(0deg, rgba(80, 89, 255, 0.1), rgba(80, 89, 255, 0.1)), #FFFFFF',
      },
      oxLogo: {
        color: '#1D164D',
      },
    },
    gptContent: {
      fontFamily:
        'Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
      answerBgColor: '#f7f7f8',
      questionBgColor: '#ffffff',
      fontColor: 'rgb(55, 65, 81)',
    },
  },
  typography: { ...typography },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent:
          (w.safari as string | undefined) !== undefined ? Zoom : Grow,
      },
      styleOverrides: {
        arrow: {
          '&:before': {
            backgroundColor: 'white',
            borderRadius: '0 0 3px 0',
          },
        },
        tooltip: {
          // maxWidth: 600,
          color: 'inherit',
          filter: 'drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15))',
          backgroundColor: 'white',
        },
      },
    },
  },
});
