enum SizesInPx {
  Small = '12px',
  Medium = '16px',
  Large = '24px',
}

export type IconSize = 'small' | 'medium' | 'large';

export interface FontAwesomeIconWrapper {
  padding: string;
  size: SizesInPx;
  color?: string;
}
export interface FontAwesomeIconStyled {
  container: FontAwesomeIconWrapper;
  fontSize: string;
}

type FontAwesomeIconGenericObjectStyled = {
  [key in IconSize]: FontAwesomeIconStyled;
};
const fontAwesomeIconStyled = {} as FontAwesomeIconGenericObjectStyled;

fontAwesomeIconStyled.small = {
  container: {
    padding: '0',
    size: SizesInPx.Small,
  },
  fontSize: '8px',
};
fontAwesomeIconStyled.medium = {
  container: {
    padding: '0 0 1px 0',
    size: SizesInPx.Medium,
  },
  fontSize: '12px',
};
fontAwesomeIconStyled.large = {
  container: {
    padding: '0 0 0.5px 0',
    size: SizesInPx.Large,
  },
  fontSize: '18px',
};

export default fontAwesomeIconStyled;
