import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraTeam,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-teams.gql';

const getTeams = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<void, Nullable<GetTeamsResponse>> => {
  return {
    execute: async (): Promise<Nullable<GetTeamsResponse>> => {
      try {
        const results = await client.query<GetTeamsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to get teams', error);
        return null;
      }
    },
  };
};

export interface GetTeamsResponse {
  getTeams: JiraTeam[];
}

export default getTeams;
