import { Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import ActionsOptions from './ActionsOptions';
import { navigateToSpecificApp } from 'app-navigator';
import { useSnapshot } from 'valtio';
import SettingsStore from '../../../../settings/stores/settings-store';
import { SettingsSubType } from '../../../../settings/types/settings-types';

const ActionsOptionsTooltip: FC<
  React.PropsWithChildren<ActionsOptionsTooltipProps>
> = ({
  value,
  displayValue,
  filterType,
  children,
  filters,
  id,
  appId,
  withFilter,
  withName,
  primaryAppReason,
}) => {
  const { orgSettingsMapByName } = useSnapshot(SettingsStore);

  const moveToApp = useCallback(() => {
    appId && navigateToSpecificApp(appId);
  }, [appId]);

  const toolTipHoverTime = orgSettingsMapByName
    .find(s => s.name === 'Tooltips')
    ?.settings.find(
      s =>
        s.settingsSubType ===
        SettingsSubType.ConfiguredAdvancedOptionsToolTipTime,
    );

  return (
    <Tooltip
      placement='top'
      enterDelay={
        toolTipHoverTime?.configured
          ? toolTipHoverTime?.configured * 1000
          : 1000
      }
      componentsProps={{
        tooltip: { sx: { maxWidth: 500 } },
      }}
      arrow
      title={
        <ActionsOptions
          value={value}
          displayValue={displayValue}
          id={id}
          filterType={filterType}
          filters={filters}
          withFilter={withFilter}
          withName={withName}
          moveToApp={moveToApp}
          primaryAppReason={primaryAppReason}
        />
      }>
      <div>{children}</div>
    </Tooltip>
  );
};

interface ActionsOptionsTooltipProps {
  value: string;
  id?: string;
  appId?: string;
  filterType?: string;
  filters?: string[];
  withFilter?: boolean;
  withName?: boolean;
  displayValue?: string;
  primaryAppReason?: string;
}

export default ActionsOptionsTooltip;
