import { getCurrentTheme } from 'app-theme';
import { AppSeverity, OneOfValues, SeverityAlert } from 'ox-common-types';

export const getSeverity = (value: number): OneOfValues<typeof AppSeverity> => {
  if (value > 66) {
    return AppSeverity.High;
  }
  if (value > 33) {
    return AppSeverity.Medium;
  }
  return AppSeverity.Low;
};

export const severities = [
  {
    key: 'appox',
    title: 'Appoxalypse',
    value: 5,
    color: getCurrentTheme().palette.categoryRiskColor['appox'],
  },
  {
    key: 'critical',
    title: 'Critical',
    value: 4,
    color: getCurrentTheme().palette.categoryRiskColor['critical'],
  },
  {
    key: 'high',
    title: 'High',
    value: 3,
    color: getCurrentTheme().palette.categoryRiskColor['high'],
  },
  {
    key: 'medium',
    title: 'Medium',
    value: 2,
    color: getCurrentTheme().palette.categoryRiskColor['medium'],
  },
  {
    key: 'low',
    title: 'Low',
    value: 1,
    color: getCurrentTheme().palette.categoryRiskColor['low'],
  },
  {
    key: 'info',
    title: 'Info',
    value: 0,
    color: getCurrentTheme().palette.categoryRiskColor['info'],
  },
];

export enum SeverityEnum {
  Appox = 'appox',
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Info = 'info',
}

export const SeverityConst = {
  Appox: 'appox',
  Critical: 'critical',
  High: 'high',
  Medium: 'medium',
  Low: 'low',
  Info: 'info',
} as const;

export const selectTopSeverities = (
  alerts?: SeverityAlert,
  show = severities.length, // how many items to show
) => {
  if (alerts) {
    return Object.entries(alerts)
      .filter(sev => sev && sev[1] && sev[1] !== 0)
      .sort(
        (s1, s2) =>
          (severities.find(s => s.key === s2[0])?.value || 0) -
          (severities.find(s => s.key === s1[0])?.value || 0),
      )
      .slice(0, show);
  }
  return null;
};

export const sumIssues = (issues: SeverityAlertIndexed) => {
  return severities.reduce((acc, s) => acc + (issues[s.key] || 0), 0);
};

interface SeverityAlertIndexed extends SeverityAlert {
  [key: string]: number | undefined;
}
