import { AppPages, decodeFromUrl, navigateWithParameters } from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { throttle } from 'lodash-es';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import apiInventoryService from '../services';
import ApiInventoryFiltersStore from '../stores/api-inventory-filters-store';
import { setLazyFilters } from '../stores/api-inventory-filters-store-actions';
import ApiInventoryStore from '../stores/api-inventory-store';
import { loadApiInventories, selectInventory } from './api-inventory-actions';
import { Nullable } from 'ox-common-types';
import { ConditionalFilter, getOpenFilterItemIds } from 'ox-filter-utils';

export const onChangeFilter = throttle(
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const { loadingFilters } = snapshot(ApiInventoryFiltersStore);
    const { loading } = snapshot(ApiInventoryStore);
    if (loadingFilters.isPending() || loading.isPending()) {
      return;
    }

    if (ApiInventoryFiltersStore.filterBy[e.target.id]) {
      const index = ApiInventoryFiltersStore.filterBy[e.target.id].indexOf(
        e.target.value,
      );
      if (index !== -1) {
        ApiInventoryFiltersStore.filterBy[e.target.id].splice(index, 1);
      } else {
        ApiInventoryFiltersStore.filterBy[e.target.id].push(e.target.value);
      }
    } else {
      ApiInventoryFiltersStore.filterBy[e.target.id] = [e.target.value];
    }
    selectInventory();
    navigateWithParameters(AppPages.ApiInventory, {
      filters: ApiInventoryFiltersStore.filterBy,
    });
    loadApiInventories({ update: true });
  },
  1000,
);

export const setInitialFilters = (encodedFilters: string | null) => {
  if (!encodedFilters) return;

  ApiInventoryFiltersStore.filterBy =
    (decodeFromUrl(encodedFilters) as ConditionalFilter[]) || {};
};

export const getApiInventoryOpenFilterItems = createSimpleAsyncAction(
  async (type?: Nullable<string>, isOpen?: boolean) => {
    const currentFilterItem =
      ApiInventoryFiltersStore.lazyFilters &&
      type &&
      ApiInventoryFiltersStore.lazyFilters[type];

    if (currentFilterItem) {
      currentFilterItem.isOpen = Boolean(isOpen);
      // todo this not reflecting on ui - need to check
      currentFilterItem.isLoading = Boolean(isOpen);
    }

    const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
      GlobalDataViewSelector,
    );
    const { lazyFilters } = snapshot(ApiInventoryFiltersStore);
    const filterItem = lazyFilters && type && lazyFilters[type];
    const openFilterItemIds = lazyFilters
      ? getOpenFilterItemIds(lazyFilters)
      : [];

    const currentFilterItemIsClosed = filterItem && !filterItem.isOpen;
    const allFiltersClosed =
      !openFilterItemIds || openFilterItemIds.length === 0;
    if (allFiltersClosed || currentFilterItemIsClosed) {
      if (currentFilterItem) {
        currentFilterItem.isLoading = false;
      }
      return;
    }

    const results =
      await apiInventoryService.getApiInventoriesFiltersLazy.execute({
        filters: ApiInventoryFiltersStore.filterBy,
        search: ApiInventoryStore.topSearchValue,
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        openItems: openFilterItemIds,
        filterSearch: ApiInventoryFiltersStore.searchValues,
      });

    if (currentFilterItem) {
      currentFilterItem.isLoading = false;
    }

    if (results && results.filters && lazyFilters) {
      setLazyFilters(results.filters, lazyFilters);
    }
  },
  {
    asyncState: ApiInventoryFiltersStore.loadingFilters,
    errorMessage: 'Failed to load API BOM filters',
  },
);
