import { env } from 'env-utils';
import { envToBoolean } from './utils/feature-flags-utils';

export enum FeatureFlagKeys {
  scheduledScansSettings = 'scheduledScansSettings',
  scheduledScanTimeSettings = 'scheduledScanTimeSettings',
  excludeFileOrFoldersSettings = 'excludeFileOrFoldersSettings',
  awsCodeCommitCloudformation = 'awsCodeCommitCloudformation',
  reslovedIssues = 'reslovedIssues',
  savedFilters = 'savedFilters',
  protectingXDevsBadge = 'protectingXDevsBadge',
  kubernetesConnectorFamily = 'kubernetesConnectorFamily',
  oxCanRunFastestScan = 'oxCanRunFastestScan',
  oxCanRunFastScan = 'oxCanRunFastScan',
  directEksConnection = 'directEksConnection',
  eksPrivateLink = 'eksPrivateLink',
  aksRunCommandConnectorEnabled = 'aksRunCommandConnectorEnabled',
  excludeByRuleIdFromAllRepos = 'excludeByRuleIdFromAllRepos',
  shouldViewIssueTrend = 'shouldViewIssueTrend',
  shouldTrackSeverity = 'shouldTrackSeverity',
  oxPipelineWorkflowsUiEnabled = 'oxPipelineWorkflowsUiEnabled',
  oxPipelineHideConfiguration = 'oxPipelineHideConfiguration',
  defineBranch = 'defineBranch',
  developerCountByCommits = 'developerCountByCommits',
  enableDefaultPipelineSettings = 'enableDefaultPipelineSettings',
  scanImagesByTags = 'scanImagesByTags',
  rbacRoles = 'rbacRoles',
  automationFamilyKey = 'automationFamilyKey',
  issueSeverityBreakdownEnabled = 'issueSeverityBreakdownEnabled',
  microsoftOutlookConnector = 'microsoftOutlookConnector',
  shouldShowTicketStatusFilter = 'shouldShowTicketStatusFilter',
  shouldShowOrgUnitFilter = 'shouldShowOrgUnitFilter',
  showConnectorSettings = 'showConnectorSettings',
  extraScanSettingsEnabled = 'extraScanSettingsEnabled',
  disableFullScanOnShift = 'disableFullScanOnShift',
  viewPreferencesEnabled = 'viewPreferencesEnabled',
  appOwnersMigration = 'appOwnersMigration',
  allowUserToRequestAccess = 'allowUserToRequestAccess',
  allowUserToRequestAccessConfig = 'allowUserToRequestAccessConfig',
  enableMultiTokens = 'enableMultiTokens',
  enableCBOM = 'enableCbom',
  gitlabGroups = 'gitlabGroups',
  executiveReportsResolvedIssues = 'executiveReportsResolvedIssues',
  oxAggregationFromBackend = 'oxAggregationFromBackend',
  enableMultiBranch = 'enableMultiBranch',
  showMissingIssues = 'showMissingIssues',
  isThreatIntellegencyEnabled = 'isThreatIntellegencyEnabled',
  credentialsTypesGitHubBrokerFf = 'credentialsTypesGitHubBrokerFf',
  credentialsTypesGitLabBrokerFf = 'credentialsTypesGitLabBrokerFf',
  slaEnabled = 'slaEnabled',
  getIssuesCsvExportV2 = 'getIssuesCsvExportV2',
  enableJiraTeamField = 'enableJiraTeamField',
  enableJiraAutoPriority = 'enableJiraAutoPriority',
  enableArtifactBomOverview = 'enableArtifactBomOverview',
  shouldHideIssueOwnerEmailParameter = 'shouldHideIssueOwnerEmailParameter',
  enableSlackSummary = 'enableSlackSummary',
  enableBroker = 'enableBroker',
  ticketingMessagingSettingsEnabled = 'ticketingMessagingSettingsEnabled',
  ticketingPrioritySettingsEnabled = 'ticketingPrioritySettingsEnabled',
  ticketingDueDateSettingsEnabled = 'ticketingDueDateSettingsEnabled',
  unscannedArtifactsEnabled = 'unscannedArtifactsEnabled',
  enableSingleRepoScan = 'enableSingleRepoScan',
  useOsDependencyGraphHelper = 'useOsDependencyGraphHelper',
  bitbucketImportTags = 'bitbucketImportTags',
  unapprovedSaasKey = 'unapprovedSaasKey',
}

export interface FeatureFlags {
  [FeatureFlagKeys.enableArtifactBomOverview]: boolean;
  [FeatureFlagKeys.isThreatIntellegencyEnabled]: boolean;
  [FeatureFlagKeys.oxAggregationFromBackend]: boolean;
  [FeatureFlagKeys.enableCBOM]: boolean;
  [FeatureFlagKeys.viewPreferencesEnabled]: boolean;
  [FeatureFlagKeys.showConnectorSettings]: boolean;
  [FeatureFlagKeys.disableFullScanOnShift]: boolean;
  [FeatureFlagKeys.issueSeverityBreakdownEnabled]: boolean;
  [FeatureFlagKeys.enableDefaultPipelineSettings]: boolean;
  [FeatureFlagKeys.scheduledScanTimeSettings]: boolean;
  [FeatureFlagKeys.scheduledScansSettings]: boolean;
  [FeatureFlagKeys.excludeFileOrFoldersSettings]: boolean;
  [FeatureFlagKeys.awsCodeCommitCloudformation]: boolean;
  [FeatureFlagKeys.reslovedIssues]: boolean;
  [FeatureFlagKeys.savedFilters]: boolean;
  [FeatureFlagKeys.protectingXDevsBadge]: boolean;
  [FeatureFlagKeys.kubernetesConnectorFamily]: boolean;
  [FeatureFlagKeys.oxCanRunFastestScan]: boolean;
  [FeatureFlagKeys.oxCanRunFastScan]: boolean;
  [FeatureFlagKeys.directEksConnection]: boolean;
  [FeatureFlagKeys.eksPrivateLink]: boolean;
  [FeatureFlagKeys.aksRunCommandConnectorEnabled]: boolean;
  [FeatureFlagKeys.excludeByRuleIdFromAllRepos]: boolean;
  [FeatureFlagKeys.shouldViewIssueTrend]: boolean;
  [FeatureFlagKeys.shouldTrackSeverity]: boolean;
  [FeatureFlagKeys.oxPipelineWorkflowsUiEnabled]: boolean;
  [FeatureFlagKeys.oxPipelineHideConfiguration]: boolean;
  [FeatureFlagKeys.defineBranch]: boolean;
  [FeatureFlagKeys.enableSingleRepoScan]: boolean;
  [FeatureFlagKeys.developerCountByCommits]: boolean;
  [FeatureFlagKeys.scanImagesByTags]: boolean;
  [FeatureFlagKeys.rbacRoles]: boolean;
  [FeatureFlagKeys.automationFamilyKey]: boolean;
  [FeatureFlagKeys.microsoftOutlookConnector]: boolean;
  [FeatureFlagKeys.extraScanSettingsEnabled]: boolean;
  [FeatureFlagKeys.appOwnersMigration]: boolean;
  [FeatureFlagKeys.allowUserToRequestAccess]: boolean;
  [FeatureFlagKeys.allowUserToRequestAccessConfig]: boolean;
  [FeatureFlagKeys.enableMultiTokens]: boolean;
  [FeatureFlagKeys.gitlabGroups]: boolean;
  [FeatureFlagKeys.executiveReportsResolvedIssues]: boolean;
  [FeatureFlagKeys.shouldShowOrgUnitFilter]: boolean;
  [FeatureFlagKeys.enableMultiBranch]: boolean;
  [FeatureFlagKeys.showMissingIssues]: boolean;
  [FeatureFlagKeys.credentialsTypesGitHubBrokerFf]: boolean;
  [FeatureFlagKeys.credentialsTypesGitLabBrokerFf]: boolean;
  [FeatureFlagKeys.slaEnabled]: boolean;
  [FeatureFlagKeys.getIssuesCsvExportV2]: boolean;
  [FeatureFlagKeys.enableJiraTeamField]: boolean;
  [FeatureFlagKeys.enableJiraAutoPriority]: boolean;
  [FeatureFlagKeys.shouldHideIssueOwnerEmailParameter]: boolean;
  [FeatureFlagKeys.enableSlackSummary]: boolean;
  [FeatureFlagKeys.enableBroker]: boolean;
  [FeatureFlagKeys.ticketingMessagingSettingsEnabled]: boolean;
  [FeatureFlagKeys.ticketingDueDateSettingsEnabled]: boolean;
  [FeatureFlagKeys.ticketingPrioritySettingsEnabled]: boolean;
  [FeatureFlagKeys.unscannedArtifactsEnabled]: boolean;
  [FeatureFlagKeys.useOsDependencyGraphHelper]: boolean;
  [FeatureFlagKeys.bitbucketImportTags]: boolean;
  [FeatureFlagKeys.unapprovedSaasKey]: boolean;
}

export const OnPremFeatureFlags: Record<FeatureFlagKeys, boolean> = {
  [FeatureFlagKeys.useOsDependencyGraphHelper]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_USE_OS_DEPENDENCY_GRAPH_HELPER`],
    false,
  ),
  enableArtifactBomOverview: envToBoolean(
    env()['REACT_APP_FEATURE_FLAG_ENABLE_ARTIFACT_BOM_OVERVIEW'],
    true,
  ),
  isThreatIntellegencyEnabled: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_IS_THREAT_INTELLEGENCE_ENABLED`],
    false,
  ),
  enableSlackSummary: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ENABLE_SLACK_SUMMARY`],
    false,
  ),
  shouldHideIssueOwnerEmailParameter: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOULD_HIDE_ISSUE_OWNER_EMAIL_PARAMETER`],
    false,
  ),
  oxAggregationFromBackend: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_OX_AGGREGATION_FROM_BACKEND`],
    false,
  ),
  enableCbom: envToBoolean(env()[`REACT_APP_FEATURE_FLAG_ENABLE_CBOM`], false),
  viewPreferencesEnabled: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_VIEW_PREFERENCES_ENABLED`],
    false,
  ),
  showConnectorSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOW_CONNECTOR_SETTINGS`],
    false,
  ),
  disableFullScanOnShift: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_DISABLE_FULL_SCAN_ON_SHIFT`],
    false,
  ),
  issueSeverityBreakdownEnabled: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ISSUE_SEVERITY_BREAKDOWN_ENABLED`],
    false,
  ),
  enableDefaultPipelineSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ENABLE_DEFAULT_PIPELINE_SETTINGS`],
    false,
  ),
  scheduledScansSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCHEDULED_SCANS_SETTINGS`],
    true,
  ),
  scheduledScanTimeSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCHEDULED_SCAN_TIME_SETTINGS`],
    true,
  ),
  excludeFileOrFoldersSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXCLUDE_FILE_OR_FOLDERS_SETTINGS`],
    false,
  ),
  awsCodeCommitCloudformation: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_AWS_CODE_COMMIT_CLOUDFORMATION`],
    true,
  ),
  reslovedIssues: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_RESOLVED_ISSUES`],
    false,
  ),
  savedFilters: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SAVED_FILTERS`],
    false,
  ),
  protectingXDevsBadge: true,
  [FeatureFlagKeys.kubernetesConnectorFamily]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_KUBERNETES_CONNECTOR_FAMILY`],
    false,
  ),
  [FeatureFlagKeys.oxCanRunFastestScan]: false,
  [FeatureFlagKeys.oxCanRunFastScan]: false,

  [FeatureFlagKeys.directEksConnection]: false,
  [FeatureFlagKeys.eksPrivateLink]: false,
  [FeatureFlagKeys.aksRunCommandConnectorEnabled]: false,
  [FeatureFlagKeys.excludeByRuleIdFromAllRepos]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXCLUDE_BY_RULE_ID_FROM_ALL_REPOS`],
    false,
  ),
  [FeatureFlagKeys.shouldViewIssueTrend]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ISSUE_TREND`],
    false,
  ),
  [FeatureFlagKeys.shouldTrackSeverity]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_TRACK_SEVERITY`],
    false,
  ),
  [FeatureFlagKeys.oxPipelineWorkflowsUiEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_PIPELINE_WORKFLOWS_UI_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.oxPipelineHideConfiguration]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_PIPELINE_HIDE_CONFIGURATION`],
    false,
  ),
  [FeatureFlagKeys.defineBranch]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_DEFINE_BRANCH`],
    false,
  ),
  [FeatureFlagKeys.developerCountByCommits]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_DEVELOPER_COUNT_BY_COMMITS`],
    false,
  ),
  [FeatureFlagKeys.scanImagesByTags]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCAN_IMAGES_BY_TAGS`],
    false,
  ),
  [FeatureFlagKeys.rbacRoles]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_RBAC_ROLES`],
    false,
  ),
  [FeatureFlagKeys.automationFamilyKey]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_AUTOMATION_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.microsoftOutlookConnector]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_MICROSOFT_OUTLOOK_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.shouldShowTicketStatusFilter]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOULD_SHOW_TICKET_STATUS_FILTER`],
    false,
  ),
  [FeatureFlagKeys.extraScanSettingsEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXTRA_SCAN_SETTINGS_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.appOwnersMigration]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_APP_OWNERS_MIGRATION`],
    false,
  ),
  [FeatureFlagKeys.allowUserToRequestAccess]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ALLOW_USER_TO_REQUEST_ACCESS`],
    false,
  ),
  [FeatureFlagKeys.allowUserToRequestAccessConfig]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ALLOW_USER_TO_REQUEST_ACCESS_CONFIG`],
    false,
  ),
  [FeatureFlagKeys.enableMultiTokens]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ENABLE_MULTI_TOKENS`],
    false,
  ),
  [FeatureFlagKeys.gitlabGroups]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_GITLAB_GROUPS`],
    false,
  ),
  [FeatureFlagKeys.executiveReportsResolvedIssues]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXECUTIVE_REPORTS_RESOLVED_ISSUES`],
    false,
  ),
  [FeatureFlagKeys.shouldShowOrgUnitFilter]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOULD_SHOW_ORG_UNIT_FILTER`],
    false,
  ),
  [FeatureFlagKeys.showMissingIssues]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOW_MISSING_ISSUES`],
    false,
  ),
  [FeatureFlagKeys.enableMultiBranch]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ENABLE_MULTI_BRANCH`],
    false,
  ),
  [FeatureFlagKeys.credentialsTypesGitHubBrokerFf]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_CREDENTIALS_TYPES_GITHUB_BROKER_FF`],
    false,
  ),
  [FeatureFlagKeys.credentialsTypesGitLabBrokerFf]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_CREDENTIALS_TYPES_GITLAB_BROKER_FF`],
    false,
  ),
  [FeatureFlagKeys.slaEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SLA_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.getIssuesCsvExportV2]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_GET_ISSUES_CSV_EXPORT_V2`],
    false,
  ),
  [FeatureFlagKeys.enableJiraTeamField]: envToBoolean(
    env()[`REACT_APP_ENABLE_JIRA_TEAM_FIELD`],
    false,
  ),
  [FeatureFlagKeys.enableJiraAutoPriority]: envToBoolean(
    env()[`REACT_APP_ENABLE_JIRA_AUTO_PRIORITY`],
    false,
  ),
  [FeatureFlagKeys.enableBroker]: envToBoolean(
    env()[`REACT_APP_ENABLE_BROKER`],
    false,
  ),
  [FeatureFlagKeys.ticketingMessagingSettingsEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_TICKETING_MESSAGING_SETTINGS_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.ticketingDueDateSettingsEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_TICKETING_DUE_DATE_SETTINGS_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.ticketingPrioritySettingsEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_TICKETING_PRIORITY_SETTINGS_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.unscannedArtifactsEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_UNSCANNED_ARTIFACTS_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.enableSingleRepoScan]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCAN_SINGLE_REPO`],
    false,
  ),
  [FeatureFlagKeys.bitbucketImportTags]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_BITBUCKET_IMPORT_TAGS`],
    false,
  ),
  [FeatureFlagKeys.unapprovedSaasKey]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_UNAPPROVED_SAAS_KEY`],
    false,
  ),
};
