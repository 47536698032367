import { AppPages, navigate } from 'app-navigator';
import createIssueStoreActions from '../../common/store-actions/create-issue-store-actions';
import {
  loadFiltersResolved,
  loadIssuesResolved,
} from '../actions/resolved-issues-actions';
import ResolvedIssuesStore, {
  initialResolvedIssuesState,
} from '../stores/resolved-issues-store';

const resolvedIssueStoreActions = createIssueStoreActions(
  ResolvedIssuesStore,
  initialResolvedIssuesState,
);

// https://stackoverflow.com/questions/29844074/es6-export-all-values-from-object
export const setStoreIssues = resolvedIssueStoreActions.setStoreIssues;
export const setStoreTotalIssues =
  resolvedIssueStoreActions.setStoreTotalIssues;
export const setStoreTotalIssuesFiltered =
  resolvedIssueStoreActions.setStoreTotalIssuesFiltered;
export const setStoreIssuesOffset =
  resolvedIssueStoreActions.setStoreIssuesOffset;
export const setStoreIssuesFiltersType =
  resolvedIssueStoreActions.setStoreIssuesFiltersType;
export const setStoreIssuesFiltersTypeSearch =
  resolvedIssueStoreActions.setStoreIssuesFiltersTypeSearch;
export const setModalVisibility = resolvedIssueStoreActions.setModalVisibility;
export const closeIssuesDrawer = resolvedIssueStoreActions.closeIssuesDrawer;
export const closeIssueModalWithoutNavigate =
  resolvedIssueStoreActions.closeIssueModalWithoutNavigate;
export const openIssueModal = resolvedIssueStoreActions.openIssueModal;
export const setSelectedIssue = resolvedIssueStoreActions.setSelectedIssue;
export const toggleFiltersPanel = resolvedIssueStoreActions.toggleFiltersPanel;
export const clearFilters = resolvedIssueStoreActions.clearFilters;
export const clearStore = resolvedIssueStoreActions.clearStore;
export const setPolicyDisableModal =
  resolvedIssueStoreActions.setPolicyDisableModal;
export const setApplicationIrrelevantModal =
  resolvedIssueStoreActions.setApplicationIrrelevantModal;
export const setShowCreateJiraTicketDialog =
  resolvedIssueStoreActions.setShowCreateJiraTicketDialog;
export const setShowSendSlackMessageDialog =
  resolvedIssueStoreActions.setShowSendSlackMessageDialog;
export const openQuickFixModal = resolvedIssueStoreActions.openQuickFixModal;
export const setQuickFixModalVisibility =
  resolvedIssueStoreActions.setQuickFixModalVisibility;
export const setFilesDiffModalVisibility =
  resolvedIssueStoreActions.setFilesDiffModalVisibility;
export const setSliderValuePriority =
  resolvedIssueStoreActions.setSliderValuePriority;
export const setLastDrawerHeight =
  resolvedIssueStoreActions.setLastDrawerHeight;
export const pushSlackNotificationToRawIssue =
  resolvedIssueStoreActions.pushSlackNotificationToRawIssue;
export const setSelectedIssueId = resolvedIssueStoreActions.setSelectedIssueId;
export const setExclusionIssueComment =
  resolvedIssueStoreActions.setExclusionIssueComment;
export const setExclusionPolicyComment =
  resolvedIssueStoreActions.setExclusionPolicyComment;
export const setExclusionAppComment =
  resolvedIssueStoreActions.setExclusionAppComment;
export const setFalsePostiveIssueComment =
  resolvedIssueStoreActions.setFalsePostiveIssueComment;
export const addJiraTicketToRawIssue =
  resolvedIssueStoreActions.addJiraTicketToRawIssue;
export const setJiraTicketsToRawIssue =
  resolvedIssueStoreActions.setJiraTicketsToRawIssue;
export const setLoadingJiraStatusUpdate =
  resolvedIssueStoreActions.setLoadingJiraStatusUpdate;
export const setDrawerJiraTicketsStatus =
  resolvedIssueStoreActions.setDrawerJiraTicketsStatus;
export const setDrawerTicketsStatus =
  resolvedIssueStoreActions.setDrawerTicketsStatus;
export const setStoreIssuesStatistics =
  resolvedIssueStoreActions.setStoreIssuesStatistics;
export const setStoreTotalResolvedIssues =
  resolvedIssueStoreActions.setStoreTotalResolvedIssues;
export const setStoreTotalActiveIssues =
  resolvedIssueStoreActions.setStoreTotalActiveIssues;
export const resetSelected = resolvedIssueStoreActions.resetSelected;

export const setSelectedIssueRow =
  resolvedIssueStoreActions.setSelectedIssueRow;

export const clearResolvedFilters = () => {
  ResolvedIssuesStore.filterIssuesBy = {};
  ResolvedIssuesStore.offset = 0;
  ResolvedIssuesStore.numberOfFilters = 0;
  ResolvedIssuesStore.sliderValuePriority = [0, 100];
  navigate(AppPages.ResolvedIssues);
  loadIssuesResolved({ update: true, cache: false });
  loadFiltersResolved();
};
export const setLoadingIssue = resolvedIssueStoreActions.setLoadingIssue;
export const setSearchValues = resolvedIssueStoreActions.setSearchValues;
export const setSearchValue = resolvedIssueStoreActions.setSearchValue;
export const onSearchValueChange =
  resolvedIssueStoreActions.onSearchValueChange;

export const setFalseResolvedIssueComment = (comment: string) => {
  if (ResolvedIssuesStore.selectedIssue) {
    ResolvedIssuesStore.selectedIssue.falseResolvedIssueComment = comment;
  }
};

export const setShowReportFalseResolvedIssueDialog = (visible: boolean) => {
  ResolvedIssuesStore.showReportFalseResolvedIssueDialog = visible;
};

export const setIsLoadingApplicationStatistics = (
  isLoadingApplicationStatistics: boolean,
) => {
  ResolvedIssuesStore.isLoadingApplicationStatistics =
    isLoadingApplicationStatistics;
};
