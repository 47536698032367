import { OxIcons } from './OxIcons';
import {
  faGear,
  faFire,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsUp,
  faEllipsisVertical,
  faCircleUser,
  faMagnifyingGlass,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faMoon,
  faBrightness,
  faCircleQuestion,
  faMessageSmile,
  faUsers,
  faArrowDownToLine,
} from '@fortawesome/pro-solid-svg-icons';

// mapping of enum values to actual icon definitions
export const OxIconMap: Record<OxIcons, IconDefinition> = {
  [OxIcons.gear]: faGear,
  [OxIcons.moon]: faMoon,
  [OxIcons.brightness]: faBrightness,
  [OxIcons.circleQuestion]: faCircleQuestion,
  [OxIcons.messageSmile]: faMessageSmile,
  [OxIcons.users]: faUsers,
  [OxIcons.chevronDown]: faChevronDown,
  [OxIcons.chevronsUp]: faChevronsUp,
  [OxIcons.chevronLeft]: faChevronLeft,
  [OxIcons.chevronRight]: faChevronRight,
  [OxIcons.ellipsisVertical]: faEllipsisVertical,
  [OxIcons.circleUser]: faCircleUser,
  [OxIcons.magnifyingGlass]: faMagnifyingGlass,
  [OxIcons.arrowDownToLine]: faArrowDownToLine,
  [OxIcons.fire]: faFire,
};
