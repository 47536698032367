import {
  Box,
  Button,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  lighten,
} from '@mui/material';
import {
  ConditionalFilter,
  ConditionalFiltersCondition,
  FilterType,
  FilterTypeToFilter,
} from 'ox-filter-utils';
import { CSSProperties, FC, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { getCategoryById, resolveFilterName } from './FilterBox-utils';
import { isArray, isEmpty } from 'lodash-es';
import { severities } from 'severity-utils';
import { MdOutlinePlayCircleFilled as ArrowCircleLeftIcon } from 'react-icons/md';
import { Panel } from '../Panel';

export const FiltersHeader: FC<React.PropsWithChildren<FiltersHeaderProps>> = ({
  onResetFilters,
  numberOfFilters = 0,
  filterIssuesBy,
  splitFilterTypeName,
  conditionalFilters,
  onCollapse,
  total,
  filtered,
}) => {
  const { classes, cx } = useStyles();
  const showCount = filtered > 0 && filtered !== total;
  const selectedFilters = useMemo(() => {
    if (conditionalFilters && conditionalFilters.length > 0) {
      return conditionalFilters.reduce(
        (acc: { [key: string]: string[] | {} }, filter) => {
          if (filter.condition === ConditionalFiltersCondition.BETWEEN) {
            acc[filter.fieldName] = {
              from: filter.greaterThan,
              to: filter.lessThan,
            };
          } else {
            acc[filter.fieldName] = filter.values;
          }
          return acc;
        },
        {},
      );
    } else {
      return filterIssuesBy;
    }
  }, [filterIssuesBy, conditionalFilters]);

  return (
    <Box display='flex' flexDirection='column'>
      <Panel
        minHeight={48}
        pl={2}
        py={1}
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        <div className={classes.counts}>
          {showCount && (
            <>
              <span className={classes.count}>
                {filtered?.toLocaleString()}{' '}
              </span>
              <span className={classes.totalCount}> / </span>
            </>
          )}
          <span className={cx(showCount ? classes.totalCount : undefined)}>
            {total?.toLocaleString()}
          </span>

          <span>Total</span>
        </div>
        <IconButton
          onClick={onCollapse}
          size='small'
          className={classes.button}>
          <ArrowCircleLeftIcon />
        </IconButton>
      </Panel>
      <div className={classes.headerContainer}>
        {selectedFilters && numberOfFilters > 0 ? (
          <div className={classes.filtersNumberContainer}>
            <div className={classes.filtersText}>
              <Typography
                variant='body2'
                color='text.primary'
                style={{ fontSize: '0.725rem' }}>
                Filters Applied:
              </Typography>

              <Tooltip
                arrow
                placement='right'
                title={
                  <div className={classes.tooltipTitle}>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      fontWeight='bold'>
                      Filters Enabled:
                    </Typography>
                    {Object.entries(selectedFilters).map(
                      (filterName, index) => {
                        const filterType = filterName[0];
                        const fallbackTitleName =
                          FilterType[filterType as keyof typeof FilterType];
                        const filterTitle =
                          resolveFilterName(filterType) || fallbackTitleName;
                        const filterData = filterName[1];

                        if (isEmpty(filterData)) {
                          return null;
                        }

                        if (filterType === FilterTypeToFilter.AppId) {
                          return (
                            <Typography
                              variant='body2'
                              color='text.secondary'
                              component='div'
                              key={filterType + index}>
                              Filtered by sub repos
                            </Typography>
                          );
                        }
                        if (
                          filterType === FilterTypeToFilter.BusinessPriority
                        ) {
                          return (
                            <Typography
                              variant='body2'
                              color='text.primary'
                              component='div'
                              key={filterType + index}>
                              <>
                                <p className={classes.categoryTitle}>
                                  {filterTitle}
                                </p>
                                <p className={classes.filterDataItem}>
                                  From: {Object.values(filterData)[0]}
                                  {'\n'}
                                  To: {Object.values(filterData)[1]}
                                </p>
                              </>
                            </Typography>
                          );
                        }
                        return (
                          <Typography
                            variant='body2'
                            color='text.primary'
                            component='div'
                            key={filterType + index}>
                            <p className={classes.categoryTitle}>
                              {filterTitle}
                            </p>
                            {filterData &&
                              isArray(filterData) &&
                              filterData?.map(
                                (value: string, index: number) => {
                                  if (
                                    filterType === FilterTypeToFilter.Category
                                  ) {
                                    return (
                                      <p
                                        className={classes.filterDataItem}
                                        key={index}>
                                        {getCategoryById(value) + '\n'}
                                      </p>
                                    );
                                  } else if (
                                    filterType === FilterTypeToFilter.Severity
                                  ) {
                                    const displayValue = severities.find(
                                      severityItem =>
                                        severityItem.value === parseInt(value),
                                    );
                                    return displayValue ? (
                                      <p
                                        className={classes.filterDataItem}
                                        key={index}>
                                        {displayValue?.title + '\n'}
                                      </p>
                                    ) : null;
                                  } else if (value.length > 30) {
                                    return (
                                      <p
                                        className={classes.filterDataItem}
                                        key={index}>
                                        {value.includes('/')
                                          ? splitFilterTypeName(value, 30) +
                                            '\n'
                                          : value.slice(0, 30) + '...\n'}
                                      </p>
                                    );
                                  } else {
                                    return (
                                      <p
                                        className={classes.filterDataItem}
                                        key={index}>
                                        {value + '\n'}
                                      </p>
                                    );
                                  }
                                },
                              )}
                          </Typography>
                        );
                      },
                    )}
                  </div>
                }>
                <div className={classes.filtersNumber}>
                  <Typography
                    variant='subtitle2'
                    mt={0.1}
                    className={classes.filtersNumberContent}>
                    {numberOfFilters}
                  </Typography>
                </div>
              </Tooltip>
            </div>

            <Button
              size='small'
              variant='text'
              style={{ textTransform: 'none', fontSize: '0.725rem' }}
              onClick={onResetFilters}
              disabled={numberOfFilters <= 0}>
              Clear all filters
            </Button>
          </div>
        ) : null}
      </div>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    transform: 'rotate(180deg)',
    color: lighten(theme.palette.text.primary, 0.5),
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  filtersNumber: {
    minWidth: 30,
    padding: '0 8px',
    height: 17,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    borderRadius: 30,
    marginInlineStart: theme.spacing(1),
    display: 'block',
  },
  filtersNumberContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingInline: theme.spacing(0.5),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  filtersNumberContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 2,
  },
  categoryTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  tooltipTitle: {
    margin: '2%',
    maxHeight: 400,
    overflowY: 'auto',
    marginRight: -5,
  },
  filterDataItem: {
    fontSize: '0.7rem',
    color: theme.palette.grey[600],
  },
  counts: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'flex-end',
  },
  totalCount: {
    ...(theme.typography.body2 as CSSProperties),
  },
  count: {
    fontWeight: 600,
  },
  filtersText: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export interface FiltersHeaderProps {
  onResetFilters?: () => void;
  numberOfFilters?: number;
  filterIssuesBy?: { [key: string]: string[] };
  splitFilterTypeName: (name: string, width: number) => void;
  conditionalFilters?: ConditionalFilter[];
  onCollapse: () => void;
  total: number;
  filtered: number;
}
