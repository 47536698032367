import { AppOwnerRole, AppOwnerRoleType, AppOwnerType } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { ApplicationRow } from '../../../../../apps/web/src/applications/applications-types';
import { filterDupAppOwners } from '../utils/aplications-owners-utils';
import { AppOwnersByRole } from '../application-owners-types';

const baseStore = createStore<AppOwnersStoreState>(
  {
    assignedAppOwners: [],
    activeMembers: [],
    isLoading: false,
    disableEditAppOwnersModalSubmit: true,
    modifiedApplications: [],
    selectedRole: AppOwnerRole.Dev,
    selectedOwnersByRole: [],
    isLoadingActiveMembers: false,
  },
  'AppOwnersStore Store',
);

export const AppOwnersStore = derive(
  {
    appOwnersSuggestions: get => {
      const { activeMembers, assignedAppOwners } = get(baseStore);
      return filterDupAppOwners([...activeMembers, ...assignedAppOwners]);
    },
  },
  {
    proxy: baseStore,
  },
);
interface AppOwnersStoreState {
  assignedAppOwners: AppOwnerType[];
  activeMembers: AppOwnerType[];
  isLoading: boolean;
  disableEditAppOwnersModalSubmit: boolean;
  modifiedApplications: ApplicationRow[];
  selectedRole: AppOwnerRoleType;
  selectedOwnersByRole: AppOwnersByRole[];
  isLoadingActiveMembers: boolean;
}
