import { Theme, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from 'app-theme';
import { FunctionComponent } from 'react';
import { useSnapshot } from 'valtio';
import LayoutStore from '../../stores/layout-store';
import { oxLightTheme } from './ox-app-theme/ox-light-theme';
import { oxDarkTheme } from './ox-app-theme/ox-dark-theme';
import { themeFlag } from './ox-app-theme/shared/theme-flag';

const AppThemeProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { themeType } = useSnapshot(LayoutStore);
  interface ThemeFlag {
    lightTheme: Theme;
    darkTheme: Theme;
  }

  const themeDevFlag = (themeType): Theme => {
    const result = {
      lightTheme,
      darkTheme,
    } as ThemeFlag;

    if (themeFlag) {
      result.lightTheme = oxLightTheme;
      result.darkTheme = oxDarkTheme;
    }

    return themeType === 'light' ? result.lightTheme : result.darkTheme;
  };

  return (
    <ThemeProvider theme={themeDevFlag(themeType)}>{children}</ThemeProvider>
  );
};

export default AppThemeProvider;
