import { setParamsToUrl } from 'app-navigator';
import { openErrorSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { updateSettings } from '../../../settings/actions/settings-actions';
import {
  SettingsSubType,
  SettingsType,
} from '../../../settings/types/settings-types';
import IssuesStore from '../../active-issues/stores/issues-store';
import { setSelectedTab } from '../components/IssueDrawer/IssueDrawerTabs/store-actions/issue-drawer-tabs-store-actions';
import issuesService from '../services';
import { FetchChatGptContentInput } from '../services/fetch-chat-gpt-content/fetch-chat-gpt-content-service';
import {
  setDisclaimerAcceptedForIssue,
  setDoNotShowSnippetAgain,
  setGptDisclaimerModalOpen,
  setGptDisclaimerText,
  setGptError,
  setGptSnippetModalOpen,
  setLoadingGptContent,
  setNeverShowDisclaimer,
  setQuestionAndAnswerForIssue,
} from '../store-actions/chat-gpt-store-actions';

export const fetchIssueChatGptContent = async (
  params: FetchChatGptContentInput,
) => {
  setLoadingGptContent(true);
  const results = await issuesService.fetchChatGptContent.execute(params);
  if (!results) {
    setGptError(true);
  } else {
    setQuestionAndAnswerForIssue(
      params.issueId,
      results.content,
      results.question,
    );
  }
  setLoadingGptContent(false);
};

export const fetchChatGptDisclaimer = async () => {
  setLoadingGptContent(true);
  const res = await issuesService.fetchChatGptDisclaimer.execute(undefined);
  if (res?.disclaimer) {
    setGptDisclaimerText(res.disclaimer);
  }
  if (res?.disclaimer === null) {
    setNeverShowDisclaimer(true);
  }
  if (res === undefined) {
    setGptDisclaimerModalOpen(false);
    openErrorSnackbar('Failed to fetch GPT, please try again later');
  }
  setLoadingGptContent(false);
};

export const onDisclaimerAccepted = (
  issueId: string,
  isDotNotShowSelected: boolean,
  doNotUpdateBackend?: boolean,
) => {
  setGptDisclaimerModalOpen(false);
  setGptSnippetModalOpen(true);
  if (isDotNotShowSelected) {
    setNeverShowDisclaimer(isDotNotShowSelected);
    if (doNotUpdateBackend) {
      return;
    }
    issuesService.setUserDisclaimer.execute({
      acknowledge: isDotNotShowSelected,
    });
  }
};

export const onSnippetModalAccept = (
  issueId: string,
  doNotShowAgain: boolean,
  doNotUpdateSettings?: boolean,
) => {
  setDisclaimerAcceptedForIssue(issueId);
  setDoNotShowSnippetAgain(doNotShowAgain);
  setGptSnippetModalOpen(false);

  const GPT_TAB_NAME = 'ChatGPT';
  const GPT_TAB_ID = 'chatGpt';
  const { issues, selectedIssueRow } = snapshot(IssuesStore);
  const _updatedIssues = issues.map(issue => {
    if (
      issue.issueId === issueId &&
      !issue.issueDetailsHeaders.find(header => header.id === GPT_TAB_ID)
    ) {
      const updated = {
        ...issue,
        issueDetailsHeaders: [
          ...issue.issueDetailsHeaders,
          { id: GPT_TAB_ID, label: GPT_TAB_NAME },
        ],
      };
      if (selectedIssueRow?.issueId === issueId) {
        IssuesStore.selectedIssueRow = updated;
      }
      return updated;
    }
    return issue;
  });
  IssuesStore.issues = _updatedIssues;
  setSelectedTab(GPT_TAB_NAME);
  setParamsToUrl({ tab: GPT_TAB_NAME });

  if (doNotUpdateSettings) {
    return;
  }
  updateSettings(
    {
      settingsType: SettingsType.Usability,
      settingsSubType: SettingsSubType.ChatGPTCodeSnippet,
      enabled: !doNotShowAgain,
    },
    false,
  );
};
