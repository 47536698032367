import { FC } from 'react';
import { useSnapshot } from 'valtio';
import SettingsStore from '../../../../../../../settings/stores/settings-store';
import {
  SettingsName,
  SettingsSubType,
} from '../../../../../../../settings/types/settings-types';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import IssueSummaryTab from './IssueSummaryTab';

const IssueSummaryTabContainer: FC<IssueDrawerTabProps> = () => {
  const { selectedIssue, filterIssuesBy, selectedIssueRow, issues } =
    useSnapshot(IssuesStore);
  const { orgSettingsMapByName } = useSnapshot(SettingsStore);
  const isEnabledChatGpt = orgSettingsMapByName
    .find(s => s.name === SettingsName.ChatGPT)
    ?.settings.find(
      s => s.settingsSubType === SettingsSubType.ChatGPT,
    )?.enabled;

  const _selectedIssueRow =
    selectedIssueRow ||
    issues?.find(issue => issue.issueId === selectedIssue?.issueId);

  return (
    <>
      {selectedIssue && (
        <IssueSummaryTab
          selectedIssue={selectedIssue}
          filterIssuesBy={filterIssuesBy}
          isEnabledChatGpt={Boolean(isEnabledChatGpt)}
          selectedIssueRow={_selectedIssueRow}
        />
      )}
    </>
  );
};

export default IssueSummaryTabContainer;
