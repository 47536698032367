import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { UpdateCredentialInput } from '../../connectors-types';
import mutation from './update-credential.gql';
import { logger } from 'logging-utils';

const updateCredential = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      credentialsId: string,
      updateCredentialInput: UpdateCredentialInput,
    ): Promise<boolean> => {
      try {
        const results = await client.mutate<
          UpdateCredentialResponse,
          { data: UpdateCredentialInput; credentialsId: string }
        >({
          mutation,
          variables: { data: updateCredentialInput, credentialsId },
        });
        return results.data?.updateCredential || false;
      } catch (err) {
        logger.error('Failed to update credential:', err);
        throw new Error('Failed to update credential');
      }
    },
  };
};

interface UpdateCredentialResponse {
  updateCredential: boolean;
}

export default updateCredential;
