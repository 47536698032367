import { FC, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationsStore } from 'organizations/src/stores/organizations-store';
import { useOrgSwitchLoginWithRedirect } from './hooks/useOrgSwitchLoginWithRedirect';

export const AppOrgInitializeProvider: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { user } = useAuth0();
  const { organizations, chosenOrganization } = useSnapshot(OrganizationsStore);
  const { handleOrgLoginWithRedirect } = useOrgSwitchLoginWithRedirect();

  useEffect(() => {
    if (
      user &&
      user.email_verified &&
      organizations.length > 0 &&
      (!chosenOrganization || !user.org_id)
    ) {
      if (
        organizations.length === 1 ||
        !chosenOrganization ||
        !organizations.some(org => org.id === chosenOrganization.id)
      ) {
        handleOrgLoginWithRedirect(organizations[0]);
      } else {
        handleOrgLoginWithRedirect(chosenOrganization);
      }
    }
  }, [chosenOrganization, handleOrgLoginWithRedirect, organizations, user]);

  return <Outlet />;
};
