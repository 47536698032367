import { ticketingClient } from 'api-clients';
import getInputFields from './get-input-fields/get-input-fields';
import createTickets from './create-ticket/create-tickets';
import searchInputOptions from './search-input-options/search-input-options';
import linkTicket from './link-ticket/link-ticket';
import unlinkTicket from './unlink-ticket/unlink-ticket';
import { getTicketsByKeys } from './get-ticket-by-keys/get-tickets-by-keys';
import { getSettings } from './get-settings/get-settings';
import updateSettings from './update-settings/update-settings';
import { getPriorities } from './get-priorities/get-priorities';

const ticketingService = {
  getInputFields: getInputFields(ticketingClient),
  createTickets: createTickets(ticketingClient),
  searchInputOptions: searchInputOptions(ticketingClient),
  linkTicket: linkTicket(ticketingClient),
  unlinkTicket: unlinkTicket(ticketingClient),
  getTicketsByKeys: getTicketsByKeys(ticketingClient),
  getSettings: getSettings(ticketingClient),
  updateSettings: updateSettings(ticketingClient),
  getPriorities: getPriorities(ticketingClient),
};

export default ticketingService;
