import { setAppIsEmptyOrg } from 'app-store';
import { AppEvents } from 'common-events';
import { loadArtifacts } from '../../artifacts/actions/artifacts-actions';
import { loadInventory } from '../../dashboard-inventory/actions/inventory-actions';
import { dashboardStoreActions } from '../../dashboard/actions/dashboard-actions';
import { fetchIssuesTrend } from '../../dashboard/actions/issues-graph-actions';
import {
  invalidateIssuesCachedQueries,
  loadIssues,
} from '../../issues/active-issues/actions/active-issues-actions';
import { loadSbomLibraries } from '../../sbom/actions/sbom-libraries-actions';
import { loadSupplyChain } from '../../supply-chain/actions/supply-chain-actions';
import { loadApiInventories } from '../../api-inventory/actions/api-inventory-actions';
import { applicationsActions } from '../../applications/actions/applications-actions';
import { snapshot } from 'valtio';
import ConnectorsStore from '../../connectors/stores/connectors-store';
import { scanTypes } from 'ox-common-types';

export const setupAppListeners = () => {
  document.addEventListener(AppEvents.Scan.ScanEnd, () => {
    setAppIsEmptyOrg(false);
    refetchOrgData();
  });
};
export const refetchOrgData = () => {
  const { scanType } = snapshot(ConnectorsStore);

  invalidateIssuesCachedQueries();
  return Promise.all([
    dashboardStoreActions.loadDashboardInfo(),
    loadInventory(),
    fetchIssuesTrend(),
    applicationsActions.loadApplications({ update: true, cache: false }),
    scanType === scanTypes.SingleRepo
      ? null
      : loadIssues({ update: true, cache: false }),
    loadSupplyChain(),
    loadSbomLibraries({ update: true, cache: false }),
    loadApiInventories({ update: true, cache: false }),
    loadArtifacts({ isLoadMoreItems: false, cache: false }),
  ]);
};
