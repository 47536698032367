import {
  chatGptClient,
  fileDownloadClient,
  pullRequestClient,
  reportClient,
} from 'api-clients';
import addCommentToIssue from './add-comment-to-issue/add-comment-to-issue';
import addPolicyFix from './add-policy-fix/add-policy-fix-service';
import deleteCommentIssue from './delete-comment-issue/delete-comment-issue';
import fetchChatGptContent from './fetch-chat-gpt-content/fetch-chat-gpt-content-service';
import fetchChatGptDisclaimer from './fetch-chat-gpt-disclaimer/fetch-chat-gpt-disclaimer';
import getExportedIssues from './get-exported-issues/get-exported-issues';
import fetchIssue from './get-issue/get-issue-service';
import fetchIssuesFilters from './get-issues-filters/get-issues-filters-service';
import fetchIssues from './get-issues/get-issues-service';
import resetIssueSeverity from './reset-issue-severity/reset-issue-severity';
import setUserDisclaimer from './set-do-not-show-gpt-disclaimer/set-user-disclaimer';
import updateIssueSeverity from './update-issue-severity/update-issue-severity';
import getExportedToolsIssues from './get-exported-tools-issues/get-exported-tools-issues';
import exportIssuesPdf from './export-issues-pdf/export-issues-pdf';
import fetchSelectedIssuesAggsItems from './get-selected-issues-aggs/get-selected-issues-aggs';
import bulkUpdateIssuesSeverity from './bulk-update-issues-severity/bulk-update-issues-severity';
import fetchIssuesFiltersLazy from './get-issues-filters-lazy/get-issues-filters-lazy';
import bulkResetIssuesSeverity from './bulk-reset-issues-severity/bulk-reset-issues-severity';
import upsertBulkCommentToIssues from './upsert-bulk-comment-to-Issues/upsert-bulk-comment-to-Issues';
import fetchIssuesConditionalFiltersLazy from './get-issues-conditional-filters-lazy/get-issues-conditional-filters-lazy';
import fetchIssueDetailsByQuery from './fetch-issue-details-by-query/fetch-issue-details-by-query';
import fetchVulnerabilities from './get-vulnerabilities/get-vulnerabilties';
import cancelReportFalsePositive from './cancel-report-false-positive/cancel-report-false-positive';

const issuesService = {
  getVulnerabilities: fetchVulnerabilities(reportClient),
  getIssues: fetchIssues(reportClient),
  getIssue: fetchIssue(reportClient),
  getExportedIssues: getExportedIssues(reportClient),
  getIssuesFilters: fetchIssuesFilters(reportClient),
  getIssuesFiltersLazy: fetchIssuesFiltersLazy(reportClient),
  addCommentToIssue: addCommentToIssue(reportClient),
  upsertBulkCommentToIssues: upsertBulkCommentToIssues(reportClient),
  addPolicyFix: addPolicyFix(pullRequestClient),
  deleteCommentIssue: deleteCommentIssue(reportClient),
  fetchChatGptContent: fetchChatGptContent(chatGptClient),
  fetchChatGptDisclaimer: fetchChatGptDisclaimer(chatGptClient),
  setUserDisclaimer: setUserDisclaimer(chatGptClient),
  updateIssueSeverity: updateIssueSeverity(reportClient),
  bulkUpdateIssuesSeverity: bulkUpdateIssuesSeverity(reportClient),
  bulkResetIssuesSeverity: bulkResetIssuesSeverity(reportClient),
  resetIssueSeverity: resetIssueSeverity(reportClient),
  getExportedToolsIssues: getExportedToolsIssues(reportClient),
  cancelReportFalsePositive: cancelReportFalsePositive(reportClient),
  exportIssuesPdf: exportIssuesPdf(fileDownloadClient),
  fetchSelectedIssuesAggsItems: fetchSelectedIssuesAggsItems(reportClient),
  getIssuesConditionalFiltersLazy:
    fetchIssuesConditionalFiltersLazy(reportClient),
  getIssueDetailsByQuery: fetchIssueDetailsByQuery(reportClient),
};

export default issuesService;
