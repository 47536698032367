import { createAsyncState } from 'async-utils';
import { Nullable, Order } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  ApplicationInfo,
  ApplicationRow,
  ApplicationsStoreCategories,
  MinifiedApp,
} from '../applications-types';

export const defaultOrderBy = {
  field: 'BusinessPriority',
  direction: Order.Desc,
};

export const initialApplicationsStoreValues = {
  applications: undefined,
  loading: createAsyncState(),
  offset: 0,
  total: 0,
  selectedAppId: null,
  selectedApp: undefined,
  orderField: defaultOrderBy.field,
  orderDirection: defaultOrderBy.direction,
  orderByCategory: undefined,
  selectedByAppId: null,
  filteredAppsSummary: [],
  allAppsSummary: [],
  loadingSingleApp: false,
  lastDrawerHeight: undefined,
  filtersOpen: true,
  appFilterValue: '',
  applicationIssuesScore: undefined,
  totalIrrelevantApps: 0,
  bpSelectedApp: 0,
  selectedPosition: { pos: 0 },
  topOffset: 0,
  isLoadingApplicationIssuesScore: true,
  isApplicationDrawerOpen: true,
  selectedSourceControl: {},
  showLimitationModal: false,
  hoveredRowAppId: null,
  loadingDefineBranch: false,
  isLoadingScanNow: false,
};

const baseStore = createStore<AppFilterStoreState>(
  initialApplicationsStoreValues,
  'Applications Store',
);

const ApplicationsStore = derive(
  {
    categories: (get): ApplicationsStoreCategories | undefined => {
      const { applications } = get(baseStore);
      if (applications?.length) {
        return applications[applications.length - 1].categories.map(
          category => {
            const { categoryName, catId } = category;
            return {
              categoryName,
              catId,
            };
          },
        );
      }
    },
  },
  { proxy: baseStore },
);

export interface AppFilterStoreState {
  applications?: ApplicationRow[];
  loading: ReturnType<typeof createAsyncState>;
  selectedAppId: Nullable<string>;
  offset: number;
  total: number;
  orderField: string;
  orderDirection: Order;
  orderByCategory?: string;
  selectedByAppId: {
    [key: string]: boolean;
  } | null;
  selectedSourceControl: { [key: string]: boolean };
  filteredAppsSummary: MinifiedApp[];
  allAppsSummary: MinifiedApp[];
  selectedApp?: ApplicationInfo;
  loadingSingleApp: boolean;
  lastDrawerHeight?: number;
  filtersOpen: boolean;
  appFilterValue: string;
  applicationIssuesScore?: {
    data: { [key: string]: number[] | undefined };
    labels: number[];
  };
  totalIrrelevantApps: number;
  bpSelectedApp: number;
  selectedPosition: { pos: number };
  topOffset: number;
  isLoadingApplicationIssuesScore: boolean;
  isApplicationDrawerOpen: boolean;
  showLimitationModal: boolean;
  hoveredRowAppId: Nullable<string>;
  loadingDefineBranch: boolean;
  isLoadingScanNow: boolean;
}

export default ApplicationsStore;
