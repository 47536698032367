import { aggregationsColumnsConfig as aggConfig } from '@oxappsec/ox-consolidated-configs';
import { marked } from 'marked';
import { IconType, SeverityType, TicketProvider } from 'ox-common-types';
import {
  FilterType,
  FilterTypeSearch,
  FilterTypeToFilter,
  FilterVariants,
} from 'ox-filter-utils';
import { DiGit } from 'react-icons/di';
import { ReactComponent as JiraIcon } from '../../../assets/icons/jira-action-icon.svg';
import { ReactComponent as MondayIcon } from '../../../assets/icons/monday-action-icon.svg';
import { ReactComponent as SlackIcon } from '../../../assets/icons/slack-action-icon.svg';
import { ReactComponent as TeamsLogo } from '../../../assets/icons/teams.svg';
import {
  AutoFixTypes,
  FixType,
  ISla,
  IssueDetails,
  SeverityBreakdownItem,
  SeverityByCategory,
  SeverityChangeFactorTypes,
  SeverityChangedReason,
  SeverityTrend,
  SlaStatus,
} from '../types/issues-types';
import { MessagingVendorTypes } from '../../../messaging-module/types/messaging-types';
import {
  BiCheck,
  BiInfoCircle,
  BiTargetLock,
  BiTrendingUp,
  BiWindowAlt,
} from 'react-icons/bi';
import { MdAccountTree, MdCode, MdPolicy, MdTableRows } from 'react-icons/md';
import { GoContainer } from 'react-icons/go';
import { IconApiApp, IconClockHour8 } from '@tabler/icons-react';
import { BsClipboard2Check } from 'react-icons/bs';
import { GiFamilyTree } from 'react-icons/gi';
import { SlGraph, SlList, SlMagicWand } from 'react-icons/sl';
import { IoTicketOutline } from 'react-icons/io5';
import { SiOpenai } from 'react-icons/si';
import { IconClockX } from '@tabler/icons-react';
import { getCurrentTheme } from 'app-theme';
import pluralize from 'pluralize';
import { formatDistanceToNowStrict } from 'date-fns';
import dayjs from 'dayjs';
import { openSnackbar } from 'snackbar-utils';

const renderer = new marked.Renderer();
renderer.link = function (href, title, text) {
  var link = marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace('<a', "<a target='_blank' ");
};

marked.setOptions({
  renderer: renderer,
});

export const resolveActionIcon = (
  actionType: TicketProvider | FixType | MessagingVendorTypes,
) => {
  return ActionsMap.get(actionType) || null;
};

export const ActionsMap = new Map<
  TicketProvider | FixType | MessagingVendorTypes,
  IconType
>([
  [TicketProvider.Jira, JiraIcon],
  [TicketProvider.Monday, MondayIcon],
  [TicketProvider.Slack, SlackIcon],
  [FixType.PullRequest, DiGit],
  [MessagingVendorTypes.Teams, TeamsLogo],
]);

export const resolvedIssuesFilters = [
  {
    type: FilterTypeToFilter.ResolvedReasons,
    header: FilterType.resolvedReasons,
  },
  {
    type: FilterTypeToFilter.Apps,
    header: FilterType.apps,
    searchType: FilterTypeSearch.apps,
  },
  {
    type: FilterTypeToFilter.Criticality,
    header: FilterType.criticality,
  },

  {
    type: FilterTypeToFilter.OriginalSeverity,
    header: FilterType.originalSeverity,
  },
  {
    type: FilterTypeToFilter.SeverityChange,
    header: FilterType.severityChange,
  },
  {
    type: FilterTypeToFilter.SeverityChangeReasons,
    header: FilterType.severityChangeReasons,
    searchType: FilterTypeSearch.severityChangeReasons,
  },

  {
    type: FilterTypeToFilter.Category,
    header: FilterType.category,
  },
  {
    type: FilterTypeToFilter.IssueNames,
    header: FilterType.issueNames,
    searchType: FilterTypeSearch.issueNames,
  },
  {
    type: FilterTypeToFilter.Policy,
    header: FilterType.policies,
    searchType: FilterTypeSearch.policies,
  },
  {
    type: FilterTypeToFilter.IssueOwner,
    header: FilterType.issueOwners,
    searchType: FilterTypeSearch.issueOwners,
  },
  {
    type: FilterTypeToFilter.SourceTool,
    header: FilterType.sourceTools,
  },
  {
    type: FilterTypeToFilter.CVE,
    header: FilterType.CVE,
    searchType: FilterTypeSearch.cve,
  },
  {
    type: FilterTypeToFilter.IssueActions,
    header: FilterType.issuesActions,
  },

  {
    type: FilterTypeToFilter.Languages,
    header: FilterType.languages,
    searchType: FilterTypeSearch.languages,
  },
  {
    type: FilterTypeToFilter.UniqueLibs,
    header: FilterType.UniqueLibs,
    searchType: FilterTypeSearch.uniqueLibs,
  },
  {
    type: FilterTypeToFilter.FilePaths,
    header: FilterType.FilePaths,
    searchType: FilterTypeSearch.filePaths,
  },
  {
    type: FilterTypeToFilter.BusinessPriority,
    header: FilterType.appBusinessPriority,
    variant: FilterVariants.Slider,
    maxValue: 100,
    minValue: 0,
  },
  {
    type: FilterTypeToFilter.Tags,
    header: FilterType.appsTags,
  },
];

const createSeverityBreakdown = (
  issueDetails: IssueDetails,
): SeverityBreakdownItem[] => {
  const { severityChangedReason } = issueDetails;
  const sorted = [...severityChangedReason].sort(
    (a, b) => b.changeNumber - a.changeNumber,
  );

  return sorted.map(raw => ({
    name: raw.shortName,
    trend: resolveTrend(raw.changeNumber),
    // can't be zero - we want to show an item there
    itemsCount: Math.ceil(raw.changeNumber) || 1,
    description: raw.reason,
    withoutAutoNumbering: raw.withoutAutoNumbering,
    evidenceLabel: raw.evidenceLabel,
    changeNumber: raw.changeNumber,
    changeCategory: raw.changeCategory !== null ? raw.changeCategory : '',
    extraInfo: raw.extraInfo ? raw.extraInfo : [],
  }));
};

export const createIssueDetails = (
  issueDetails: IssueDetails,
): IssueDetails => {
  return {
    ...issueDetails,
    severity:
      issueDetails.severity &&
      (issueDetails.severity.toLowerCase() as SeverityType),
    originalToolSeverity:
      issueDetails.originalToolSeverity &&
      (issueDetails.originalToolSeverity.toLowerCase() as SeverityType),
    severityBreakdown: createSeverityBreakdown(issueDetails),
    secondTitleMarkup: marked.parse(issueDetails.secondTitle || ''),
    recommendationMarkup: marked.parse(issueDetails.recommendation || ''),
  };
};

const resolveTrend = (changeNumber: number): SeverityTrend => {
  if (changeNumber === 0) {
    return SeverityTrend.Neutral;
  } else if (changeNumber > 0) {
    return SeverityTrend.Up;
  } else {
    return SeverityTrend.Down;
  }
};

export const sortSeverities = obj => {
  const keys = [
    SeverityChangeFactorTypes.Reachability,
    SeverityChangeFactorTypes.Exportability,
    SeverityChangeFactorTypes.Damage,
  ];
  const sortedObj = keys.reduce((acc, key) => {
    if (obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
  Object.entries(sortedObj).forEach(sev => {
    if (Array.isArray(sev[1])) {
      if (sev[1].length === 0) {
        sev[1].push({
          changeNumber: 0,
          name: 'None Found',
          trend: 'neutral',
        });
      }
    }
  });
  return sortedObj as SeverityByCategory;
};

export const mappingSeveritisChangeReason = (
  severities: SeverityBreakdownItem[],
) => {
  const keys = [
    SeverityChangeFactorTypes.Reachability,
    SeverityChangeFactorTypes.Exportability,
    SeverityChangeFactorTypes.Damage,
  ];
  const obj = severities.reduce((acc, severity) => {
    if (acc[severity.changeCategory]) {
      acc[severity.changeCategory].push(severity);
    } else {
      acc[severity.changeCategory] = [severity];
    }
    return acc;
  }, {});

  keys.forEach(key => {
    if (!Object.keys(obj).includes(key)) {
      obj[key] = [];
    }
  });
  return sortSeverities(obj);
};

export const mappingSeveritiesItems = (severities: SeverityChangedReason[]) => {
  const obj = severities.reduce((acc, severity) => {
    if (acc[severity.changeCategory]) {
      acc[severity.changeCategory as SeverityChangeFactorTypes].push(severity);
    } else {
      acc[severity.changeCategory as SeverityChangeFactorTypes] = [severity];
    }
    return acc;
  }, {});

  return sortSeverities(obj);
};

export const resolveAggregationName = (type?: string) => {
  if (type && aggConfig[type]) {
    return aggConfig[type].aggLabel || 'Aggregations';
  }
  return 'Aggregations';
};

export const mappingAutoFixTitle = new Map<string, string>([
  [AutoFixTypes.archiveRepo, 'Archive Repo'],
  [AutoFixTypes.changeOrgUserStatus, 'Change Org User Status'],
  [
    AutoFixTypes.changeRepoCollaboratorStatus,
    'Change Repo Collaborator Status',
  ],
  [
    AutoFixTypes.setRepoBranchProtectionForAddingSignedCommits,
    'Set Repo Branch Protection For Adding Signed Commits',
  ],
  [
    AutoFixTypes.setRepoBranchProtectionForDeletionOnBranch,
    'Set Repo Branch Protection For Deletion On Branch',
  ],
  [
    AutoFixTypes.setRepoBranchProtectionUnReviewedCode,
    'Set Repo Branch Protection UnReviewed Code',
  ],
  [AutoFixTypes.setRepoToPrivate, 'Set Repo To Private'],
]);

export const resolveAutoFix = (autoFix: string) => {
  return mappingAutoFixTitle.get(autoFix);
};

export const issueDrawerTabIcon = {
  summary: BiWindowAlt,
  devSummary: BiWindowAlt,
  attackPath: MdPolicy,
  appInfo: MdTableRows,
  vulnerabilities: BiTargetLock,
  resolvedVulnerabilities: BiTargetLock,
  aggregation: MdCode,
  artifactFiles: MdCode,
  cloudResources: GoContainer,
  policySecurityScanSCA: MdCode,
  policySecurityContainerScan: MdCode,
  apis: IconApiApp,
  compliance: BsClipboard2Check,
  dependencyGraph: GiFamilyTree,
  sbomInfo: MdAccountTree,
  sbomChecks: BiCheck,
  oscar: SlList,
  cwe: SlList,
  severityFactors: SlGraph,
  tickets: IoTicketOutline,
  moreInfo: BiInfoCircle,
  policy: MdPolicy,
  fixApplied: SlMagicWand,
  prApplied: DiGit,
  chatGpt: SiOpenai,
  trend: BiTrendingUp,
};

export const copyIssueLink = (issueId: string) => {
  navigator.clipboard.writeText(getLinkToIssue(issueId));
  openSnackbar(
    'Link copied!',
    {
      variant: 'success',
      autoHideDuration: 2000,
    },
    {
      hideCloseIcon: true,
      maxwidth: 10,
    },
  );
};

export const getLinkToIssue = (issueId: string) => {
  const currentUrl = new URL(window.location.href);
  return `${currentUrl.origin}${currentUrl.pathname}?issueId=${issueId}`;
};

export const getSlaData = (sla?: ISla) => {
  const theme = getCurrentTheme();
  if (!sla) return null;
  const { status } = sla;
  const daysPastSLA = Math.abs(sla.daysPastSLA);
  const dayPlural = pluralize('day', daysPastSLA);

  const getDateFormatted = () => {
    const suffix = status === SlaStatus.Overdue ? 'late' : 'left';
    if (daysPastSLA < 30) {
      return `${daysPastSLA} days ${suffix}`;
    }
    const dateNow = new Date();
    const slaDate = dayjs(dateNow).add(daysPastSLA, 'day');
    const formattedDate = formatDistanceToNowStrict(slaDate.toDate(), {
      addSuffix: false,
    });

    return `About ${formattedDate} ${suffix}`;
  };

  const formattedDate = getDateFormatted();

  switch (status) {
    case SlaStatus.Overdue:
      return {
        icon: IconClockX,
        color: theme.palette.error.main,
        tableText: formattedDate,
        cardText: `SLA overdue by ${daysPastSLA} ${dayPlural}`,
      };
    case SlaStatus.Near:
      return {
        icon: IconClockHour8,
        color: theme.palette.categoryRiskColor.medium,
        tableText: formattedDate,
        cardText: `SLA due in ${daysPastSLA} ${dayPlural}`,
      };

    default:
      return null;
  }
};
