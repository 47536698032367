import { OxCategoriesKeys } from '@oxappsec/ox-consolidated-categories';
import {
  NoConnectivityBannerInfo,
  NoConnectivityReason,
} from '../connector-connectivity-types';
import { ConnectorsByFamilyObject } from '../connectors-types';
import { isTokenExpired } from './credentials-utils';

export const findConfiguredSourceControlConnectors = (
  connectorsByFamily: ConnectorsByFamilyObject[],
) => {
  const connectorsToCheck = findSourceControlConnectors(connectorsByFamily);
  return connectorsToCheck.some(c => c.isConfigured);
};

export const findConfiguredInvalidConnectors = (
  connectorsByFamily: ConnectorsByFamilyObject[],
  enableMultiTokens,
): findConfiguredExpiredConnectorsOutput => {
  const connectorsToCheck = findInvalidConnectors(
    connectorsByFamily,
    enableMultiTokens,
  );
  const invalidConnectors = connectorsToCheck.filter(
    c =>
      (c.isConfigured &&
        (c.credentials.length > 0 && enableMultiTokens
          ? c.credentials.every(item =>
              isTokenExpired(item.tokenExpirationDate),
            )
          : isTokenExpired(c.credentials[0]?.tokenExpirationDate))) ||
      c.isEmptyOfRepos,
  );

  if (invalidConnectors.length > 0) {
    const sourceControlConnectors = invalidConnectors?.filter(
      connector => connector.family === OxCategoriesKeys.SourceControl,
    );
    const restOfConnectors = invalidConnectors?.filter(
      connector => connector.family !== OxCategoriesKeys.SourceControl,
    );
    return {
      valid: false,
      sourceControlConnectors: sourceControlConnectors.map(c => ({
        id: c.id,
        name: c.displayName,
        parentConnectorId: c.aliasFor,
      })),
      restOfConnectors: restOfConnectors.map(c => ({
        id: c.id,
        name: c.displayName,
        parentConnectorId: c.aliasFor,
      })),
    };
  }

  return { valid: true };
};

interface findConfiguredExpiredConnectorsOutput {
  valid: boolean;
  sourceControlConnectors?: connectorsExpiredOutput[];
  restOfConnectors?: connectorsExpiredOutput[];
}

interface connectorsExpiredOutput {
  id: string;
  name: string;
}

const findInvalidConnectors = (
  connectorsByFamily: ConnectorsByFamilyObject[],
  enableMultiTokens: boolean,
) => {
  const flatConnectors = connectorsByFamily.map(f => f.connectors).flat();
  const expiredConnectors = flatConnectors.filter(
    c =>
      (c.isConfigured &&
        (c.credentials.length > 0 && enableMultiTokens
          ? c.credentials.every(item =>
              isTokenExpired(item.tokenExpirationDate),
            )
          : isTokenExpired(c.credentials[0]?.tokenExpirationDate))) ||
      c.isEmptyOfRepos,
  );
  return expiredConnectors;
};

export const findConnectedSourceControlConnectors = (
  connectorsByFamily: ConnectorsByFamilyObject[],
) => {
  const connectors = findSourceControlConnectors(connectorsByFamily);
  return connectors.filter(c => c.isConfigured);
};

const findSourceControlConnectors = (
  connectorsByFamily: ConnectorsByFamilyObject[],
) => {
  const sourceControlFamily = connectorsByFamily.find(
    f => f.family === OxCategoriesKeys.SourceControl,
  );

  return sourceControlFamily?.connectors || [];
};

export const shouldShowBanner = ({
  isDemoEnabled,
  info,
  dismissed,
}: ShouldShowBannerInput) => {
  if (isDemoEnabled) {
    return false;
  }

  // Not dismissable
  if (info?.reason === NoConnectivityReason.NotConnected) {
    return true;
  }

  if (info?.reason === NoConnectivityReason.ExpiredOrRevoked && !dismissed) {
    return true;
  }

  return false;
};
interface ShouldShowBannerInput {
  isDemoEnabled?: boolean;
  info?: NoConnectivityBannerInfo;
  dismissed: boolean;
}
