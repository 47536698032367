import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { SbomType } from 'ox-common-types';
import query from './get-applications-sbom-v2.gql';

const getApplicationsSbomV2 = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getApplicationSbomInput: SBOMInput) => {
      try {
        const results = await client.query<{
          getApplicationSBOMV2: { requestId: string };
        }>({
          query,
          variables: {
            getApplicationSbomInput,
          },
          fetchPolicy: 'no-cache',
        });

        return results.data.getApplicationSBOMV2;
      } catch (error) {
        logger.error('Failed to fetch applications sbom', error);
        return null;
      }
    },
  };
};

export interface SBOMInput {
  appId: string[];
  type: SbomType;
}

export default getApplicationsSbomV2;
