import {
  Link,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { generateIcons } from 'application-utils';
import { RepoTypes } from 'ox-common-types';
import React, { FC } from 'react';
import { processText } from 'string-utils';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as FakeAppLogoDark } from './assets/fake-app-icon-dark.svg';
import { ReactComponent as FakeAppLogoLight } from './assets/fake-app-icon-light.svg';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { BiGitRepoForked } from 'react-icons/bi';

export const ApplicationCell: FC<
  React.PropsWithChildren<ApplicationCellProps>
> = ({
  appName,
  appType,
  ContainerIcon,
  onClick,
  alwaysFull,
  repoLink,
  length,
  primaryApp,
  ...textProps
}) => {
  const { classes } = useStyles();
  const { RepoIcon } = generateIcons(undefined, appType);

  const theme = useTheme();

  return (
    <div className={classes.applicationCell}>
      <div className={classes.iconContainer}>
        {RepoIcon ? (
          <>
            <RepoIcon className={classes.repoIcon} />
            {ContainerIcon && '\u2194'}
            {ContainerIcon}
          </>
        ) : theme.palette.mode === 'dark' ? (
          <FakeAppLogoLight className={classes.repoIcon} />
        ) : (
          <FakeAppLogoDark className={classes.repoIcon} />
        )}
      </div>
      <Typography
        className={classes.applicationName}
        variant='body2'
        onClick={onClick}
        {...textProps}>
        {alwaysFull ? appName : processText(appName, length || 35)}
        {primaryApp && (
          <BiGitRepoForked
            style={{ verticalAlign: 'middle', marginLeft: '0.5em' }}
          />
        )}
      </Typography>
      {repoLink && (
        <Link
          sx={{ fontSize: 10, marginInlineStart: '3px', marginTop: '3px' }}
          href={repoLink}
          target='_blank'>
          <FaExternalLinkAlt style={{ color: '#585cfc' }} />
        </Link>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  applicationName: {
    lineClamp: 2,
    WebkitLineClamp: 2,
    lineHeight: 1.2,
    wordBreak: 'break-all',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  applicationCell: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  repoIcon: {
    width: '1rem',
    height: '1rem',
    color: theme.palette.text.primary,
    flexShrink: 0,
  },
}));

interface ApplicationCellProps extends TypographyProps {
  appName: string;
  length?: number;
  appType: RepoTypes;
  alwaysFull?: boolean;
  ContainerIcon?: JSX.Element;
  repoLink?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  primaryApp?: boolean;
}
