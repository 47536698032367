import { IconType, PackageManagerTypes } from 'ox-common-types';
import { BsGem } from 'react-icons/bs';
import { DiNpm } from 'react-icons/di';
import { SiApachemaven, SiGo, SiPnpm, SiPypi } from 'react-icons/si';

export const packageManagerIconsMap = new Map<PackageManagerTypes, IconType>([
  [PackageManagerTypes.Gem, BsGem],
  [PackageManagerTypes.Pypi, SiPypi],
  [PackageManagerTypes.Maven, SiApachemaven],
  [PackageManagerTypes.Go, SiGo],
  [PackageManagerTypes.Npm, DiNpm],
  [PackageManagerTypes.Pnpm, SiPnpm],
]);

export const resolveManagerIcon = (managerType: string) => {
  const type = managerType?.toLowerCase() as PackageManagerTypes;
  return packageManagerIconsMap.get(type) || null;
};
