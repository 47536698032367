import { FC } from 'react';
import { UncontrolledCollapsiblePanel } from '../CollapsiblePanel/UncontrolledCollapsiblePanel';
import { Box } from '@mui/material';
import { CustomExtLink } from '../Aggregation/elements/CustomExtLink';
import CodeLocationList from './CodeLocationList';

const ApiCallstack: FC<ApiCallstackProps> = ({ codeLocations }) => {
  return (
    <Box sx={{ py: 0.5, overflowY: 'auto' }}>
      {codeLocations.map((codeLocation, index) => (
        <UncontrolledCollapsiblePanel
          key={index}
          defaultState={index === 0}
          text={
            <CustomExtLink
              value={codeLocation.link.split('/').pop() ?? codeLocation.link}
              href={codeLocation.link}
            />
          }
          unmountOnExit={false}
          mountOnEnter={true}>
          <CodeLocationList
            callBranch={codeLocation.callBranch}
            language={codeLocation.language}
            snippet={codeLocation.snippet}
            snippetLineNumber={codeLocation.snippetLineNumber}
            withDescriptions
            withNesting
          />
        </UncontrolledCollapsiblePanel>
      ))}
    </Box>
  );
};

type ApiCallstackProps = {
  codeLocations: Array<{
    link: string;
    snippet?: string;
    language?: string;
    snippetLineNumber?: number;
    callBranch: string[];
  }>;
};

export default ApiCallstack;
