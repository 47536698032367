import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box, Typography } from '@mui/material';

const CallStackListItem: React.FC<CallStackListItemProps> = ({
  entry,
  isDivider,
  itemIndex,
  lastItem,
  withDescriptions,
}) => {
  const { method, file, line } = entry;

  const width = calculateLineWidth(itemIndex);
  return (
    <ListItem
      sx={{
        px: 1,
        py: 0.5,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        margin: 0,
      }}
      divider={isDivider}>
      <Box sx={{ width: `${width} !important` }}>
        <CustomIcon width={width} />
      </Box>

      <ListItemText
        sx={{ margin: 0 }}
        primary={
          <>
            {method && (
              <>
                <b>{method}</b>
              </>
            )}
            {' in '} <b>{file}</b>
            {' at line '}
            <b>{line}</b>
            {lastItem && withDescriptions && (
              <>
                <br />
                <Typography color='text.secondary' variant='caption'>
                  Location of the issue in the code
                </Typography>
              </>
            )}
            {itemIndex === 0 && withDescriptions && (
              <>
                <br />
                <Typography color='text.secondary' variant='caption'>
                  API Definition
                </Typography>
              </>
            )}
            {itemIndex === 1 && withDescriptions && (
              <>
                <br />
                <Typography color='text.secondary' variant='caption'>
                  Handler Function
                </Typography>
              </>
            )}
          </>
        }
      />
    </ListItem>
  );
};

type CallStackEntry = {
  method: string;
  file: string;
  line: string;
};

type CallStackListItemProps = {
  entry: CallStackEntry;
  isDivider: boolean;
  itemIndex: number;
  lastItem: boolean;
  withDescriptions?: boolean;
};

const CustomIcon = ({ width }: { width: number }) => {
  const viewBoxWidth = width + 4;

  return (
    <svg width={viewBoxWidth} height='27' viewBox={`0 0 ${viewBoxWidth} 24`}>
      <path d='M2 2 V8' stroke='#6200EA' strokeWidth='1' />
      <path
        d={`M${2 + 8} 14 H${width}`}
        stroke='#6200EA'
        strokeWidth='1'
        strokeLinecap='round'
      />
      <path
        d={`M2 8 Q2 15 12 14`}
        stroke='#6200EA'
        strokeWidth='1'
        fill='none'
      />
      <path
        d={`M${width - 1} 10 L${width + 3} 14 L${width - 1} 18`}
        fill='#6200EA'
      />
    </svg>
  );
};

const calculateLineWidth = (index: number) => {
  const baseWidth = 10;
  const maxLineWidth = 56;
  const widthMultiplier = 4;
  return Math.min(maxLineWidth, baseWidth + index * widthMultiplier);
};

export default CallStackListItem;
