import { createStore } from 'store-utils';
import { SLAItem } from '../types/settings-types';

const SLASettingsStore = createStore<SLASettingsStoreState>(
  {
    severitySLA: {
      isEnabled: false,
      settings: [],
    },
    nearExceedingSLA: 3,
    isLoadingSaveSettings: false,
    isLoadingGetSettings: true,
    isErrorGetSettings: false,
  },
  'SLA Settings Store',
);

interface SLASettingsStoreState {
  severitySLA: {
    isEnabled: boolean;
    settings: SLAItem[];
  };
  nearExceedingSLA: number;
  isLoadingSaveSettings: boolean;
  isLoadingGetSettings: boolean;
  isErrorGetSettings: boolean;
}
export default SLASettingsStore;
