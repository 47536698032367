import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import {
  CredentialsConfigureResponse,
  GitHubAppInstallationInput,
} from '../../connectors-types';
import mutation from './configure-github-app-installation.gql';

const configureGitHubAppInstallation = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      gitHubAppInstallationInput: GitHubAppInstallationInput,
      enableMultiTokens: boolean,
    ): Promise<Nullable<CredentialsConfigureResponse>> => {
      const results =
        await client.mutate<ConfigureGitHubAppInstallationResponse>({
          mutation,
          variables: {
            gitHubAppInstallationInput,
            isMultiCredentials: enableMultiTokens,
          },
        });

      if (results?.data?.configureGitHubAppInstallation) {
        return results.data.configureGitHubAppInstallation.connector;
      }

      throw new Error('Failed to add credentials');
    },
  };
};

interface ConfigureGitHubAppInstallationResponse {
  configureGitHubAppInstallation: { connector: CredentialsConfigureResponse };
}

export default configureGitHubAppInstallation;
