export const themeBaseColors = {
  base: {
    white: '#FFFFFF',
    OXorange: '#FE906D',
    OXblue: '#1E144D',
    OXpurple: '#924AC7',
    darkgrey: '#D4D4D8',
    lightgrey: '#EFF0F5',
  },
  light: {
    typography: {
      primary: '#222222',
      secondary: '#96969D',
      tertiary: '#FFFFFF',
      disabled: '#D4D4D8',
      link: '#0000EE',
    },
    severity: {
      appoxalypse: '#8B30AB',
      critical: '#843131',
      high: '#D01502',
      medium: '#FFC051',
      low: '#5C94ED',
      info: '#848484',
    },
  },
  dark: {
    typography: {
      primary: '#FFFFFF',
      secondary: '#9696A2',
      tertiary: '#222222',
      disabled: '#363637',
      link: '#0000EE',
    },
    severity: {
      appoxalypse: '#8B30AB',
      critical: '#843131',
      high: '#D01502',
      medium: '#FFC051',
      low: '#5C94ED',
      info: '#848484',
    },
  },
};
