import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { SlaSettings, SlaSettingsResponse } from '../../types/settings-types';
import mutation from './update-sla-settings.gql';

export const updateSlaSettings = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (input: SlaSettings) => {
      const errorMessage = 'Failed to update SLA settings';
      try {
        const results = await client.mutate<{
          updateSlaSettings: SlaSettingsResponse;
        }>({
          mutation: mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data?.updateSlaSettings) {
          logger.error(errorMessage);
          throw new Error(errorMessage);
        }
        return results.data;
      } catch (error) {
        logger.error(errorMessage, error);
        throw error;
      }
    },
  };
};
