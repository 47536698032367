import { LoadingStatus } from 'async-utils';
import { AppEvents } from 'common-events';
import { logger } from 'logging-utils';
import { openErrorSnackbar } from 'snackbar-utils';
import { trackFirstResultsAppCuesEvent } from 'telemetry';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import { loadConnectorsByFamily } from '../../connectors/api/connectors-api';
import InventoryFiltersStore from '../../dashboard-inventory/stores/inventory-filters-store';
import {
  setSupplyChainItems,
  setSupplyChainLoadingStatus,
} from '../../dashboard/store-actions/supply-chain-store-actions';
import ScanStore from '../../new-scan/store/scan-store';
import dashboardService from '../services';
import { createConnectorsPanelData } from '../utils/supply-chain-connectors-utils';
import { createSupplyChainInfo } from '../utils/supply-chain-utils';
import { OxCategoriesNames } from '@oxappsec/ox-consolidated-categories';
import { AppStore } from 'app-store/src/app-store';

export const loadSupplyChain = async () => {
  try {
    const { filters } = snapshot(InventoryFiltersStore);
    const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
      GlobalDataViewSelector,
    );
    const { dateRange } = snapshot(TopBarStore);
    const [from, to] = calcDateRange(dateRange);
    const { scanID, isScanning, realScanId } = snapshot(ScanStore);

    setSupplyChainLoadingStatus(LoadingStatus.Pending);
    const result = await dashboardService.getSupplyChain.execute(
      {
        dateRange: { from, to },
        ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        filters,
      },
      !isScanning,
    );

    if (result) {
      const { ldClient } = snapshot(AppStore);
      const connectorsByFamily = await loadConnectorsByFamily(
        false,
        // It's done like this because to provide it via parameter I need to add it in useEffect in App Init functions that can be called multiple times because of effect
        ldClient?.allFlags().enableMultiTokens,
      );
      const { connectorsPanelData } =
        createConnectorsPanelData(connectorsByFamily);
      if (isScanning && result.some(res => !!res.issues.values)) {
        trackFirstResultsAppCuesEvent(AppEvents.Scan.FirstResult);
      }
      const info = createSupplyChainInfo(result, connectorsPanelData);

      const filteredData = info.filter(
        item =>
          !(
            item?.header?.categoryName ===
              OxCategoriesNames.DynamicApplicationSecurityTesting &&
            item.connectors?.items === undefined
          ),
      );
      setSupplyChainItems(filteredData);
    }
    setSupplyChainLoadingStatus(LoadingStatus.Success);
  } catch (err) {
    logSnackbarError('Failed to load supply chain info', err as Error);
    setSupplyChainLoadingStatus(LoadingStatus.Error);
  }
};

export const logSnackbarError = (message: string, err: Error) => {
  logger.error(message, err);
  openErrorSnackbar(message);
};
