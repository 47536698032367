import { ApolloError } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { OxCategoriesKeys } from '@oxappsec/ox-consolidated-categories';
import { AppPages, navigate } from 'app-navigator';
import { AppEvents } from 'common-events';
import { formatDistanceToNow } from 'date-fns';
import { GraphQLError } from 'graphql';
import {
  HubSpotFormsIds,
  getHubSpotFieldForSourceControl,
  submitHubSpotForm,
} from 'hubspot';
import { logger } from 'logging-utils';
import { OnboardingIDPCallbackDetail } from 'ox-common-types';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { scanStore } from '../../new-scan/api/new-scan-api';
import { getChosenOrganization } from '../../organizations/api/organizations-api';
import { CredentialsType } from '../connector-configure-types';
import {
  AddCredentialsInput,
  Connector,
  ConnectorsBitbucketAppErrors,
  ConnectorsGitHubAppErrors,
  ConnectorsIDPErrors,
  Credential,
  CredentialsConfigureResponse,
  EConnectorFields,
  GitHubAppInstallationInput,
  GitHubAppSetupAction,
  IdentityProviderInput,
  RemoveCredentialsInput,
  UpdateCredentialInput,
} from '../connectors-types';
import connectorService from '../services';
import {
  setIsLoadingConnectors,
  updateSingleConnectorsField,
} from '../store-actions/connectors-store-actions';
import ConnectorConfigureStore from '../stores/connector-configure-store';
import ConnectorsStore from '../stores/connectors-store';
import { isTokenExpired } from '../utils/credentials-utils';
import { findConnectorById, updateConnectorFields } from './connector-actions';
import {
  openRepoSelectionModal,
  setActiveCredentialsId,
  setIsAddingNewCredential,
  setIsLoadingDeleteCredentials,
  setIsLoadingVerifyAndSaveCredentials,
  updateSelectedConnectorField,
} from './connector-configure-actions';
import { disableConnector } from './enable-disable-actions';
import { Location } from 'history';
import {
  removeControlObjectByCredential,
  removeMonitorAllNewlyCreatedResourcesByCredentials,
} from '../../onboarding/actions/onboarding-repo-selection-actions';
import {
  resetAWSExternalIdFormInput,
  resetFormInputs,
} from '../store-actions/connector-config-form-store-action';

export const postSuccessAction = (
  credentialsConfigureResponse: CredentialsConfigureResponse,
  enableMultiTokens: boolean,
) => {
  const { id, credentials, hostURL, isConfigured } =
    credentialsConfigureResponse;
  updateConnectorFields(id, {
    [EConnectorFields.credentials]: credentials,
    [EConnectorFields.isConfigured]: isConfigured,
    [EConnectorFields.hostURL]: hostURL,
    [EConnectorFields.errorMessage]: undefined,
    [EConnectorFields.isConnectorInvalid]: isConfigured
      ? enableMultiTokens
        ? credentials.every(item => isTokenExpired(item.tokenExpirationDate))
        : isTokenExpired(credentials[0].tokenExpirationDate)
      : false,
  });
};

const postActionError = (
  graphQLErrors?: GraphQLError[],
  defaultErrorMessage?: string,
) => {
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return;
  }
  const { id: connectorId } = selectedConnector;
  const errorMsg =
    graphQLErrors?.[0]?.message ||
    defaultErrorMessage ||
    'Oops, we encountered an error';
  if (connectorId) {
    updateSingleConnectorsField(
      connectorId,
      EConnectorFields.errorMessage,
      errorMsg,
    );
    updateSelectedConnectorField(
      connectorId,
      EConnectorFields.errorMessage,
      errorMsg,
    );
  }
  openSnackbar(errorMsg, { variant: 'error' });
};

export const updateCredentials = async (
  addCredentialsInput: AddCredentialsInput,
  credentialsId: string,
) => {
  const result = await connectorService.updateCredentials.execute(
    addCredentialsInput,
    credentialsId,
  );
  return result;
};

export const updateCredential = async (
  credentialsId: string,
  updateCredentialInput: UpdateCredentialInput,
  connector: Connector,
) => {
  await connectorService.updateCredential.execute(
    credentialsId,
    updateCredentialInput,
  );

  updateConnectorFields(connector.id, {
    [EConnectorFields.credentials]: connector.credentials.map(item =>
      item.credentialsId === credentialsId
        ? { ...item, credentialsName: updateCredentialInput.credentialsName }
        : item,
    ),
  });
};

export const addCredentials = async (
  addCredentialsInput: AddCredentialsInput,
  selectedConnector: Connector,
  // TODO: remove here the temp flag for multi token support
  isMultiToken: boolean = false,
) => {
  try {
    // TODO: NEED TO MODIFY THE SCHEMA TO RETURN ONLY 1 CREDENTIAL
    const result = await connectorService.addCredentials.execute(
      addCredentialsInput,
      isMultiToken,
    );
    if (isMultiToken) {
      postSuccessAction(
        {
          ...result,
          credentials: [
            ...selectedConnector.credentials,
            ...result.credentials,
          ],
        },
        isMultiToken,
      );
    } else {
      postSuccessAction(result, isMultiToken);
    }

    openSnackbar('Connector connected successfully!', { variant: 'success' });
    const lastCredentialsId =
      result.credentials[result.credentials.length - 1].credentialsId;
    if (selectedConnector.family === OxCategoriesKeys.SourceControl) {
      const hubspotConnectorField = getHubSpotFieldForSourceControl(
        selectedConnector.displayName,
      );
      if (!hubspotConnectorField) {
        logger.warn(
          `Could not find hubspot source control field for ${selectedConnector.displayName}`,
        );
      } else {
        submitHubSpotForm(
          {
            [hubspotConnectorField]: true,
          },
          HubSpotFormsIds.OxAppForm,
        );
      }
      openRepoSelectionModal(
        true,
        isMultiToken ? lastCredentialsId : undefined,
      );
    } else {
      if (lastCredentialsId) {
        setActiveCredentialsId(lastCredentialsId);
      }
    }
    if (isMultiToken) {
      resetFormInputs();
      resetAWSExternalIdFormInput();
      setIsAddingNewCredential(false);
    }
  } catch (err) {
    datadogLogs.logger.error(
      `Failed adding credentials for connector ${selectedConnector.displayName} id: ${addCredentialsInput.connectorID}. Error: ${err}`,
    );
    if (err instanceof ApolloError) {
      postActionError(
        err.graphQLErrors as GraphQLError[],
        `Adding credentials failed due to an error: ${err}`,
      );
    }
  } finally {
    setIsLoadingVerifyAndSaveCredentials(false);
  }
};

export const removeCredentials = async (
  removeCredentialsInput: RemoveCredentialsInput,
  selectedConnector: Connector,
  isMultiToken: boolean = false,
): Promise<void> => {
  try {
    const result = await connectorService.removeCredentials.execute(
      removeCredentialsInput,
      isMultiToken,
    );
    if (result) {
      selectedConnector.dependencyConnectors?.forEach(dependentConnectorId => {
        const dependentConnector = findConnectorById(dependentConnectorId);
        if (dependentConnector?.isConfigured) {
          disableConnector({ connectorID: dependentConnector.id });
        }
      });
      if (isMultiToken && removeCredentialsInput.credentialsId) {
        removeControlObjectByCredential(removeCredentialsInput.credentialsId);
        removeMonitorAllNewlyCreatedResourcesByCredentials(
          removeCredentialsInput.credentialsId,
        );
      }
    }
    if (isMultiToken) {
      postSuccessAction(
        {
          ...result,
          credentials: result.credentials.filter(
            item => item.credentialsId !== removeCredentialsInput.credentialsId,
          ),
        },
        isMultiToken,
      );
    } else {
      postSuccessAction(result, isMultiToken);
    }
  } catch (err) {
    if (err instanceof ApolloError) {
      postActionError(
        err.graphQLErrors as GraphQLError[],
        `Remove credentials failed due to an error: ${err}`,
      );
    }
  } finally {
    setIsLoadingDeleteCredentials(false);
  }
};

export const validateSingleConnector = async (
  connectorID: string,
  validationOnly = false,
  credentialsId?: string,
  enableMultiTokens?: boolean,
) => {
  try {
    const connector = findConnectorById(connectorID);

    if (!connector?.isConfigured) {
      if (!validationOnly) {
        const error = `Validation called for connector ${connector?.name} which is not configured!`;
        logger.error(error);
        throw new Error(error);
      }
      return false;
    }
    const { isDemoEnabled } = scanStore();
    if (isDemoEnabled && connector?.isDemoEnabled && validationOnly) {
      return true;
    }
    setSingleConnectorValidationInProgress(true);
    const results = await connectorService.validateSingleConnector.execute({
      connectorID,
      credentialsID: credentialsId,
    });

    if (results === null || results === undefined) {
      logger.warn(
        'validateSingleConnector: received an invalid response from validation service',
      );
      return;
    }

    if (validationOnly) {
      return results.credentialsAreValid;
    }

    const {
      credentialsAreValid,
      noReposFound,
      validationMessage = '',
    } = results;

    const { connectors } = snapshot(ConnectorsStore);
    const connectorToUpdate = connectors.find(
      connector => connector.id === connectorID,
    );
    if (!connectorToUpdate) {
      logger.error(
        'Credentials Validation failed: could not find a connector with id: ',
        connectorID,
      );
      return;
    }
    const tokenExpirationDate = !credentialsAreValid
      ? new Date().toUTCString()
      : undefined;
    const credentials = enableMultiTokens
      ? connectorToUpdate.credentials.map(item =>
          item.credentialsId === credentialsId
            ? {
                ...item,
                tokenExpirationDate,
              }
            : item,
        )
      : [{ ...connectorToUpdate.credentials[0], tokenExpirationDate }];

    updateConnectorFields(connectorID, {
      [EConnectorFields.credentials]: credentials,
      [EConnectorFields.isEmptyOfRepos]: noReposFound,
      [EConnectorFields.errorMessage]: undefined,
      [EConnectorFields.isConnectorInvalid]:
        isTokenExpired(tokenExpirationDate),
    });

    const isValid = credentialsAreValid && !noReposFound;

    if (validationMessage) {
      openSnackbar(validationMessage, {
        variant: isValid ? 'success' : 'error',
      });
    } else {
      const successSnackbarMsg = 'Connectivity Verified!';
      const invalidSnackbarMsg = noReposFound
        ? 'Could not fetch your repositories, please check the provided token scopes'
        : 'Your token has become invalid. Please add a new token.';
      openSnackbar(isValid ? successSnackbarMsg : invalidSnackbarMsg, {
        variant: isValid ? 'success' : 'error',
      });
    }
    showSingleValidationMsg(credentialsAreValid, noReposFound, credentialsId);
  } catch (err) {
    logger.error(err);
    showValidationError();
  } finally {
    setSingleConnectorValidationInProgress(false);
  }
};

export const validateAllCredentials = async (
  enableMultiTokens: boolean,
): Promise<void> => {
  setCredentialsValidationInProgress(true);

  try {
    const chosenOrg = getChosenOrganization();
    const orgId = chosenOrg?.id;
    if (!orgId) {
      logger.warn(
        'Validating all credentials did not execute because of a missing org id in the chosen organization: ',
        chosenOrg,
      );
      return;
    }
    const results = await connectorService.validateAllCredentials.execute(
      orgId,
    );

    if (results === null || results === undefined) {
      return;
    }
    if (results.invalidCredentialsConnectors.length > 0) {
      setIsLoadingConnectors(true);
      const { connectors } = snapshot(ConnectorsStore);
      results.invalidCredentialsConnectors.forEach(connectorId => {
        const connectorToUpdate = connectors.find(
          connector => connector.id === connectorId,
        );
        if (!connectorToUpdate) {
          logger.error(
            'Credentials Validation: could not find a connector with id: ',
            connectorId,
          );
          return;
        }
        // For now supporting only single credentials
        const creds = enableMultiTokens
          ? connectorToUpdate.credentials.map(item =>
              isTokenExpired(item.tokenExpirationDate)
                ? {
                    ...item,
                    tokenExpirationDate: new Date().toUTCString(),
                  }
                : item,
            )
          : [
              {
                ...connectorToUpdate.credentials[0],
                tokenExpirationDate: new Date().toUTCString(),
              },
            ];

        updateConnectorFields(connectorId, {
          [EConnectorFields.credentials]: creds,
          [EConnectorFields.isConnectorInvalid]: true,
        });
      });
      setIsLoadingConnectors(false);
    }
    updateValidConnectors(
      results.invalidCredentialsConnectors,
      enableMultiTokens,
    );
    showValidationMsg();
  } catch (err) {
    logger.error(err);
    showValidationError();
  } finally {
    setCredentialsValidationInProgress(false);
  }
};

const updateValidConnectors = (
  invalidConnectorsIdList: string[],
  enableMultiTokens: boolean,
) => {
  const { connectors } = snapshot(ConnectorsStore);
  const invalidConnectors = connectors.filter(
    connector => !!connector.credentials[0]?.tokenExpirationDate,
  );
  invalidConnectors.forEach(connector => {
    if (!invalidConnectorsIdList.includes(connector.id)) {
      const creds = enableMultiTokens
        ? connector.credentials.map(item =>
            !isTokenExpired(item.tokenExpirationDate)
              ? { ...item, tokenExpirationDate: undefined }
              : item,
          )
        : [{ ...connector.credentials[0], tokenExpirationDate: undefined }];
      updateConnectorFields(connector.id, {
        [EConnectorFields.credentials]: creds,
        [EConnectorFields.isConnectorInvalid]: false,
      });
    }
  });
};

export const showValidationMsg = () => {
  ConnectorsStore.showValidationMsg = true;
};

export const dismissValidationMsg = () => {
  ConnectorsStore.showValidationMsg = false;
};

export const showValidationError = () => {
  ConnectorsStore.validationError = true;
};

export const dismissValidationError = () => {
  ConnectorsStore.validationError = false;
};

export const showSingleValidationMsg = (
  isConnectorValid: boolean,
  noReposFound: boolean,
  credentialsId?: string,
) => {
  ConnectorsStore.showSingleValidationMsg = true;
  ConnectorsStore.singleConnectorIsEmptyOfRepos = noReposFound;
  ConnectorsStore.singleConnectorIsValid = isConnectorValid;
  ConnectorsStore.validationCredentialsId = credentialsId;
};

export const dismissSingleValidationMsg = () => {
  ConnectorsStore.showSingleValidationMsg = false;
  ConnectorsStore.singleConnectorIsEmptyOfRepos = undefined;
  ConnectorsStore.singleConnectorIsValid = undefined;
  ConnectorsStore.validationCredentialsId = '';
};

export const setCredentialsValidationInProgress = (isInProgress: boolean) => {
  ConnectorsStore.credentialsValidationInProgress = isInProgress;
};

export const setSingleConnectorValidationInProgress = (
  isInProgress: boolean,
) => {
  ConnectorsStore.singleConnectorValidationInProgress = isInProgress;
};

export const createTokenExpirationHelperMsg = (
  connector: Connector,
  credential?: Credential,
) => {
  const cred = credential || connector.credentials[0];
  const {
    singleConnectorIsValid,
    showSingleValidationMsg,
    singleConnectorIsEmptyOfRepos,
  } = snapshot(ConnectorsStore);

  if (
    showSingleValidationMsg &&
    singleConnectorIsValid &&
    !singleConnectorIsEmptyOfRepos
  ) {
    return 'Connectivity Verified!';
  }
  if (
    (connector.credentials.length === 0 || !cred.tokenExpirationDate) &&
    !connector.isEmptyOfRepos
  ) {
    return '';
  }

  if (connector.isEmptyOfRepos && !cred?.tokenExpirationDate) {
    return 'Could not fetch your repositories, please check the provided token scopes';
  }

  if (!cred.tokenExpirationDate) {
    return '';
  }

  const expDate = new Date(cred.tokenExpirationDate);
  if (expDate.toString() === 'Invalid Date') {
    return '';
  }
  if (isTokenExpired(expDate)) {
    return `Your token has become invalid. Please add a new token.`;
  }
  return `Your token will expire ${formatDistanceToNow(expDate, {
    addSuffix: true,
  })}`;
};

export const configureIDP = async (
  idpCode: string,
  idpState: string,
  isMultiCredentials: boolean,
): Promise<{ success: boolean; error?: string }> => {
  try {
    const decodedState = decodeURIComponent(idpState);
    const parsedState = JSON.parse(decodedState);

    const { idpBaseURL, exchangeAuthorizationCodeURL, credentialsName } =
      parsedState;

    const idpInput: IdentityProviderInput = {
      code: idpCode,
      state: idpState,
      identityProviderBaseURL: exchangeAuthorizationCodeURL || idpBaseURL,
      credentialsName,
    };

    const result = await connectorService.configureIdentityProvider.execute(
      idpInput,
      isMultiCredentials,
    );
    if (!result) {
      return {
        success: false,
        error:
          'Oops.. An unknown error happened, please refresh the page and try again',
      };
    }

    const connector = findConnectorById(result.id);
    if (connector) {
      if (isMultiCredentials) {
        postSuccessAction(
          {
            ...result,
            credentials: [...connector.credentials, ...result.credentials],
          },
          isMultiCredentials,
        );
      } else {
        postSuccessAction(result, isMultiCredentials);
      }
    }
    return { success: true };
  } catch (err) {
    let errMsg = `Didn't manage to add credentials due to an error: ${err}`;
    if (err instanceof ApolloError) {
      postActionError(err.graphQLErrors as GraphQLError[], errMsg);
      errMsg = err.graphQLErrors[0].message || errMsg;
    }
    return { success: false, error: errMsg };
  } finally {
    setIsLoadingVerifyAndSaveCredentials(false);
  }
};

export const configureGitHubAppInstallation = async (
  code: string,
  installationId: number,
  redirectUrlPathname: string,
  credentialsName: string,
  enableMultiTokens: boolean,
  connectorId: string,
): Promise<{ success: boolean; error?: string }> => {
  try {
    const input: GitHubAppInstallationInput = {
      code,
      installationId,
      redirectUrlPathname,
      ...(enableMultiTokens ? { credentialsName } : {}),
    };
    const result =
      await connectorService.configureGitHubAppInstallation.execute(
        input,
        enableMultiTokens,
      );
    if (!result) {
      return {
        success: false,
        error:
          'Oops.. An unknown error happened, please refresh the page and try again',
      };
    }
    const connector = findConnectorById(connectorId);
    if (connector) {
      if (enableMultiTokens) {
        postSuccessAction(
          {
            ...result,
            credentials: [...connector.credentials, ...result.credentials],
          },
          enableMultiTokens,
        );
      } else {
        postSuccessAction(result, enableMultiTokens);
      }
    }
    return { success: true };
  } catch (err) {
    let errMsg = `Didn't manage to configure OX GitHub App installation due to an error: ${err}`;
    if (err instanceof ApolloError) {
      postActionError(err.graphQLErrors as GraphQLError[], errMsg);
      errMsg = err.graphQLErrors[0].message || errMsg;
    }
    return { success: false, error: errMsg };
  } finally {
    setIsLoadingVerifyAndSaveCredentials(false);
  }
};

export const configureBitbucketAppInstallation = async (
  jwt: string,
  userUuid: string,
  enableMultiTokens: boolean,
  connectorId: string,
  credentialsName: string,
): Promise<{ success: boolean; error?: string }> => {
  try {
    const input = {
      jwt,
      userUuid,
      credentialsName,
    };
    const result =
      await connectorService.configureBitbucketAppInstallation.execute(input);
    const connector = findConnectorById(connectorId);
    if (connector) {
      if (enableMultiTokens) {
        postSuccessAction(
          {
            ...result,
            credentials: [...connector.credentials, ...result.credentials],
          },
          enableMultiTokens,
        );
      } else {
        postSuccessAction(result, enableMultiTokens);
      }
    }
    return { success: true };
  } catch (err) {
    let errMsg = `Didn't manage to configure OX Bitbucket App installation due to an error: ${err}`;
    if (err instanceof ApolloError) {
      postActionError(err.graphQLErrors as GraphQLError[], errMsg);
      errMsg = err.graphQLErrors[0].message || errMsg;
    }
    return { success: false, error: errMsg };
  } finally {
    setIsLoadingVerifyAndSaveCredentials(false);
  }
};

export const checkWhichTabToShow = () => {
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return null;
  }
  const lastCredIndex = (selectedConnector.credentials?.length || 1) - 1;
  const credentialType =
    selectedConnector.credentials[lastCredIndex]?.credentialsType;
  if (selectedConnector.isConfigured && credentialType) {
    return CredentialsType[credentialType];
  }

  return null;
};

export const handleIdpCallback = async (
  searchParams: URLSearchParams,
  isMultiCredentials: boolean,
) => {
  const idpCode = searchParams.get('code');
  const idpState = searchParams.get('state');
  const error = searchParams.get('error');

  if (!idpState) {
    navigate(
      AppPages.Connectors,
      `idpError=${
        error === 'access_denied'
          ? ConnectorsIDPErrors.AccessDenied
          : ConnectorsIDPErrors.MissingState
      }`,
    );
    return;
  }
  const decodedState = decodeURIComponent(idpState);
  const parsedState = JSON.parse(decodedState);
  if (error) {
    logger.error(error);
    const errType =
      error === 'access_denied'
        ? ConnectorsIDPErrors.AccessDenied
        : ConnectorsIDPErrors.GeneralError;
    navigate(
      AppPages.Connectors,
      `connectorId=${parsedState.connectorId}&idpError=${errType}`,
    );
    return;
  }
  if (!idpCode) {
    navigate(
      AppPages.Connectors,
      `connectorId=${parsedState.connectorId}&idpError=${ConnectorsIDPErrors.MissingCode}`,
    );
    return;
  }
  const configureResults = await configureIDP(
    idpCode,
    idpState,
    isMultiCredentials,
  );
  if (parsedState.onboarding) {
    datadogLogs.logger.info(
      `Onboarding: ${
        configureResults.success
          ? 'Connected successfully'
          : 'Failed connecting'
      } using IDP to connector ID ${parsedState.connectorId}`,
      { connectorId: parsedState.connectorId },
    );
    document.dispatchEvent(
      new CustomEvent<OnboardingIDPCallbackDetail>(
        configureResults.success
          ? AppEvents.Onboarding.ConnectIdpSuccess
          : AppEvents.Onboarding.ConnectIdpFailed,
        {
          detail: {
            connectorId: parsedState.connectorId,
            error: configureResults.error,
          },
        },
      ),
    );
  } else {
    const connector = findConnectorById(parsedState.connectorId);
    if (configureResults.success) {
      const sourceControlHubSpotField = getHubSpotFieldForSourceControl(
        connector?.displayName || '',
      );
      if (!sourceControlHubSpotField) {
        logger.warn(
          `Could not find HubSpot source control field for ${connector?.displayName} with id ${parsedState.connectorId}`,
        );
      } else {
        submitHubSpotForm(
          {
            [sourceControlHubSpotField]: true,
          },
          HubSpotFormsIds.OxAppForm,
        );
      }
    }
    navigate(
      AppPages.Connectors,
      `connectorId=${parsedState.connectorId}&idp=true${
        configureResults.success
          ? ''
          : `&idpError=${
              configureResults.error || ConnectorsIDPErrors.ValidationError
            }`
      }`,
    );
  }
};

export const handleGitHubAppInstallationCallback = async (
  location: Location,
  enableMultiTokens: boolean,
) => {
  const searchParams = new URLSearchParams(location.search);
  const redirectUrlPathname = location.pathname;
  const connectorId = '1'; // always GitHub
  const userOauthCode = searchParams.get('code');
  const setupAction = searchParams.get('setup_action');
  const installationIdParam = searchParams.get('installation_id');
  const prevState = searchParams.get('state');

  const decodedState = decodeURIComponent(prevState || '');
  const parsedState = !!decodedState ? JSON.parse(decodedState) : {};

  const navigateToConnectors = (e?: ConnectorsGitHubAppErrors | string) => {
    if (parsedState.onboarding) {
      datadogLogs.logger.info(
        `Onboarding: ${
          !e ? 'Connected successfully' : 'Failed connecting'
        } using GitHub App to connector ID ${1}`,
        { connectorId: '1' },
      );
      document.dispatchEvent(
        new CustomEvent<OnboardingIDPCallbackDetail>(
          !e
            ? AppEvents.Onboarding.ConnectIdpSuccess
            : AppEvents.Onboarding.ConnectIdpFailed,
          {
            detail: {
              connectorId: '1',
              error: e,
            },
          },
        ),
      );
    } else {
      const qs = [
        `connectorId=${connectorId}`,
        `gitHubApp=true`,
        e ? `gitHubAppError=${e}` : '',
      ]
        .filter(Boolean)
        .join('&');

      navigate(AppPages.Connectors, qs);
    }
  };

  if (!userOauthCode) {
    navigateToConnectors(ConnectorsGitHubAppErrors.MissingCode);
    return;
  }

  if (setupAction === GitHubAppSetupAction.request) {
    navigateToConnectors(ConnectorsGitHubAppErrors.InstallationRequest);
    return;
  }

  if (!installationIdParam) {
    navigateToConnectors(ConnectorsGitHubAppErrors.MissingInstallationId);
    return;
  }

  const parsedInstallationId = parseInt(installationIdParam);
  if (parsedInstallationId <= 0 || isNaN(parsedInstallationId)) {
    navigateToConnectors(ConnectorsGitHubAppErrors.MissingInstallationId);
    return;
  }

  if (setupAction === GitHubAppSetupAction.update) {
    navigateToConnectors(
      'GitHub App was already installed on another organization, please uninstall it if you want to install in this organization',
    );
    return;
  }

  // unknown unhandled case
  if (setupAction !== GitHubAppSetupAction.install) {
    navigateToConnectors(ConnectorsGitHubAppErrors.UnhandledSetupAction);
    return;
  }

  const configureResults = await configureGitHubAppInstallation(
    userOauthCode,
    parsedInstallationId,
    redirectUrlPathname,
    parsedState.credentialsName,
    enableMultiTokens,
    connectorId,
  );

  const connector = findConnectorById(connectorId);

  if (configureResults.success) {
    const sourceControlHubSpotField = getHubSpotFieldForSourceControl(
      connector?.displayName || '',
    );
    if (!sourceControlHubSpotField) {
      logger.warn(
        `Could not find HubSpot source control field for ${connector?.displayName} with id ${connector?.id}`,
      );
    } else {
      submitHubSpotForm(
        {
          [sourceControlHubSpotField]: true,
        },
        HubSpotFormsIds.OxAppForm,
      );
    }

    navigateToConnectors();
  } else {
    navigateToConnectors(
      configureResults.error || ConnectorsGitHubAppErrors.ValidationError,
    );
  }
};

export const handleBitbucketAppInstallationCallback = async (
  searchParams: URLSearchParams,
  enableMultiTokens: boolean,
) => {
  const jwt = searchParams.get('jwt');
  const userUuid = searchParams.get('user_uuid');
  const connectorId = '3'; // always Bitbucket

  const prevState = searchParams.get('state');

  const decodedState = decodeURIComponent(prevState || '');
  const parsedState = !!decodedState ? JSON.parse(decodedState) : {};

  const navigateToConnectors = (e?: ConnectorsBitbucketAppErrors | string) => {
    if (parsedState.onboarding) {
      datadogLogs.logger.info(
        `Onboarding: ${
          !e ? 'Connected successfully' : 'Failed connecting'
        } using Bitbucket App to connector ID ${3}`,
        { connectorId: '3' },
      );
      document.dispatchEvent(
        new CustomEvent<OnboardingIDPCallbackDetail>(
          !e
            ? AppEvents.Onboarding.ConnectIdpSuccess
            : AppEvents.Onboarding.ConnectIdpFailed,
          {
            detail: {
              connectorId: '3',
              error: e,
            },
          },
        ),
      );
    } else {
      const sp = new URLSearchParams([
        ['connectorId', connectorId],
        ['bitbucketApp', 'true'],
      ]);
      if (e) sp.set('bitbucketAppError', e);

      navigate(AppPages.Connectors, sp.toString());
    }
  };

  if (!jwt) {
    navigateToConnectors(ConnectorsBitbucketAppErrors.MissingJWT);
    return;
  }

  if (!userUuid) {
    navigateToConnectors(ConnectorsBitbucketAppErrors.MissingUserUuid);
    return;
  }

  const configureResults = await configureBitbucketAppInstallation(
    jwt,
    userUuid,
    enableMultiTokens,
    connectorId,
    parsedState.credentialsName,
  );

  const connector = findConnectorById(connectorId);

  if (configureResults.success) {
    const sourceControlHubSpotField = getHubSpotFieldForSourceControl(
      connector?.displayName || '',
    );
    if (!sourceControlHubSpotField) {
      logger.warn(
        `Could not find HubSpot source control field for ${connector?.displayName} with id ${connector?.id}`,
      );
    } else {
      submitHubSpotForm(
        {
          [sourceControlHubSpotField]: true,
        },
        HubSpotFormsIds.OxAppForm,
      );
    }

    navigateToConnectors();
  } else {
    navigateToConnectors(
      configureResults.error || ConnectorsBitbucketAppErrors.ValidationError,
    );
  }
};
