import { reportClient, fileDownloadClient } from 'api-clients';
import getIssuesScore from '../../dashboard/services/get-issues-score.service';
import exportAppSbomToCsv from './export-app-sbom-to-csv/export-app-sbom-to-csv';
import exportSbomOrgToCsv from './export-sbom-to-csv/export-sbom-to-csv';
import getApplicationsIds from './get-applications-ids/get-applications-ids-service';
import getApplicationsSbom from './get-applications-sbom/get-applications-sbom-service';
import getApplications from './get-applications-service/get-applications-service';
import getOrganizationSbom from './get-organization-sbom/get-organization-sbom-service';
import getSingleApplication from './get-single-application/get-single-application-service';
import restorePriority from './restore-priority/restore-priority';
import setPriority from './set-business-priority/set-business-priority-service';
import setRelevance from './set-relevance-service/set-relevance-service';
import getApplicationsCSV from './get-applications-csv/get-applications-csv';
import getApplicationsPDF from './get-applications-pdf/get-applications-pdf';
import getApplicationsFiltersLazy from './get-applications-filters-lazy/get-applications-filters-lazy';
import getApplicationsConditionalFilters from './get-applications-conditional-filters/get-applications-conditional-filters';
import getApplicationFlow from './get-application-flow/get-application-flow';
import getApplicationsSbomV2 from './get-applications-sbom-v2/get-applications-sbom-v2-service';

const applicationsService = {
  getApplications: getApplications(reportClient),
  getApplicationsIds: getApplicationsIds(reportClient),
  getSingleApplication: getSingleApplication(reportClient),
  getApplicationsSbom: getApplicationsSbom(reportClient),
  getOrganizationSbom: getOrganizationSbom(reportClient),
  setRelevance: setRelevance(reportClient),
  setPriority: setPriority(reportClient),
  getIssuesScore: getIssuesScore(reportClient),
  exportSbomToCsv: exportSbomOrgToCsv(reportClient),
  exportAppsToCsv: getApplicationsCSV(reportClient),
  exportAppsToPdf: getApplicationsPDF(fileDownloadClient),
  exportAppSbomToCsv: exportAppSbomToCsv(reportClient),
  restorePriority: restorePriority(reportClient),
  getApplicationsFiltersLazy: getApplicationsFiltersLazy(reportClient),
  getApplicationsConditionalFilters:
    getApplicationsConditionalFilters(reportClient),
  getApplicationFlow: getApplicationFlow(reportClient),
  getApplicationsSbomV2: getApplicationsSbomV2(reportClient),
};
export default applicationsService;
