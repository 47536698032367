import { Theme } from '@mui/material';

export const sharedTypography = {
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h2',
          subtitle2: 'h3',
          body1: 'span',
          body2: 'span',
          caption: 'span',
          overline: 'span',
          tiny: 'span',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontWeightBold: 'normal',
    fontWeightRegular: 'normal',
    fontWeightLight: 'normal',
    fontWeightMedium: 'normal',
    h1: {
      fontSize: '2.125rem',
      letterSpacing: '0.25px',
      fontWeight: '600',
    },
    h2: {
      fontSize: '1.5rem',
      letterSpacing: '0.25px',
      fontWeight: '700',
    },
    h3: {
      fontSize: '1.25rem',
      letterSpacing: '0.25px',
      fontWeight: '700',
    },
    h4: {},
    h5: {},
    h6: {},
    subtitle1: {
      fontSize: '1rem',
      letterSpacing: '0.25px',
      fontWeight: '700',
    },
    subtitle2: {
      fontSize: '0.875rem',
      letterSpacing: '0.25px',
      fontWeight: '700',
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: '0.25px',
      fontWeight: '400',
    },
    body2: {
      fontSize: '0.875rem',
      letterSpacing: '0.25px',
      fontWeight: '400',
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: '0.25px',
      fontWeight: '400',
    },
    captionBold: {
      fontSize: '0.75rem',
      letterSpacing: '0.25px',
      fontWeight: '700',
    },
    overline: {
      fontSize: '0.688rem',
      letterSpacing: '0.25px',
      fontWeight: '500',
    },
    tiny: {
      fontSize: '0.625rem',
      letterSpacing: '0.25px',
      fontWeight: '700',
    },
  },
} as Theme;
