import { createAsyncState } from 'async-utils';
import { Direction, Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { SupplyChainItem } from '../../supply-chain/types/supplychain-types';
import {
  ArtifactInfo,
  ArtifactTopFilter,
  ArtifactTopFiltersTypes,
  ArtifactsSortKey, ArtifactsType,
} from '../types/artifacts-type';
import { ScaVulnerability } from '../../issues/common/types/issues-types';
import { Misconfiguration } from '../services/get-artifact-missconfigurations/get-artifact-missconfigurations';
import { FilterItems } from 'ox-filter-utils';
import {
  filterItemsBySearchValues,
  filterEmptyItems,
} from '../../../../../utilities/ox-filter-utils/src/filters-utils';

export const initialArtifactsStore: Partial<ArtifactsStoreState> = {
  artifacts: undefined,
  loading: createAsyncState(),
  loadingFilters: createAsyncState(),
  loadingTopFilters: createAsyncState(),
  offset: 0,
  total: 0,
  totalFiltered: 0,
  selectedArtifact: null,
  loadingArtifact: false,
  isArtifactDrawerOpen: false,
  order: Direction.DESC,
  orderBy: ArtifactsSortKey.ArtifactType,
  filtersOpen: true,
  totalFilterd: 0,
  artifactsTopFiltersList: [],
  filterArtifactsBy: {},
  topFiltersArtifactsBy: [],
  supplyChainItems: [],
  searchValues: {},
  hashSearch: '',
  vulnDepIssues: [],
  vulnDepBaseIssues: [],
  vulnDepInstructionIssues: [],
  confIssues: [],
  secretIssues: [],
  selectedArtifactId: null,
  artifactsTabValue: ArtifactsType.Scanned,
};

const baseStore = createStore<ArtifactsStoreState>(
  {
    ...JSON.parse(JSON.stringify(initialArtifactsStore)),
    loading: createAsyncState(),
    loadingFilters: createAsyncState(),
  },
  'Artifacts Store',
);

const ArtifactsStore = derive(
  {
    numberOfFilters: get => {
      const { filterArtifactsBy } = get(baseStore);
      return (
        Object.keys(filterArtifactsBy).reduce(
          (acc, key) => acc + filterArtifactsBy[key].length,
          0,
        ) || 0
      );
    },
    artifactsTopFiltersListConverted: get => {
      const { artifactsTopFiltersList } = get(baseStore);
      artifactsTopFiltersList.forEach(filter => {
        if (filter.name === ArtifactTopFiltersTypes.LastVersion) {
          filter.isDevelopment = true;
        }
      });
      return artifactsTopFiltersList;
    },
    filterItems: (get): Nullable<FilterItems> => {
      const { searchValues, lazyFilters, filterArtifactsBy } = get(baseStore);
      const filteredSearchValues = filterEmptyItems(searchValues);
      const isSearchValuesExist = Object.keys(filteredSearchValues).length > 0;

      if (isSearchValuesExist && lazyFilters) {
        return filterItemsBySearchValues(
          lazyFilters,
          filteredSearchValues,
          filterArtifactsBy,
        );
      } else {
        return lazyFilters;
      }
    },
  },
  { proxy: baseStore },
);

export interface ArtifactsStoreState {
  artifacts?: ArtifactInfo[];
  loading: ReturnType<typeof createAsyncState>;
  loadingFilters: ReturnType<typeof createAsyncState>;
  loadingTopFilters: ReturnType<typeof createAsyncState>;
  offset: number;
  total: number;
  totalFiltered: number;
  selectedArtifact: Nullable<ArtifactInfo>;
  loadingArtifact: boolean;
  isArtifactDrawerOpen: boolean;
  order: Direction;
  orderBy: ArtifactsSortKey;
  filtersOpen: boolean;
  totalFilterd: number;
  artifactsTopFiltersList: ArtifactTopFilter[];
  filterArtifactsBy: {};
  topFiltersArtifactsBy: ArtifactTopFiltersTypes[];
  supplyChainItems: SupplyChainItem[];
  searchValues: {};
  hashSearch: string;
  vulnDepIssues: ScaVulnerability[];
  vulnDepBaseIssues: ScaVulnerability[];
  vulnDepInstructionIssues: ScaVulnerability[];
  secretIssues: Misconfiguration[];
  confIssues: Misconfiguration[];
  artifactsTopFiltersListConverted: ArtifactTopFilter[];
  lazyFilters: Nullable<FilterItems>;
  artifactsIds: string[];
  selectedArtifactId: Nullable<string>;
  artifactsTabValue: ArtifactsType;
}

export default ArtifactsStore;
