import { debounce } from 'lodash-es';
import { Nullable, Order } from 'ox-common-types';
import { snapshot } from 'valtio';

import {
  setContextOptions,
  setLoadingVulns,
  setOffset,
  setSearchValue,
  setSeverityFactors,
  setTotal,
  setVulnerabilities,
} from '../containers/store-actions';
import VulnerabilitiesStore from '../stores/vulnerabilities-store';
import { VulnerabilitiesStoreState, VulnerabilityTab } from '../types';
import {
  GetVulnerabiltiesInput,
  VulnerabilitiesResponse,
} from '../../../../../../types/issues-types';

export const fetchVulnerabilities = async (input: {
  selectedIssueId: Nullable<string>;
  getVulnerabilitiesFunction: (
    input: GetVulnerabiltiesInput,
  ) => Promise<VulnerabilitiesResponse | null>;
  update?: boolean;
}) => {
  setLoadingVulns(true);

  if (input.update) {
    setOffset(0);
  }
  if (input.selectedIssueId) {
    const {
      offset,
      total,
      searchValue,
      severityFactors,
      orderField,
      orderDirection,
    } = snapshot(VulnerabilitiesStore);
    if (total !== 0 && offset >= total) {
      setLoadingVulns(false);
      return;
    }

    const data = await input.getVulnerabilitiesFunction({
      issueId: input.selectedIssueId,
      offset,
      tab: VulnerabilityTab.Indirect,
      search: searchValue,
      cveSeverityFactors: severityFactors,
      orderField,
      orderDirection,
    });

    if (data) {
      const { total, vulnerabilities, offset, severityFactorBreakdown } = data;
      setTotal(total);
      setVulnerabilities(vulnerabilities, input.update);
      setOffset(offset);
      setContextOptions(severityFactorBreakdown);
    }
  }
  setLoadingVulns(false);
};

export const searchVulnerabilities = debounce(
  (input: {
    selectedIssueId: Nullable<string>;
    getVulnerabilitiesFunction: (
      input: GetVulnerabiltiesInput,
    ) => Promise<VulnerabilitiesResponse | null>;
    search: string;
    context: string[];
    update?: boolean;
  }) => {
    setSearchValue(input.search);
    setSeverityFactors(input.context);
    fetchVulnerabilities({ ...input, update: true });
  },
  500,
);

export const sortVulnerabilities = (input: {
  selectedIssueId: Nullable<string>;
  getVulnerabilitiesFunction: (
    input: GetVulnerabiltiesInput,
  ) => Promise<VulnerabilitiesResponse | null>;
  field?: string;
  update?: boolean;
}) => {
  const executeSort = () => fetchVulnerabilities({ ...input, update: true });
  input.field
    ? sortByField(VulnerabilitiesStore, input.field, executeSort)
    : sortByDefault(VulnerabilitiesStore, executeSort);
};

const sortByDefault = (
  store: VulnerabilitiesStoreState,
  executeSort: () => void,
) => {
  store.orderField = undefined;
  store.orderDirection = undefined;

  executeSort();
};

const sortByField = (
  store: VulnerabilitiesStoreState,
  field: string,
  executeSort: () => void,
) => {
  store.orderField = field;
  store.orderDirection =
    store.orderDirection === Order.Desc ? Order.Asc : Order.Desc;
  executeSort();
};
