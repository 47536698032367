import React, { FC } from 'react';
import { List, useTheme } from '@mui/material';
import ApiCallstackItem from './ApiCallstackItem';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const CodeLocationList: FC<React.PropsWithChildren<CodeLocationListProps>> = ({
  callBranch,
  language,
  snippet,
  snippetLineNumber,
  withDescriptions,
  withNesting,
}) => {
  const theme = useTheme();
  return (
    <List
      sx={{
        border: '1px solid #ddd',
        borderRadius: '4px',
        overflow: 'hidden',
        p: 0,
      }}
      component='nav'
      aria-label='callstack'>
      {callBranch?.map((callEntry, callIndex) => (
        <ApiCallstackItem
          key={callIndex}
          itemIndex={withNesting ? callIndex : 0}
          lastItem={callIndex === callBranch.length - 1}
          entry={parseCallStackEntry(callEntry)}
          isDivider={callIndex < callBranch.length - 1}
          withDescriptions={withDescriptions}
        />
      ))}
      {snippet && (
        <SyntaxHighlighter
          language={language}
          style={theme.palette.mode === 'dark' ? vs : docco}
          startingLineNumber={snippetLineNumber ? +snippetLineNumber : 1}
          showLineNumbers>
          {snippet}
        </SyntaxHighlighter>
      )}
    </List>
  );
};

const parseCallStackEntry = (entry: string) => {
  const hasMethod = entry.includes('@');
  const [methodWithFile, line] = entry.split('#');
  const [method, file] = hasMethod
    ? methodWithFile.split('@')
    : ['', methodWithFile];
  return { method, file, line };
};

export interface CodeLocationListProps {
  callBranch?: string[];
  snippet?: string;
  language?: string;
  snippetLineNumber?: number;
  withDescriptions?: boolean;
  withNesting?: boolean;
}

export default CodeLocationList;
