import { AppEvents } from 'common-events';
import { DashboardResponse } from '../../dashboard/dashboard-types';
import {
  handleSearchConnector,
  loadConnectorsByFamily,
} from '../actions/connector-actions';
import {
  handleBitbucketAppInstallationCallback,
  handleGitHubAppInstallationCallback,
  handleIdpCallback,
  postSuccessAction,
} from '../actions/credentials-actions';
import { CredentialsConfigureResponse } from '../connectors-types';
import {
  setNumberOfScans,
  setScanType,
} from '../store-actions/connectors-store-actions';
import { Location } from 'history';

export const setupConnectorsListeners = () => {
  document.addEventListener(
    AppEvents.Connectors.ConnectorConfigured,
    (
      event: CustomEventInit<{
        configureResponse: CredentialsConfigureResponse;
        enableMultiTokens: boolean;
      }>,
    ) => {
      if (event.detail) {
        postSuccessAction(
          event.detail.configureResponse,
          event.detail.enableMultiTokens,
        );
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.IdentityProviderCallback,
    (
      event: CustomEventInit<{
        searchParams: URLSearchParams;
        enableMultiTokens: boolean;
      }>,
    ) => {
      if (event.detail) {
        handleIdpCallback(
          event.detail.searchParams,
          event.detail.enableMultiTokens,
        );
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.GitHubAppInstallationCallback,
    (
      event: CustomEventInit<{
        location: Location;
        enableMultiTokens: boolean;
      }>,
    ) => {
      if (event.detail) {
        handleGitHubAppInstallationCallback(
          event.detail.location,
          event.detail.enableMultiTokens,
        );
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.BitbucketAppInstallationCallback,
    (
      event: CustomEventInit<{
        searchParams: URLSearchParams;
        enableMultiTokens: boolean;
      }>,
    ) => {
      if (event.detail) {
        handleBitbucketAppInstallationCallback(
          event.detail.searchParams,
          event.detail.enableMultiTokens,
        );
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.PlusMoreClicked,
    (event: CustomEventInit<string>) => {
      if (event.detail) {
        handleSearchConnector(event.detail);
      }
    },
  );
  document.addEventListener(
    AppEvents.Scan.ScanEnd,
    (event: CustomEventInit<{ enableMultiTokens: boolean }>) => {
      loadConnectorsByFamily(true, event.detail?.enableMultiTokens);
    },
  );
  document.addEventListener(AppEvents.DashboardInfo.Loaded, e => {
    const customEvent = e as CustomEvent;
    const { getOrgScanInfo: orgScanInfo } =
      customEvent.detail as DashboardResponse;
    const scanType = orgScanInfo ? orgScanInfo.scanType : undefined;
    const numberOfScans = orgScanInfo ? orgScanInfo.numberOfScans : undefined;
    setNumberOfScans(numberOfScans);
    setScanType(scanType);
  });
};
