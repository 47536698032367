import { ReactComponent as AzureIcon } from '../assets/icons/azure-icon.svg';
import { ReactComponent as BitbucketIcon } from '../assets/icons/bitBucket-icon.svg';
import { ReactComponent as GithubIcon } from '../assets/icons/github-icon.svg';
import { ReactComponent as GitlabIcon } from '../assets/icons/gitlab-onboarding-icon.svg';

import {
  CodeRepos,
  ControlObject,
  FlattenedItem,
  ResourceIcon,
  NodeTypes,
  RepoNode,
} from './onboarding-types';
import { PiGitBranchFill } from 'react-icons/pi';

export const codeRepoIconMap = new Map([
  [CodeRepos.AzureCode, AzureIcon],
  [CodeRepos.Bitbucket, BitbucketIcon],
  [CodeRepos.GitHub, GithubIcon],
  [CodeRepos.GitLab, GitlabIcon],
]);

export const itemTypeIconMap = new Map([
  [ResourceIcon.branch, PiGitBranchFill],
]);

export const AdvancedOptionsCheckboxesOptions = {
  [CodeRepos.GitHub]: [
    'repo',
    'read:packages',
    'write:org',
    'read:org',
    'read:repo_hook',
    'read:user',
    'user:email',
    'read:audit_log',
  ],
  [CodeRepos.GitLab]: ['api', 'read_user', 'read_registry'],
  [CodeRepos.AzureCode]: [
    'Auditing - Read Audit Log',
    'Build - Read',
    'Code - Full and Status',
    'Graph - Read and Manage',
    'Identity - Read and Manage',
    'Member Entitlement Management - Read and Write',
    'Packaging - Read',
    'Project and Team - Read, Write and Manage',
    'Release - Read',
    'Security - Manage',
    'User Profile - Read',
    'Wiki - Read',
    'Work Items - Read and Write',
  ],
  [CodeRepos.Bitbucket]: [
    'Account - Read and Write',
    'Workspace memberships - Read and Write',
    'Projects - Write and Admin',
    'Repositories - Write and Admin',
    'Pull requests - Read and Write',
    'Issues - Read and Write',
    'Wikis - Read and Write',
    'Snippets - Read',
    'Webhooks - Read and Write',
    'Pipelines - Read',
    'Runners - Read',
  ],
};

export const createFlattenList = (
  treeData: RepoNode[],
  controlObject: ControlObject,
  parents: string[],
  indeterminateState = {},
  toggleSingleSelection: (id: string, value: boolean) => void,
) => {
  return treeData.reduce((flattenedTree, node) => {
    const deepness = parents.length;
    let selectedChildrenCount = 0;
    let indeterminateChildrenCount = 0;
    node.children?.forEach(child => {
      selectedChildrenCount += controlObject[child.displayId]?.selected ? 1 : 0;
      indeterminateChildrenCount += indeterminateState[child.displayId] ? 1 : 0;
    });

    const isIndeterminate =
      indeterminateChildrenCount > 0 ||
      (selectedChildrenCount !== 0 &&
        selectedChildrenCount < node.children.length);

    const nodeWithHelpers = {
      ...node,
      deepness,
      parents,
      ...controlObject[node.displayId],
      isIndeterminate,
    };
    indeterminateState[node.displayId] = isIndeterminate;

    if (!node.children?.length || !controlObject[node.displayId]?.expanded) {
      return [...flattenedTree, nodeWithHelpers];
    }
    if (
      controlObject[node.displayId].selected !==
      (selectedChildrenCount === node.children.length)
    ) {
      toggleSingleSelection(
        node.displayId,
        selectedChildrenCount === node.children.length,
      );
    }

    return [
      ...flattenedTree,
      nodeWithHelpers,
      ...createFlattenList(
        node.children,
        controlObject,
        [...parents, node.id],
        indeterminateState,
        toggleSingleSelection,
      ),
    ];
  }, [] as FlattenedItem[]);
};

export const filterTreeData = (treeData: RepoNode[], searchString: string) => {
  const filterFunction = (node: RepoNode) => {
    return node.name.toLowerCase().includes(searchString.toLowerCase());
  };

  const filtered = treeData.reduce((acc: RepoNode[], node) => {
    if (filterFunction(node)) {
      acc.push(node);
    } else if (node.children?.length > 0) {
      const filteredChildren = filterTreeData([...node.children], searchString);
      if (filteredChildren.length > 0) {
        acc.push({ ...node, children: filteredChildren });
      }
    }
    return acc;
  }, []);
  return filtered;
};

export const selectRecursively = (
  id: string,
  value: boolean,
  children: RepoNode[],
  newObject: ControlObject,
) => {
  newObject[id] = {
    ...newObject[id],
    selected: value,
  };
  children?.forEach(node => {
    return selectRecursively(node.id, value, node.children, newObject);
  });
  return newObject;
};

export const createInputArray = (
  treeData: RepoNode[],
  controlObject: ControlObject,
) => {
  const res =
    treeData?.reduce((flattenedTree, node) => {
      const input = {
        id: node.id,
        resourceType: node.resourceType,
        name: node.name,
        isMonitored: !!controlObject[node.displayId].selected,
        ...(node.sourceType && { sourceType: node.sourceType }),
      };
      if (
        controlObject[node.displayId].selected &&
        !controlObject[node.displayId].disabled &&
        node.resourceType === NodeTypes.Edge
      ) {
        return [
          ...flattenedTree,
          input,
          ...createInputArray(node.children, controlObject),
        ];
      }

      return [
        ...flattenedTree,
        ...createInputArray(node.children, controlObject),
      ];
    }, [] as RepoNode[]) || [];

  return res as RepoNode[];
};
