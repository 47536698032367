import { AiOutlineInfoCircle } from 'react-icons/ai';
import { DiCss3, DiGo, DiRuby, DiScala } from 'react-icons/di';
import {
  FaHtml5,
  FaJava,
  FaLaptopCode,
  FaPhp,
  FaPython,
  FaRust,
} from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { ImSvg } from 'react-icons/im';
import { IoLogoJavascript } from 'react-icons/io';
import { RiMarkdownLine } from 'react-icons/ri';
import {
  SiCplusplus,
  SiCsharp,
  SiGnubash,
  SiTerraform,
  SiTypescript,
} from 'react-icons/si';
import { VscJson, VscTerminalPowershell } from 'react-icons/vsc';

export const resolveLanguageIcon = (language?: string) => {
  return language ? iconsMap.get(language) || FaLaptopCode : FaLaptopCode;
};

export const iconsMap = new Map([
  ['ini', AiOutlineInfoCircle],
  ['markdown', RiMarkdownLine],
  ['terraform', SiTerraform],
  ['shell', VscTerminalPowershell],
  ['scala', DiScala],
  ['rust', FaRust],
  ['ruby', DiRuby],
  ['python', FaPython],
  ['php', FaPhp],
  ['go', DiGo],
  ['c++', SiCplusplus],
  ['bash', SiGnubash],
  ['javascript', IoLogoJavascript],
  ['c#', SiCsharp],
  ['html', FaHtml5],
  ['css', DiCss3],
  ['typescript', SiTypescript],
  ['java', FaJava],
  ['svg', ImSvg],
  ['json', VscJson],
  ['text', FiFileText],
]);
