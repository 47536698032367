import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import query from './get-tickets-by-keys.gql';
import {
  GraphQLTypes,
  ResolverInputTypes,
} from '@oxappsec/ox-autogenerated-types-frontend-ticketing-service';
import { ApolloClientCache } from 'ox-common-types';
import { logger } from 'logging-utils';
import { ticketingStoreActions } from '../../store-actions/ticketing-store-actions';
export const getTicketsByKeys = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getTicketsByKeys: ResolverInputTypes['GetTicketByKeyInput'],
    ) => {
      try {
        const results = await client.query<GetTicketByKeyResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getTicketsByKeys },
        });
        return results?.data?.getTicketByKey?.tickets;
      } catch (error) {
        logger.error('Failed to get ticket by keys', error);
        ticketingStoreActions.setIsLoadingStatuses(false);
        return null;
      }
    },
  };
};

export interface GetTicketByKeyResponse {
  getTicketByKey: ResolverInputTypes['GetTicketsByKeysRes'];
}

export type TicketingTickets = GraphQLTypes['GetTicketsByKeysRes']['tickets'];
