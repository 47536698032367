import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setChosenOrganization } from '../../../../../organizations/store-actions/organization-store-actions';
import { dispatchChosenOrgUpdatedEvent } from '../../../../../organizations/dispatchers/organizations-dispatchers';
import { OrganizationsStore } from 'organizations/src/stores/organizations-store';
import { Organization } from 'common-auth/src/common-auth-types';
import { setLastLoggedInOrg } from '../../../../../organizations/utils/local-storage-utils';
import { useSnapshot } from 'valtio';

export const useOrgSwitchLoginWithRedirect = () => {
  const { loginWithRedirect } = useAuth0();
  const { organizations, chosenOrganization } = useSnapshot(OrganizationsStore);

  useEffect(() => {
    dispatchChosenOrgUpdatedEvent(chosenOrganization);
  }, [organizations, chosenOrganization]);

  const handleOrgLoginWithRedirect = useCallback(
    (
      organization: Organization,
      _pathname?: string,
      _queryParam?: string | null,
    ) => {
      const cleanPath = (pathname: string) =>
        pathname.split('/').filter(item => !!item);
      const splitedLocation = cleanPath(_pathname || window.location.pathname);
      const queryParam =
        _queryParam || (_queryParam === null ? null : window.location.search);

      if (splitedLocation[0]?.includes('org_')) {
        if (splitedLocation[0] !== organization.id) {
          splitedLocation[0] = organization.id;
        }
      } else if (organization?.id) {
        splitedLocation.unshift(organization.id);
      }

      let fullNextPath = splitedLocation.join('/');

      if (queryParam) {
        fullNextPath += queryParam;
      }
      setLastLoggedInOrg(organization);
      setChosenOrganization(organization);
      loginWithRedirect({
        organization: organization.id,
        appState: {
          returnTo: fullNextPath,
          key: organization.id,
        },
      });
    },
    [loginWithRedirect],
  );

  return {
    handleOrgLoginWithRedirect,
  };
};
