export const NUMBER_OS_ALLOWED_SCANS = 5;

export interface ScanResponse {
  scanID: string;
}

export interface ScanInProgressResponse extends ScanResponse {
  isInProgress: boolean;
  scanStage: ScanStage;
  isFullScan: boolean;
}

export enum ScanStage {
  None = 'None',
  Init = 'Init',
  Discovery = 'Discovery',
  Overview = 'Overview',
}

export interface ScanInput {
  connectorIDs: string[];
}

export interface CancelScanInput {
  scanId: string;
}

export interface CancelScanResponse {
  isCanceled: boolean;
  error: string;
  scanId: string;
  previousScanId: string;
}

export interface EnableScheduleScanResponse {
  setEnabledScheduledScan: boolean;
}

export enum VerificationText {
  CancelScan = 'cancel',
}

export interface ScanSettings {
  maxReposToScan: number;
  maxScansToRun: number;
  scanCount: number;
  isDemoEnabled: boolean;
}

export interface DiscoveryResponse {
  reportData: {
    total: number;
    scanned: number;
    progressEvent: {
      type: string;
      label: string;
    };
  };
  metadata: {
    realScanId: string;
  };
  isStillScanning: boolean;
}

export interface GradualUpdateResponse {
  totalApps: number;
  isStillScanning: boolean;
  scannedApps: number;
  scanProgressItems: ScanProgressItem[];
  phase: String;
}

export interface ScanProgressItem {
  phase: Phase;
  count: number;
  total: number;
}
export interface ScanAllInput {
  isDemo?: boolean;
  isFullScan?: boolean;
  isContainerFullScan?: boolean;
  isThirdPartyFullScan?: boolean;
}

export interface ScanOutput {
  scanID: string;
  isFullScan: boolean;
  realScanId: string;
}

export enum Phase {
  Repo = 'repo',
  Artifact = 'artifact',
  Cloud = 'cloud',
  ExternalTools = 'externalTools',
}
