import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import {
  SLACK_API_GET_CHANNELS_LIMIT,
  getAndSetPublicChannels,
  setChannels,
  setDisableTypingOnFirstRequest,
} from 'slack-logic';
import { snapshot } from 'valtio';
import ScanStore from '../../../new-scan/store/scan-store';
import IssuesStore from '../../active-issues/stores/issues-store';
import { default as SlackChannelRes } from '../mocks/slack-channels.json';
import { IssuePages } from '../types/issues-types';
import { getIssueActionsByIssuesPage } from '../utils/slack-message-utils';
import ConnectorsStore from '../../../connectors/stores/connectors-store';

export const openSendSlackMessageDialog = async (issuesPage: IssuePages) => {
  const { selectedIssue } = snapshot(IssuesStore);
  const { isActiveSlackConnection } = snapshot(ConnectorsStore);

  const { showSlackDialog } = getIssueActionsByIssuesPage(issuesPage);

  showSlackDialog(true);
  setDisableTypingOnFirstRequest(true);
  if (!isNull(selectedIssue) && isActiveSlackConnection) {
    try {
      const { isDemoEnabled } = snapshot(ScanStore);
      if (isDemoEnabled) {
        setChannels(SlackChannelRes.data.getSlackChannelsList);
        return;
      }
      await getAndSetPublicChannels(
        {
          limit: SLACK_API_GET_CHANNELS_LIMIT,
          search: '',
          channelsInput: { appId: selectedIssue.app.id },
        },
        false,
        null,
      );
      setDisableTypingOnFirstRequest(false);
    } catch (e) {
      logger.error(e);
      showSlackDialog(false);
    }
  }
};
