import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  Branch,
  BranchesPerConnector,
  ResourceAppItem,
} from '../../../connectors-types';
import SettingsStore from '../../../../settings/stores/settings-store';
import { SettingsSubType } from '../../../../settings/types/settings-types';
import { getSettingsBySubType } from '../../../../settings/utils/settings-utils';
const baseStore = createStore<MultiBranchSelectionStoreState>(
  {
    selectedApp: null,
    searchValue: '',
    isLoadingSave: false,
    isLoadingBranches: false,
    branchesToScanPerConnector: {},
    branchesToScanModalState: [],
    errorMessageLoadingBranches: null,
  },
  'Multi Branch Selection Store',
);

export const MultiBranchSelectionStore = derive(
  {
    filteredBranchesToScan: (get): Branch[] => {
      const { branchesToScanModalState, searchValue } = get(baseStore);
      const { orgSettingsMapByName } = get(SettingsStore);
      const globalSettingsMultiBranchSettings = getSettingsBySubType(
        orgSettingsMapByName,
        SettingsSubType.DefineMultiBranchList,
      );
      const globalSettingsMultiBranchList =
        globalSettingsMultiBranchSettings?.valueList;
      const searchValueLower = searchValue.toLowerCase();

      return branchesToScanModalState?.filter(branch => {
        branch.isSelectedInGlobalSettings =
          globalSettingsMultiBranchList?.includes(branch.name);
        return (
          branch.isMonitored ||
          !searchValueLower ||
          branch.name.toLowerCase().includes(searchValueLower)
        );
      });
    },
    selectedBranches: (get): Branch[] => {
      const { branchesToScanModalState } = get(baseStore);
      return (
        branchesToScanModalState?.filter(branch => branch.isMonitored) || []
      );
    },
  },
  { proxy: baseStore },
);

interface MultiBranchSelectionStoreState {
  selectedApp: Nullable<ResourceAppItem>;
  searchValue: string;
  branchesToScanPerConnector: BranchesPerConnector;
  isLoadingSave: boolean;
  isLoadingBranches: boolean;
  errorMessageLoadingBranches: string | null;
  branchesToScanModalState: Branch[];
}
