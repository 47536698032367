import { CredentialsType } from './connector-configure-types';

export const CREDENTIAL_NAME_NUM_COUNT_DIGITS = 3;
export const CREDENTIAL_NAME_MAX_LENGTH = 200;
export const COUNT_CREDENTIALS_PER_TYPE = 50;
export const SUPPORTED_MULTI_CREDENTIALS_TYPES = [
  CredentialsType.Token,
  CredentialsType.GitHubApp,
  CredentialsType.IdentityProvider,
  CredentialsType.BitbucketApp,
  CredentialsType.UserPassword,
  CredentialsType.UserPasswordOnly,
  CredentialsType.ServicePrincipal,
  CredentialsType.AWSAssumeRoleCloudFormation,
  CredentialsType.AWSOrganizationConnection,
  CredentialsType.AWSAssumeRole,
  CredentialsType.AWSAssumeRoleCloudFormationOnprem,
  CredentialsType.AWSOrganizationConnectionOnprem,
  CredentialsType.AWSAssumeRoleOnprem,
  CredentialsType.AWSAssumeRoleCodeCommit,
  CredentialsType.AWSAssumeRoleCodeCommitOrganization,
  CredentialsType.AWSAssumeRoleCodeCommitOnprem,
  CredentialsType.AWSAssumeRoleCodeCommitOrganizationOnprem,
  CredentialsType.TokenAndProjectId,
  CredentialsType.AzureCloud,
];
