import { Nullable } from 'ox-common-types';
import { TicketingStore } from '../store/ticketing-store';
import { TicketingAction, TicketingVendor } from '../types/ticketing-types';
import { SettingsModel } from '../../ticketing-settings/types';

const setShowCreateTicketModal = (isOpen: boolean) => {
  TicketingStore.showCreateTicketModal = isOpen;
};

const setShowCreateBulkTicketModal = (isOpen: boolean) => {
  TicketingStore.showCreateBulkTicketModal = isOpen;
};
const setChosenTicketingVendor = (provider: Nullable<TicketingVendor>) => {
  TicketingStore.chosenTicketingVendor = provider;
};

const setShowLinkUnlinkTicketModal = (isOpen: boolean) => {
  TicketingStore.showLinkUnlinkTicketModal = isOpen;
};

const setSelectedAction = (action: Nullable<TicketingAction>) => {
  TicketingStore.selectedAction = action;
};

const setIsLoadingSubmitAction = (isLoading: boolean) => {
  TicketingStore.isLoadingSubmitAction = isLoading;
};

const setTicketKeyInputControl = (value: string) => {
  TicketingStore.ticketKeyInputControl = value;
};

const setIsLoadingStatuses = (isLoading: boolean) => {
  TicketingStore.isLoadingStatuses = isLoading;
};

const setIsLoadingTicketingStatus = (isLoading: boolean) => {
  TicketingStore.isLoadingTicketingStatus = isLoading;
};

export const setTicketingSettings = (settings: SettingsModel[]) => {
  TicketingStore.settings = settings;
};

export const ticketingStoreActions = {
  setShowCreateTicketModal,
  setShowCreateBulkTicketModal,
  setShowLinkUnlinkTicketModal,
  setChosenTicketingVendor,
  setSelectedAction,
  setIsLoadingSubmitAction,
  setTicketKeyInputControl,
  setIsLoadingStatuses,
  setIsLoadingTicketingStatus,
};
