export const splitLongNames = (name: string, width: number) => {
  if (!name) {
    return '';
  }
  const removeSpaces = name.replace(' / ', '/');
  const splittedNameSlash = removeSpaces.split('/');
  if (name.length < width) {
    return name;
  } else {
    if (splittedNameSlash.length === 2) {
      return '.../' + splittedNameSlash[1];
    } else if (splittedNameSlash.length > 2) {
      if (
        splittedNameSlash[splittedNameSlash.length - 2].length +
          splittedNameSlash[splittedNameSlash.length - 1].length <
        width
      ) {
        return (
          '.../' +
          splittedNameSlash[splittedNameSlash.length - 2] +
          '/' +
          splittedNameSlash[splittedNameSlash.length - 1]
        );
      } else if (
        splittedNameSlash[splittedNameSlash.length - 2].length < width &&
        splittedNameSlash[splittedNameSlash.length - 1].length > width
      ) {
        return (
          '.../' + splittedNameSlash[splittedNameSlash.length - 2] + '/...'
        );
      } else {
        return '.../' + splittedNameSlash[splittedNameSlash.length - 1];
      }
    }
    return name;
  }
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const processText = (text: string, limit: number): string => {
  //const cleanedText = text.replace(/\s*\/\s*/g, '/');

  if (text.length <= limit) {
    return text;
  }

  const parts = text.split('/');
  let result = parts[parts.length - 1];

  for (let i = parts.length - 2; i >= 0; i--) {
    const newResult = parts[i] + '/' + result;
    if (newResult.length <= limit) {
      result = newResult;
    } else if (result.length < limit) {
      result = '.../' + result;
      return result;
    } else {
      result = '...' + result.slice(-(limit - 4));
      return result;
    }
  }

  if (result.length > limit) {
    result = '...' + result.slice(-(limit - 3));
  }

  return result;
};

export const splitByCapitalLetters = (text: string) => {
  return text.replace(/([A-Z])/g, ' $1').trim();
};
