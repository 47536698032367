import { formatDistanceToNow } from 'date-fns';
import { logger } from 'logging-utils';
import { CustomLink } from 'ox-react-components';
import { AttackPathType } from '../attack-path-types';
import {
  extractNameAndEmail,
  getAttackPathCloudIdentifier,
  getAttackPathLinkIdentifier,
  resolveSaasCategory,
} from '../utils/text-utils';

import pluralize from 'pluralize';
import { ReactElement } from 'react';
import { frameworkNamesMap } from '../../api-inventory/api-inventory-utils';

export const textConfig: {
  [key: string]: {
    title?: (metadata) => string | undefined | ReactElement;
    description?: (metadata) => string | undefined | ReactElement;
    fontSize?: number;
    width?: number;
    textBackground?: string;
  };
} = {
  [AttackPathType.ApplicationLoadBalancer]: {
    description: () => 'Load Balancer',
    title: metadata =>
      metadata.name.length > 20
        ? metadata.name?.slice(0, 20) + '...'
        : metadata.name,
    width: 125,
    fontSize: 8,
  },
  [AttackPathType.CloudLoadBalancer]: {
    description: () => 'Load Balancer',
    title: metadata =>
      metadata.name.length > 20
        ? metadata.name?.slice(0, 20) + '...'
        : metadata.name,
    width: 125,
    fontSize: 8,
  },
  [AttackPathType.CloudNetworkLoadBalancer]: {
    description: () => 'Load Balancer',
    title: metadata =>
      metadata.name.length > 20
        ? metadata.name?.slice(0, 20) + '...'
        : metadata.name,
    width: 125,
    fontSize: 8,
  },
  [AttackPathType.ImageId]: {
    description: metadata => 'Image',
    width: 125,
    title: metadata => metadata.imageId?.split('/')?.pop() || metadata.imageId,
  },
  [AttackPathType.Image]: {
    description: metadata => metadata.registryType,
    title: metadata => metadata.name?.split('/')?.pop() || metadata.name,
    width: 200,
  },
  [AttackPathType.ImageArtifact]: {
    title: metadata => 'Link to Artifact',

    width: 125,
  },
  [AttackPathType.K8sIngress]: {
    description: metadata => 'Ingress',
    title: metadata => metadata.name,
    textBackground: 'transparent',
    width: 75,
    fontSize: 8,
  },
  [AttackPathType.K8sLoadBalancer]: {
    description: metadata => 'Load Balancer',
    title: metadata =>
      metadata.name.length > 20
        ? metadata.name?.slice(0, 20) + '...'
        : metadata.name,
    width: 75,
    textBackground: 'transparent',
    fontSize: 8,
  },

  [AttackPathType.K8sApplication]: {
    description: metadata => 'Workfload',
    title: metadata => metadata.name,
    width: 75,
    textBackground: 'transparent',
    fontSize: 8,
  },
  [AttackPathType.ApiSource]: {
    description: metadata => 'Source',
    title: metadata => metadata.source,
  },
  [AttackPathType.ApiCount]: {
    description: metadata => 'Number of APIs',
    title: metadata => `${pluralize('API', metadata.count, true)}`,
  },
  [AttackPathType.CloudDns]: {
    description: metadata => 'DNS Name',
    title: metadata => metadata.dns,
  },
  [AttackPathType.CloudRegion]: {
    description: metadata => 'Region Name',
    title: metadata => metadata.name,
  },
  [AttackPathType.CloudLink]: {
    title: metadata => getAttackPathLinkIdentifier(metadata?.cloudType),
    width: 100,
  },
  [AttackPathType.Cloud]: {
    description: metadata => getAttackPathCloudIdentifier(metadata?.cloudType),
    title: metadata => metadata.accountId,
    width: 150,
  },
  [AttackPathType.Internet]: {
    title: () => 'Internet',
  },
  [AttackPathType.K8s]: {
    title: metadata => metadata.name,
    width: 125,
    description: metadata => 'K8s Cluster',
  },
  [AttackPathType.K8sLink]: {
    width: 125,
    title: () => 'Link to Cluster',
  },
  [AttackPathType.K8sImageId]: {
    title: metadata =>
      metadata.imageIds?.length && metadata.imageIds?.length === 1
        ? `${metadata.imageIds[0]?.imageId.split('/')?.pop()} ${
            metadata.imageIds[0]?.tag || ''
          }`
        : `${pluralize('Image', metadata.imageIds?.length, true)}`,
    width: 125,
    description: metadata => 'Image',
  },
  [AttackPathType.ApiFunction]: {
    title: metadata =>
      metadata.functions.length === 1 ? (
        <CustomLink
          value={metadata.functions[0].function}
          href={metadata.functions[0].link}
        />
      ) : (
        pluralize('Function', metadata.functions.length, true)
      ),
    description: metadata => 'Function',
  },
  [AttackPathType.ApiFramework]: {
    title: metadata =>
      frameworkNamesMap[metadata.framework] ?? metadata.framework,
    description: metadata => 'Framework',
  },
  [AttackPathType.AppLanguage]: {
    title: metadata => metadata?.language?.language,
    description: metadata => 'Language',
  },
  [AttackPathType.AppLink]: { title: metadata => 'Link to App' },

  [AttackPathType.Root]: {
    title: metadata => `${metadata.categoryName} Issue`,
    fontSize: 16,
    width: 175,
  },
  [AttackPathType.IssueOwner]: {
    title: metadata => metadata.name,
    description: metadata => 'Issue Owner',
  },
  [AttackPathType.Category]: { title: metadata => metadata.name },
  [AttackPathType.Language]: { title: metadata => 'Language' },
  [AttackPathType.Oscar]: { title: metadata => 'OSC&R' },
  [AttackPathType.OscarItem]: { title: metadata => metadata.id },
  [AttackPathType.Cwe]: { title: metadata => 'CWE' },
  [AttackPathType.CweItem]: { title: metadata => metadata.id },
  [AttackPathType.Compliance]: { title: metadata => 'Compliance' },
  [AttackPathType.ComplianceItem]: { title: metadata => metadata.standard },
  [AttackPathType.SeverityFactor]: {
    title: metadata => 'Severity Factors',
    fontSize: 16,
    width: 100,
  },
  [AttackPathType.SeverityFactorChange]: {
    title: metadata => metadata.shortName,
    fontSize: 16,
    width: 100,
  },
  [AttackPathType.SeverityFactorCategory]: {
    title: metadata => metadata.changeCategory,
    fontSize: 16,
  },
  [AttackPathType.Commit]: { title: metadata => 'Commits' },
  [AttackPathType.CommitItem]: {
    title: metadata =>
      metadata.single ? (
        <CustomLink value={'Commit'} href={metadata.link} />
      ) : (
        <CustomLink
          value={`Commit ${metadata.order + 1}`}
          href={metadata.link}
        />
      ),
  },
  [AttackPathType.CommitItemCommitBy]: {
    title: metadata => {
      const { name, email } = extractNameAndEmail(metadata.commitBy);
      return name || email;
    },
  },
  [AttackPathType.User]: { title: metadata => 'Users' },
  [AttackPathType.UserItem]: { title: metadata => metadata.user },
  [AttackPathType.Repos]: { title: metadata => 'Repos' },
  [AttackPathType.ReposItem]: {
    title: metadata => {
      const repos = metadata.destinationRepoName?.split('/');
      return repos[repos.length - 1];
    },
  },
  [AttackPathType.Service]: { title: metadata => 'Services' },
  [AttackPathType.ServiceItem]: { title: metadata => metadata.service },
  [AttackPathType.Webhook]: { title: metadata => 'Webhooks' },
  [AttackPathType.SBOMRepos]: { title: metadata => 'Repos' },
  [AttackPathType.SBOMReposItem]: {
    title: metadata => {
      const repos = metadata.repo?.split('/');
      return repos[repos.length - 1];
    },
  },
  [AttackPathType.Artifact]: { title: metadata => 'Artifacts' },
  [AttackPathType.ArtifactItem]: {
    title: metadata => {
      const name = metadata.image.split('/');
      return name[name.length - 1];
    },
    description: metadata => metadata.registryName,
  },

  [AttackPathType.CommitItemCommitDate]: {
    title: metadata => {
      try {
        return formatDistanceToNow(new Date(metadata.date), {
          addSuffix: true,
        });
      } catch (e) {
        logger.warn('Wrong date', e);
      }
    },
  },
  [AttackPathType.CommitItemCommitReviewedBy]: {
    title: metadata => {
      const { name, email } = extractNameAndEmail(metadata.commitBy);
      return name || email;
    },
  },
  [AttackPathType.CommitItemFile]: {
    title: metadata => {
      const name = metadata.fileName.split('/');
      return name[name.length - 1];
    },
  },
  [AttackPathType.Library]: {
    title: metadata => metadata.libraryName,
    description: metadata => 'Library',
  },

  [AttackPathType.Action]: { title: () => 'Actions' },

  [AttackPathType.SlackItem]: {
    title: metadata => `#${metadata.channelName}`,
    fontSize: 8,
    description: metadata => {
      try {
        return `Sent ${formatDistanceToNow(
          new Date(parseInt(metadata.timestamp || 0)),
          {
            addSuffix: true,
          },
        )}`;
      } catch (e) {
        logger.warn('Failed to parse slack date', e);
      }
    },
  },

  [AttackPathType.JiraTicketItem]: {
    title: metadata => (
      <CustomLink
        fontSize={8}
        linkSize={5}
        gap={0.5}
        value={metadata.key}
        href={metadata.self}
      />
    ),
    fontSize: 8,
    description: metadata => metadata.status,
  },

  [AttackPathType.PrItem]: {
    title: metadata => (
      <CustomLink
        fontSize={8}
        linkSize={5}
        gap={0.5}
        value={`PR #${metadata.prId} [${metadata.prStatus}]`}
        href={metadata.prURL}
      />
    ),
  },
  [AttackPathType.App]: {
    title: metadata => metadata.name,
    description: metadata => 'Application',
    fontSize: 16,
    width: 150,
  },
  [AttackPathType.BusinessPriority]: {
    title: metadata => 'Business Priority',
  },

  [AttackPathType.Cve]: { title: metadata => 'Vulnerabilities' },
  [AttackPathType.CveItem]: { title: metadata => metadata.id },
  [AttackPathType.Tag]: { title: metadata => 'Process Data' },
  [AttackPathType.Detection]: {
    title: metadata => 'Detection & Response',
    fontSize: 16,
  },
  [AttackPathType.Intelligence]: {
    title: metadata => 'Intelligence',
    fontSize: 16,
  },
  [AttackPathType.FirstSeen]: {
    title: metadata =>
      metadata.date &&
      formatDistanceToNow(new Date(metadata.date), { addSuffix: true }),
    description: metadata => 'First Seen',
  },
  [AttackPathType.Saas]: {
    title: metadata => metadata.displayName,
    width: 125,
    description: ({ purpose }) => {
      return resolveSaasCategory(purpose);
    },
  },
  [AttackPathType.Api]: {
    title: metadata => metadata.methodName,
    width: 150,
    description: metadata => (metadata.total ? 'APIs' : 'API - Issue Exposed'),
  },
  [AttackPathType.ApiFileName]: {
    title: metadata => metadata.fileName,
    width: 125,
    description: metadata => 'File',
  },
  [AttackPathType.ApiLink]: {
    title: () => 'Link to API BOM',
  },
};
