import { Box, CircularProgress, Theme, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { makeStyles } from 'tss-react/mui';

export const AppSpinner: FunctionComponent<
  React.PropsWithChildren<AppSpinnerProps>
> = ({ text, size, noText, disablePositionAbsolute }) => {
  const { classes } = useStyles();
  const spinnerText = text ? text : 'Loading';

  return (
    <Box
      data-testid='app-loader'
      display='flex'
      alignItems='center'
      justifyContent='center'
      className={disablePositionAbsolute ? '' : classes.appLoader}>
      <Box textAlign='center'>
        <CircularProgress color='primary' size={size || 40} />
        {!noText && (
          <Typography
            variant='body2'
            color='textPrimary'
            className={classes.title}>
            {spinnerText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  appLoader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.modal,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export interface AppSpinnerProps {
  text?: string;
  size?: number | string;
  noText?: boolean;
  disablePositionAbsolute?: boolean;
}
