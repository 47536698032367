import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import query from './get-priorities.gql';
import { ApolloClientCache } from 'ox-common-types';
import { logger } from 'logging-utils';
import {
  GetPrioritiesInput,
  GetPrioritiesRes,
} from '../../../ticketing-settings/types';

export const getPriorities = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getPrioritiesInput: GetPrioritiesInput) => {
      try {
        const results = await client.query<GetPrioritiesResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getPrioritiesInput },
        });
        if (!(results?.data?.getPriorities?.priorities?.length > 0)) {
          throw new Error('Failed to fetch priorities');
        }
        return results.data.getPriorities.priorities;
      } catch (error) {
        logger.error('Failed to get priorities', error);
        throw new Error('Failed to fetch priorities');
      }
    },
  };
};

export interface GetPrioritiesResponse {
  getPriorities: GetPrioritiesRes;
}
