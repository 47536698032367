import { createStore, derive } from 'store-utils';
import { TicketFieldConfig } from '../types/ticketing-types';
import { KeyStringGenericObject, Nullable } from 'ox-common-types';

const InitialCreateTicketStore = createStore<CreateTicketStoreState>(
  {
    inputControlObject: {},
    inputSelectedControlObject: {},
    customFieldsControlObject: {},
    inputOptions: {},
    fieldsConfig: [],
    customFieldsConfig: [],
    isLoadingOptions: {},
    isLoadingInputFields: false,
    isLoadingCustomFields: false,
    isErrorLoadingInputFields: false,
  },
  'Create Ticket Store',
);

const baseStore = createStore<CreateTicketStoreState>(
  {
    ...InitialCreateTicketStore,
  },
  'Create ticket Store',
);

export const CreateTicketStore = derive({}, { proxy: baseStore });

export interface CreateTicketStoreState {
  inputControlObject: KeyStringGenericObject<Nullable<string>>;
  inputSelectedControlObject: KeyStringGenericObject<
    Nullable<string | InputOption | (string | InputOption)[]>
  >;
  inputOptions: KeyStringGenericObject<InputOption[]>;
  fieldsConfig: TicketFieldConfig[];
  customFieldsConfig: TicketFieldConfig[];
  isLoadingInputFields: boolean;
  isLoadingCustomFields: boolean;
  isLoadingOptions: KeyStringGenericObject<boolean>;
  isErrorLoadingInputFields: boolean;
  customFieldsControlObject: KeyStringGenericObject<Nullable<InputOption>>;
}

export interface InputOption {
  id: string;
  name: string;
}
