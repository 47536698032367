export const pathBuilder = (paths: Array<string>): string => {
  const result: Array<string> = [];

  paths.forEach((item: string) => {
    item.split('/').forEach((splitedItem: string) => {
      const trim = splitedItem?.trim();
      if (trim) {
        result.push(trim);
      }
    });
  });

  let fullPath = result.join('/');
  if (fullPath?.charAt(0) !== '/') {
    fullPath = '/' + fullPath;
  }
  return fullPath;
};
