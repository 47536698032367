import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { ControlObject, NodeTypes, RepoNode } from '../onboarding-types';

const baseStore = createStore<OnboardingRepoSelectionStoreState>(
  {
    repoTreeData: null,
    filteredRepoTreeData: null,
    wereChangesMade: false,
    isChooseAllSelected: true,
    searchValue: '',
    expandedNodes: [],
    isLoading: false,
    monitorAllNewlyCreatedResources: true,
    monitorAllNewlyCreatedResourcesByCredentials: {},
    controlObject: {},
    controlObjectByCredentials: {},
    originalControlState: {},
  },
  'Onboarding Repo Selection Store',
);

const RepoSelectionStore = derive(
  {
    checkedReposUtils: get => {
      const { controlObject } = get(baseStore);
      let checked = 0;
      let all = 0;
      let edgesChecked = 0;
      let allEdges = 0;
      Object.values(controlObject).forEach(obj => {
        all += 1;
        allEdges += obj.nodeType === NodeTypes.Edge ? 1 : 0;
        if (obj.selected) {
          checked += 1;
          edgesChecked += obj.nodeType === NodeTypes.Edge ? 1 : 0;
        }
      });

      baseStore.isChooseAllSelected = checked === all;

      return {
        checkedReposNumber: edgesChecked,
        isContinueDisabled: checked === 0,
        reposFound: allEdges,
      };
    },
  },
  { proxy: baseStore },
);

export const RepoSelectionCacheStore =
  createStore<RepoSelectionCacheStoreState>({}, 'Repo Selection Cache Store');

interface RepoSelectionCacheStoreState {
  [connectorId: string]: typeof RepoSelectionStore & {
    saveControlObj?: ControlObject;
  };
}

interface OnboardingRepoSelectionStoreState {
  repoTreeData: Nullable<RepoNode[]>;
  filteredRepoTreeData: Nullable<RepoNode[]>;
  isChooseAllSelected: boolean;
  searchValue: string;
  expandedNodes: string[];
  isLoading: boolean;
  monitorAllNewlyCreatedResources: boolean;
  monitorAllNewlyCreatedResourcesByCredentials: {
    [credentialsId: string]: boolean;
  };
  controlObject: ControlObject;
  controlObjectByCredentials: { [credentialsId: string]: ControlObject };
  wereChangesMade: boolean;
  originalControlState: ControlObject;
}

export default RepoSelectionStore;
