import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import query from './get-applications-pdf.gql';

const getApplicationsPDF = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: GenerateAppsPdfInput,
    ): Promise<Nullable<GenerateAppsPdfResponse['generateAppsPdf']>> => {
      try {
        const results = await client.query<GenerateAppsPdfResponse>({
          query,
          variables: { ...input },
          fetchPolicy: 'no-cache',
        });

        if (
          !results.data ||
          !results.data.generateAppsPdf ||
          !results.data.generateAppsPdf.requestId
        ) {
          return null;
        }

        return results.data.generateAppsPdf;
      } catch (error) {
        logger.error(`failed to generate apps PDF error:`, error);
        return null;
      }
    },
  };
};

interface GenerateAppsPdfResponse {
  generateAppsPdf: {
    requestId: string;
  };
}

interface GenerateAppsPdfInput {
  scanId: string;
  filters?: {};
  owners?: string[];
  tagIds?: string[];
}

export default getApplicationsPDF;
