import { Nullable } from 'ox-common-types';
import RequestAccessStore from '../stores/request-access-store';
import { UserRequestAccess } from '../types/members-types';
import { enrichPendingRequests } from '../utils/members-utils';

export const setRequestAccessInStore = (
  pendingRequests: UserRequestAccess[],
) => {
  RequestAccessStore.pendingRequests = enrichPendingRequests(pendingRequests);
};

export const setSelectedPendingRequest = (
  userRequestAccess: Nullable<UserRequestAccess>,
): void => {
  RequestAccessStore.selectedUserRequestAccess = userRequestAccess;
};

export const openDismissRequestAccessModal = (): void => {
  setModalVisibility(true);
};

export const closeDismissRequestAccessModal = (): void => {
  setModalVisibility(false);
};

const setModalVisibility = (payload: boolean): void => {
  RequestAccessStore.isModalVisible = payload;
};

export const setIsLoading = (payload: boolean): void => {
  RequestAccessStore.isLoading = payload;
};
