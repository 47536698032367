import { memo, useCallback, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { Button, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { useNodeTooltip } from '../utils/tooltip-utils';
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci';
import { makeStyles } from 'tss-react/mui';
import { Box, Theme } from '@mui/system';
import { toggleBranch } from '../actions/issue-graph-actions';
import { useSpring, animated } from 'react-spring';
import { useNode } from '../hooks/useNode';
import { useNodeText } from '../hooks/useNodeText';
import NodeTooltipAwayListener from './NodeTooltipAwayListener';
import { stopPropagation } from 'common-utils';
import useCommonGraphStore from '../stores/common-graph-store';
import { onAttackNodeClick } from '../utils/attack-path-utils';
import { FaExpand } from 'react-icons/fa';
import { FaShareNodes } from 'react-icons/fa6';
export default memo((props: NodeProps) => {
  const { data, id, isConnectable } = props;
  const {
    Icon,
    size,
    backgroundColor,
    iconColor,
    onClick,
    borderColor,
    badge,
    withPulse,
    withBadgePulse,
    iconScale,
  } = useNode(data.type, data);

  const theme = useTheme();
  const { title, description, fontSize, width, textBackground } = useNodeText(
    data.type,
    data.metadata,
  );

  const { collapsedBranches, highlightedNodes } = useCommonGraphStore();
  const grayedOut = highlightedNodes?.length
    ? !highlightedNodes.includes(id)
    : false;
  const { cx, classes } = useStyles();
  const style = useSpring({
    from: { opacity: 0, transform: 'scale(0)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { duration: 500 },
  });

  const onExpandClick = useCallback(() => {
    toggleBranch(id, data.metadata.graphPart);
  }, [id, data.metadata.graphPart]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const closeTooltip = useCallback(() => setTooltipOpen(false), []);
  const openTooltip = useCallback(() => setTooltipOpen(true), []);
  const tooltip = useNodeTooltip(data.type, data.metadata, closeTooltip);
  const onIconClick = useCallback(
    event => {
      onClick && onClick();
      setTooltipOpen(prev => !prev);
      stopPropagation(event);
    },
    [onClick],
  );
  return (
    <div style={{ position: 'relative', opacity: grayedOut ? 0.1 : 1 }}>
      <animated.div style={style}>
        <Handle
          type='target'
          position={Position.Left}
          style={{ visibility: 'hidden' }}
          isConnectable={isConnectable}
        />

        {data.childrenCount ? (
          <IconButton
            size='small'
            onClick={onExpandClick}
            className={classes.expandIcon}>
            {collapsedBranches[id] ? <CiSquarePlus /> : <CiSquareMinus />}
          </IconButton>
        ) : null}
        {data.grouped && (
          <Button
            className={classes.expandButton}
            startIcon={
              <FaExpand fontSize={10} size={10} width={10} height={10} />
            }
            size='small'
            variant='outlined'
            onClick={() => onAttackNodeClick(props)}
            style={{}}>
            <Typography sx={{ mt: 0.25 }} lineHeight={1} fontSize={8}>
              Expand
            </Typography>
          </Button>
        )}
        <NodeTooltipAwayListener
          open={tooltipOpen}
          tooltip={tooltip}
          closeTooltip={closeTooltip}
          openTooltip={openTooltip}>
          <Box>
            {data.topbarText && (
              <Button
                onClick={onIconClick}
                className={classes.expandButton}
                startIcon={
                  <FaShareNodes
                    fontSize={10}
                    size={10}
                    width={10}
                    height={10}
                    color={data.metadata.exposeIssue ? '#f58535' : '#c3c3c8'}
                  />
                }
                size='small'
                variant='outlined'
                style={{
                  borderColor: data.metadata.exposeIssue
                    ? '#f58535'
                    : '#c3c3c8',
                }}>
                <Typography
                  color={data.metadata.exposeIssue ? '#f58535' : '#c3c3c8'}
                  sx={{ mt: 0.25 }}
                  lineHeight={1}
                  fontSize={8}>
                  {data.topbarText}
                </Typography>
              </Button>
            )}
            <IconButton
              size='small'
              sx={{
                pointerEvents: tooltip || onClick ? 'auto' : 'none',
              }}
              onClick={onIconClick}>
              <Paper
                style={{
                  width: size,
                  height: size,
                  ...(borderColor && {
                    padding: '5%',
                    border: `2px solid ${borderColor}`,
                  }),
                }}
                className={cx(classes.iconContainer)}>
                <div
                  style={{
                    backgroundColor,

                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    position: 'relative',
                    borderRadius: '50%',
                    borderColor: 'red',
                  }}>
                  <div style={{ display: 'inline-flex' }}>
                    {Icon && (
                      <Icon
                        className={cx(withPulse && classes.withPulse)}
                        style={{
                          opacity: 1,
                          zIndex: 10000,
                          width: size * iconScale,
                          height: size * iconScale,
                        }}
                        fill={iconColor}
                        color={iconColor}
                      />
                    )}
                  </div>
                </div>
                {badge && (
                  <div
                    className={cx(withBadgePulse && classes.withBadgePulse)}
                    style={{
                      position: 'absolute',
                      bottom: '85%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}>
                    {badge}
                  </div>
                )}

                {(title || description) && (
                  <div
                    className={classes.textContainer}
                    style={{ width: width }}>
                    {description && (
                      <Typography
                        noWrap
                        sx={{
                          backgroundColor:
                            textBackground ||
                            (theme.palette.mode === 'light'
                              ? '#edeef4'
                              : theme.palette.background.paper),
                        }}
                        fontSize={fontSize}
                        variant='caption'
                        color='text.secondary'>
                        {description}
                      </Typography>
                    )}
                    {title && (
                      <Typography
                        textAlign={'center'}
                        variant='body1'
                        sx={{
                          backgroundColor:
                            textBackground ||
                            (theme.palette.mode === 'light'
                              ? '#edeef4'
                              : theme.palette.background.paper),
                        }}
                        fontSize={fontSize}
                        color='text.primary'>
                        {title}
                      </Typography>
                    )}
                  </div>
                )}
              </Paper>
            </IconButton>
          </Box>
        </NodeTooltipAwayListener>

        <Handle
          type='source'
          style={{ visibility: 'hidden' }}
          position={Position.Right}
          isConnectable={isConnectable}
        />
      </animated.div>
    </div>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  tooltip: {
    minWidth: 200,
    maxWidth: 600,
  },
  textContainer: {
    position: 'absolute',
    top: '103%',
    marginTop: 1,
    width: 75,
    display: 'flex',
    borderRadius: 10,
    pointerEvents: 'all',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',

    borderRadius: '50%',
    borderColor: 'red',
    marginBottom: 2,
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    top: '5%',
    left: '90%',
  },
  moreIcon: {
    position: 'absolute',
    bottom: '5%',
    left: '90%',
  },
  withBadgePulse: {
    animation: 'pulsate-badge 3s infinite',

    '@keyframes pulsate-badge': {
      '0%': {
        transform: 'translateX(-50%) scale(1)',
      },
      '50%': {
        transform: 'translateX(-50%) scale(1.2)',
      },
      '100%': {
        transform: 'translateX(-50%) scale(1)',
      },
    },
  },
  withPulse: {
    animation: 'pulsate 3s infinite',

    '@keyframes pulsate': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.3)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  },
  expandButton: {
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 8,
    textTransform: 'none',
    position: 'absolute',
    height: 20,
    left: '50%',
    top: -20,
    margin: 0,
    padding: 0,
    transform: 'translateX(-50%)',
  },
}));
