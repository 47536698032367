import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { GetGeneralSettingsResponse } from '../../types/settings-types';
import query from './get-general-settings.gql';

export const getGeneralSettings = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      settingsSubType: string,
      cache: boolean = true,
    ): Promise<GetGeneralSettingsResponse> => {
      const errorMessage = 'Failed to get general settings';
      try {
        const results = await client.query<{
          getGeneralSettings: GetGeneralSettingsResponse;
        }>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
          variables: {
            settingsSubType,
          },
        });
        if (!results.data) {
          logger.error(errorMessage);
          throw new Error(errorMessage);
        }
        return results.data.getGeneralSettings;
      } catch (error) {
        logger.error(errorMessage, error);
        throw error;
      }
    },
  };
};
