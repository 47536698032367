import { createStore } from 'store-utils';

export const fileDownloadStore = createStore<FileDownloadStore>(
  {
    downloadProgress: 0,
    issuesCsvDownloadInProgress: false,
    toolsIssuesCsvDownloadInProgress: false,
    sbomCsvDownloadInProgress: false,
    appsCsvDownloadInProgress: false,
    apiItemsCsvDownloadInProgress: false,
    saasBomItemsCsvDownloadInProgress: false,
    cloudBomItemsCsvDownloadInProgress: false,
  },
  'File Download store',
);

interface FileDownloadStore {
  downloadProgress: number;
  issuesCsvDownloadInProgress: boolean;
  toolsIssuesCsvDownloadInProgress: boolean;
  sbomCsvDownloadInProgress: boolean;
  appsCsvDownloadInProgress: boolean;
  apiItemsCsvDownloadInProgress: boolean;
  saasBomItemsCsvDownloadInProgress: boolean;
  cloudBomItemsCsvDownloadInProgress: boolean;
}
