import { datadogLogs } from '@datadog/browser-logs';
import { AppPages, navigate } from 'app-navigator';
import {
  HubSpotFormsIds,
  getHubSpotFieldForSourceControlRepos,
  submitHubSpotForm,
} from 'hubspot';
import { logger } from 'logging-utils';
import React from 'react';
import { snapshot } from 'valtio';
import {
  findConnectorById,
  loadConnectorResources,
  monitorConnectorResources,
} from '../../connectors/api/connectors-api';
import { scanAll } from '../../new-scan/actions/scan-actions';
import { ControlObject, NodeTypes, RepoNode } from '../onboarding-types';
import {
  setChosenCodeRepo,
  setOnboardingError,
} from '../store-actions/onboarding-store-actions';
import {
  overrideEntireRepoSelectionStore,
  resetRepoSelectionStore,
  setFilteredRepoTreeData,
  setIsChooseAllSelected,
  setIsLoading,
  setMonitorAllNewlyCreatedResources,
  setRepoTreeData,
  setSearchValue,
} from '../store-actions/repo-selection-store-actions';
import RepoSelectionStore, {
  RepoSelectionCacheStore,
} from '../stores/onboarding-repo-selection-store';
import OnboardingStore from '../stores/onboarding-store';
import {
  createInputArray,
  filterTreeData,
  selectRecursively,
} from '../onboarding-utils';
import { openSnackbar } from 'snackbar-utils';
import {
  closeConnectorConfigureModal,
  closeRepoSelectionModal,
} from '../../connectors/actions/connector-configure-actions';
import ConnectorConfigureStore from '../../connectors/stores/connector-configure-store';
import {
  setIsConfirmationOpen,
  setIsStartNewScanModalOpen,
} from '../../connectors/store-actions/connector-config-form-store-action';
import { Nullable } from 'ox-common-types';
import { setIsOnboardingRequired } from '../../organizations/api/organizations-api';
import { cloneDeep, omit } from 'lodash-es';

export const createAppSelectionControlV2 = (
  treeData: RepoNode[],
  level = 0,
  withControlObject = false,
): RepoNode[] => {
  return treeData.map(node => {
    let displayId =
      node.resourceType === NodeTypes.Node
        ? node.id + node.resourceType
        : node.id;
    if (withControlObject) {
      setControlObject(
        displayId,
        node.isMonitored,
        level === 0,
        node.resourceType,
      );
    }
    return {
      ...node,
      displayId,
      children: createAppSelectionControlV2(
        node.children,
        0,
        withControlObject,
      ),
    };
  });
};

export const createAppSelectionControl = (treeData: RepoNode[], level = 0) => {
  treeData.forEach(node => {
    node.displayId =
      node.resourceType === NodeTypes.Node
        ? node.id + node.resourceType
        : node.id;
    setControlObject(
      node.displayId,
      node.isMonitored,
      level === 0,
      node.resourceType,
    );
    node.children?.length && createAppSelectionControl(node.children);
  });
};

export const handleChooseAll = (isSelected: boolean) => {
  setIsChooseAllSelected(isSelected);
  const { controlObject } = snapshot(RepoSelectionStore);
  const newControlObject = {};
  Object.keys(controlObject).forEach(id => {
    newControlObject[id] = {
      ...controlObject[id],
      selected: isSelected,
    };
  });
  RepoSelectionStore.controlObject = newControlObject;
};

export const initRepoSelectionStep = async (
  selectedConnectorId: string,
  disableCache?: boolean,
  useSavedData?: boolean,
  resourceType?: string,
  credentialsId?: string,
  enableMultiTokens?: boolean,
) => {
  const cachedRepoSelection = snapshot(RepoSelectionCacheStore);
  if (cachedRepoSelection[selectedConnectorId] && !enableMultiTokens) {
    overrideEntireRepoSelectionStore(cachedRepoSelection[selectedConnectorId]);
  } else {
    const res = await loadConnectorResources(
      {
        connectorID: selectedConnectorId,
        resourceType,
        credentialsId,
      },
      disableCache,
      enableMultiTokens,
    );
    if (!res) {
      datadogLogs.logger.info(
        `Loading connector resources failed, setting an empty array`,
      );
      setRepoTreeData([]);
      setFilteredRepoTreeData([]);
      return;
    }
    const { resources, monitorAllNewlyCreatedResources } = res;
    if (resources) {
      datadogLogs.logger.info(`Loading connector resources was successful`);
      if (enableMultiTokens) {
        const {
          controlObjectByCredentials,
          monitorAllNewlyCreatedResourcesByCredentials,
        } = snapshot(RepoSelectionStore);
        const obj = controlObjectByCredentials[credentialsId as string];
        if (obj) {
          RepoSelectionStore.controlObject = cloneDeep(obj);
        }
        const updatedResources = createAppSelectionControlV2(
          resources,
          0,
          !obj,
        );
        setRepoTreeData(updatedResources);
        setFilteredRepoTreeData(updatedResources);
        setMonitorAllNewlyCreatedResources(
          monitorAllNewlyCreatedResourcesByCredentials[
            credentialsId as string
          ] || !!monitorAllNewlyCreatedResources,
        );
      } else {
        createAppSelectionControl(resources);
        setRepoTreeData(resources);
        setFilteredRepoTreeData(resources);
        setMonitorAllNewlyCreatedResources(!!monitorAllNewlyCreatedResources);
      }
      if (!useSavedData) {
        setIsChooseAllSelected(true);
      }
    }
  }
};

export const searchRepos = (searchString: string) => {
  const { repoTreeData } = snapshot(RepoSelectionStore);
  setSearchValue(searchString);
  if (!repoTreeData) {
    return;
  }
  if (!searchString) {
    setFilteredRepoTreeData(repoTreeData);
    return;
  }
  const filtered = filterTreeData(repoTreeData, searchString);
  expandAll();
  setFilteredRepoTreeData(filtered);
};

export const handleContinue = async (isMultiToken: boolean) => {
  const { wereChangesMade } = snapshot(RepoSelectionStore);
  const { selectedConnector, activeCredentialsId } = snapshot(
    ConnectorConfigureStore,
  );
  setIsLoading(true);
  if (!selectedConnector) {
    openSnackbar('Oops! Could not complete operation, please try again.', {
      variant: 'error',
    });
    closeRepoSelectionModal();
    return;
  }
  const response = await onContinueClick(
    null,
    selectedConnector.id,
    true,
    isMultiToken ? activeCredentialsId : undefined,
  );

  if (!response) {
    openSnackbar('Oops! Could not complete operation, please try again.');
    return;
  }
  openSnackbar('Saved successfully', { variant: 'success' });

  if (wereChangesMade) {
    setIsStartNewScanModalOpen(true);
    closeConnectorConfigureModal();
  }
  setIsLoading(false);
  setIsConfirmationOpen(false);
  closeRepoSelectionModal();
  resetRepoSelectionStore(selectedConnector.id, true);
};

export const onOnboardingContinueClick = async (
  e: Nullable<React.MouseEvent<HTMLButtonElement, MouseEvent>>,
  connectorId?: string,
  skipDiscovery?: boolean,
  credentialsId?: string,
) => {
  setIsOnboardingRequired(false);
  onContinueClick(e, connectorId, skipDiscovery, credentialsId);
};

export const onContinueClick = async (
  e: Nullable<React.MouseEvent<HTMLButtonElement, MouseEvent>>,
  connectorId?: string,
  skipDiscovery?: boolean,
  credentialsId?: string,
) => {
  const {
    repoTreeData,
    controlObject,
    isChooseAllSelected,
    monitorAllNewlyCreatedResources,
    checkedReposUtils: { checkedReposNumber, reposFound },
  } = snapshot(RepoSelectionStore);

  const { chosenCodeRepo: chosenConnector } = snapshot(OnboardingStore);
  const chosenCodeRepoId = chosenConnector?.id || connectorId;
  if (!chosenCodeRepoId || !controlObject) {
    setOnboardingError(
      true,
      'Oops! We encountered an error.. Please refresh the page',
    );
    datadogLogs.logger.error(
      'Onboarding: repo selection continue failed due to no chosenCodeRepo or repoCheckboxControlObj',
    );
    return;
  }
  datadogLogs.logger.info(
    `Onboarding: user selected ${checkedReposNumber} repos out of ${reposFound}`,
    { reposFound, repoSelected: checkedReposNumber },
  );
  const resources = createInputArray(repoTreeData!, controlObject);
  const response = await monitorConnectorResources({
    connectorID: chosenCodeRepoId,
    monitorAllResources: isChooseAllSelected || undefined,
    monitorAllNewlyCreatedResources: monitorAllNewlyCreatedResources
      ? new Date().getTime()
      : undefined,
    resources: !isChooseAllSelected ? resources : null,
    resourceCount: resources.length,
    credentialsId: credentialsId, // TODO: UNCOMMENT THIS FOR ONBOARDING MULTI TOKEN SUPPORT --> || chosenConnector?.credentials[0].credentialsId,
  });

  if (!response) {
    setOnboardingError(
      true,
      'Oops! We encountered an error.. Please refresh the page',
    );
    datadogLogs.logger.error(
      'Failed to set selected repos, received an invalid response',
    );
    return;
  }
  if (credentialsId) {
    setControlObjectByCredentials(credentialsId, cloneDeep(controlObject));
    setMonitorAllNewlyCreatedResourcesByCredentials(
      credentialsId,
      monitorAllNewlyCreatedResources,
    );
  }
  datadogLogs.logger.info('Onboarding: Successfully selected repos to monitor');
  if (!skipDiscovery) {
    scanAll();
    navigate(AppPages.Dashboard);
  }
  const connector = chosenConnector || findConnectorById(chosenCodeRepoId);
  if (connector) {
    const repoCountField = getHubSpotFieldForSourceControlRepos(
      connector.displayName,
    );
    if (!repoCountField) {
      logger.error(`Could not find repoCountField of ${connector.displayName}`);
    } else {
      submitHubSpotForm(
        {
          [repoCountField]: `${checkedReposNumber} / ${reposFound}`,
        },
        HubSpotFormsIds.OxAppForm,
      );
    }
  }
  if (chosenConnector) {
    setChosenCodeRepo(null);
  }
  return response;
};

export const toggleExpand = (id: string) => {
  const { controlObject } = snapshot(RepoSelectionStore);
  const copy = { ...controlObject[id] };
  RepoSelectionStore.controlObject[id] = {
    ...copy,
    expanded: !controlObject[id]?.expanded,
  };
};

export const toggleSelection = (
  id: string,
  value: boolean,
  children: RepoNode[],
) => {
  const { controlObject } = snapshot(RepoSelectionStore);
  let newObject = { ...controlObject };
  newObject = selectRecursively(id, value, children, newObject);
  RepoSelectionStore.controlObject = newObject;
  RepoSelectionStore.wereChangesMade = true;
};

export const toggleSingleSelection = (id: string, value: boolean) => {
  const { controlObject } = snapshot(RepoSelectionStore);
  const copy = { ...controlObject[id] };
  RepoSelectionStore.controlObject[id] = {
    ...copy,
    selected: value,
  };
};

export const expandAll = () => {
  const { controlObject } = snapshot(RepoSelectionStore);
  const newObject = {};
  Object.keys(newObject).forEach(id => {
    newObject[id] = {
      ...controlObject[id],
      expanded: true,
    };
  });
};

export const setControlObjectByCredentials = (
  credentialsId: string,
  controlObject: ControlObject,
) => {
  if (!RepoSelectionStore.controlObjectByCredentials[credentialsId]) {
    RepoSelectionStore.controlObjectByCredentials[credentialsId] = {};
  }
  RepoSelectionStore.controlObjectByCredentials[credentialsId] =
    cloneDeep(controlObject);
};

export const setMonitorAllNewlyCreatedResourcesByCredentials = (
  credentialsId: string,
  value: boolean,
) => {
  RepoSelectionStore.monitorAllNewlyCreatedResourcesByCredentials[
    credentialsId
  ] = value;
};

export const removeMonitorAllNewlyCreatedResourcesByCredentials = (
  credentialsId: string,
) => {
  if (
    RepoSelectionStore.monitorAllNewlyCreatedResourcesByCredentials[
      credentialsId
    ]
  ) {
    RepoSelectionStore.monitorAllNewlyCreatedResourcesByCredentials = omit(
      RepoSelectionStore.monitorAllNewlyCreatedResourcesByCredentials,
      credentialsId,
    );
  }
};

export const removeControlObjectByCredential = (credentialsId: string) => {
  if (RepoSelectionStore.controlObjectByCredentials[credentialsId]) {
    RepoSelectionStore.controlObjectByCredentials = omit(
      RepoSelectionStore.controlObjectByCredentials,
      credentialsId,
    );
  }
};

export const setControlObject = (
  id: string,
  selected: boolean,
  expanded: boolean,
  nodeType: NodeTypes,
) => {
  RepoSelectionStore.controlObject[id] = {
    selected,
    expanded,
    nodeType,
  };
  RepoSelectionStore.originalControlState[id] = {
    selected,
    expanded,
    nodeType,
  };
};
