import SLASettingsStore from '../stores/sla-settings-store';
import { SLAItem } from '../types/settings-types';

const getSLASettingsPerSeverityItem = (severityId: string) => {
  return SLASettingsStore.severitySLA?.settings?.find(
    item => item.id === severityId,
  );
};

const setIsSLABySeveritySettingsEnabled = (isEnabled: boolean) => {
  SLASettingsStore.severitySLA.isEnabled = isEnabled;
};

const setSLASettingsPerSeverityEnabled = (
  severityId: string,
  isEnabled: boolean,
) => {
  const slaSettingsPerSeverityItem = getSLASettingsPerSeverityItem(severityId);
  if (slaSettingsPerSeverityItem) {
    slaSettingsPerSeverityItem.isEnabled = isEnabled;
  }
};

const setSLAPerSeverityValue = (severityId: string, value: number) => {
  const slaSettingsPerSeverityItem = getSLASettingsPerSeverityItem(severityId);
  if (slaSettingsPerSeverityItem) {
    slaSettingsPerSeverityItem.value = value;
  }
};

const setNearExceedingSLA = (value: number) => {
  SLASettingsStore.nearExceedingSLA = value;
};

const setSLAPerSeveritySettings = (data: SLAItem[]) => {
  if (SLASettingsStore.severitySLA) {
    SLASettingsStore.severitySLA.settings = data;
  }
};

const setIsLoadingGetSettings = (isLoading: boolean) => {
  SLASettingsStore.isLoadingGetSettings = isLoading;
};

const setIsLoadingSaveSettings = (isLoading: boolean) => {
  SLASettingsStore.isLoadingSaveSettings = isLoading;
};

const setIsErrorGetSettings = (isError: boolean) => {
  SLASettingsStore.isErrorGetSettings = isError;
};

export const SLASettingsStoreActions = {
  setIsSLABySeveritySettingsEnabled,
  setSLASettingsPerSeverityEnabled,
  setNearExceedingSLA,
  setSLAPerSeverityValue,
  setSLAPerSeveritySettings,
  setIsLoadingGetSettings,
  setIsLoadingSaveSettings,
  setIsErrorGetSettings,
};
