import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { SetConfigurationInput } from '../request-access-types';
import requestAccessService from '../services';
import {
  setIsLoading,
  setRequestAccessConfiguration,
} from '../store-actions/request-access-settings-store-actions';
import RequestAccessSettingsStore from '../stores/request-access-settings-store';

export const getRequestAccessConfiguration = async () => {
  setIsLoading(true);

  const response =
    await requestAccessService.getRequestAccessConfiguration.execute();

  setRequestAccessConfiguration(response);
  setIsLoading(false);
};

export const setAllowUserToRequestAccess = async (allow: boolean) => {
  const { requestAccessConfiguration } = snapshot(RequestAccessSettingsStore);
  if (!requestAccessConfiguration) {
    return;
  }

  const input: SetConfigurationInput = { allow };

  const response =
    await requestAccessService.setAllowUserToRequestAccess.execute(input);

  if (!response || !response.updateSuccess) {
    openSnackbar(`Failed to set allow user request access`, {
      variant: 'error',
    });
    return;
  }

  const message = allow
    ? 'Users are now allowed to request access'
    : 'Users are now not allowed to request access';
  openSnackbar(message, {
    variant: 'success',
  });

  setRequestAccessConfiguration({
    ...requestAccessConfiguration,
    allowUserToRequestAccess: allow,
  });

  if (!allow && !requestAccessConfiguration.allowUserToCreateOrg) {
    setAllowUserToCreateOrg(true);
  }
};

export const setAllowUserToCreateOrg = async (allow: boolean) => {
  const { requestAccessConfiguration } = snapshot(RequestAccessSettingsStore);
  if (!requestAccessConfiguration) {
    return;
  }

  const input: SetConfigurationInput = { allow };

  const response = await requestAccessService.setAllowUserToCreateOrg.execute(
    input,
  );

  if (!response || !response.updateSuccess) {
    openSnackbar(`Failed to set allow user to create org`, {
      variant: 'error',
    });
    return;
  }

  const message = allow
    ? 'Users are now allowed to create organizations'
    : 'Users are now not allowed to create organizations';
  openSnackbar(message, {
    variant: 'success',
  });

  setRequestAccessConfiguration({
    ...requestAccessConfiguration,
    allowUserToCreateOrg: allow,
  });
};
