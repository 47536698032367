import { AdditionalInfo } from '@oxappsec/policy-workflow-service';

export const enum PolicyWorkflowOption {
  Delete = 'Delete',
  Reset = 'Reset',
  Edit = 'Edit',
  // Split = 'Split',
  Copy = 'Duplicate',
  Execute = 'Execute',
}
export const enum WorkflowNodeType {
  Action = 'Action',
  Condition = 'Condition',
  Trigger = 'Trigger',
  Pipeline = 'Pipeline',
}

export enum ConditionType {
  Severity = 'Severity',
  Language = 'Language',
  PII = 'PII',
  Compiliance = 'Compiliance',
  PRAvailable = 'PR Available',
  PipelineDetection = 'Pipeline Detection',
}

export const conditions = {
  [ConditionType.Severity]: [
    'Appoxalypse',
    'Critical',
    'High',
    'Medium',
    'Low',
  ],
  [ConditionType.Language]: [
    'Java',
    'Python',
    'JavaScript',
    'Golang',
    'Ruby',
    'C#',
    'C++',
  ],
  [ConditionType.PII]: ['True', 'False'],
  [ConditionType.PipelineDetection]: [
    'Pipeline Detection',
    'Schedule Detection',
  ],
  [ConditionType.Compiliance]: ['SOC2'],
  [ConditionType.PRAvailable]: ['True', 'False'],
};

export interface WorkflowNode {
  label: string;
  selected: boolean;
  tooltip: string;
  type: string;
  additionalInfo?: AdditionalInfo;
  typeId?: string;
  valid?: boolean;
  hasParent?: boolean;
  hasChildren?: boolean;
}

export interface ToolboxData {
  name: string;
  defaultOpen?: boolean;
  children: ToolboxDataItem[];
}

export interface ToolboxDataItem {
  name: string;
  id: string;
  category?: string;
  disabled?: boolean;
  disconnected?: boolean;
  connectionTooltip?: string;
}

export type KeyValueField = {
  key: string;
  value: string;
  id?: string;
};

export interface ActionParameters {
  fieldName: string;
  value: string;
}
